import React, { useEffect } from 'react';
import { Box, Typography, Button, InputBase, FormHelperText } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UserAttributeBackend, CampaignBackend } from 'types';
import { getAttributeValueCampaigns } from './../audienceUtils';
import DeleteTooltip from './../audienceDeleteTooltip';
import { pluralise } from 'utils/pluralise';
import CustomTooltip from 'components/customTooltip';
import PenIcon from '@material-ui/icons/CreateRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    fixedTextContainer: {
        '& > div:first-child': {
            paddingTop: 0,
        },
        '& > div:last-child': {
            paddingBottom: 0,
            borderBottom: 'none',
        },
    },
    fixedTextRows: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.grey}`,
        padding: '12px 0',
    },
    attribute: {
        width: '100%',
        height: 48,
        paddingLeft: 17,
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
    },
    inputBaseRoot: {
        padding: '14px 16px',
        fontSize: 16,
        lineHeight: 24,
        borderRadius: 4,
        border: `1px solid ${colors.coolGrey}`,
        '&.error': {
            borderColor: colors.red,
        },
    },
    focused: {
        borderColor: colors.blue,
    },
}));

const DeleteDisabledButton = () => {
    return (
        <Box style={{ color: colors.darkGrey }}>
            <DeleteRoundedIcon style={{ marginLeft: 12, cursor: 'pointer' }} color={'inherit'} />
        </Box>
    );
};

interface FixedTextFormRowProps {
    index: number;
    attributeValue: string;
    attributeValues: string[];
    attributeValueCampaigns: CampaignBackend[];
    showUsage: (index: number) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    onDelete: (index: number) => void;
    setAttributeUsageType: (value: AttributeUsage) => void;
    setDeleteValue: (value: string) => void;
}

const FixedTextFormRow = ({
    index,
    attributeValue,
    attributeValues,
    attributeValueCampaigns,
    showUsage,
    onChange,
    onDelete,
    setAttributeUsageType,
    setDeleteValue,
}: FixedTextFormRowProps) => {
    const classes = useStyles();
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [inputError, setInputError] = React.useState<string>('');

    useEffect(() => {
        if (!attributeValue) {
            setIsEdit(true);
        }
    }, [attributeValue]);

    const optionExists = (value: string) => {
        return attributeValues.find((item, i) => i !== index && item.toLowerCase() === value.toLowerCase());
    };

    const handleOnBlur = (event: React.SyntheticEvent, index: number) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.target as HTMLInputElement;
        if (!target.value) {
            setInputError('This field cannot be empty');
        } else if (optionExists(target.value)) {
            setInputError('This option already exists');
        } else {
            setIsEdit(false);
            setInputError('');
        }
    };

    return (
        <Box className={classes.fixedTextRows}>
            <Box width={'50%'} display={'flex'} alignItems={'left'} flexDirection={'column'}>
                <Box width={'100%'} display={'flex'} alignItems={'center'}>
                    {isEdit ? (
                        <Box width={'100%'} display={'flex'} alignItems={'left'} flexDirection={'column'}>
                            <InputBase
                                classes={{ root: classes.inputBaseRoot, focused: classes.focused }}
                                className={inputError && !attributeValue ? 'error' : ''}
                                value={attributeValue}
                                autoFocus={true}
                                name={`name-${index}`}
                                inputComponent={'input'}
                                autoComplete="attributeName"
                                fullWidth={true}
                                placeholder={'Enter pre-set value'}
                                onBlur={(e) => handleOnBlur(e, index)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, index)}
                            />
                        </Box>
                    ) : (
                        <Typography variant="body1" className={classes.attribute}>
                            {attributeValue}
                        </Typography>
                    )}
                    {attributeValueCampaigns.length ? (
                        <CustomTooltip
                            title={
                                <DeleteTooltip
                                    title={`This value cannot be edited as it is being used to personalise ${
                                        attributeValueCampaigns.length
                                    } campaign ${pluralise(attributeValueCampaigns.length, 'workflow')}`}
                                    onClick={() => {
                                        setDeleteValue(attributeValue);
                                        setAttributeUsageType('value');
                                        showUsage(index);
                                    }}
                                />
                            }
                            placement={'right'}
                            arrow={true}
                            interactive={true}
                        >
                            <Box style={{ color: colors.darkGrey }}>
                                <PenIcon style={{ marginLeft: 19, cursor: 'pointer' }} color={'inherit'} />
                            </Box>
                        </CustomTooltip>
                    ) : (
                        <PenIcon
                            style={{ marginLeft: 19, cursor: 'pointer' }}
                            color={'primary'}
                            onClick={() => setIsEdit(true)}
                        />
                    )}

                    {attributeValueCampaigns.length ? (
                        <CustomTooltip
                            title={
                                <DeleteTooltip
                                    title={`This value cannot be deleted as it is being used to personalise ${
                                        attributeValueCampaigns.length
                                    } campaign ${pluralise(attributeValueCampaigns.length, 'workflow')}`}
                                    onClick={() => {
                                        setDeleteValue(attributeValue);
                                        setAttributeUsageType('value');
                                        showUsage(index);
                                    }}
                                />
                            }
                            placement={'right'}
                            arrow={true}
                            interactive={true}
                        >
                            <DeleteDisabledButton />
                        </CustomTooltip>
                    ) : (
                        <>
                            {attributeValues.length === 1 ? (
                                <CustomTooltip
                                    title={'This value cannot be deleted as you need at least one value'}
                                    placement="right"
                                    arrow
                                >
                                    <DeleteDisabledButton />
                                </CustomTooltip>
                            ) : (
                                <DeleteRoundedIcon
                                    style={{ marginLeft: 12, cursor: 'pointer' }}
                                    color={'primary'}
                                    onClick={() => onDelete(index)}
                                />
                            )}
                        </>
                    )}
                </Box>
                {!attributeValue && inputError !== '' && isEdit && (
                    <FormHelperText error style={{ marginTop: 5 }}>
                        {inputError}
                    </FormHelperText>
                )}
            </Box>
        </Box>
    );
};

type AttributeUsage = 'attribute' | 'value';
interface AttributeFixedTextProps {
    selectedAttribute: UserAttributeBackend;
    campaigns: CampaignBackend[];
    setAttributeValueCampaigns: (campaigns: CampaignBackend[]) => void;
    setShowUsage: (isShow: boolean) => void;
    setAttributeUsageType: (value: AttributeUsage) => void;
    setSelectedAttribute: (payload: UserAttributeBackend) => void;
    setDeleteValue: (value: string) => void;
    attributes: UserAttributeBackend[];
}

export const AttributeFixedText = ({
    selectedAttribute,
    campaigns,
    setAttributeValueCampaigns,
    setShowUsage,
    setAttributeUsageType,
    setSelectedAttribute,
    setDeleteValue,
    attributes,
}: AttributeFixedTextProps) => {
    const classes = useStyles();
    const [attributeValues, setAttributeValues] = React.useState<string[]>([]);
    const { audienceId } = useParams<{ audienceId: string }>();

    useEffect(() => {
        setAttributeValues([...selectedAttribute.values!]);
    }, [selectedAttribute]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        attributeValues[index] = e.target.value;
        setSelectedAttribute({ ...selectedAttribute, values: [...attributeValues] });
    };

    const handleOnDelete = (index: number) => {
        if (attributeValues.length > 1) {
            const values = [...attributeValues.slice(0, index), ...attributeValues.slice(index + 1)];
            setSelectedAttribute({ ...selectedAttribute, values: [...values] });
        }
    };

    const addRow = () => {
        const found = attributeValues.some((attribute) => !attribute);
        if (!found) {
            setSelectedAttribute({ ...selectedAttribute, values: [...selectedAttribute.values!, ''] });
        }
    };

    const handleShowUsage = (index: number) => {
        setAttributeValueCampaigns(
            getAttributeValueCampaigns(campaigns, audienceId, selectedAttribute, attributeValues[index]),
        );
        setShowUsage(true);
    };

    const getAttributeValue = (index: number) => {
        const attribute = attributes.find((a) => a.name === selectedAttribute.name);
        return attribute && attribute.values ? attribute?.values[index] : '';
    };

    return (
        <Box>
            <Box display={'flex'} flexDirection={'row'} mb={1} alignItems={'center'}>
                <Typography variant={'body2'}>Pre-set values</Typography>
                <CustomTooltip
                    title={'Pre-determine the available data values for this attribute'}
                    placement="right"
                    icon={'help'}
                    arrow
                />
            </Box>
            <Box className={classes.fixedTextContainer}>
                {attributeValues.map((value, index) => {
                    return (
                        <FixedTextFormRow
                            attributeValue={value}
                            key={index}
                            index={index}
                            onChange={handleOnChange}
                            onDelete={handleOnDelete}
                            setAttributeUsageType={setAttributeUsageType}
                            showUsage={handleShowUsage}
                            attributeValueCampaigns={getAttributeValueCampaigns(
                                campaigns,
                                audienceId,
                                selectedAttribute,
                                getAttributeValue(index),
                            )}
                            attributeValues={attributeValues}
                            setDeleteValue={setDeleteValue}
                        />
                    );
                })}
            </Box>
            <Box display={'flex'} mt={2} mb={4}>
                <Button onClick={addRow} color={'primary'} style={{ fontSize: 14 }}>
                    <AddCircleOutlineRoundedIcon color={'primary'} style={{ marginRight: 10, fontSize: 16 }} />
                    Add value
                </Button>
            </Box>
        </Box>
    );
};

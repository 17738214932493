import React, { useEffect, useState } from 'react';
import datetime from 'utils/datetime';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';
import zIndex from 'common/constants/zIndex';

const useStyles = makeStyles(() => ({
    postContainer: {
        position: 'absolute',
        bottom: 0,
        padding: '8px 24px',
        backgroundColor: colors.lighterGrey,
        color: colors.darkGrey,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: `1px solid ${colors.grey}`,
        zIndex: zIndex.footer,
        height: 38,
    },
    preContainer: {
        textAlign: 'center',
        marginTop: 32,
        zIndex: zIndex.footer,
    },
    label: {
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: colors.darkGrey,
    },
}));

type placementType = 'preLogin' | 'postLogin';

interface CopyRightProps {
    placement: placementType;
}

const CopyRight = ({ placement }: CopyRightProps) => {
    const classes = useStyles();
    const [lastUpdatedDate, setLastUpdatedDate] = useState<string>('');
    const [lastUpdatedYear, setLastUpdatedYear] = useState<string>('');

    useEffect(() => {
        const unixTimestamp = process.env.REACT_APP_BUILD_TIME || Date.now() / 1000;
        if (unixTimestamp) {
            const unixDate = new Date(+unixTimestamp * 1000);
            setLastUpdatedDate(datetime.toFormatDate(unixDate, 'dd MMM YYY'));
            setLastUpdatedYear(unixDate.getFullYear().toString());
        }
    }, []);

    const ReportVulnerabilityLink = () => {
        return (
            <a
                style={{ color: colors.darkGrey }}
                href={'https://www.tech.gov.sg/report_vulnerability'}
                rel="noopener noreferrer"
                target={'_blank'}
            >
                <Typography className={classes.label}>Report Vulnerability</Typography>
            </a>
        );
    };

    return (
        <div className={placement === 'preLogin' ? classes.preContainer : classes.postContainer}>
            <Typography className={classes.label}>
                © {lastUpdatedYear} Government of Singapore. Last Updated {lastUpdatedDate}
            </Typography>
            <ReportVulnerabilityLink />
        </div>
    );
};

export default CopyRight;

import React, { useState, useEffect } from 'react';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { ApiResponse } from 'apisauce';
import { Box, Typography, Button, FormControl, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Footer from './footer';
import colors from 'colors';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import SelectImportType from './import/selectImportType';
import { Audience, EApiIssue, Team } from 'types';
import RadioSupportingLabel from 'components/radioSupportingLabel';
import BaseHeader from 'components/baseHeader';
import { getPersonalAudiences, getTeamAudiences } from './audienceSlice';
import { updateDashboardSelected, updateApiIssue } from 'reducers/userSlice';
import { getTeams } from 'features/team/teamSlice';
import { isTeamValid } from 'features/team/teamUtils';
import { isAudienceValid } from './audienceUtils';
import api from 'api';

export enum ImportType {
    EMAIL = 'EMAIL',
    CSV = 'CSV',
}

interface NewContactsProps {
    audiences: Audience[];
    teams: Team[];
    getPersonalAudiences: () => void;
    getTeamAudiences: (teamId: string) => void;
    selectedTeamId?: string;
    updateDashboardSelected: (dashboard: string) => void;
    getTeams: () => void;
    updateApiIssue: (apiIssue: EApiIssue) => void;
}

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        background: colors.white,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    innerContainer: {
        margin: '40px 32px',
    },
    input: {
        width: '100%',
        marginBottom: 45,
        '& input': {
            padding: '12px 16px',
        },
    },
}));

const NewContacts = ({
    audiences,
    teams,
    getPersonalAudiences,
    getTeamAudiences,
    updateDashboardSelected,
    getTeams,
    updateApiIssue,
}: NewContactsProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [importType, setImportType] = useState<ImportType>(ImportType.CSV);
    const [showSteps, setShowSteps] = useState<boolean>(true); // TODO: change to false when import by email is implemented
    const [audienceName, setAudienceName] = useState<string>('');
    const { audienceId, teamId = '' } = useParams<{ audienceId: string; teamId: string }>();
    const isTeamAudience = Boolean(teamId);
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const campaignSource = query.get('campaignSource');

    useEffect(() => {
        getTeams();
    }, [teamId]);

    useEffect(() => {
        if (isTeamAudience && isTeamValid(teamId, teams)) {
            updateDashboardSelected(teamId);
            getTeamAudiences(teamId);
        } else if (!isTeamAudience) {
            updateDashboardSelected('personal');
            getPersonalAudiences();
        }
    }, [teamId, teams]);

    useEffect(() => {
        if (audiences) {
            const currentAudience = audiences.find((audience) => audience.id === audienceId);
            if (currentAudience) setAudienceName(currentAudience.name);
        }
    }, [audiences, audienceId]);

    useEffect(() => {
        if (audienceId) {
            if (teamId) {
                api.getTeamAudiences(teamId).then((response: ApiResponse<any>) => {
                    if (response.ok && !isAudienceValid(audienceId, response.data)) {
                        updateApiIssue(EApiIssue.NOT_FOUND_404);
                    }
                });
            } else {
                api.getPersonalAudiences().then((response: ApiResponse<any>) => {
                    if (response.ok && !isAudienceValid(audienceId, response.data)) {
                        updateApiIssue(EApiIssue.NOT_FOUND_404);
                    }
                });
            }
        }
        if (campaignSource) {
            api.getCampaignById(campaignSource).then((response: ApiResponse<any>) => {
                if (response.status === 404) updateApiIssue(EApiIssue.NOT_FOUND_404);
            });
        }
    }, [teamId, audienceId]);

    return (
        <div className={classes.container}>
            {!showSteps ? (
                <>
                    <BaseHeader
                        title="Add contacts"
                        backButton
                        buttonLink={() => {
                            history.goBack();
                        }}
                    />
                    <Box className={classes.innerContainer} flexGrow={1}>
                        <Typography style={{ marginBottom: 32 }} variant="h1">
                            Import contacts to {audienceName}
                        </Typography>
                        <div style={{ marginBottom: 32 }}>Select where you would like to import your contacts from</div>
                        <FormControl component="fieldset">
                            <RadioGroup
                                value={importType}
                                onChange={(event) => {
                                    setImportType(event.target.value as ImportType);
                                }}
                            >
                                <RadioSupportingLabel
                                    value={ImportType.CSV}
                                    label="CSV file"
                                    subLabel="Upload .csv file"
                                />
                                {/* <RadioSupportingLabel
                                        value={ImportType.EMAIL}
                                        label="Email"
                                        subLabel="Send email containing .csv file"
                                    /> */}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Footer
                        right={
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setShowSteps(true);
                                }}
                            >
                                Next
                            </Button>
                        }
                    />
                </>
            ) : (
                <SelectImportType
                    handleClickBack={() => setShowSteps(false)}
                    importType={importType}
                    audiences={audiences}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    audiences: state.audienceSlice.audiences,
    teams: state.teamSlice.teams,
});

const mapDispatchToProps = {
    getPersonalAudiences,
    getTeamAudiences,
    updateDashboardSelected,
    updateApiIssue,
    getTeams,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewContacts);

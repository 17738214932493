import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ICanvasOuterDefaultProps } from '@mrblenny/react-flow-chart';
import colors from 'colors';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        backgroundColor: colors.backgroundGrey,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        cursor: 'not-allowed',
        '& div': {
            outline: 'none !important',
        },
    },
}));

const Canvas = React.forwardRef(function Canvas(
    { children, config, ...otherProps }: ICanvasOuterDefaultProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const classes = useStyles();
    return (
        <div
            ref={ref}
            onClick={(e) => {
                e.stopPropagation();
            }}
            {...otherProps}
            className={classes.container}
        >
            {children}
        </div>
    );
});

export default Canvas;

import React, { ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';

type ConfirmModalProps = {
    open: boolean;
    onClose: () => void;
    confirm: () => void;
    title: string;
    content: string | ReactNode;
    textNo?: string;
    textYes?: string;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    destructive?: boolean;
};

const useStyles = makeStyles(() => ({
    dialog: {
        width: 464,
    },
    destructive: {
        backgroundColor: colors.red,
        '&:hover': {
            backgroundColor: colors.lightRed,
        },
    },
}));

const BUTTON_WIDTH = 122;

const ConfirmModal = ({
    open,
    onClose,
    confirm,
    title,
    content,
    textNo = 'No',
    textYes = 'Yes',
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    destructive = false,
}: ConfirmModalProps) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            classes={{ paper: classes.dialog }}
        >
            <Box px={8} pt={6}>
                <DialogTitle style={{ textAlign: 'center', padding: 0, paddingBottom: 12 }}>{title}</DialogTitle>
                <DialogContent style={{ padding: 0, paddingBottom: 24 }}>
                    <DialogContentText style={{ textAlign: 'center', margin: 0 }}>{content}</DialogContentText>
                </DialogContent>
            </Box>
            <Box px={6} pb={6}>
                <DialogActions style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={() => {
                            onClose();
                        }}
                        color="primary"
                        variant="outlined"
                        style={{ minWidth: BUTTON_WIDTH, padding: '16px 24px' }}
                    >
                        {textNo}
                    </Button>
                    <Button
                        onClick={() => {
                            confirm();
                        }}
                        style={{ marginLeft: 16, minWidth: BUTTON_WIDTH, padding: '16px 24px' }}
                        color="primary"
                        autoFocus
                        variant="contained"
                        className={destructive ? classes.destructive : ''}
                    >
                        {textYes}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ConfirmModal;

import React, { useState, ChangeEvent } from 'react';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/VisibilityRounded';
import EyeOffIcon from '@material-ui/icons/VisibilityOffRounded';
import { isPasswordValid, passwordHelperText, isPasswordEqualEmail } from 'utils/password';

interface PasswordFieldProps {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    password: string;
    showError: boolean;
    serverError?: string;
    email?: string;
}

const PasswordField = ({ onChange, password, showError, serverError, email }: PasswordFieldProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <TextField
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            value={password}
            onChange={onChange}
            error={
                (password && !isPasswordValid(password)) ||
                (showError && (!password || !!serverError)) ||
                (email ? isPasswordEqualEmail(password, email) : false)
            }
            helperText={
                (password && !isPasswordValid(password) && passwordHelperText.complexity) ||
                (showError && ((!password && 'This field cannot be empty') || serverError)) ||
                (email && isPasswordEqualEmail(password, email) && passwordHelperText.email)
            }
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Button
                            aria-label="toggle password visibility"
                            onClick={() => {
                                setShowPassword((showPassword) => !showPassword);
                            }}
                        >
                            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                        </Button>
                    </InputAdornment>
                ),
            }}
            fullWidth
            autoComplete="new-password"
            inputProps={{ 'aria-label': 'password' }}
        />
    );
};

export default PasswordField;

import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, Team } from 'types';
import api from 'api';

interface TeamState {
    teams: Team[];
    createTeamDialog: boolean;
    selectedTeam: Team;
}

const initialTeamState: TeamState = {
    teams: [],
    createTeamDialog: false,
    selectedTeam: {
        id: '',
        name: '',
        createdOn: '',
        updatedOn: '',
        users: [],
    },
};

const teamSlice = createSlice({
    name: 'team',
    initialState: initialTeamState,
    reducers: {
        updateTeams(state, action) {
            state.teams = action.payload;
        },
        updateCreateTeam(state, action) {
            state.teams = [...state.teams, action.payload];
        },
        updateSelectedTeam(state, action) {
            state.selectedTeam = action.payload;
        },
        showCreateTeamDialog(state, action) {
            state.createTeamDialog = action.payload;
        },
        updateTeamList(state, action) {
            state.teams.map((team: Team, index: number) => {
                if (team.id === action.payload.id) {
                    state.teams[index] = action.payload;
                }
            });
        },
        updateTeamNameSuccess(state, action) {
            state.teams.map((team: Team, index: number) => {
                if (team.id === action.payload.id) {
                    state.teams[index] = {
                        ...team,
                        name: action.payload.name,
                        updatedOn: action.payload.lastUpdated,
                    };
                    state.selectedTeam = state.teams[index];
                }
            });
        },
    },
});

export const {
    updateTeams,
    updateCreateTeam,
    updateSelectedTeam,
    updateTeamList,
    updateTeamNameSuccess,
    showCreateTeamDialog,
} = teamSlice.actions;

export const getTeams =
    (selectedTeamId?: string): AppThunk =>
    async (dispatch) => {
        api.getAllTeams().then((response: any) => {
            if (response.status === 200) {
                dispatch(updateTeams(response.data));
                if (!selectedTeamId) {
                    dispatch(updateSelectedTeam((response.data || [])[0]));
                } else {
                    const selectedTeam = response.data.find((t: Team) => t.id === selectedTeamId);
                    if (selectedTeam) dispatch(updateSelectedTeam(selectedTeam));
                }
            }
        });
    };

export const deleteTeam =
    (id: string): AppThunk =>
    async (dispatch) => {
        api.deleteTeam(id).then((response: any) => {
            if (response.status === 204) {
                api.getAllTeams().then((response: any) => {
                    if (response.status === 200) {
                        dispatch(updateTeams(response.data));
                        dispatch(updateSelectedTeam((response.data || [])[0]));
                    }
                });
            }
        });
    };

export default teamSlice.reducer;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';

const useStyles = makeStyles(() => ({
    '@keyframes flash': {
        '0%': { opacity: 1 },
        '49%': { opacity: 1 },
        '50%': { opacity: 0 },
        '100%': { opacity: 0 },
    },
    spinner: {
        margin: '0 auto',
        width: 56,
        height: 56,
        position: 'relative',
        '& div': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
        },
        '& div:before': {
            content: `''`,
            display: 'block',
            margin: '0 auto',
            width: '15%',
            height: '15%',
            backgroundColor: colors.blue,
            borderRadius: '100%',
        },
        '& .flash:before': {
            animation: '$flash 1.2s infinite',
        },
        '& .circle1': {
            '-webkit-transform': 'rotate(270deg)',
            '-ms-transform': 'rotate(270deg)',
            transform: 'rotate(270deg)',
        },
        '& .circle2': {
            '-webkit-transform': 'rotate(315deg)',
            '-ms-transform': 'rotate(315deg)',
            transform: 'rotate(315deg)',
        },
        '& .circle3': {
            '-webkit-transform': 'rotate(360deg)',
            '-ms-transform': 'rotate(360deg)',
            transform: 'rotate(360deg)',
        },
        '& .circle4': {
            '-webkit-transform': 'rotate(45deg)',
            '-ms-transform': 'rotate(45deg)',
            transform: 'rotate(45deg)',
        },
        '& .circle5': {
            '-webkit-transform': 'rotate(90deg)',
            '-ms-transform': 'rotate(90deg)',
            transform: 'rotate(90deg)',
        },
        '& .circle6': {
            '-webkit-transform': 'rotate(135deg)',
            '-ms-transform': 'rotate(135deg)',
            transform: 'rotate(135deg)',
        },
        '& .circle7': {
            '-webkit-transform': 'rotate(180deg)',
            '-ms-transform': 'rotate(180deg)',
            transform: 'rotate(180deg)',
        },
        '& .circle8': {
            '-webkit-transform': 'rotate(225deg)',
            '-ms-transform': 'rotate(225deg)',
            transform: 'rotate(225deg)',
        },
        '& .circle1:before': {
            '-webkit-animation-delay': '-0.52s',
            animationDelay: '-0.52s',
        },
        '& .circle2:before': {
            '-webkit-animation-delay': '-0.48s',
            animationDelay: '-0.48s',
        },
        '& .circle3:before': {
            '-webkit-animation-delay': '-0.4s',
            animationDelay: '-0.4s',
        },
        '& .circle4:before': {
            '-webkit-animation-delay': '-0.32s',
            animationDelay: '-0.32s',
        },
        '& .circle5:before': {
            '-webkit-animation-delay': '-0.24s',
            animationDelay: '-0.24s',
        },
        '& .circle6:before': {
            '-webkit-animation-delay': '-0.16s',
            animationDelay: '-0.16s',
        },
        '& .circle7:before': {
            '-webkit-animation-delay': '-0.08s',
            animationDelay: '-0.08s',
        },
    },
}));

const CirclesLoadingSpinner = () => {
    const classes = useStyles();
    return (
        <div className={classes.spinner}>
            <div className="circle1 stay" />
            <div className="circle2 flash" />
            <div className="circle3 flash" />
            <div className="circle4 flash" />
            <div className="circle5 flash" />
            <div className="circle6 flash" />
            <div className="circle7 flash" />
            <div className="circle8 flash" />
        </div>
    );
};

export default CirclesLoadingSpinner;

import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type FormModalProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    content: string;
    error?: boolean;
    inputPlaceholder?: string;
    inputErrorText?: string;
    handleSubmit: () => void;
    handleFormOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles(() => ({
    line: {
        height: 'auto !important',
        padding: '0 0 4px 0',
    },
}));

const FormModal = ({
    open,
    onClose,
    title,
    content,
    error,
    inputPlaceholder,
    inputErrorText,
    handleSubmit,
    handleFormOnChange,
}: FormModalProps) => {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">
            <DialogTitle style={{ textAlign: 'center', padding: '40px 64px 8px' }}>{title}</DialogTitle>
            <DialogContent style={{ padding: '0 64px' }}>
                <DialogContentText style={{ textAlign: 'center' }}>{content}</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    placeholder={inputPlaceholder}
                    fullWidth
                    error={error}
                    helperText={error && inputErrorText}
                    inputProps={{ style: { textAlign: 'center', padding: 0 } }}
                    InputProps={{ classes: { root: classes.line } }}
                    FormHelperTextProps={{ style: { textAlign: 'center' } }}
                    onChange={handleFormOnChange}
                />
            </DialogContent>
            <DialogActions style={{ padding: '32px 64px 48px', display: 'flex', justifyContent: 'center' }}>
                <Button onClick={onClose} color="primary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained" style={{ marginLeft: 16 }}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormModal;

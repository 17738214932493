import React, { useState, ChangeEvent } from 'react';
import { Typography, Select, MenuItem, Button, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import { ApiResponse } from 'apisauce';
import EmailEditor from './emailEditor';
import { AssetModalMismatchedAttributeWarningAlertBar } from 'components/alertBar';
import api from 'api';
import colors from 'colors';
import { Audience, AssetValidationErrors, Asset } from 'types';
import { checkAssetHasInvalidSyntaxAttributeErrors, checkAssetHasMismatchedAttributeErrors } from '../campaignUtils';

const useStyles = makeStyles((theme: Theme) => ({
    selectContainer: {
        '& > div': {
            '&::before, &::after, &:hover::before': {
                borderBottom: 'none!important',
            },
        },
    },
    list: {
        minWidth: 112,
    },
    paper: {
        marginLeft: 12,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    errorFoldingButton: {
        fontWeight: 500,
        fontSize: 16,
        margin: '4px 0px',
        cursor: 'pointer',
        width: 90,
    },
}));

type EmailPreviewProps = {
    asset: Asset;
    emailTemplate: string;
    campaignId: string;
    assetId: string;
    deleteAsset: (id: string) => void;
    isViewingAsset: boolean;
    campaignAudiences?: Audience[];
};

const EmailPreview = ({
    asset,
    emailTemplate,
    campaignId,
    assetId,
    deleteAsset,
    isViewingAsset,
    campaignAudiences,
}: EmailPreviewProps) => {
    const classes = useStyles();
    const [previewSize, setPreviewSize] = useState('desktop');
    const [showMoreErrors, setShowMoreErrors] = useState(false);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                marginBottom: 32,
                background: colors.backgroundGrey,
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 32, width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography style={{ marginRight: 12, color: colors.darkGrey }}>Preview in:</Typography>
                    <div className={classes.selectContainer}>
                        <Select
                            value={previewSize}
                            onChange={(event: ChangeEvent<any>) => {
                                setPreviewSize(event.target.value);
                            }}
                            MenuProps={{
                                classes: { list: classes.list, paper: classes.paper },
                                style: { marginTop: 8 },
                                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                transformOrigin: { vertical: 'top', horizontal: 'center' },
                                getContentAnchorEl: null,
                            }}
                            IconComponent={ArrowIcon}
                        >
                            <MenuItem value={'desktop'}>Desktop</MenuItem>
                            <MenuItem value={'mobile'}>Mobile</MenuItem>
                        </Select>
                    </div>
                </div>
                {!isViewingAsset && (
                    <Button
                        color="primary"
                        onClick={() => {
                            api.deleteAsset(campaignId, assetId).then((response: ApiResponse<any>) => {
                                if (response.ok) {
                                    deleteAsset(assetId);
                                }
                            });
                        }}
                    >
                        Remove email asset
                    </Button>
                )}
            </div>
            {!checkAssetHasInvalidSyntaxAttributeErrors(asset) && checkAssetHasMismatchedAttributeErrors(asset) && (
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', padding: '0 32px 10px', width: '100%' }}
                >
                    <AssetModalMismatchedAttributeWarningAlertBar
                        campaignAudiences={campaignAudiences!}
                        errors={asset.validationErrors!.mismatchedAttributeErrors!}
                    />
                </div>
            )}
            <div style={{ padding: '0 32px', flex: 1, width: '100%', textAlign: 'center', marginBottom: 38 }}>
                <EmailEditor src={emailTemplate} size={previewSize} />
            </div>
        </div>
    );
};

export default EmailPreview;

import React, { MouseEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Typography,
    Box,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Link,
    Popper,
    Grow,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';
import { RootState } from 'reducers';
import { pluralise } from 'utils/pluralise';

import DeleteTooltip from './../audienceDeleteTooltip';
import EmptyPage from 'components/emptyPage';
import { getPersonalCampaigns, getTeamCampaigns } from './../../campaigns/campaignSlice';
import CustomTooltip from 'components/customTooltip';
import { getAttributeCampaigns, getAttributesUsageData } from './../audienceUtils';
import ConfirmModal from 'components/confirmModal';
import { UserAttributeBackend, CampaignBackend } from 'types';
import api from 'api';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AudienceUsageModal from './../audienceUsageModal';
import AttributeEditModal from './attributeEditModal';

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflow: 'hidden',
        tableLayout: 'fixed',
        '& th': {
            borderBottom: 'none',
            padding: '24px 64px 24px 0',
            display: 'inline-block',
        },
        '& td': {
            padding: '24px 64px 24px 0',
            display: 'inline-block',
            borderBottom: 'none',
            '& p': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            '&:last-child': {
                paddingLeft: '2px',
                paddingRight: 0,
            },
        },
        '& tr': {
            borderBottom: `1px solid ${colors.grey}`,
        },
        '& tr:last-child': {
            borderBottom: 'none',
        },
    },
    paper: {
        padding: '4px 0',
        background: colors.dark,
        color: colors.white,
        position: 'absolute',
        right: 0,
        top: 0,
    },
}));

export interface ToolTipProps {
    count: number;
    handleClose: () => void;
    setShowUsage: (isVisible: boolean) => void;
}

type AttributeUsage = 'attribute' | 'value';

interface AttributesProps {
    audienceAttributes?: UserAttributeBackend[];
    selectedTeamId: string | null;
    campaigns: CampaignBackend[];
    getPersonalCampaigns: () => void;
    getTeamCampaigns: (teamId: string) => void;
    onAttributeUpdate: () => void;
}

export const Attributes = ({
    audienceAttributes,
    selectedTeamId,
    campaigns,
    getPersonalCampaigns,
    getTeamCampaigns,
    onAttributeUpdate,
}: AttributesProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [attributes, setAttributes] = useState<UserAttributeBackend[]>([]);
    const [selectedAttribute, setSelectedAttribute] = useState<UserAttributeBackend>();
    const [attributeCampaigns, setAttributeCampaigns] = useState<CampaignBackend[]>([]);
    const [attributeValueCampaigns, setAttributeValueCampaigns] = useState<CampaignBackend[]>([]);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [showUsage, setShowUsage] = React.useState<boolean>(false);
    const [attributeUsageType, setAttributeUsageType] = React.useState<AttributeUsage>('attribute');
    const [showEdit, setShowEdit] = React.useState<boolean>(false);
    const [manageAttributeOpened, setManageAttributeOpened] = React.useState<boolean>(false);
    const [deleteValue, setDeleteValue] = useState<string>('');
    const { audienceId } = useParams<{ audienceId: string }>();

    useEffect(() => {
        if (audienceAttributes) {
            setAttributes([...audienceAttributes]);
        }
    }, [audienceAttributes]);

    useEffect(() => {
        if (selectedTeamId) {
            getTeamCampaigns(selectedTeamId);
        } else {
            getPersonalCampaigns();
        }
    }, [selectedTeamId]);

    useEffect(() => {
        if (showUsage) {
            setShowEdit(false);
        }
    }, [showUsage]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: MouseEvent<HTMLAnchorElement>, attribute: UserAttributeBackend) => {
        setAnchorEl(event.currentTarget as any);
        setSelectedAttribute({ ...attribute });
        setAttributeCampaigns(getAttributeCampaigns(campaigns, audienceId, attribute));
    };

    const handleDeleteAttribute = () => {
        setShowEdit(false);
        setOpenDelete(true);
        handleClose();
    };

    const handleManageAttribute = () => {
        setShowEdit(true);
        handleClose();
        setManageAttributeOpened(true);
    };

    return (
        <>
            {attributes.length ? (
                <Box display="flex" overflow="auto">
                    <Paper style={{ width: '100%', margin: '32px 0', display: 'inline-block' }}>
                        <Box style={{ borderBottom: `1px solid ${colors.grey}` }}>
                            <Box style={{ margin: '0 32px' }}>
                                <Table className={classes.tableContainer}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: colors.darkGrey, width: '34%' }}>
                                                <Typography variant="body2" style={{ fontWeight: 500 }}>
                                                    Attribute
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ color: colors.darkGrey, width: '50%' }}>
                                                <Typography variant="body2" style={{ fontWeight: 500 }}>
                                                    Data type
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '3%' }} />
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </Box>
                        </Box>
                        <Box style={{ margin: '0 32px' }}>
                            <Table className={classes.tableContainer}>
                                <TableBody>
                                    {attributes.map((attribute: UserAttributeBackend, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ width: '34%' }}>
                                                <Typography variant="body2">{attribute.displayName}</Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '63%' }}>
                                                <Typography variant="body2">
                                                    {attribute.type === 'TEXT' ? (
                                                        <>{'Free text'}</>
                                                    ) : (
                                                        <>
                                                            {`Fixed values`}{' '}
                                                            <span
                                                                style={{ fontWeight: 500 }}
                                                            >{`(${attribute.values?.join(', ')})`}</span>
                                                        </>
                                                    )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: '3%' }}>
                                                <Link
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                                        e.stopPropagation();
                                                        handleClick(e, attribute);
                                                    }}
                                                >
                                                    <MoreIcon />
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Paper>
                    <ConfirmModal
                        open={openDelete}
                        title={`Delete “${selectedAttribute?.displayName}” attribute`}
                        content={
                            'This action will also delete the column from your audience permanently. Are you sure you want to delete it?'
                        }
                        textNo={'Cancel'}
                        textYes={'Delete'}
                        onClose={() => {
                            setOpenDelete(false);
                            if (manageAttributeOpened) {
                                setShowEdit(true);
                            }
                        }}
                        confirm={() => {
                            if (selectedAttribute!.name && audienceId) {
                                api.deleteAttribute(audienceId, selectedAttribute!.name).then((response) => {
                                    if (response.ok) {
                                        setManageAttributeOpened(false);
                                        onAttributeUpdate();
                                        setOpenDelete(false);
                                    }
                                });
                            }
                        }}
                    />
                    {selectedAttribute && (
                        <AttributeEditModal
                            title={'Manage attribute'}
                            selectedTeamId={selectedTeamId}
                            open={showEdit}
                            audienceId={audienceId}
                            selectedAttribute={selectedAttribute}
                            setSelectedAttribute={setSelectedAttribute}
                            setAttributeUsageType={setAttributeUsageType}
                            setAttributes={setAttributes}
                            setShowUsage={setShowUsage}
                            attributeCampaigns={attributeCampaigns}
                            setAttributeValueCampaigns={setAttributeValueCampaigns}
                            campaigns={campaigns}
                            openDelete={handleDeleteAttribute}
                            setDeleteValue={setDeleteValue}
                            attributes={attributes}
                            getPersonalCampaigns={getPersonalCampaigns}
                            getTeamCampaigns={getTeamCampaigns}
                            handleClose={() => {
                                setShowEdit(false);
                                setManageAttributeOpened(false);
                                onAttributeUpdate();
                            }}
                        />
                    )}
                    <AudienceUsageModal
                        title={attributeUsageType === 'attribute' ? 'Attribute usage' : 'Value usage'}
                        open={showUsage}
                        usedEntity={
                            attributeUsageType === 'attribute'
                                ? selectedAttribute?.displayName || ''
                                : `${selectedAttribute?.displayName || ''} (${deleteValue})`
                        }
                        usageData={
                            attributeUsageType === 'attribute'
                                ? getAttributesUsageData(attributeCampaigns)
                                : getAttributesUsageData(attributeValueCampaigns)
                        }
                        handleClose={() => {
                            setAttributeUsageType('attribute');
                            setShowUsage(false);
                            if (manageAttributeOpened) {
                                setShowEdit(true);
                            }
                        }}
                    />
                    <Popper
                        style={{ zIndex: 999 }}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        role={undefined}
                        transition
                        disablePortal
                        placement="top-end"
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}>
                                <Paper className={classes.paper} style={{ transformOrigin: 'right top' }} elevation={2}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList style={{ padding: 0 }}>
                                            <MenuItem onClick={handleManageAttribute}>Manage attribute</MenuItem>
                                            {selectedAttribute && !attributeCampaigns.length ? (
                                                <MenuItem onClick={handleDeleteAttribute}>Delete attribute</MenuItem>
                                            ) : (
                                                <CustomTooltip
                                                    title={
                                                        <DeleteTooltip
                                                            title={`This attribute cannot be deleted as it is being used to personalise ${
                                                                attributeCampaigns.length
                                                            } campaign ${pluralise(
                                                                attributeCampaigns.length,
                                                                'workflow',
                                                            )}`}
                                                            onClick={() => {
                                                                handleClose();
                                                                setShowUsage(true);
                                                            }}
                                                        />
                                                    }
                                                    placement={'left'}
                                                    arrow={true}
                                                    interactive={true}
                                                >
                                                    <MenuItem disabled={true}>Delete attribute</MenuItem>
                                                </CustomTooltip>
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            ) : (
                <EmptyPage
                    style={{ marginTop: 32 }}
                    title={'No existing attributes'}
                    subtitle={'Import contacts to add attributes'}
                    withBackground
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTeamId: state.userSlice.dashboardSelected !== 'personal' ? state.userSlice.dashboardSelected : null,
    campaigns: state.campaignSlice.campaigns,
});

const mapDispatchToProps = {
    getPersonalCampaigns,
    getTeamCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);

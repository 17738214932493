import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Button, Paper } from '@material-ui/core';
import { ConflictUpdate } from './importingContacts';
import { pluralise, pluraliseVerb } from 'utils/pluralise';
import api from 'api';
import BaseHeader from 'components/baseHeader';
import colors from 'colors';
import { useHistory, useParams } from 'react-router-dom';
import { ApiResponse } from 'apisauce';
import Footer from '../footer';
import CirclesLoadingSpinner from 'components/circlesLoadingSpinner';
import { addPendingAudience, removePendingAudience, putAudience } from '../audienceSlice';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Audience, UserAttributeBackend } from 'types';
import { isAudienceComplete, isAudienceImporting } from '../audienceUtils';
import { toggleSnackbar } from 'reducers/appSlice';
import { ToggleSnackbarProps } from 'components/snackBar';

interface ResolveConflictsProps {
    addPendingAudience: (audienceId: string) => void;
    removePendingAudience: (audienceId: string) => void;
    putAudience: (audience: any) => void;
    toggleSnackbar: (data: ToggleSnackbarProps) => void;
}

const ResolveConflicts = ({
    addPendingAudience,
    removePendingAudience,
    putAudience,
    toggleSnackbar,
}: ResolveConflictsProps) => {
    const history = useHistory();
    const { audienceId } = useParams<{ audienceId: string }>();
    const [unresolvedConflicts, setUnresolvedConflicts] = useState<number>(0);
    const [conflicts, setConflicts] = useState<string[]>([]);
    const [mapping, setMapping] = useState<any>({});
    const [attributes, setAttributes] = useState<UserAttributeBackend[]>();
    const [shouldFetch, setShouldFetch] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<number>(0);
    const [audience, setAudience] = useState<Audience>();
    const [conflictUpdateArray, setConflictUpdateArray] = useState<ConflictUpdate[]>([]);

    const handleClickBack = () => {
        history.goBack();
    };

    const handleUpdateAll = () => {
        setConflictUpdateArray(
            conflictUpdateArray.map((conflict) => {
                return { ...conflict, update: true };
            }),
        );
    };

    const handleDontUpdateAll = () => {
        setConflictUpdateArray(
            conflictUpdateArray.map((conflict) => {
                return { ...conflict, update: false };
            }),
        );
    };

    const handleUpdateOne = (index: number) => {
        setConflictUpdateArray(
            conflictUpdateArray.map((conflict, i) => {
                return {
                    ...conflict,
                    update: index === i ? true : conflict.update,
                };
            }),
        );
    };

    const handleDontUpdateOne = (index: number) => {
        setConflictUpdateArray(
            conflictUpdateArray.map((conflict, i) => {
                return {
                    ...conflict,
                    update: index === i ? false : conflict.update,
                };
            }),
        );
    };

    const handleResetOne = (index: number) => {
        setConflictUpdateArray(
            conflictUpdateArray.map((conflict, i) => {
                return {
                    ...conflict,
                    update: index === i ? null : conflict.update,
                };
            }),
        );
    };

    const isUpdateAll = () => {
        return conflictUpdateArray.every((conflict) => conflict.update);
    };

    const isDontUpdateAll = () => {
        return conflictUpdateArray.every((conflict) => conflict.update === false);
    };

    const countUnresolvedConflicts = () => {
        const unresolved = conflictUpdateArray.filter((conflict) => conflict.update === null);
        return unresolved.length;
    };

    const handleReset = () => {
        setConflictUpdateArray(
            conflictUpdateArray.map((conflict) => {
                return { ...conflict, update: null };
            }),
        );
    };

    const handleResolveConflicts = () => {
        putAudience(audience);
        addPendingAudience(audienceId);

        handleClickBack();

        let conflictsOverwrite = conflictUpdateArray!.map((conflict) => {
            return {
                [conflict.name]: conflict.update,
            };
        });

        conflictsOverwrite = Object.assign({}, ...conflictsOverwrite);

        const formDataPayload = new FormData();
        formDataPayload.append(
            'data',
            JSON.stringify({
                conflictsOverwrite,
                attributeToColumnMapping: mapping,
            }),
        );

        return api.addAudienceContacts(audienceId!, formDataPayload).then((response: ApiResponse<any>) => {
            if (response.ok) {
                const { name, state, importedOn } = response.data;

                if (isAudienceImporting(state)) {
                    toggleSnackbar({
                        message: `${name} import has resumed. This may take a few minutes.`,
                        type: 'success',
                    });
                } else if (isAudienceComplete(state)) {
                    toggleSnackbar({ message: `${name} import complete.`, type: 'success' });
                }

                putAudience({ ...audience, importedOn, state });
                removePendingAudience(audienceId);
            }
        });
    };

    useEffect(() => {
        setConflictUpdateArray(
            conflicts.map((conflict) => {
                return {
                    name: conflict,
                    update: null,
                };
            }),
        );
    }, [conflicts]);

    useEffect(() => {
        setUnresolvedConflicts(countUnresolvedConflicts());
    }, [conflictUpdateArray]);

    useEffect(() => {
        setShouldFetch(false);
        setIsFetching((isFetching) => isFetching + 1);

        Promise.all([api.getAudience(audienceId), api.getAudienceConflicts(audienceId), api.getAttributes(audienceId)])
            .then(([audienceResponse, conflictsResponse, attributesResponse]: ApiResponse<any>[]) => {
                if (conflictsResponse.ok) {
                    const { mapping, conflicts } = conflictsResponse.data;
                    setConflicts(conflicts);
                    setMapping(mapping);
                }

                if (audienceResponse.ok) {
                    setAudience(audienceResponse.data);
                }

                if (attributesResponse.ok) {
                    setAttributes(attributesResponse.data[audienceId]);
                }
            })
            .finally(() => {
                setIsFetching((isFetching) => isFetching - 1);
            });
    }, [shouldFetch]);

    const renderRightFooterButton = () => {
        return conflicts.length > 0 ? (
            <div>
                {unresolvedConflicts > 0 && (
                    <Typography color="textPrimary" style={{ marginRight: 24, display: 'inline' }}>
                        ({unresolvedConflicts}) {pluralise(unresolvedConflicts, 'column')}{' '}
                        {pluraliseVerb(unresolvedConflicts, 'has', 'have')} unresolved conflicts
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={unresolvedConflicts > 0}
                    onClick={handleResolveConflicts}
                >
                    Continue import
                </Button>
            </div>
        ) : (
            <Button variant="contained" color="primary" disabled={unresolvedConflicts > 0} onClick={handleClickBack}>
                Back
            </Button>
        );
    };

    return (
        <>
            <BaseHeader title="Import contacts" backButton buttonLink={handleClickBack} />
            <Box mx={4} flexGrow={1} style={{ paddingBottom: 138, marginTop: 40 }}>
                <Paper
                    style={{
                        border: `1px solid ${colors.coolGrey}`,
                        boxShadow: 'none',
                        margin: '0 10%',
                        marginBottom: 170,
                    }}
                >
                    <Box mx={5} my={4}>
                        <Typography variant="h6">
                            {conflicts.length > 0 ? 'Importing contacts...' : 'Nothing to import'}
                        </Typography>
                        <Box mb={3} mt={1}>
                            <span>
                                {`${conflicts.length} ${pluralise(conflicts.length, 'column')} in your import
                                ${pluraliseVerb(
                                    conflicts.length,
                                    'has',
                                    'have',
                                )} values that conflict with existing values in \"${audience?.name}\"`}
                            </span>
                        </Box>
                    </Box>
                    {isFetching !== 0 && (
                        <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            justifyContent="center"
                            height="100%"
                        >
                            <CirclesLoadingSpinner />
                            <Typography variant="h2" style={{ marginTop: 24, marginBottom: 48 }}>
                                Loading conflicts...
                            </Typography>
                        </Box>
                    )}
                    {isFetching == 0 && conflicts.length > 0 && (
                        <>
                            <Divider style={{ backgroundColor: colors.coolGrey }} />
                            <Box mx={5} my={4}>
                                <Box display="flex" justifyContent="space-between" mb={3} alignItems="center">
                                    <span style={{ fontSize: '16px' }}>
                                        Do you want to update existing records with the new values?
                                    </span>
                                    <span>
                                        {isUpdateAll() || isDontUpdateAll() ? (
                                            <Box display="flex" flexDirection="column" alignItems="flex-end">
                                                <span>
                                                    {isUpdateAll() && 'Update all records'}
                                                    {isDontUpdateAll() && `Don't update all records`}
                                                </span>
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    variant="text"
                                                    onClick={handleReset}
                                                >
                                                    Edit
                                                </Button>
                                            </Box>
                                        ) : (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    onClick={handleDontUpdateAll}
                                                >
                                                    Don’t update all
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    style={{ marginLeft: 8 }}
                                                    onClick={handleUpdateAll}
                                                >
                                                    Update all
                                                </Button>
                                            </>
                                        )}
                                    </span>
                                </Box>
                                <Divider style={{ marginBottom: 24, backgroundColor: colors.grey }} />
                                {conflictUpdateArray.map((conflict, index: number) => (
                                    <div key={conflict.name}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            key={conflict.name}
                                        >
                                            <span>
                                                {
                                                    attributes?.find((attribute) => attribute.name === conflict.name)
                                                        ?.displayName
                                                }
                                                <div style={{ fontSize: 14, color: colors.darkGrey, marginTop: 8 }}>
                                                    mapped to {`"${mapping[conflict.name]}"`}
                                                </div>
                                            </span>

                                            {conflict.update === null && (
                                                <span>
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                        onClick={() => handleDontUpdateOne(index)}
                                                        style={{ marginRight: 32 }}
                                                    >
                                                        Don’t update
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                        onClick={() => handleUpdateOne(index)}
                                                    >
                                                        Update
                                                    </Button>
                                                </span>
                                            )}
                                            {conflict.update !== null && !(isUpdateAll() || isDontUpdateAll()) && (
                                                <Box display="flex">
                                                    <div>{conflict.update ? 'Update' : `Don't update`}</div>
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => handleResetOne(index)}
                                                        style={{ marginLeft: 16 }}
                                                    >
                                                        edit
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                        {index !== conflictUpdateArray.length - 1 && (
                                            <Divider style={{ margin: '24px 0', backgroundColor: colors.grey }} />
                                        )}
                                    </div>
                                ))}
                            </Box>
                        </>
                    )}
                </Paper>
            </Box>
            <Footer right={renderRightFooterButton()} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    audiencesPending: state.audienceSlice.audiencesPending,
});

const mapDispatchToProps = {
    addPendingAudience,
    removePendingAudience,
    putAudience,
    toggleSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResolveConflicts);

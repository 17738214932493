import { useEffect, useState } from 'react';
import { Box, Typography, Divider, Button, Paper } from '@material-ui/core';
import { pluralise, pluraliseVerb } from 'utils/pluralise';
import api from 'api';
import BaseHeader from 'components/baseHeader';
import colors from 'colors';
import { useHistory, useParams } from 'react-router-dom';
import Footer from '../footer';
import CirclesLoadingSpinner from 'components/circlesLoadingSpinner';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Audience, ImportSummary } from 'types';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { formatNumberWithCommas } from 'utils/numbers';
import { ApiResponse } from 'apisauce';

const useStyles = makeStyles(() => ({
    completeNotification: {
        padding: '16px 16px',
        marginBottom: 16,
        backgroundColor: colors.lightGreen,
        display: 'flex',
        borderColor: colors.green,
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    infoIcon: {
        marginRight: 10,
        color: colors.darkYellow,
    },
    checkIcon: {
        marginRight: 10,
        color: colors.green,
    },
    errorIcon: {
        marginRight: 10,
        color: colors.red,
    },
}));

const ImportComplete = () => {
    const classes = useStyles();
    const history = useHistory();
    const { audienceId } = useParams<{ audienceId: string }>();
    const [shouldFetch, setShouldFetch] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<number>(0);
    const [audience, setAudience] = useState<Audience>();
    const [importSummary, setImportSummary] = useState<ImportSummary>({});

    const handleClickBack = () => {
        history.goBack();
    };

    const handleDownloadIssues = (audienceId: string) => {
        api.getAudienceIssuesFile(audienceId).then((response: ApiResponse<any>) => {
            if (response.ok) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'issues.csv');
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    useEffect(() => {
        setShouldFetch(false);
        setIsFetching((isFetching) => isFetching + 1);

        const apiCalls = [
            api.getAudience(audienceId),
            api.getImportSummary(audienceId),
            api.dismissNotifications(`${audienceId}.import.errors`),
        ];

        Promise.all(apiCalls)
            .then(([audienceResponse, importSummaryResponse]: any[]) => {
                if (audienceResponse.ok) {
                    setAudience(audienceResponse.data);
                }

                if (importSummaryResponse.ok) {
                    setImportSummary(importSummaryResponse.data);
                }
            })
            .finally(() => {
                setIsFetching((isFetching) => isFetching - 1);
            });
    }, [shouldFetch]);

    return (
        <>
            <BaseHeader title="Import contacts" backButton buttonLink={handleClickBack} />
            <Box mx={4} flexGrow={1} style={{ paddingBottom: 138, marginTop: 40 }}>
                <Paper style={{ border: `1px solid ${colors.coolGrey}`, boxShadow: 'none', margin: '0 10%' }}>
                    {isFetching !== 0 && (
                        <Box
                            my={5}
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            justifyContent="center"
                            height="100%"
                        >
                            <CirclesLoadingSpinner />
                            <Typography variant="h2" style={{ marginTop: 24 }}>
                                Loading import info
                            </Typography>
                        </Box>
                    )}
                    {isFetching == 0 && (
                        <>
                            <Box mx={5} my={4}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <CheckIcon className={classes.checkIcon} />
                                    <Typography variant="h6">{`${audience?.name} import is complete`}</Typography>
                                </div>
                                <Box mb={3} mt={1}>
                                    <div>
                                        {importSummary.addCount &&
                                            `${formatNumberWithCommas(importSummary.addCount)} new ${pluralise(
                                                importSummary.addCount,
                                                'contact',
                                            )} added`}
                                    </div>
                                    <div>
                                        {importSummary.updateCount &&
                                            `${formatNumberWithCommas(importSummary.updateCount)} existing ${pluralise(
                                                importSummary.updateCount,
                                                'contact',
                                            )} updated`}
                                    </div>
                                </Box>
                            </Box>
                            <Divider style={{ backgroundColor: colors.coolGrey }} />
                            <Box mx={5} my={4} display="flex" alignItems="center" justifyContent="space-between">
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    <ErrorIcon className={classes.errorIcon} />
                                    <Typography style={{ color: colors.red }}>
                                        {importSummary.issuesCount &&
                                            `${importSummary.issuesCount} ${pluralise(
                                                importSummary.issuesCount,
                                                'contact',
                                            )} ${pluraliseVerb(importSummary.issuesCount, 'has', 'have')} issues`}
                                    </Typography>
                                </span>
                                <Button onClick={() => handleDownloadIssues(audienceId)}>
                                    Download contacts with issues
                                </Button>
                            </Box>
                        </>
                    )}
                </Paper>
            </Box>
            <Footer
                right={
                    <Button variant="contained" color="primary" onClick={handleClickBack}>
                        Back
                    </Button>
                }
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    notifications: state.userSlice.notifications,
});

export default connect(mapStateToProps)(ImportComplete);

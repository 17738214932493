import React, { useState, useEffect } from 'react';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Button,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ApiResponse } from 'apisauce';
import colors from 'colors';
import api from 'api';
import DropdownWithSearch, { DropdownOption } from 'components/dropdownWithSearch';
import { ColumnMap } from './selectImportType';
import { UniqueUserAttributeDisplayName, UserAttributeGroup, UserAttribute, UserAttributeBackend } from 'types';
import CreateNewAttributeModal from './createNewAttributeModal';
import AttributeInfoModal from '../attributeInfoModal';

interface StepTwoProps {
    contacts: string[][];
    columnsMapped: ColumnMap[];
    userAttributes: UserAttribute[];
    handleAttributeChange: (value: DropdownOption, index?: number) => void;
    handleDoNotImport: (index: number) => void;
    audienceId: string;
    fetchNewAttributes: () => void;
    setNewAttribute: (newAttributeId: string) => void;
}

interface Option {
    group: string;
    name: string;
}

const useStyles = makeStyles(() => ({
    tableContainer: {
        border: `1px solid ${colors.grey}`,
        boxShadow: 'none !important',
        '& th, td': {
            padding: '24px 40px',
            fontSize: 16,
        },
        marginBottom: 40,
        '& tbody tr:last-child th, & tbody tr:last-child td': {
            border: 0,
        },
    },
    notImporting: {
        color: colors.coolGrey,
    },
    helperText: {
        color: colors.darkGrey,
        fontSize: 14,
        marginTop: 10,
        display: 'inline-block',
    },
}));

const StepTwo = ({
    contacts,
    columnsMapped,
    userAttributes,
    handleAttributeChange,
    handleDoNotImport,
    audienceId,
    fetchNewAttributes,
    setNewAttribute,
}: StepTwoProps) => {
    const classes = useStyles();
    const firstThreeItems = contacts.slice(0, 3);
    const [showCreateNewAttribute, setShowCreateNewAttribute] = useState<boolean>(false);
    const [attributeToView, setAttributeToView] = useState<UserAttribute>();
    const [rowIndexSelected, setRowIndexSelected] = useState<number>();
    const [options, setOptions] = useState<UserAttribute[]>([]);

    useEffect(() => {
        setOptions(userAttributes);
        if (options.length !== 0 && userAttributes !== options) {
            const [newAttribute] = userAttributes.slice(-1);
            handleAttributeChange(newAttribute, rowIndexSelected);
        }
    }, [userAttributes]);

    // const isBothUniqueIdentifiersSelected = () => {
    //     const uniqueIdentifiersSelected = columnsMapped.filter(
    //         (column: ColumnMap) => column.mappedAttribute.group === UserAttributeGroup.UNIQUE_IDENTIFIERS,
    //     );
    //     return uniqueIdentifiersSelected.length === 2;
    // };

    const isUniqueIdentifierHelperShown = (attributeMapped: string) => {
        // if (isBothUniqueIdentifiersSelected() && attributeMapped === UniqueUserAttributeDisplayName.EMAIL) return false;
        // if (attributeMapped === UniqueUserAttributeDisplayName.EMAIL || attributeMapped === UniqueUserAttributeDisplayName.NRIC_FIN)
        //     return true;
        // return false;
        return attributeMapped === UniqueUserAttributeDisplayName.EMAIL;
    };

    const isOptionMapped = (option: Option): boolean => {
        const mapped = columnsMapped.filter((column: ColumnMap) => column.mappedAttribute.name === option.name);
        return mapped.length > 0;
    };

    const addAttribute = (attribute: UserAttributeBackend) => {
        return new Promise((resolve, reject) => {
            api.addAttribute(audienceId, attribute).then((response: ApiResponse<any>) => {
                if (response.ok) {
                    setNewAttribute(attribute.displayName);
                    setShowCreateNewAttribute(false);
                    fetchNewAttributes();
                    resolve(true);
                } else {
                    reject({ message: response.data.error });
                }
            });
        });
    };

    const getDropdownButtonText = (rowIndex: number) => {
        const mappedColumn = columnsMapped[rowIndex];
        if (mappedColumn.mappedAttribute.name !== '') return <span>{mappedColumn.mappedAttribute.name}</span>;
        if (mappedColumn.isImporting === false) return <span>Don’t import column</span>;
        return <span style={{ color: colors.coolGrey }}>Select or create new attribute</span>;
    };

    return (
        <>
            <Typography color="textPrimary" style={{ marginTop: 12, marginBottom: 32 }}>
                Each column header should be mapped to an existing user attribute. If the column header cannot be mapped
                to any existing attributes, you may create a new attribute or choose not to import the column.
            </Typography>
            <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: colors.lightGrey }}>
                            <TableCell style={{ width: '30%', color: colors.dark, fontSize: 14 }}>
                                <span>Column headers</span>
                            </TableCell>
                            <TableCell style={{ width: '30%', color: colors.dark, fontSize: 14 }}>
                                <span>Preview column values</span>
                            </TableCell>
                            <TableCell style={{ width: '40%', color: colors.dark, fontSize: 14 }}>
                                <span>User attribute</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {columnsMapped &&
                            columnsMapped.map((column: ColumnMap, rowIndex: number) => (
                                <TableRow key={column.columnHeader}>
                                    <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                                        <span className={!column.isImporting ? classes.notImporting : ''}>
                                            {column.columnHeader}
                                        </span>
                                    </TableCell>
                                    <TableCell style={{ verticalAlign: 'top' }}>
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="column"
                                            className={!column.isImporting ? classes.notImporting : ''}
                                        >
                                            {firstThreeItems.map((item: string[], index: number) => (
                                                <Grid item key={item[rowIndex] + index}>
                                                    {item[rowIndex]}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TableCell>
                                    <TableCell style={{ verticalAlign: 'top' }}>
                                        <DropdownWithSearch
                                            selectType="outlined"
                                            options={options}
                                            handleOptionChange={handleAttributeChange}
                                            index={rowIndex}
                                            buttonText={getDropdownButtonText(rowIndex)}
                                            isOptionSelected={isOptionMapped}
                                            additionalOptions={[
                                                {
                                                    name: 'Create new attribute',
                                                    onClick: () => {
                                                        setRowIndexSelected(rowIndex);
                                                        setShowCreateNewAttribute(true);
                                                    },
                                                    buttonColor: 'primary',
                                                },
                                                {
                                                    name: `Don't import column`,
                                                    onClick: () => {
                                                        handleDoNotImport(rowIndex);
                                                    },
                                                },
                                            ]}
                                        />
                                        {columnsMapped[rowIndex].mappedAttribute.name !== '' &&
                                            columnsMapped[rowIndex].mappedAttribute.group !==
                                                UserAttributeGroup.UNIQUE_IDENTIFIERS && (
                                                <Button
                                                    variant="text"
                                                    size="small"
                                                    color="primary"
                                                    style={{ marginTop: 8 }}
                                                    onClick={() => {
                                                        setAttributeToView(columnsMapped[rowIndex].mappedAttribute);
                                                    }}
                                                >
                                                    View details
                                                </Button>
                                            )}
                                        {isUniqueIdentifierHelperShown(
                                            columnsMapped[rowIndex].mappedAttribute.name,
                                        ) && (
                                            <span className={classes.helperText}>
                                                This will be used as the unique identifier
                                            </span>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CreateNewAttributeModal
                open={showCreateNewAttribute}
                handleClose={() => {
                    setShowCreateNewAttribute(false);
                }}
                handleSave={addAttribute}
            />
            {attributeToView && (
                <AttributeInfoModal
                    open
                    handleClose={() => {
                        setAttributeToView(undefined);
                    }}
                    attribute={attributeToView}
                />
            )}
        </>
    );
};

export default StepTwo;

import React from 'react';
import { Box, Typography } from '@material-ui/core';

interface DashboardTitleProps {
    title: string;
    subtitle: string;
    button?: React.ReactNode;
    isTechPassUser?: boolean;
}

const DashboardTitle = ({ title, subtitle, button }: DashboardTitleProps) => (
    <>
        <Box display="flex" justifyContent="space-between" style={{ padding: '0 0 24px', flex: 1 }}>
            <div>
                <Typography variant="h1" color="textPrimary" style={{ marginBottom: 8 }}>
                    {title}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                    {subtitle}
                </Typography>
            </div>
            {button}
        </Box>
    </>
);

export default DashboardTitle;

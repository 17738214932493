import React, { FC, RefObject } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography, Divider, Box, Button } from '@material-ui/core';
import TeamIcon from '@material-ui/icons/FolderSharedOutlined';
import colors from 'colors';

import { Team } from './../../types';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        position: 'fixed',
        marginTop: 'calc(72px + 32px)',
        marginBottom: 'calc(32px + 38px)',
        top: 0,
        bottom: 0,
    },
    container: {
        width: 292,
        maxHeight: 680,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    listContainer: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        height: '100%',
        overflow: 'auto',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 32px',
        marginTop: 24,
        color: colors.darkGrey,
        fontWeight: 400,
        '&.active': {
            fontWeight: 500,
            color: colors.blue,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

interface TeamItemProps {
    teamId: string;
    name: string;
    selected: boolean;
    onClickTeam: (id: string) => void;
}

const TeamItem = ({ teamId, name, selected, onClickTeam }: TeamItemProps) => {
    const classes = useStyles();
    return (
        <li className={[classes.listItem, selected ? 'active' : ''].join(' ')} onClick={() => onClickTeam(teamId)}>
            <TeamIcon style={{ marginRight: 12, height: 28 }} />
            <span>{name}</span>
        </li>
    );
};

interface TeamListProps {
    teams: Team[];
    selectedTeam: Team;
    onClickTeam: (id: string) => void;
    teamItemRef: RefObject<HTMLLIElement>;
    showCreateTeamDialog: (show: boolean) => void;
}

const TeamList = ({
    teams,
    showCreateTeamDialog,
    selectedTeam = { id: '', name: '', createdOn: '', users: [] },
    onClickTeam,
    teamItemRef,
}: TeamListProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Paper className={classes.container}>
                <Typography style={{ fontSize: 20, fontWeight: 500, padding: '24px 32px' }}>My teams</Typography>
                <Divider />
                <Box component="div" overflow="auto">
                    <ul className={classes.listContainer}>
                        {teams &&
                            teams.map((team: any, index: number) => (
                                <TeamItem
                                    key={index}
                                    teamId={team.id}
                                    name={selectedTeam.id === team.id ? selectedTeam.name : team.name}
                                    selected={selectedTeam.id === team.id}
                                    onClickTeam={onClickTeam}
                                />
                            ))}
                        <li ref={teamItemRef}>&nbsp;</li>
                    </ul>
                </Box>
                <div style={{ padding: 24, marginTop: 'auto' }}>
                    <Button variant="outlined" color="primary" fullWidth onClick={() => showCreateTeamDialog(true)}>
                        Create new team
                    </Button>
                </div>
            </Paper>
        </Box>
    );
};

export default TeamList;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button, Box } from '@material-ui/core';
import debounce from 'lodash/debounce';
import api from 'api';
import colors from 'colors';
import BaseHeader from 'components/baseHeader';
import EmptyPage from 'components/emptyPage';
import SaveStatus, { ESaveStatus } from 'components/saveStatus';
import CreateTeamModal from './CreateTeamModal';
import { teamHasAudienceOrCampaign } from './teamUtils';
import { ApiResponse } from 'apisauce';

import { RootState } from 'reducers';
import {
    getTeams,
    updateTeamNameSuccess,
    updateTeamList,
    updateCreateTeam,
    updateSelectedTeam,
    deleteTeam,
    showCreateTeamDialog,
} from './teamSlice';
import { Team, Campaign, Audience } from 'types';
import { User, getUser } from 'reducers/userSlice';

import TeamList from './teamList';
import TeamProfile from './teamProfile';
import TeamMembers from './teamMembers';
import CopyRight from '../../components/copyRight';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: '100%',
        background: colors.backgroundGrey,
        display: 'flex',
        flexDirection: 'column',
    },
    subcontainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        height: '100vh',
    },
}));

const autoSave = debounce((id, name) => {
    return api.updateTeamName(id, name);
}, 500);

interface TeamNameUpdate {
    id: string;
    name: string;
    lastUpdated: number | Date;
}

interface TeamSettingsProps {
    getTeams: () => void;
    updateCreateTeam: (team: Team) => void;
    updateSelectedTeam: (team: Team) => void;
    updateTeamList: (team: Team) => void;
    updateTeamNameSuccess: (response: TeamNameUpdate) => void;
    deleteTeam: (id: string) => void;
    teams: Team[];
    selectedTeam: Team;
    showCreateTeamDialog: (isShow: boolean) => void;
    createTeamDialog: boolean;
    user: User;
    getUser: () => void;
}

const TeamSettings = ({
    getTeams,
    teams,
    updateCreateTeam,
    selectedTeam,
    updateSelectedTeam,
    updateTeamList,
    updateTeamNameSuccess,
    deleteTeam,
    showCreateTeamDialog,
    createTeamDialog,
    user,
    getUser,
}: TeamSettingsProps) => {
    const classes = useStyles();
    const teamItemRef = React.createRef<HTMLLIElement>();
    const [teamName, setTeamName] = React.useState<string>('');
    const [editId, setEditId] = React.useState<string>('');
    const [isUpdateName, setIsUpdateName] = React.useState<boolean>(false);
    const [saveStatus, setSaveStatus] = React.useState<ESaveStatus>(ESaveStatus.LAST_UPDATED);
    const [isTeamDeletable, setIsTeamDeletable] = React.useState<boolean>(false);
    const [teamCampaigns, setTeamCampaigns] = React.useState<Campaign[]>([]);
    const [teamAudiences, setTeamAudiences] = React.useState<Audience[]>([]);

    useEffect(() => {
        if (user && !user.email) {
            getUser();
        }
    }, [getUser, user]);

    useEffect(() => {
        if (createTeamDialog) {
            return function cleanup() {
                showCreateTeamDialog(false);
            };
        }
    }, []);

    useEffect(() => {
        if (!(selectedTeam || {}).id) {
            getTeams();
        }
    }, [getTeams]);

    useEffect(() => {
        if ((selectedTeam || {}).name) {
            setIsUpdateName(false);
            setTeamName(selectedTeam.name);
        }
    }, [selectedTeam]);

    useEffect(() => {
        const update = async () => {
            setSaveStatus(ESaveStatus.SAVING);
            updateTeamNameSuccess({ id: editId, name: teamName, lastUpdated: Date.now() });
            const response = await autoSave(editId, teamName);
            setIsUpdateName(false);
            if (response && response.ok) {
                setSaveStatus(ESaveStatus.SAVED);
                setTimeout(() => {
                    setSaveStatus(ESaveStatus.LAST_UPDATED);
                }, 5000);
            } else {
                setSaveStatus(ESaveStatus.LAST_UPDATED);
            }
        };
        if (editId && isUpdateName) {
            update();
        }
    }, [editId, teamName, updateTeamNameSuccess, isUpdateName, setIsUpdateName]);

    useEffect(() => {
        if (selectedTeam && selectedTeam.id) {
            api.getTeamCampaigns(selectedTeam.id).then((response: ApiResponse<any>) => {
                if (response.ok) setTeamCampaigns(response.data);
            });
            api.getTeamAudiences(selectedTeam.id).then((response: ApiResponse<any>) => {
                if (response.ok) setTeamAudiences(response.data);
            });
        }
    }, [selectedTeam]);

    useEffect(() => {
        setIsTeamDeletable(!teamHasAudienceOrCampaign(teamAudiences, teamCampaigns));
    }, [teamCampaigns, teamAudiences]);

    const handleTeamName = (id: string, name: string) => {
        if (id) {
            setTeamName(name);
            setEditId(id);
            setIsUpdateName(true);
        }
    };

    const handleClickTeam = (id: string) => {
        const selectedTeam = teams.find((team) => team.id === id);
        if (selectedTeam) {
            updateSelectedTeam(selectedTeam);
            setIsUpdateName(false);
        }
    };

    return (
        <>
            <Typography component="div" className={classes.container}>
                <BaseHeader
                    title="Team settings"
                    content={
                        selectedTeam && selectedTeam.updatedOn ? (
                            <SaveStatus status={saveStatus} lastUpdated={new Date(selectedTeam.updatedOn)} />
                        ) : null
                    }
                    backButton
                />
                <CreateTeamModal
                    open={createTeamDialog}
                    handleClose={() => {
                        showCreateTeamDialog(false);
                    }}
                    createTeam={(name: string) => {
                        if (name) {
                            api.createTeam({ name }).then((response: ApiResponse<any>) => {
                                if (response.ok) {
                                    updateCreateTeam(response.data);
                                    updateSelectedTeam(response.data);
                                }
                                showCreateTeamDialog(false);
                            });
                        }
                    }}
                />

                {teams && teams.length ? (
                    <Box
                        display="flex"
                        style={{
                            position: 'relative',
                            height: 'calc(100% - 72px)',
                            padding: '32px 24px',
                            overflow: 'auto',
                        }}
                    >
                        <TeamList
                            teams={teams}
                            showCreateTeamDialog={showCreateTeamDialog}
                            selectedTeam={selectedTeam}
                            onClickTeam={handleClickTeam}
                            teamItemRef={teamItemRef}
                        />
                        <Box flex={1} style={{ marginLeft: 'calc(292px + 20px)' }}>
                            <TeamProfile
                                setTeamName={handleTeamName}
                                selectedTeam={selectedTeam}
                                teamName={teamName}
                                deleteTeam={deleteTeam}
                                user={user}
                                isTeamDeletable={isTeamDeletable}
                            />
                            <TeamMembers
                                style={{ marginTop: 20 }}
                                selectedTeam={selectedTeam}
                                updateTeamList={updateTeamList}
                                updateSelectedTeam={updateSelectedTeam}
                                selectedUser={user}
                                getTeams={getTeams}
                            />
                        </Box>
                    </Box>
                ) : (
                    <div style={{ overflow: 'auto', flexGrow: 1, height: '100%' }}>
                        <EmptyPage title="No existing teams" subtitle="You are currently not part of any teams">
                            <Button
                                onClick={() => showCreateTeamDialog(true)}
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 24 }}
                            >
                                Create a team
                            </Button>
                        </EmptyPage>
                    </div>
                )}
            </Typography>
            <CopyRight placement={'postLogin'} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    teams: state.teamSlice.teams,
    selectedTeam: state.teamSlice.selectedTeam,
    createTeamDialog: state.teamSlice.createTeamDialog,
    user: state.userSlice.user,
});

const mapDispatchToProps = {
    getTeams,
    updateCreateTeam,
    updateSelectedTeam,
    updateTeamList,
    updateTeamNameSuccess,
    deleteTeam,
    showCreateTeamDialog,
    getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSettings);

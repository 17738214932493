import React, { RefObject, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ApiResponse } from 'apisauce';
import { AppBar, Box, Button, Dialog, IconButton, InputLabel, TextField, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/ClearRounded';
import InfoIcon from '@material-ui/icons/Info';
import {
    Asset,
    Attachments,
    Audience,
    BackendErrorMessage,
    CampaignStateType,
    EUploadStatus,
    UnlayerTemplateJsonPayload,
    UserAttributeBackend,
} from 'types';
import { getFileExtension, getRootDir, isHiddenFile, removeRootDirFromPaths, validateFileExtension } from 'utils/file';

import CopyRight from 'components/copyRight';
import AlertDialog from 'components/alertDialog';
import ConfirmModal from 'components/confirmModal';
import DropZone from './dropZone';
import UnlayerEditor from './unlayerEditor';
import EmailPreview from './emailPreview';
import {
    AlertMessage,
    assetsModalBackendErrors,
    assetsModalErrors,
    IAssetsModalBackendErrors,
} from './assetsModalErrors';

import colors from 'colors';
import api from 'api';
import { unzipFile } from 'utils/zip';
import { v4 as uuidv4 } from 'uuid';
import { getInvalidAndMismatchedAudienceAttributes, shortenCloudfrontImageUrlsToLocalUrls } from '../campaignUtils';
import { getCampaignById } from 'features/campaigns/campaignSlice';
import store from 'store';

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        margin: '24px 0',
    },
    closeButton: {
        marginLeft: 0,
        color: colors.white,
        padding: 0,
        marginRight: 16,
    },
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    textField: {
        marginBottom: 32,
    },
    sideMenu: {
        width: 380,
        minWidth: 380,
        overflow: 'auto',
    },
    saveBtn: {
        background: colors.darkBlue,
        color: colors.white,
        padding: '12px 24px',
        fontSize: 14,
        margin: '16px 0',
        '&:hover': {
            background: colors.mediumDarkBlue,
            color: colors.white,
        },
    },
    sideMenuTitle: {
        padding: '24px 32px',
    },
    formContainer: {
        padding: '0 32px 32px 32px',
    },
    content: {
        flex: 1,
        background: colors.backgroundGrey,
        padding: 0,
    },
    addDropAnimation: {
        animation: '$dropZone 0.3s ease alternate infinite',
    },
    dropZone: {
        margin: '48px 32px',
        height: 'calc(100% - 64px)',
        background: colors.white,
        borderRadius: 4,
        border: `1px dashed ${colors.coolGrey}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 0,
        outlineColor: colors.yellow,
    },
    '@keyframes dropZone': {
        from: { padding: 0 },
        to: { padding: 24 },
    },
    proTipContainer: {
        background: colors.backgroundGrey,
        padding: 16,
        borderRadius: 4,
        marginBottom: 32,
    },
}));

interface AssetsModalProps {
    campaignId: string;
    asset: Asset;
    open: boolean;
    handleClose: () => void;
    createOrUpdateAsset: (asset: Asset) => void;
    updateAsset: (type: string, payload: any) => void;
    handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    hasChanges: (id: string) => boolean;
    campaignState: CampaignStateType;
    campaignAssets: Asset[];
    isUsedInWorkflow: (asset: Asset) => boolean;
    campaignAudiences?: Audience[];
    useUnlayerEditor: boolean;
}

enum FileUploadType {
    EMAIL_ASSET,
    ATTACHMENT,
}

const SEVEN_MB = 7000 * 1024;

const AssetsModal = ({
    campaignId,
    asset,
    open,
    handleClose,
    createOrUpdateAsset,
    updateAsset,
    handleOnChange,
    hasChanges,
    campaignState,
    campaignAssets,
    isUsedInWorkflow,
    campaignAudiences,
    useUnlayerEditor,
}: AssetsModalProps) => {
    const classes = useStyles();
    const [showError, setShowError] = useState(false);
    const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);
    const [openConfirmChanges, setOpenConfirmChanges] = useState(false);
    const [inProgressHtml, setInProgressHtml] = useState<boolean>(false);
    const [assetAttachments, setAssetAttachments] = useState<Attachments[]>([]);
    const [isUpdateAttachments, setIsUpdateAttachments] = useState<boolean>(false);
    const [attachmentsPayload, setAttachmentsPayload] = useState<FormData>(new FormData());
    const [assetTemplateUrl, setAssetTemplateUrl] = useState<string>('');
    const dropZone: RefObject<HTMLDivElement> = React.createRef();
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();
    const folderInputRef: RefObject<HTMLInputElement> = React.createRef();
    const zipFileInputRef: RefObject<HTMLInputElement> = React.createRef();
    const [openUnSavedChanges, setOpenUnSavedChanges] = useState(false);
    const [audienceAttributes, setAudienceAttributes] = useState<UserAttributeBackend[]>();
    const unlayerTemplateJson = useRef<UnlayerTemplateJsonPayload | null>(null);
    const unlayerEditorRef: React.MutableRefObject<any> = useRef(null);
    const [showUnlayerEditor, setShowUnlayerEditor] = useState(false);
    const isViewingAsset =
        campaignState === CampaignStateType.SCHEDULED ||
        (campaignState !== CampaignStateType.DRAFT && isUsedInWorkflow(asset)) ||
        asset.hasMetrics;
    const isAddingAsset = asset.isNew;

    useEffect(() => {
        if (asset.template) {
            setAssetTemplateUrl(
                `${process.env.REACT_APP_CLOUDFRONT_URL}/${campaignId}/assets/${asset.id}/${asset.template}.html`,
            );
        }

        const attachments = asset.attachments
            ? asset.attachments.map((attachment) => {
                  return { name: (attachment || '').toString(), status: EUploadStatus.DELETED };
              })
            : [];
        setAssetAttachments([...attachments]);
    }, [asset]);

    useEffect(() => {
        if (isUpdateAttachments) {
            setIsUpdateAttachments(false);
            api.uploadAttachments(attachmentsPayload, campaignId, asset.id).then((response: ApiResponse<any>) => {
                if (response.ok) {
                    const attachments = assetAttachments.map((attachment: Attachments) => {
                        let itemFound = false;
                        for (const file of attachmentsPayload.values()) {
                            if (typeof file === 'object') {
                                if (file!.name === attachment.name) {
                                    itemFound = true;
                                }
                            }
                        }
                        return itemFound ? { ...attachment, status: EUploadStatus.UPLOADED } : attachment;
                    });
                    setAssetAttachments([...attachments]);
                    setTimeout(() => {
                        const attachmentsUpdated = attachments.map((attachment: Attachments) => {
                            return attachment.status === EUploadStatus.UPLOADED
                                ? { ...attachment, status: EUploadStatus.DELETED }
                                : attachment;
                        });
                        setAssetAttachments([...attachmentsUpdated]);
                    }, 1000);
                    const map: { [key: string]: boolean } = {};
                    for (const att of attachments) {
                        map[att.name] = true;
                    }
                    updateAsset('attachments', Object.keys(map));
                } else {
                    for (const file of attachmentsPayload.values()) {
                        if (typeof file === 'object') {
                            updateRemoveAttachment(file!.name);
                        }
                    }
                }
            });
        }
    }, [asset, assetAttachments, updateAsset, attachmentsPayload, isUpdateAttachments]);

    // Side effect to load the json template
    useEffect(() => {
        if (!isViewingAsset && asset.template) {
            api.getUnlayerTemplateJson(campaignId, asset.id).then((response: ApiResponse<any>) => {
                if (response.ok) {
                    setShowUnlayerEditor(true);
                    unlayerTemplateJson.current = response.data;
                } else {
                    setShowUnlayerEditor(false);
                    unlayerTemplateJson.current = null;
                }
            });
        } else {
            setShowUnlayerEditor(useUnlayerEditor);
            unlayerTemplateJson.current = null;
        }
    }, [asset.id]);

    // Side effect to get audience attributes for unlayer editor merge tags each time a new audience is selected
    useEffect(() => {
        // Clicking on audience tab then assets tab without selecting audience results in campaignAudiences = [{empty_audience_object}]
        if (campaignAudiences && campaignAudiences.length > 0 && campaignAudiences[0].id) {
            const getAttributePromises = campaignAudiences.map((audience) => api.getAttributes(audience.id));
            Promise.all(getAttributePromises).then((responses: ApiResponse<any>[]) => {
                const audienceAttributes = responses.flatMap((response: ApiResponse<any>, i) => {
                    const id = campaignAudiences[i].id;
                    const attribute = response.data[id];
                    return attribute;
                });
                setAudienceAttributes(audienceAttributes);
            });
        } else {
            setAudienceAttributes(undefined);
        }
    }, [campaignAudiences]);

    const handleCloseAlert = () => {
        if (fileInputRef.current) fileInputRef.current.value = '';
        if (folderInputRef.current) folderInputRef.current.value = '';
        if (zipFileInputRef.current) zipFileInputRef.current.value = '';
        setAlertMessage(null);
    };

    const updateAssetValidationErrors = (htmlString: string) => {
        updateAsset('validationErrors', getInvalidAndMismatchedAudienceAttributes(htmlString, audienceAttributes));
    };

    const validateUploadFiles = (files: File[]) => {
        const validImageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/gif'];
        const validImageExtensions = ['png', 'jpg', 'jpeg', 'gif'];

        let htmlFilesCount = 0;
        let isValidImage = true;

        for (const file of files) {
            const fileType = file.type;
            const fileExtension = getFileExtension(file.name);

            switch (true) {
                case fileType === 'text/html':
                case fileExtension === 'html':
                    htmlFilesCount++;
                    break;
                case fileType === 'application/json':
                case fileExtension === 'json':
                    break;
                case fileType && !validImageTypes.includes(fileType):
                case fileExtension && !validImageExtensions.includes(fileExtension):
                    isValidImage = false;
                    break;
                case !isHiddenFile(file.name) && !fileType:
                    isValidImage = false;
                    if (validImageExtensions.includes(fileExtension)) isValidImage = true;
            }
        }

        if (htmlFilesCount === 1 && isValidImage) return true;

        if (!isValidImage) return setAlertMessage(assetsModalErrors.UNSUPPORTED_FILE_FORMATS);

        if (htmlFilesCount > 1) return setAlertMessage(assetsModalErrors.MORE_THAN_ONE_HTML);

        if (htmlFilesCount === 0) return setAlertMessage(assetsModalErrors.HTML_NOT_FOUND);

        return false;
    };

    const validateHtmlFiles = (paths: string[], root: string) => {
        let htmlFilesCount = 0;

        for (const path of paths) {
            const fileName = path.substring(path.lastIndexOf('/') + 1);
            if (validateFileExtension(fileName, 'html')) htmlFilesCount++;
        }

        if (htmlFilesCount === 1) return true;

        if (htmlFilesCount === 0) {
            setAlertMessage(assetsModalErrors.HTML_NOT_FOUND);
            return false;
        }

        if (htmlFilesCount > 1) {
            setAlertMessage(assetsModalErrors.MORE_THAN_ONE_HTML);
            return false;
        }

        return false;
    };

    const validateEmailSize = (files: File[], fileUploadType: FileUploadType) => {
        let newEmailSize = 0;
        for (const file of files) {
            // UnlayerEditor json template file for internal use only & not sent out
            if (file.name.includes('unlayer.json')) {
                continue;
            }
            newEmailSize += file.size;
            if (newEmailSize > SEVEN_MB) {
                setAlertMessage(assetsModalErrors.SIZE_EXCEEDED);
                return false;
            }
        }
        return true;
    };

    const isAssetValid = () => {
        return asset.id && asset.name && asset.senderName && asset.subject && (showUnlayerEditor || asset.template);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (validateUploadFiles(e.target.files as any)) {
            const path = [...Array(e.target.files ? e.target.files.length : 0)].fill('');
            e.target.files![0].text().then((htmlString) => updateAssetValidationErrors(htmlString));
            upload(e.target.files as any, '', path);
        }
    };

    const unzipAndUploadFile = (zippedFileArrayBuffer: ArrayBuffer) => {
        unzipFile(zippedFileArrayBuffer).then((results) => {
            console.log(results);
            if (
                validateHtmlFiles(results.paths, '') &&
                validateUploadFiles(results.files) &&
                validateEmailSize(results.files, FileUploadType.EMAIL_ASSET)
            ) {
                results.files
                    .find((file) => file.type === 'text/html' || file.name.indexOf('.html') >= 0)
                    ?.text()
                    .then((htmlString) => updateAssetValidationErrors(htmlString));
                upload(results.files, '', results.paths);
            }
        });
    };

    const handleUploadZipFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.files?.[0].arrayBuffer().then((zippedFileArrayBuffer) => unzipAndUploadFile(zippedFileArrayBuffer));
    };

    const handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files as any;

        if (validateEmailSize(files, FileUploadType.ATTACHMENT)) {
            const data = new FormData();
            data.append('key', asset.id);
            const attachments = [];

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                data.append('files', file);

                const itemFound = assetAttachments.find((attachment: Attachments) => attachment.name === files[i].name);
                if (!itemFound) {
                    attachments.push({
                        name: files[i].name,
                        status: EUploadStatus.UPLOADING,
                    });
                }
            }
            setAssetAttachments([...assetAttachments, ...attachments]);
            setAttachmentsPayload(data);
            setIsUpdateAttachments(true);
        }
    };

    const upload = (files: File[], root?: string, paths?: string[]) => {
        if (files.length) {
            const data = new FormData();
            data.append('key', asset.id!);
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (!isHiddenFile(file.name)) {
                    let fileName = file.name;
                    let path = file.name;
                    if (root) {
                        path = paths![i].replace(`/${root}/`, '');
                    } else if (paths && paths[i].startsWith('/')) {
                        path = paths![i].replace('/', '');
                    }
                    // Rename html file to prevent iframe caching & bypass slow cloudfront invalidation
                    if (file.type === 'text/html') {
                        const oldFileName = file.name;
                        const newFileName = `${uuidv4()}.html`;
                        path = path.replace(oldFileName, newFileName);
                        fileName = newFileName;
                    }
                    data.append('paths', path);
                    // axios throws error if only some files have webkitrelativepath so all files are reinstantiated
                    data.append('files', new File([files[i]], fileName, { type: files[i].type }));
                }
            }
            setInProgressHtml(true);

            api.uploadCampaignAsset(campaignId, asset.id, data)!.then((response: ApiResponse<any>) => {
                setInProgressHtml(false);
                if (response.ok) {
                    updateUploadAssets(data);
                } else if (response.data.message) {
                    setAlertMessage(assetsModalBackendErrors[response.data.message as keyof IAssetsModalBackendErrors]);
                }
            });
        }
    };

    const updateUploadAssets = (data: FormData) => {
        let fileName = '';
        for (const file of data.values()) {
            if (typeof file === 'object' && !fileName) {
                fileName = file.type === 'text/html' ? (file.name || '').replace('.html', '') : '';
            }
        }
        updateAsset('id', asset.id!);
        updateAsset('template', fileName);
    };

    const validateZippedUpload = (files: File[]) => {
        // If files are dragged from within a zipped folder, no information can be read
        if (files.length === 0) {
            return setAlertMessage(assetsModalErrors.UNSUPPORTED_FILE_FORMATS);
        }

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type === 'application/zip' || file.type === 'application/x-zip-compressed') {
                return true;
            }
        }
        return false;
    };

    const handleDropFolder = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const items = e.dataTransfer.items as any;
        const root = getRootDir(items);
        const queue = [];
        const paths: string[] = [];
        const files: File[] = [];
        for (const item of items) {
            if (item.webkitGetAsEntry) {
                queue.push(item.webkitGetAsEntry());
            } else if (items.getAsEntry) {
                queue.push(item.getAsEntry());
            } else {
                alert('Your browser does not support folder upload');
            }
        }
        while (queue.length) {
            const item = queue.shift();
            if (item.isDirectory) {
                const dirReader = item.createReader();
                const readPromise = new Promise((resolve, reject) => {
                    dirReader.readEntries(
                        (entries: any) => {
                            for (const en of entries) {
                                queue.push(en);
                            }
                            resolve(true);
                        },
                        () => reject(false),
                    );
                });
                await readPromise;
            } else {
                const getFilePromise = new Promise<File>((resolve, reject) => {
                    item.file(
                        (file: File) => {
                            if (file.type === '' && file.name.indexOf('.html') >= 0) {
                                const blob = new Blob([file]);
                                file = new File([blob], file.name, { type: 'text/html' });
                            }
                            resolve(file);
                        },
                        () => {
                            reject(`get file failed! ${item.fullPath}`);
                        },
                    );
                });
                files.push(await getFilePromise);
                paths.push(item.fullPath);
            }
        }
        if (validateZippedUpload(files) && files.length < 2) {
            unzipAndUploadFile(await files[0].arrayBuffer());
        } else if (
            validateUploadFiles(files) &&
            validateHtmlFiles(paths, root) &&
            validateEmailSize(files, FileUploadType.EMAIL_ASSET)
        ) {
            files
                .find((file) => file.type === 'text/html' || file.name.indexOf('.html') >= 0)
                ?.text()
                .then((htmlString) => updateAssetValidationErrors(htmlString));
            upload(files, root, paths);
        }
    };

    const handleUploadFolder = (e: any) => {
        const items = e.target.files;
        const files = Array.from(items) as File[];
        const paths: string[] = [];
        for (const item of items) {
            if (item.webkitRelativePath) {
                paths.push(item.webkitRelativePath);
            } else {
                alert('Your browser does not support folder upload');
                return false;
            }
        }
        const root = paths[0].split('/')[0];
        if (
            validateHtmlFiles(paths, '') &&
            validateUploadFiles(files) &&
            validateEmailSize(files, FileUploadType.EMAIL_ASSET)
        ) {
            files
                .find((file) => file.type === 'text/html' || file.name.indexOf('.html') >= 0)
                ?.text()
                .then((htmlString) => updateAssetValidationErrors(htmlString));
            upload(files, '', removeRootDirFromPaths(paths, root));
        }
    };

    /**
     * Function used by UnlayerEditor to upload exported Html & Json email templates
     * @param data Data object of unlayer json template & string html output
     */
    const handleSaveAndCloseUnlayer = () => {
        if (unlayerEditorRef.current !== null) {
            unlayerEditorRef.current.editor.exportHtml((data: { html: string; design: any }) => {
                const { html, design } = data;
                // Do nothing if no exports
                if (!html || !design) {
                    return;
                }
                // Shorten all cloudfront image urls to local urls as backend send mail function uses cid tags with attached images
                const transformedHtml = shortenCloudfrontImageUrlsToLocalUrls(html);

                // Rename html file to prevent iframe caching & bypass slow cloudfront invalidation
                const htmlFileName = uuidv4();
                const htmlFile: File = new File([transformedHtml], `${htmlFileName}.html`, { type: 'text/html' });
                const jsonFile: File = new File([JSON.stringify(design)], 'unlayer.json', {
                    type: 'application/json',
                });
                const files = [htmlFile, jsonFile];
                const newAsset = { ...asset, template: htmlFileName, isNew: false };
                if (!validateUploadFiles(files) || !validateEmailSize([htmlFile], FileUploadType.EMAIL_ASSET)) return;

                const formData = new FormData();

                formData.append('key', asset.id!);
                formData.append('files', htmlFile);
                formData.append('files', jsonFile);
                formData.append('template', newAsset.template);

                newAsset.name && formData.append('name', newAsset.name);
                newAsset.senderName && formData.append('senderName', newAsset.senderName);
                newAsset.subject && formData.append('subject', newAsset.subject);

                setInProgressHtml(true);
                api.uploadCampaignUnlayerAsset(campaignId, asset.id, formData)
                    .then((response: ApiResponse<any>) => {
                        if (response.ok) {
                            createOrUpdateAsset(newAsset);
                            setShowError(false);
                            handleClose();
                        } else if (response.data && response.data.message) {
                            setAlertMessage(
                                assetsModalBackendErrors[response.data.message as keyof IAssetsModalBackendErrors],
                            );
                        }
                    })
                    .finally(() => {
                        setInProgressHtml(false);
                    });
            });
        }
    };

    const handleSaveAndClose = (isNew = true) => {
        createOrUpdateAsset({ ...asset, isNew: isNew });
        setShowError(false);
        handleClose();
    };

    /**
     * Callback used by UnlayerEditor to upload image files to s3.
     * @param imageFile File object of image to upload
     * @returns String of cloudfront url to image depending on deployment environment or void if response has errors
     *
     */
    const handleUploadImageToS3 = async (imageFile: File): Promise<string | void> => {
        const data = new FormData();
        data.append('files', new File([imageFile], imageFile.name, { type: imageFile.type }));
        const response: ApiResponse<string[], BackendErrorMessage> = await api.uploadImages(campaignId, asset.id, data);
        if (response.ok) {
            if (!response.data || !response.data[0]) {
                setAlertMessage(assetsModalBackendErrors['400 INVALID_FILE_NAME']);
                return;
            }
            const hostedImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/${campaignId}/assets/${asset.id}/images/${response.data[0]}`;
            return hostedImageUrl;
        } else if (response.data && response.data.message) {
            setAlertMessage(assetsModalBackendErrors[response.data.message]);
        }
    };

    const handleRemoveAttachment = (name: string) => {
        api.deleteAttachment(campaignId, asset.id, name)!.then((response) => {
            if (response.ok) {
                updateRemoveAttachment(name);
            }
        });
    };

    const updateRemoveAttachment = (name: string) => {
        const attachments = asset.attachments.filter((attachment) => (attachment || '').toString() !== name);
        updateAsset('attachments', attachments);
    };

    const handleSaveAndExit = () => {
        setShowError(true);
        if (isAssetValid()) {
            if (showUnlayerEditor) {
                handleSaveAndCloseUnlayer();
            } else {
                handleSaveAndClose(false);
            }
        }
    };

    const hasAssetUnSavedChanges = () => {
        if (asset.id) {
            if (asset.name === '' && asset.senderName === '' && asset.subject === '' && asset.template === '') {
                return false;
            }
            if (campaignAssets.length) {
                const assetObj = campaignAssets.find((a) => a.id === asset.id);
                if (
                    !assetObj ||
                    assetObj.name !== asset.name ||
                    assetObj.senderName !== asset.senderName ||
                    assetObj.subject !== asset.subject ||
                    assetObj.template !== asset.template
                ) {
                    return true;
                }
            } else {
                return true;
            }
        }
        return false;
    };

    const handleDeleteAssetTemplate = () => {
        updateAsset('template', '');
    };

    const getTitle = () => {
        if (isAddingAsset) return 'Add email asset';
        return isViewingAsset ? 'View email asset' : 'Edit email asset';
    };

    return (
        <>
            <Dialog open={open} fullScreen={true}>
                <AppBar className={classes.appBar}>
                    <Toolbar style={{ padding: '0 32px' }}>
                        {(isAddingAsset || isViewingAsset) && (
                            <IconButton
                                edge="start"
                                className={classes.closeButton}
                                onClick={() => {
                                    setShowError(false);
                                    if (hasAssetUnSavedChanges()) {
                                        setOpenUnSavedChanges(true);
                                    } else {
                                        handleClose();
                                    }
                                }}
                                aria-label="close"
                            >
                                <CloseIcon style={{ fontSize: 27.5 }} />
                            </IconButton>
                        )}
                        <Typography variant="h6" className={classes.title}>
                            {getTitle()}
                        </Typography>
                        {!isViewingAsset && (
                            <Button autoFocus onClick={handleSaveAndExit} className={classes.saveBtn}>
                                Save and exit
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
                <Typography className={classes.container} component="div">
                    <form
                        autoComplete="off"
                        style={{ borderRight: `1px solid ${colors.grey}` }}
                        className={classes.sideMenu}
                    >
                        <Typography className={classes.sideMenuTitle} variant="h6" style={{ fontSize: 16 }}>
                            Settings
                        </Typography>
                        <div className={classes.formContainer}>
                            <InputLabel>Name of email asset</InputLabel>
                            <TextField
                                autoComplete="emailAssetName"
                                disabled={isViewingAsset}
                                variant="outlined"
                                className={classes.textField}
                                fullWidth={true}
                                name="name"
                                type="text"
                                value={asset.name}
                                onChange={handleOnChange}
                                error={showError && !asset.name}
                                helperText={showError && !asset.name ? 'Enter the name of email asset' : ''}
                            />
                            <InputLabel>Sender name</InputLabel>
                            <TextField
                                disabled={isViewingAsset}
                                variant="outlined"
                                className={classes.textField}
                                fullWidth={true}
                                name="senderName"
                                type="text"
                                value={asset.senderName}
                                onChange={handleOnChange}
                                error={showError && !asset.senderName}
                                helperText={showError && !asset.senderName ? 'Enter the sender name' : ''}
                            />
                            <InputLabel>Subject</InputLabel>
                            <TextField
                                disabled={isViewingAsset}
                                variant="outlined"
                                className={classes.textField}
                                fullWidth={true}
                                name="subject"
                                type="text"
                                value={asset.subject}
                                onChange={handleOnChange}
                                error={showError && !asset.subject}
                                helperText={showError && !asset.subject ? 'Enter the subject' : ''}
                            />
                            {showUnlayerEditor && (
                                <Box className={classes.proTipContainer}>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            color: colors.blue,
                                            marginBottom: 5,
                                        }}
                                    >
                                        <InfoIcon style={{ marginRight: 5 }} />
                                        <Typography variant="h6" style={{ fontSize: 16 }}>
                                            Pro Tip
                                        </Typography>
                                    </Box>
                                    <Typography component="div">
                                        To insert an existing user attribute in your email asset, add a{' '}
                                        <Box fontWeight="fontWeightMedium" display="inline">
                                            text
                                        </Box>{' '}
                                        block from content section and click{' '}
                                        <Box fontWeight="fontWeightMedium" display="inline">
                                            Merge Tags
                                        </Box>{' '}
                                        in the tool bar.
                                    </Typography>
                                </Box>
                            )}
                        </div>
                        {/* <Divider style={{ backgroundColor: colors.coolGrey }} />
                            <div className={classes.formContainer}>
                                <Box>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography style={{ fontSize: 14 }}>
                                            {campaignState === CampaignStateType.SCHEDULED
                                                ? 'Email attachments'
                                                : 'Attach files'}
                                        </Typography>
                                        {campaignState !== CampaignStateType.SCHEDULED && (
                                            <Link
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: 14,
                                                    cursor: 'pointer',
                                                }}
                                                component="label"
                                            >
                                                Insert attachment
                                                <input
                                                    onChange={handleAttachmentChange}
                                                    type="file"
                                                    accept=".pdf"
                                                    style={{ display: 'none' }}
                                                    multiple={true}
                                                />
                                            </Link>
                                        )}
                                    </Box>
                                    <Box style={{ marginTop: 24 }}>
                                        {!assetAttachments.length ? (
                                            <Typography style={{ color: colors.darkGrey, fontSize: 14 }}>
                                                No files attached to this email
                                            </Typography>
                                        ) : (
                                            <Box>
                                                {assetAttachments.map((attachment: Attachments, index: number) => {
                                                    return (
                                                        <Attachment
                                                            key={index}
                                                            attachment={attachment}
                                                            onRemove={handleRemoveAttachment}
                                                            campaignState={campaignState}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </div> */}
                    </form>
                    {showUnlayerEditor ? (
                        <UnlayerEditor
                            asset={asset}
                            audienceAttributes={audienceAttributes}
                            unlayerTemplateJson={unlayerTemplateJson}
                            handleUploadImageToS3={handleUploadImageToS3}
                            unlayerEditorRef={unlayerEditorRef}
                            inProgressHtml={inProgressHtml}
                            campaignAudiences={campaignAudiences}
                            validateHtmlFileDataFields={updateAssetValidationErrors}
                        />
                    ) : !asset.template ? (
                        <DropZone
                            handleDropFolder={handleDropFolder}
                            handleFileChange={handleFileChange}
                            handleUploadFolder={handleUploadFolder}
                            handleUploadZipFile={handleUploadZipFile}
                            inProgressHtml={inProgressHtml}
                            dropZoneRef={dropZone}
                            fileInputRef={fileInputRef}
                            folderInputRef={folderInputRef}
                            zipFileInputRef={zipFileInputRef}
                            error={showError && !asset.template}
                        />
                    ) : (
                        <EmailPreview
                            asset={asset}
                            emailTemplate={assetTemplateUrl}
                            assetId={asset.id}
                            campaignId={campaignId}
                            deleteAsset={handleDeleteAssetTemplate}
                            isViewingAsset={isViewingAsset}
                            campaignAudiences={campaignAudiences}
                        />
                    )}
                </Typography>
                <CopyRight placement={'postLogin'} />
            </Dialog>
            {alertMessage && (
                <AlertDialog
                    open
                    onClose={handleCloseAlert}
                    title={alertMessage.title}
                    content={alertMessage.content}
                />
            )}
            <ConfirmModal
                open={openConfirmChanges}
                onClose={() => {
                    setOpenConfirmChanges(false);
                }}
                confirm={() => {
                    if (isAssetValid()) {
                        if (showUnlayerEditor) {
                            handleSaveAndCloseUnlayer();
                            setOpenConfirmChanges(false);
                        } else {
                            handleSaveAndClose();
                            setOpenConfirmChanges(false);
                        }
                    }
                }}
                title="Save changes"
                content="Changes once saved will be implemented immediately. Do you wish to continue?"
            />
            <ConfirmModal
                open={openUnSavedChanges}
                onClose={() => {
                    setOpenUnSavedChanges(false);
                }}
                textNo={'Cancel'}
                textYes={'Discard'}
                confirm={() => {
                    setOpenUnSavedChanges(false);
                    handleClose();
                }}
                title="Discard unsaved changes?"
                content=""
            />
        </>
    );
};

export default AssetsModal;

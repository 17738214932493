import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';

const useStyles = makeStyles(() => ({
    withBackground: {
        flex: 1,
        height: 390,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    noBackground: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'inherit',
        paddingTop: 40,
    },
    subtitle: {
        color: colors.darkGrey,
        marginTop: 8,
        maxWidth: 378,
        textAlign: 'center',
    },
}));

type EmptyPageProps = {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    withBackground?: boolean;
};

const EmptyPage = ({ title, subtitle, children, style, withBackground = false }: EmptyPageProps) => {
    const classes = useStyles();
    return (
        <Paper className={withBackground ? classes.withBackground : classes.noBackground} style={style} elevation={1}>
            <Typography variant="h2">{title}</Typography>
            {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
            {children}
        </Paper>
    );
};

export default EmptyPage;

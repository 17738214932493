// From https://v4.mui.com/components/tables/#sorting-amp-selecting
export type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const x = a[orderBy];
    const y = b[orderBy];
    if (y === undefined && x !== undefined) {
        return -1;
    } else if (x === undefined && y !== undefined) {
        return 1;
    } else if (y < x) {
        return -1;
    } else if (y > x) {
        return 1;
    } else {
        return 0;
    }
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | boolean | undefined },
    b: { [key in Key]: number | string | boolean | undefined },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

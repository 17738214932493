import { CSSProperties } from 'react';
import { Typography, Box, Divider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    equationBox: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 12,
    },
    equationBoxContainer: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: colors.backgroundGrey,
        marginTop: 7,
        padding: 16,
        width: 410,
    },
}));

interface EquationBoxProps {
    product: string;
    numerator: string;
    denominator: string;
    className?: string;
    style?: CSSProperties;
}

const EquationBox = ({ product, numerator, denominator, className, style = {} }: EquationBoxProps) => {
    const classes = useStyles();

    return (
        <Box className={`${classes.equationBox} ${className || ''}`} style={{ ...style }}>
            <Typography variant="inherit" style={{ fontWeight: 500 }}>
                How is this calculated?
            </Typography>
            <Box className={classes.equationBoxContainer}>
                <Typography variant="body2" style={{ fontWeight: 500 }}>
                    {product}
                </Typography>
                <Box style={{ maxWidth: 220 }}>
                    <Typography variant="inherit">{numerator}</Typography>
                    <Divider style={{ margin: '8px 0' }} />
                    <Typography variant="inherit">{denominator}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default EquationBox;

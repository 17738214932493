import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import colors from 'colors';

interface LabelProps {
    value: string;
    label: string;
    subLabel: string;
}

const RadioSupportingLabel = ({ value, label, subLabel }: LabelProps) => (
    <FormControlLabel
        value={value}
        control={<Radio disableRipple color="primary" style={{ paddingTop: 0, paddingRight: 14 }} />}
        label={
            <>
                <div>{label}</div>
                <span style={{ color: colors.darkGrey, fontSize: 14 }}>{subLabel}</span>
            </>
        }
        style={{ display: 'flex', alignItems: 'start', marginBottom: 35 }}
    />
);

export default RadioSupportingLabel;

export const compareSortedDistinctStringArraysEquals = (stringArrayA?: string[], stringArrayB?: string[]): boolean => {
    if (!stringArrayA && !stringArrayB) {
        return true;
    } else if (!stringArrayA || !stringArrayB || stringArrayA.length !== stringArrayB.length) {
        return false;
    }
    for (let index = 0; index < stringArrayA.length; index++) {
        if (stringArrayA[index] !== stringArrayB[index]) return false;
    }
    return true;
};

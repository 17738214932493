import colors from 'colors';
import { SUPPORT_EMAIL, SUPPORT_EMAIL_MSG_PRE, SUPPORT_EMAIL_MSG_POST } from 'common/constants';

export const GenericServerFailureMessage = () => {
    const MAIL_TO = `mailto:${SUPPORT_EMAIL}`;
    return (
        <div>
            {SUPPORT_EMAIL_MSG_PRE}
            <address style={{ display: 'inline', margin: '0 0.3rem' }}>
                <a style={{ color: colors.blue }} href={MAIL_TO}>
                    {SUPPORT_EMAIL}
                </a>
            </address>
            {SUPPORT_EMAIL_MSG_POST}
        </div>
    );
};

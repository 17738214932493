import React, { CSSProperties, MouseEvent } from 'react';
import { Typography } from '@material-ui/core';
import colors from 'colors';

interface AvatarProps {
    size?: number;
    firstName: string;
    lastName: string;
    rounded?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    cursor?: 'pointer' | 'auto';
    className?: string;
    style?: CSSProperties;
    fontSize?: number;
}

const colours = [colors.dark, colors.darkBlue, colors.blue, colors.lightBlue, colors.red, colors.green, colors.yellow];

const Avatar = ({
    className,
    style = {},
    size = 48,
    firstName,
    lastName,
    rounded = false,
    onClick,
    cursor = 'auto',
    fontSize = 20,
}: AvatarProps) => {
    const initials =
        firstName && lastName ? firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase() : 'UU';

    const charIndex = initials.charCodeAt(0) - 65;
    const colourIndex = charIndex % 7;
    return (
        <div
            onClick={onClick}
            className={className || ''}
            style={{
                width: size,
                height: size,
                color: colors.white,
                background: colours[colourIndex],
                borderRadius: rounded ? '50%' : '0',
                fontSize,
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor,
                ...style,
            }}
        >
            <Typography>{initials}</Typography>
        </div>
    );
};

export default Avatar;

import React, { MouseEvent, KeyboardEvent, useState, useEffect } from 'react';
import { Asset, CampaignStateType } from 'types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Box, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

import ConfirmModal from 'components/confirmModal';
import Tooltip from 'components/customTooltip';

import colors from 'colors';
import {
    checkAssetHasInvalidSyntaxAttributeErrors,
    checkAssetHasMismatchedAttributeErrors,
    checkAssetHasValidationErrors,
} from '../campaignUtils';

type AssetsListProps = {
    assets: Asset[];
    editAsset: (payload: Asset) => void;
    duplicateAsset: (payload: Asset) => void;
    deleteAsset: (campaignId: string, assetId: string) => void;
    addAsset: () => void;
    addAssetUnlayerEditor: () => void;
    campaignId: string;
    campaignState: CampaignStateType;
    isUsedInWorkflow: (asset: Asset) => boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: '4px 14px 0',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 290,
        height: 315,
    },
    addAssetCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 0,
        width: 290,
        height: 315,
    },
    addAssetSubCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 0,
        border: `2px dashed ${colors.blue}`,
        width: 290,
        height: 150,
        borderRadius: 4,
        cursor: 'pointer',
        backgroundColor: colors.white,
    },
    iconContainer: {
        position: 'relative',
    },
    iconBlue: {
        color: colors.blue,
    },
    iconMain: {
        fontSize: 60,
        margin: '0 5px',
    },
    iconAdd: {
        position: 'absolute',
        fontSize: 32,
        bottom: 0,
        right: 0,
        backgroundColor: colors.white,
        borderRadius: '50%',
        border: 0,
        margin: 0,
        padding: 0,
    },
    iframeContainer: {
        width: 290,
        height: 214,
        padding: '0px 24px 16px',
    },
    iframeInnerContainer: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },
    iframe: {
        transform: 'scale(0.30)',
        transformOrigin: 'top left',
        border: 0,
        overflow: 'hidden',
    },
    name: {
        textTransform: 'capitalize',
        padding: 16,
        fontWeight: 500,
        textAlign: 'center',
        borderTop: `1px solid ${colors.lightGrey}`,
    },
    nameWithIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    paper: {
        padding: '4px 0',
        background: colors.dark,
        color: colors.white,
        position: 'absolute',
        right: 0,
        minWidth: 112,
    },
}));

const AssetsList = ({
    assets,
    editAsset,
    duplicateAsset,
    deleteAsset,
    addAsset,
    addAssetUnlayerEditor,
    campaignState,
    campaignId,
    isUsedInWorkflow,
}: AssetsListProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selectedAsset, setSelectedAsset] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const history = useHistory();
    const { emailAssetId, id } = useParams<{ emailAssetId: string; id: string }>();

    useEffect(() => {
        if (emailAssetId && assets && assets.length) {
            const index = assets.map((e) => e.id).indexOf(emailAssetId);
            if (index >= 0) {
                setSelectedAsset(index);
                editAsset(assets[index]);
            } else {
                history.push(`/campaigns/update/${id}/assets`);
            }
        }
    }, []);

    const handleClick = (event: MouseEvent<HTMLAnchorElement>, index: number) => {
        setSelectedAsset(index);
        setAnchorEl(event.currentTarget as any);
        setOpen(true);
    };

    const handleClickAsset = (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>, index: number) => {
        setSelectedAsset(index);
        editAsset(assets[index]);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleListKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    const handleEditOrView = () => {
        editAsset(assets[selectedAsset]);
        handleClose();
    };

    const handleDuplicate = () => {
        duplicateAsset(assets[selectedAsset]);
        handleClose();
    };

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
        handleClose();
    };

    const handleDelete = () => {
        deleteAsset(campaignId, assets[selectedAsset].id!);
        setShowDeleteModal(false);
    };

    return (
        <div>
            <Popper
                style={{ zIndex: 999 }}
                open={open}
                anchorEl={anchorEl}
                role={undefined}
                transition={true}
                disablePortal={true}
                placement="top-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                        <Paper className={classes.paper} elevation={2}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList style={{ padding: 0 }} autoFocusItem={open} onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleEditOrView}>
                                        {campaignState !== CampaignStateType.DRAFT &&
                                        (assets[selectedAsset].hasMetrics || isUsedInWorkflow(assets[selectedAsset]))
                                            ? 'View'
                                            : 'Edit'}
                                    </MenuItem>
                                    {campaignState !== CampaignStateType.DRAFT ? (
                                        <Tooltip
                                            title="Email asset cannot be duplicated as campaign is active"
                                            placement="left"
                                            arrow
                                            interactive
                                            spanStyle={{ width: '100%' }}
                                        >
                                            <MenuItem onClick={handleDuplicate} disabled>
                                                Duplicate
                                            </MenuItem>
                                        </Tooltip>
                                    ) : (
                                        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
                                    )}
                                    {campaignState !== CampaignStateType.DRAFT && (
                                        <Tooltip
                                            title="Email asset cannot be deleted as campaign is active or has started"
                                            placement="left"
                                            arrow
                                            interactive
                                            spanStyle={{ width: '100%' }}
                                        >
                                            <MenuItem onClick={handleDeleteClick} disabled>
                                                Delete
                                            </MenuItem>
                                        </Tooltip>
                                    )}
                                    {campaignState === CampaignStateType.DRAFT &&
                                        (isUsedInWorkflow(assets[selectedAsset]) ? (
                                            <Tooltip
                                                title="Email asset cannot be deleted as it is in use in this campaign"
                                                placement="left"
                                                arrow
                                                interactive
                                                spanStyle={{ width: '100%' }}
                                            >
                                                <MenuItem onClick={handleDeleteClick} disabled>
                                                    Delete
                                                </MenuItem>
                                            </Tooltip>
                                        ) : (
                                            <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                                        ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Box mx={3} style={{ marginBottom: 62, marginTop: 24 }}>
                <Grid container spacing={3} style={{ width: 'auto' }}>
                    {campaignState === CampaignStateType.DRAFT && (
                        <Grid item>
                            <Box className={classes.addAssetCard}>
                                <Box className={classes.addAssetSubCard} onClick={addAssetUnlayerEditor}>
                                    <Box className={classes.iconContainer}>
                                        <SettingsIcon className={`${classes.iconBlue} ${classes.iconMain}`} />
                                        <AddIcon className={`${classes.iconBlue} ${classes.iconAdd}`} />
                                    </Box>
                                    <Box style={{ display: 'flex', marginTop: 16 }}>
                                        <Typography style={{ fontWeight: 500, fontSize: 16, color: colors.blue }}>
                                            Use email editor
                                        </Typography>
                                        <Tooltip
                                            title="Use drag & drop to create emails in minutes. No coding skills are required."
                                            icon="help"
                                            arrow
                                            placement="top"
                                            spanStyle={{ alignItems: 'center' }}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.addAssetSubCard} onClick={addAsset}>
                                    <Box className={classes.iconContainer}>
                                        <ListAltOutlinedIcon className={`${classes.iconBlue} ${classes.iconMain}`} />
                                        <AddIcon className={`${classes.iconBlue} ${classes.iconAdd}`} />
                                    </Box>
                                    <Box style={{ display: 'flex', marginTop: 16 }}>
                                        <Typography style={{ fontWeight: 500, fontSize: 16, color: colors.blue }}>
                                            Upload file / folder
                                        </Typography>
                                        <Tooltip
                                            title="Upload HTML file, folder or ZIP file containing HTML file and relevant images."
                                            icon="help"
                                            arrow
                                            placement="top"
                                            spanStyle={{ alignItems: 'center' }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                    {assets.map((asset: Asset, index: number) => {
                        const templateUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/${campaignId}/assets/${asset.id}/${asset.template}.html`;
                        return (
                            <Grid item key={asset.id}>
                                <Card className={classes.card}>
                                    {campaignState !== CampaignStateType.SCHEDULED && (
                                        <div className={classes.container}>
                                            <Link
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e: MouseEvent<HTMLAnchorElement>) => handleClick(e, index)}
                                            >
                                                <MoreIcon />
                                            </Link>
                                        </div>
                                    )}
                                    <Link
                                        style={{ cursor: 'pointer', textDecoration: 'none', color: colors.dark }}
                                        onClick={(e: MouseEvent<HTMLAnchorElement>) => handleClickAsset(e, index)}
                                    >
                                        <div className={classes.iframeContainer} style={{ position: 'relative' }}>
                                            <iframe
                                                title="Email Asset Preview"
                                                className={classes.iframe}
                                                src={templateUrl}
                                                width={800}
                                                height={660}
                                                scrolling="no"
                                            />
                                            <div
                                                className={classes.iframeInnerContainer}
                                                onClick={(e: MouseEvent<HTMLDivElement>) => handleClickAsset(e, index)}
                                            />
                                        </div>
                                        {checkAssetHasInvalidSyntaxAttributeErrors(asset) && (
                                            <Typography
                                                className={`${classes.name} ${classes.nameWithIcon}`}
                                                style={{
                                                    color: colors.red,
                                                }}
                                            >
                                                <Tooltip
                                                    icon="warning"
                                                    title={`Email asset contains invalid user attribute(s).`}
                                                    arrow
                                                    style={{ marginRight: '5px' }}
                                                    iconStyle={{ color: colors.red }}
                                                />
                                                <span> {asset.name} </span>
                                            </Typography>
                                        )}
                                        {!checkAssetHasInvalidSyntaxAttributeErrors(asset) &&
                                            checkAssetHasMismatchedAttributeErrors(asset) && (
                                                <Typography
                                                    className={classes.name}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexWrap: 'wrap',
                                                        color: colors.yellow,
                                                    }}
                                                >
                                                    <Tooltip
                                                        icon="warning"
                                                        title={`Email asset contains mismatched user attribute(s).`}
                                                        arrow
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                    <span> {asset.name} </span>
                                                </Typography>
                                            )}
                                        {!checkAssetHasValidationErrors(asset) && (
                                            <Typography className={classes.name}>{asset.name}</Typography>
                                        )}
                                    </Link>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
                {showDeleteModal && (
                    <ConfirmModal
                        open
                        title="Delete email asset"
                        content={`“${assets[selectedAsset].name}” will be removed from the campaign permanently`}
                        confirm={handleDelete}
                        onClose={() => {
                            setShowDeleteModal(false);
                        }}
                        textNo="Cancel"
                        textYes="Delete"
                    />
                )}
            </Box>
        </div>
    );
};

export default AssetsList;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'colors';

type EmailEditorProps = {
    srcDoc?: string;
    src?: string;
    size: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
        background: colors.white,
    },
}));

const EmailEditor = ({ srcDoc, src, size }: EmailEditorProps) => {
    const classes = useStyles();
    let style = null;
    switch (size) {
        case 'desktop':
            style = {
                width: '100%',
            };
            break;
        case 'mobile':
            style = {
                width: '640px',
                transform: 'scale(0.5)',
                transformOrigin: 'top middle',
            };
            break;
    }
    return (
        <iframe
            title="Email Asset Preview"
            style={style!}
            className={classes.container}
            srcDoc={srcDoc}
            src={src}
            frameBorder="0"
        />
    );
};

export default EmailEditor;

import Cookies from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const IS_LOCAL_ENV = process.env.REACT_APP_IS_LOCAL_ENV === 'true' || false;

const setAuthToken = (authorization: string) => {
    Cookies.set('authorization', authorization, { secure: !IS_LOCAL_ENV, expires: 1 });
};

const getAuthToken = () => {
    return Cookies.get('authorization');
};

const expireIn = (exp: number) => {
    return exp * 1000 - Date.now();
};

const isSignedIn = () => {
    const authorizationCookie = Cookies.get('authorization');
    if (!authorizationCookie || authorizationCookie === 'null') {
        return false;
    }
    const decodedToken = jwtDecode<JwtPayload>(authorizationCookie);
    if (!decodedToken.exp || expireIn(decodedToken.exp) <= 0) {
        return false;
    }
    return true;
};

const removeCookies = () => {
    Cookies.remove('authorization');
    Cookies.remove('XSRF-TOKEN');
};

export { expireIn, setAuthToken, getAuthToken, isSignedIn, removeCookies };

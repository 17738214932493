import React, { CSSProperties, PropsWithChildren } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import colors from 'colors';

interface CustomTooltipProps extends Omit<TooltipProps, 'children'> {
    icon?: 'help' | 'secondaryHelp' | 'info' | 'warning';
    spanStyle?: CSSProperties;
    iconStyle?: CSSProperties;
    maxWidth?: number;
}

const useStyles = (maxWidth: number) => {
    return makeStyles(() => ({
        tooltip: {
            backgroundColor: colors.white,
            color: colors.dark,
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.06)',
            fontSize: 11,
            lineHeight: '18px',
            padding: '12px 16px',
            fontWeight: 'normal',
            border: `1px solid ${colors.grey}`,
            width: 'auto',
            maxWidth: maxWidth,
        },
        arrow: {
            '&:before': {
                border: `1px solid ${colors.grey}`,
            },
            color: colors.white,
        },
        tooltipPlacementTop: {
            margin: '10px 0',
        },
        tooltipPlacementBottom: {
            margin: '10px 0',
        },
        tooltipPlacementRight: {
            margin: '0 10px',
        },
        tooltipPlacementLeft: {
            margin: '0 10px',
        },
    }));
};

const useIconStyles = makeStyles(() => ({
    helpIcon: {
        fontSize: 15,
        marginLeft: 4,
        paddingLeft: 1,
        color: colors.blue,
        cursor: 'pointer',
    },
    secondaryHelpIcon: {
        fontSize: 15,
        marginLeft: 4,
        paddingLeft: 1,
        color: colors.darkGrey,
        cursor: 'pointer',
    },
    infoIcon: {
        fontSize: 14,
        marginLeft: 4,
        color: colors.darkGrey,
        cursor: 'pointer',
    },
    warningIcon: {
        color: colors.yellow,
        cursor: 'pointer',
        marginRight: 4,
    },
}));

const CustomTooltip = ({
    maxWidth = 239,
    children,
    title,
    icon,
    spanStyle,
    iconStyle,
    ...rest
}: PropsWithChildren<CustomTooltipProps>) => {
    const classes = useStyles(maxWidth)();
    const iconClasses = useIconStyles();
    return (
        <Tooltip title={title} classes={classes} {...rest}>
            <span style={{ display: 'inline-flex', ...spanStyle }}>
                {icon === 'help' && <HelpIcon className={iconClasses.helpIcon} style={{ ...iconStyle }} />}
                {icon === 'secondaryHelp' && (
                    <HelpIcon className={iconClasses.secondaryHelpIcon} style={{ ...iconStyle }} />
                )}
                {icon === 'info' && <InfoIcon className={iconClasses.infoIcon} style={{ ...iconStyle }} />}
                {icon === 'warning' && <ErrorIcon className={iconClasses.warningIcon} style={{ ...iconStyle }} />}
                {!icon && children}
            </span>
        </Tooltip>
    );
};

export default CustomTooltip;

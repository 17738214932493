import React, { useEffect, useState } from 'react';
import { Paper, TableContainer, Table, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';
import { pluralise } from 'utils/pluralise';
import { ColumnMap } from './selectImportType';
import { UniqueUserAttributeDisplayName } from 'types';

interface StepThreeProps {
    fileName: string;
    numberOfRecords: number;
    columns: ColumnMap[];
    newAttributes: string[];
}

const useStyles = makeStyles(() => ({
    tableContainer: {
        border: `1px solid ${colors.coolGrey}`,
        boxShadow: 'none !important',
        marginBottom: 40,
        '& th, td': {
            padding: '24px 40px',
            fontSize: 16,
            borderBottom: `1px solid ${colors.coolGrey}`,
        },
        '& tbody tr:last-child th, & tbody tr:last-child td': {
            border: 0,
        },
    },
    helper: {
        fontSize: 14,
        color: colors.darkGrey,
    },
}));

const StepThree = ({ fileName, numberOfRecords, columns, newAttributes }: StepThreeProps) => {
    const classes = useStyles();
    const [mappedToExisting, setMappedToExisting] = useState<ColumnMap[]>([]);
    const [mappedToNew, setMappedToNew] = useState<ColumnMap[]>([]);

    useEffect(() => {
        const existingMapped = columns.filter(
            (column) =>
                column.mappedAttribute.name !== '' &&
                !newAttributes.includes(column.mappedAttribute.name.toLowerCase()),
        );
        const newMapped = columns.filter((column) => newAttributes.includes(column.mappedAttribute.name.toLowerCase()));
        setMappedToNew(newMapped);
        setMappedToExisting(existingMapped);
    }, [columns, newAttributes]);

    // const isBothUniqueIdentifiersSelected = () => {
    //     const uniqueIdentifiersSelected = columns.filter(
    //         (header: ColumnMap) => header.mappedAttribute.group === UserAttributeGroup.UNIQUE_IDENTIFIERS,
    //     );
    //     return uniqueIdentifiersSelected.length === 2;
    // };

    const isUniqueIdentifier = (attributeMapped: string) => {
        // if (isBothUniqueIdentifiersSelected() && attributeMapped === UniqueUserAttributeDisplayName.EMAIL) return false;
        // if (attributeMapped === UniqueUserAttributeDisplayName.EMAIL || attributeMapped === UniqueUserAttributeDisplayName.NRIC_FIN)
        //     return true;
        // return false;
        return attributeMapped === UniqueUserAttributeDisplayName.EMAIL;
    };

    return (
        <div style={{ margin: '0 10%' }}>
            <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6" style={{ marginBottom: 12 }}>
                                    Import from
                                </Typography>
                                <div style={{ marginBottom: 8 }}>{fileName}</div>
                                <div className={classes.helper}>
                                    {columns.length} {pluralise(columns.length, 'column')} {numberOfRecords}{' '}
                                    {pluralise(columns.length, 'record')}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6" style={{ marginBottom: 12 }}>
                                    {mappedToExisting &&
                                        `${mappedToExisting?.length} ${pluralise(
                                            mappedToExisting?.length,
                                            'column',
                                        )} mapped to
                                    existing user ${pluralise(mappedToExisting?.length, 'attribute')}`}
                                </Typography>
                                <div style={{ marginBottom: 8 }}>
                                    {mappedToExisting
                                        ?.map(
                                            (column) =>
                                                column.columnHeader +
                                                (isUniqueIdentifier(column.mappedAttribute.name) ? '*' : ''),
                                        )
                                        .join(', ')}
                                </div>
                                <div className={classes.helper}>*Unique identifier</div>
                            </TableCell>
                        </TableRow>
                        {mappedToNew.length > 0 && (
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6" style={{ marginBottom: 12 }}>
                                        {mappedToNew &&
                                            `${mappedToNew?.length} ${pluralise(
                                                mappedToNew?.length,
                                                'column',
                                            )} mapped to
                                    new user ${pluralise(mappedToNew?.length, 'attribute')}`}
                                    </Typography>
                                    <div style={{ marginBottom: 8 }}>
                                        {mappedToNew
                                            ?.map(
                                                (column) =>
                                                    column.columnHeader +
                                                    (isUniqueIdentifier(column.mappedAttribute.name) ? '*' : ''),
                                            )
                                            .join(', ')}
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default StepThree;

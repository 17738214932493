import React, { CSSProperties } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme: Theme) => ({
    deleteIcon: {
        padding: '11px',
        fontSize: '32px',
        minWidth: 'auto',
    },
}));

type ColorType = 'default' | 'inherit' | 'primary' | 'secondary';
type VariantType = 'contained' | 'outlined' | 'text';

interface DeleteButtonProps {
    style?: CSSProperties;
    disabled?: boolean;
    onClick?: () => void;
    color?: ColorType;
    variant?: VariantType;
}

const DeleteButton = ({
    style = {},
    onClick,
    disabled = false,
    color = 'primary',
    variant = 'outlined',
}: DeleteButtonProps) => {
    const classes = useStyles();
    return (
        <Button
            color={color}
            variant={variant}
            onClick={onClick}
            disabled={disabled}
            className={classes.deleteIcon}
            style={{ ...style }}
        >
            <DeleteIcon fontSize={'inherit'} />
        </Button>
    );
};

export default DeleteButton;

// eslint-disable-next-line no-useless-escape
const HIDDEN_FILE_REGEX = /(^|\/)\.[^\/\.]/;

export const isHiddenFile = (fileName: string) => HIDDEN_FILE_REGEX.test(fileName);

export const getFileExtension = (fileName: string) => {
    // eslint-disable-next-line no-bitwise
    return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
};

export const validateFileExtension = (fileName: string, extension: string) => {
    return getFileExtension(fileName) === extension;
};

export const getRootDir = (items: any) => {
    if (items.length === 1) {
        if (items[0].webkitGetAsEntry) {
            const item = items[0].webkitGetAsEntry();
            if (item.isDirectory) {
                return item.name;
            }
        }
        if (items[0].getAsEntry) {
            const item = items[0].getAsEntry();
            if (item.isDirectory) {
                return item.name;
            }
        }
    }
    return '';
};

export const removeRootDirFromPaths = (paths: string[], root: string) => paths.map((path) => path.replace(root, ''));

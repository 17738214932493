import React from 'react';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { Dialog, AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'colors';

type BaseModalProps = {
    open: boolean;
    title: string;
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
    children: React.ReactNode;
    handleClose: () => void;
    scroll?: 'body' | 'paper';
    secondaryAction?: string | React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        position: 'relative',
        height: 80,
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        marginLeft: 16,
        flex: 1,
    },
    header: {
        boxShadow: 'none',
    },
}));

const BaseModal = ({
    title,
    children,
    open,
    handleClose,
    maxWidth = 'sm',
    scroll = 'paper',
    secondaryAction,
}: BaseModalProps) => {
    const classes = useStyles();
    return (
        <Dialog open={open} fullScreen={false} fullWidth={false} maxWidth={maxWidth} scroll={scroll}>
            <AppBar className={classes.appBar}>
                <Toolbar style={{ padding: '0 32px' }}>
                    <IconButton onClick={handleClose} style={{ padding: 0 }}>
                        <ClearIcon style={{ color: colors.white, fontSize: 27.5 }} />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <>{secondaryAction}</>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    );
};

export default BaseModal;

import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CustomChip from 'components/customChip';

interface AutocompleteMultipleProps {
    value: string[];
    onChange: (event: ChangeEvent<any>, value: unknown) => void;
    onSubmitCallback: (newInputValue: string) => void;
    placeholder: string;
    error: boolean;
    errorHelperText: string;
    onInputChange?: (event: ChangeEvent<any>, value: unknown) => void;
}

const useStyles = makeStyles(() => ({
    autocompleteInputRoot: {
        minHeight: `48px !important`,
        '&&[class*="MuiOutlinedInput-root"]': {
            padding: '4px 12px',
        },
        '&&[class*="MuiOutlinedInput-root"] $input': {
            padding: '8px 4px !important',
        },
    },
    textFieldInputRoot: {
        '& > div': {
            paddingRight: '44px !important',
        },
        '& button': {
            right: 5,
        },
    },
}));

const AutocompleteMultiple = ({
    value,
    onChange,
    placeholder,
    error,
    errorHelperText,
    onInputChange,
    onSubmitCallback,
}: AutocompleteMultipleProps) => {
    const classes = useStyles();
    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onSubmitCallback(event.target.value);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
            case ';':
            case ',': {
                event.preventDefault();
                event.stopPropagation();
                onSubmitCallback((event.target as HTMLInputElement).value);
                break;
            }
            default:
        }
    };
    return (
        <Autocomplete
            classes={{ inputRoot: classes.autocompleteInputRoot }}
            multiple
            freeSolo
            value={value}
            options={[]}
            onChange={onChange}
            renderTags={(value: string[], getTagProps) => {
                return value.map((option: string, index: number) => (
                    <CustomChip key={option} label={option} {...getTagProps({ index })} />
                ));
            }}
            renderInput={(params) => (
                <form autoComplete="off">
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={placeholder}
                        onKeyDown={handleKeyDown}
                        onBlur={handleOnBlur}
                        error={error}
                        helperText={error && errorHelperText}
                        classes={{ root: classes.textFieldInputRoot }}
                    />
                </form>
            )}
            onInputChange={onInputChange}
        />
    );
};

export default AutocompleteMultiple;

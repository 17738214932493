import React, { useState, useEffect, useRef, ChangeEvent, MouseEvent, CSSProperties } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Paper,
    Checkbox,
    IconButton,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import { pluralise } from 'utils/pluralise';
import colors from 'colors';
import zIndex from 'common/constants/zIndex';
import ConfirmModal from 'components/confirmModal';
import Tooltip from 'components/customTooltip';
import { UniqueUserAttribute, UserAttributeBackend } from 'types';
import { formatNumberWithCommas } from 'utils/numbers';
import { orderHeadCells } from './contactsUtils';
import LoadingSpinner from './loadingSpinner';
import { Category } from './index';
import datetime from 'utils/datetime';

interface EnhancedTableHeadProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
    headCells: HeadCell[];
    enableShadow: boolean;
}

const NO_DESCRIPTION_COPY = 'No description provided';

export const EnhancedTableHead = ({ classes, headCells, enableShadow }: EnhancedTableHeadProps) => {
    const boxShadow = enableShadow ? '4px 0px 3px -2px rgba(0, 0, 0, 0.1' : 'none';
    return (
        <TableHead>
            <TableRow>
                {headCells &&
                    headCells.map((headCell, index) => (
                        <TableCell
                            key={headCell.id}
                            padding="normal"
                            className={clsx({ [classes.frozenColumn]: index === 0 }, classes.cell, classes.headCell)}
                            style={{ minWidth: index === 0 ? 288 : 236, boxShadow }}
                        >
                            <Box display="flex" alignItems="center">
                                {headCell.label}
                                {headCell.tooltip && (
                                    <Tooltip
                                        title={
                                            <Box>
                                                <div
                                                    style={{
                                                        color:
                                                            headCell.tooltip === NO_DESCRIPTION_COPY
                                                                ? colors.darkGrey
                                                                : '',
                                                    }}
                                                >
                                                    {headCell.tooltip}
                                                </div>
                                                {/* {index !== 0 && (
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        style={{ marginTop: 8, fontSize: 12 }}
                                                    >
                                                        Manage attribute
                                                    </Button>
                                                )} */}
                                            </Box>
                                        }
                                        icon="help"
                                        arrow
                                        interactive
                                    />
                                )}
                            </Box>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
};

const useActionsStyles = makeStyles(() =>
    createStyles({
        root: {
            flexShrink: 0,
        },
        iconButton: {
            padding: 0,
        },
    }),
);

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useActionsStyles();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    return (
        <div className={classes.root}>
            <IconButton
                className={classes.iconButton}
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                style={{ marginRight: 8 }}
            >
                <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
                className={classes.iconButton}
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRightIcon />
            </IconButton>
        </div>
    );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: colors.lightGrey,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottom: `1px solid ${colors.grey}`,
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 'initial',
        },
        title: {
            display: 'flex',
        },
        select: {
            paddingBottom: '0 !important',
        },
        backNextIcons: {
            color: colors.darkGrey,
        },
        backNextIconsDisabled: {
            color: `${colors.grey} !important`,
        },
        indeterminate: {
            color: colors.blue,
        },
        checkbox: {
            padding: 0,
            marginRight: 16,
        },
        paper: {
            marginTop: 8,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },
        selectRoot: {
            marginLeft: 0,
            marginRight: 16,
            height: 'initial',
            padding: '20px 0',
        },
        caption: {
            marginRight: 8,
        },
        toolbar: {
            padding: 0,
            height: 'initial',
        },
    }),
);

interface EnhancedTableToolbarProps {
    currentPageNumSelected: number;
    totalNumSelected: number;
    props?: EnhancedTableToolbarProps;
    rowCount: number;
    rowsPerPage: number;
    pageNumber: number;
    onChangePage: (event: unknown, newPage: number) => void;
    onChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    totalRows: number;
    onDeleteContacts: (contacts: string[]) => void;
    selected: string[];
    isFetching: number;
}

const EnhancedTableToolbar = ({
    currentPageNumSelected,
    totalNumSelected,
    rowCount,
    rowsPerPage,
    pageNumber,
    onChangePage,
    onChangeRowsPerPage,
    onSelectAllClick,
    totalRows,
    onDeleteContacts,
    selected,
    isFetching,
}: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const handleDeleteContactsClick = () => {
        setOpenDelete(true);
    };

    return (
        <>
            <Toolbar className={classes.root}>
                <div className={classes.title}>
                    <Checkbox
                        indeterminate={currentPageNumSelected > 0 && currentPageNumSelected < rowCount}
                        checked={rowCount > 0 && currentPageNumSelected === rowCount}
                        onChange={onSelectAllClick}
                        classes={{ indeterminate: classes.indeterminate, root: classes.checkbox }}
                        size="small"
                        disabled={isFetching > 0}
                    />
                    {totalNumSelected > 0 && (
                        <Button color="primary" onClick={handleDeleteContactsClick} size="small">
                            <Box display="flex" alignItems="center">
                                <DeleteIcon fontSize="small" />
                                <Box ml={1}>
                                    Delete {formatNumberWithCommas(totalNumSelected)}{' '}
                                    {pluralise(totalNumSelected, 'contact')}
                                </Box>
                            </Box>
                        </Button>
                    )}
                </div>
                <TablePagination
                    rowsPerPageOptions={[
                        { value: 10, label: '10 per page' },
                        { value: 25, label: '25 per page' },
                        { value: 50, label: '50 per page' },
                        { value: 100, label: '100 per page' },
                    ]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    onPageChange={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    labelRowsPerPage="View:"
                    SelectProps={{
                        IconComponent: ArrowIcon,
                        classes: {
                            select: classes.select,
                        },
                        MenuProps: {
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.paper },
                        },
                        renderValue: (value) => {
                            return `${value} per page`;
                        },
                    }}
                    backIconButtonProps={{
                        classes: {
                            root: classes.backNextIcons,
                            disabled: classes.backNextIconsDisabled,
                        },
                    }}
                    nextIconButtonProps={{
                        classes: {
                            root: classes.backNextIcons,
                            disabled: classes.backNextIconsDisabled,
                        },
                    }}
                    ActionsComponent={TablePaginationActions}
                    classes={{ selectRoot: classes.selectRoot, caption: classes.caption, toolbar: classes.toolbar }}
                />
            </Toolbar>
            <ConfirmModal
                open={openDelete}
                onClose={() => {
                    setOpenDelete(false);
                }}
                confirm={() => {
                    onDeleteContacts(selected);
                    setOpenDelete(false);
                }}
                title="Delete contacts"
                content="Deleted contacts will be removed from your audience permanently"
                textYes="Delete"
                textNo="Cancel"
            />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: 40,
            overflowX: 'auto',
            border: `1px solid ${colors.grey}`,
            borderBottom: 0,
        },
        table: {
            fontSize: 14,
            minWidth: 750,
            '& tbody > tr > td:last-child': {
                borderRight: 0,
            },
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        frozenColumn: {
            position: 'sticky',
            left: 0,
            zIndex: zIndex.tableFrozenColumn,
            background: colors.white,
        },
        headCell: {
            color: colors.dark,
            backgroundColor: colors.lighterGrey,
            '&:last-child': {
                borderRight: 0,
            },
        },
        cell: {
            fontSize: 14,
            padding: '12px 24px !important',
            borderRight: `1px solid ${colors.grey}`,
            backgroundClip: 'padding-box',
        },
    }),
);

export interface Row {
    [key: string]: string;
}

interface ContactsTableProps {
    rows: Row[];
    schema: any;
    category: Category;
    rowsPerPage: number;
    pageNumber: number;
    onChangeRowsPerPage: (rowsPerPage: number) => void;
    onChangePageNumber: (pageNumber: number) => void;
    count: number;
    onDeleteContacts: (contacts: string[]) => void;
    attributes?: UserAttributeBackend[];
    isFetching: number;
    style?: CSSProperties;
}

export interface HeadCell {
    id: string;
    label: any;
    tooltip?: string | null;
}

const ContactsTable = ({
    rows,
    schema,
    category,
    rowsPerPage,
    pageNumber,
    onChangeRowsPerPage,
    onChangePageNumber,
    count,
    attributes,
    onDeleteContacts,
    isFetching,
    style,
}: ContactsTableProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string[]>([]);
    const [orderedHeadCells, setOrderedHeadCells] = useState<HeadCell[]>([{ id: '', label: '' }]);
    const [enableShadow, setEnableShadow] = useState<boolean>(false);
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const addTooltipContentToHeadCells = (orderedHeadCells: HeadCell[]) =>
        orderedHeadCells.map((headCell, index) => {
            let tooltipContent;
            const systemAttributes = ['email', 'createdOn', 'updatedOn', 'state'];
            if (systemAttributes.includes(headCell.id)) {
                tooltipContent = null;
            } else {
                if (attributes) {
                    const attribute = attributes.find((attribute) => headCell.id === attribute.name);
                    if (attribute) {
                        tooltipContent = attribute.description ? attribute.description : NO_DESCRIPTION_COPY;
                    }
                }
            }
            return {
                ...headCell,
                tooltip: index === 0 ? 'This is the unique identifier and cannot be modified' : tooltipContent,
            };
        });

    useEffect(() => {
        if (schema) {
            const orderedHeadCells = orderHeadCells(schema, category);
            const headCellsWithTooltips = addTooltipContentToHeadCells(orderedHeadCells);
            setOrderedHeadCells(headCellsWithTooltips);
        }
    }, [category, schema, attributes]);

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelected = rows.map((n) => n.id);
        const isIndeterminate = event.target.getAttribute('data-indeterminate') === 'true';
        if (event.target.checked && !isIndeterminate) {
            setSelected(selected.concat(newSelected));
            return;
        }
        const newFiltered = selected.filter((s) => !newSelected.includes(s));
        setSelected(newFiltered);
    };

    const handleClick = (event: MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handlePageChange = (event: unknown, newPage: number) => {
        onChangePageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeRowsPerPage(parseInt(event.target.value, 10));
        onChangePageNumber(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const ref = useRef<HTMLInputElement>(null);

    const handleScroll = () => {
        if (ref.current) setScrollLeft(ref.current.scrollLeft);
    };

    useEffect(() => {
        scrollLeft > 0 ? setEnableShadow(true) : setEnableShadow(false);
    }, [scrollLeft]);

    const currentPageRows = rows.map((r) => r.id);
    const currentPageSelectedRows = selected.filter((r) => currentPageRows.includes(r));

    const handleDeleteContacts = (contacts: string[]) => {
        onDeleteContacts(contacts);
        setSelected([]);
    };

    return (
        <div className={classes.root} style={style}>
            <Paper className={classes.paper} elevation={0} onScroll={handleScroll}>
                <EnhancedTableToolbar
                    currentPageNumSelected={currentPageSelectedRows.length}
                    totalNumSelected={selected.length}
                    rowCount={rows.length}
                    rowsPerPage={rowsPerPage}
                    pageNumber={pageNumber}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onSelectAllClick={handleSelectAllClick}
                    totalRows={count}
                    onDeleteContacts={handleDeleteContacts}
                    selected={selected}
                    isFetching={isFetching}
                />
                <TableContainer ref={ref}>
                    <Table className={classes.table} size="medium" aria-label="table">
                        {isFetching !== 0 ? (
                            <Box style={{ height: 450, borderBottom: `1px solid ${colors.grey}` }}>
                                <LoadingSpinner />
                            </Box>
                        ) : (
                            <>
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    rowCount={rows.length}
                                    headCells={orderedHeadCells}
                                    enableShadow={enableShadow}
                                />
                                <TableBody>
                                    {orderedHeadCells &&
                                        !isFetching &&
                                        rows.map((row: Row) => {
                                            const isItemSelected = isSelected(row.id);
                                            const attributes = orderedHeadCells.map((cell: HeadCell) => cell.id);
                                            const firstColumn = UniqueUserAttribute.EMAIL;
                                            attributes.splice(attributes.indexOf(UniqueUserAttribute.EMAIL), 1);

                                            return (
                                                <TableRow
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell
                                                        padding="checkbox"
                                                        className={clsx(classes.cell, classes.frozenColumn)}
                                                        style={
                                                            enableShadow
                                                                ? { boxShadow: '4px 0px 3px -2px rgba(0, 0, 0, 0.1' }
                                                                : {}
                                                        }
                                                    >
                                                        <Box display="flex">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                style={{ padding: 0, marginRight: 16 }}
                                                                size="small"
                                                            />
                                                            {row[firstColumn]}
                                                        </Box>
                                                    </TableCell>
                                                    {attributes.map((attribute: string) => {
                                                        let data;
                                                        if (attribute === 'state') {
                                                            row[attribute] === 'Complained'
                                                                ? (data = 'Reported spam')
                                                                : (data = row[attribute]);
                                                            row[attribute] === 'Bounced'
                                                                ? (data = 'Permanently bounced')
                                                                : (data = row[attribute]);
                                                        } else if (
                                                            attribute === 'updatedOn' ||
                                                            attribute === 'createdOn'
                                                        ) {
                                                            data = datetime.toFormatDateAtTime(row[attribute]);
                                                        } else {
                                                            data = row[attribute];
                                                        }
                                                        return (
                                                            <TableCell
                                                                key={attribute}
                                                                classes={{ root: classes.cell }}
                                                                padding="none"
                                                            >
                                                                {data}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

export default ContactsTable;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Typography, Divider } from '@material-ui/core';
import { RootState } from 'reducers';
import { Audience as AudienceType, CampaignStateType } from 'types';
import api from 'api';
import { createTargetedAudience, DefaultDraftCampaign } from 'features/campaigns/campaignSlice';
import { pluralise } from 'utils/pluralise';

import SelectAudience from './selectAudience';
import DisplayAudience from './displayAudience';

import colors from 'colors';

type AudienceProps = {
    audiences: AudienceType[];
    createTargetedAudience: (audience: AudienceType[]) => void;
    teamId: string | null;
    draftCampaign: DefaultDraftCampaign;
};

const useStyles = makeStyles(() => ({
    popper: {
        padding: '12px 16px',
        background: colors.white,
        borderRadius: 4,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.06)',
        margin: 8,
    },
    tooltip: {
        margin: 0,
        padding: 0,
        background: colors.white,
        color: colors.dark,
    },
    tooltipTitle: {
        fontSize: 12,
        lineHeight: '18px',
    },
    subHeader: {
        fontSize: 16,
        fontWeight: 500,
        marginRight: 8,
    },
    disabledLabel: {
        color: `${colors.coolGrey} !important`,
    },
}));

const Audience = ({ audiences, createTargetedAudience, teamId, draftCampaign }: AudienceProps) => {
    const classes = useStyles();
    const [isAllAudienceSelected, setIsAllAudienceSelected] = useState<boolean>(false);
    const [isAudienceExists, setIsAudienceExists] = useState<boolean>(false);
    const [audienceList, setAudienceList] = useState<AudienceType[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);

    useEffect(() => {
        const getCombinedAudiences = async () => {
            const [personalOrTeamAudiencesResponse] = await Promise.all([
                teamId ? api.getTeamAudiences(teamId!) : api.getPersonalAudiences(),
            ]);

            const personalOrTeamAudiences = (personalOrTeamAudiencesResponse.data as AudienceType[]).map(
                (audience: AudienceType) => {
                    return {
                        ...audience,
                        group: `${teamId ? 'Team' : 'Personal'} ${pluralise(
                            (personalOrTeamAudiencesResponse.data as AudienceType[]).length,
                            'audience',
                        )}`,
                    };
                },
            );
            setAudienceList([...personalOrTeamAudiences]);
            if (audiences.length < 1) createTargetedAudience([{ name: '', id: '', group: '' }]);
            setIsFetching(false);
        };
        getCombinedAudiences();
    }, []);

    useEffect(() => {
        if (isAudienceExists) {
            const isLastRowFilled = audiences.length > 0 && audiences[audiences.length - 1].name !== '';
            setIsAllAudienceSelected(audienceList.length === audiences.length && isLastRowFilled);
        }
    }, [audiences, audienceList]);

    useEffect(() => {
        setIsAudienceExists(audiences.length > 0);
    }, [audiences]);

    return (
        <>
            {!isFetching && (
                <Paper style={{ margin: '40px 12%' }}>
                    <Box
                        style={{ padding: '24px 32px' }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h2">
                            {draftCampaign.state === CampaignStateType.SCHEDULED ||
                            draftCampaign.state === CampaignStateType.ACTIVE ||
                            draftCampaign.state === CampaignStateType.INACTIVE
                                ? 'Campaign audience'
                                : 'Define campaign audience'}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box style={{ padding: '32px' }}>
                        <Typography className={classes.subHeader}>Target audience</Typography>
                        {draftCampaign.state === CampaignStateType.SCHEDULED ||
                        draftCampaign.state === CampaignStateType.ACTIVE ||
                        draftCampaign.state === CampaignStateType.INACTIVE ? (
                            <DisplayAudience audiences={audiences} />
                        ) : (
                            <>
                                <Box style={{ fontSize: 14, marginTop: 8 }}>
                                    Select the audience that you wish to target
                                </Box>
                                <Box>
                                    <SelectAudience
                                        isAllAudienceSelected={isAllAudienceSelected}
                                        audienceList={audienceList}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Paper>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    audiences: state.campaignSlice.draftCampaign.audiences || [],
    teamId: state.campaignSlice.draftCampaign.teamId,
    draftCampaign: state.campaignSlice.draftCampaign,
});

const mapDispatchToProps = { createTargetedAudience };

export default connect(mapStateToProps, mapDispatchToProps)(Audience);

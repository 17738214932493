import React from 'react';
import { Box, Typography, TableContainer, Table, TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';
import { AudienceUsageData } from 'types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BaseModal from 'components/baseModal';

const useStyles = makeStyles((theme: Theme) => ({
    TableContainer: {
        overflow: 'hidden',
        '& th': {
            borderBottom: 'none',
            padding: '0 0 8px 0',
            display: 'inline-block',
            '&:last-child': {
                paddingLeft: '32px',
            },
        },
        '& td': {
            padding: '16px 32px 16px 0',
            display: 'inline-block',
            '& p': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            '&:last-child': {
                paddingLeft: '32px',
                paddingRight: 0,
            },
        },
        '& tr:last-child td': {
            borderBottom: 'none',
            paddingBottom: 0,
        },
    },
}));

interface AudienceUsageModalProps {
    title: string;
    open: boolean;
    handleClose: () => void;
    usageData: AudienceUsageData[];
    usedEntity: string;
}

const AudienceUsageModal = ({ title, open, handleClose, usageData, usedEntity }: AudienceUsageModalProps) => {
    const classes = useStyles();
    return (
        <BaseModal title={title} open={open} handleClose={handleClose} maxWidth="lg" scroll="body">
            <Box width={704} minHeight={680}>
                <Box px={4} pt={4} pb={4}>
                    <Typography variant="body1" style={{ paddingBottom: 24 }}>
                        <span style={{ fontWeight: 500 }}>{usedEntity}</span> is used in
                    </Typography>
                    <TableContainer className={classes.TableContainer}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '36%' }}>
                                        <Typography variant="body2">Campaign</Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '64%' }}>
                                        <Typography variant="body2">Owned by</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usageData && usageData.length ? (
                                    <>
                                        {usageData.map((a: AudienceUsageData, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ width: '36%' }}>
                                                    <Typography variant="body2">{a.campaignName}</Typography>
                                                </TableCell>
                                                <TableCell style={{ width: '64%' }}>
                                                    <Typography variant="body2">{a.ownedBy}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                ) : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </BaseModal>
    );
};

export default AudienceUsageModal;

import React, { useState } from 'react';
import BaseModal from 'components/baseModal';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, Box, Divider, Typography, InputAdornment } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import datetime from 'utils/datetime';

import colors from 'colors';

type ScheduleModalProps = {
    open: boolean;
    date?: string | null;
    onDateChange: (val: MaterialUiPickersDate) => void;
    onSchedule: () => void;
    handleClose: () => void;
    isInactive: boolean | undefined;
};

const ScheduleModal = ({ open, handleClose, date, onDateChange, onSchedule, isInactive }: ScheduleModalProps) => {
    const [error, setError] = useState('');
    const [errorTime, setErrorTime] = useState('');

    const isValidDate = (date: Date | string) => {
        const dateAfter5Mins = datetime.addMinutes(new Date(), 5);
        const currentDate = datetime.convertToLocalDateTime(dateAfter5Mins.toString());
        const selectedDate = datetime.convertToLocalDateTime(new Date(date!).toString());
        return datetime.compareDates(new Date(selectedDate!), new Date(currentDate!));
    };

    return (
        <BaseModal title={isInactive ? 'Resume later' : 'Schedule start date'} open={open} handleClose={handleClose}>
            <Box style={{ padding: 32, width: 493, maxWidth: '100%' }}>
                <Typography style={{ fontWeight: 500 }}>
                    {isInactive
                        ? 'Select date and time to resume the campaign'
                        : 'Select date and time to start the campaign'}
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box mt={3} display="flex" alignItems="center">
                        <Typography style={{ color: colors.darkGrey }}>Date</Typography>
                        <DatePicker
                            inputVariant="outlined"
                            value={date ? date : null}
                            style={{ width: 167, marginLeft: 32 }}
                            format="dd-MM-yyyy"
                            disablePast={true}
                            onChange={(date) => {
                                onDateChange(date);
                                setError('');
                                setErrorTime('');
                            }}
                            error={!!error}
                            helperText={error ? error : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarIcon color="primary" style={{ fontSize: 20 }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mt={3} display="flex" alignItems="baseline">
                        <Typography style={{ color: colors.darkGrey }}>Time</Typography>
                        <TimePicker
                            inputVariant="outlined"
                            value={date ? date : null}
                            style={{ width: 167, marginLeft: 32 }}
                            format="hh:mm a"
                            onChange={(date) => {
                                onDateChange(date);
                                setError('');
                                setErrorTime('');
                            }}
                            error={!!errorTime}
                            helperText={errorTime ? errorTime : ''}
                        />
                    </Box>
                </MuiPickersUtilsProvider>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (!date) {
                            setError('Please choose a start date');
                            setErrorTime('Please choose a start time');
                        } else {
                            if (isValidDate(date!)) {
                                setError('');
                                setErrorTime('');
                                onSchedule();
                            } else {
                                setErrorTime('The start time must be more than 5 minutes from now');
                            }
                        }
                    }}
                    style={{ margin: '24px 32px' }}
                >
                    Start
                </Button>
            </Box>
        </BaseModal>
    );
};

export default ScheduleModal;

import { actions } from '@mrblenny/react-flow-chart';
import { v4 } from 'uuid';
import { LIVE } from 'common/constants';

export default function (name: string, mode?: string) {
    const flowCharActions = actions as any;
    return (state: any, action: { type: string; payload: any }) => {
        flowCharActions[name](action.payload)(
            mode === LIVE ? state.draftCampaign.workflow : state.draftCampaign.workflowDraft,
        );
    };
}

export const onCanvasDropAutoSelect = (state: any, action: { type: string; payload: any }) => {
    const node = action.payload;
    const config = node.config;
    const data = node.data;
    const position = node.position;
    const chart = state.draftCampaign.workflowDraft;
    const id = v4();
    chart.nodes[id] = {
        id,
        position:
            config && config.snapToGrid
                ? { x: Math.round(position.x / 20) * 20, y: Math.round(position.y / 20) * 20 }
                : { x: position.x, y: position.y },
        orientation: data.orientation || 0,
        type: data.type,
        ports: data.ports,
        properties: data.properties,
    };
    if (chart.selected.id !== id || chart.selected.type !== 'node') {
        chart.selected = {
            type: 'node',
            id,
        };
    }
};

export const onCanvasClickCustom = (state: any, action: { type: string; payload: any }) => {
    const chart = state.draftCampaign.workflowDraft;
    if (chart.selected.id && chart.selected.type === 'node') {
        chart.nodes[chart.selected.id].properties.isNew = false;
        chart.selected = {};
    }
};

export const onNodeClickWithMarkAsNotNew = (state: any, action: { type: string; payload: any }) => {
    const chart = state.draftCampaign.workflowDraft;
    const data = action.payload;
    const nodeId = data.nodeId;
    if (chart.selected.id !== nodeId || chart.selected.type !== 'node') {
        if (chart.selected.id && chart.selected.type === 'node') {
            chart.nodes[chart.selected.id].properties.isNew = false;
        }
        chart.selected = {
            type: 'node',
            id: nodeId,
        };
    }
};

import { formatNumberWithCommas, formatNumberWithDp } from 'utils/numbers';

export interface IMetrics {
    sents: number;
    unsubscribes: number;
    bounces: number;
    temporaryBounces: number;
    deliveries: number;
    complaints: number;
    opens: number;
    uniqueOpens: number;
    clicks: number;
    uniqueClicks: number;
}

class Metrics {
    sents: number;
    sents_display: string;
    unsubscribes: number;
    unsubscribes_display: string;
    bounces: number;
    bounces_display: string;
    temporaryBounces: number;
    temporaryBounces_display: string;
    deliveries: number;
    deliveries_display: string;
    complaints: number;
    complaints_display: string;
    clicks: number;
    clicks_display: string;
    uniqueClicks: number;
    uniqueClicks_display: string;
    opens: number;
    opens_display: string;
    uniqueOpens: number;
    uniqueOpens_display: string;
    uniqueOpenRate: number | undefined;
    uniqueOpenRate_display: string;
    totalOpenRate: number | undefined;
    totalOpenRate_display: string;
    uniqueClickRate: number | undefined;
    uniqueClickRate_display: string;
    totalClickRate: number | undefined;
    totalClickRate_display: string;
    clicksPerUniqueOpens: number | undefined;

    constructor(metrics: IMetrics) {
        this.sents = metrics.sents;
        this.unsubscribes = metrics.unsubscribes;
        this.bounces = metrics.bounces;
        this.temporaryBounces = metrics.temporaryBounces;
        this.deliveries = metrics.deliveries;
        this.complaints = metrics.complaints;

        this.opens = metrics.opens;
        this.totalOpenRate = this.calculateRate(metrics.opens, metrics.deliveries);
        this.uniqueOpens = metrics.uniqueOpens;
        this.uniqueOpenRate = this.calculateRate(metrics.uniqueOpens, metrics.deliveries);

        this.clicks = metrics.clicks;
        this.totalClickRate = this.calculateRate(metrics.clicks, metrics.deliveries);
        this.uniqueClicks = metrics.uniqueClicks;
        this.uniqueClickRate = this.calculateRate(metrics.uniqueClicks, metrics.deliveries);

        this.clicksPerUniqueOpens = this.calculateRate(metrics.uniqueClicks, metrics.uniqueOpens);

        this.sents_display = formatNumberWithCommas(metrics.sents);
        this.unsubscribes_display = formatNumberWithCommas(metrics.unsubscribes);
        this.bounces_display = formatNumberWithCommas(metrics.bounces);
        this.temporaryBounces_display = formatNumberWithCommas(metrics.temporaryBounces);
        this.deliveries_display = formatNumberWithCommas(metrics.deliveries);
        this.complaints_display = formatNumberWithCommas(metrics.complaints);

        this.opens_display = formatNumberWithCommas(metrics.opens);
        this.totalOpenRate_display = this.formatRate(this.totalOpenRate);
        this.uniqueOpens_display = formatNumberWithCommas(metrics.uniqueOpens);
        this.uniqueOpenRate_display = this.formatRate(this.uniqueOpenRate);

        this.clicks_display = formatNumberWithCommas(metrics.clicks);
        this.totalClickRate_display = this.formatRate(this.totalClickRate);
        this.uniqueClicks_display = formatNumberWithCommas(metrics.uniqueClicks);
        this.uniqueClickRate_display = this.formatRate(this.uniqueClickRate);
    }

    private calculateRate(divident: number, divisor: number): number | undefined {
        const rate = (divident / divisor) * 100;
        if (!Number.isFinite(rate)) {
            return undefined;
        }
        return rate;
    }

    private formatRate(rate: number | undefined) {
        return rate ? formatNumberWithDp(rate) + '%' : '-';
    }
}

export default Metrics;

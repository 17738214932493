import React from 'react';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import { MenuItem, Box } from '@material-ui/core';
import colors from 'colors';
import { Team } from 'types';

interface HeaderTeamsListProps {
    teams: Team[];
    dashboardSelected: string;
    onClickTeam: (id: string) => void;
}

interface TeamItemProps {
    teamId: string;
    name: string;
    dashboardSelected: string;
    onClickTeam: (id: string) => void;
}

const TeamItem = ({ teamId, name, onClickTeam, dashboardSelected }: TeamItemProps) => {
    return (
        <MenuItem onClick={() => onClickTeam(teamId)}>
            <DotIcon
                style={{
                    color: dashboardSelected === teamId ? colors.blue : colors.white,
                    width: 16,
                    marginRight: 16,
                }}
            />
            <span>{name}</span>
        </MenuItem>
    );
};

const HeaderTeamsList = ({ teams, onClickTeam, dashboardSelected }: HeaderTeamsListProps) => {
    return (
        <Box component="div" overflow="auto" style={{ maxHeight: 245 }}>
            {teams &&
                teams.map((team: Team, index: number) => (
                    <TeamItem
                        key={index}
                        teamId={team.id}
                        name={team.name}
                        onClickTeam={onClickTeam}
                        dashboardSelected={dashboardSelected}
                    />
                ))}
        </Box>
    );
};

export default HeaderTeamsList;

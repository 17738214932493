import React, { useEffect, useCallback, useRef } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import ReactEmailEditor from 'react-email-editor';
import { Audience, UnlayerTemplateJsonPayload, UserAttributeBackend, Asset } from 'types';
import CircularLoader from 'components/circularLoader';
import {
    AssetModalInvalidAttributeErrorAlertBar,
    AssetModalMismatchedAttributeWarningAlertBar,
} from 'components/alertBar';
import { checkAssetHasInvalidSyntaxAttributeErrors, checkAssetHasMismatchedAttributeErrors } from '../campaignUtils';

const useStyles = makeStyles((theme: Theme) => ({
    alertBarContainer: {
        padding: '10px 32px',
    },
    loadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
}));

type UnlayerEditorProps = {
    asset: Asset;
    audienceAttributes?: UserAttributeBackend[];
    unlayerTemplateJson: React.MutableRefObject<UnlayerTemplateJsonPayload | null>;
    handleUploadImageToS3: (imageFile: File) => Promise<string | void>;
    unlayerEditorRef: React.MutableRefObject<any>;
    inProgressHtml: boolean;
    campaignAudiences?: Audience[];
    validateHtmlFileDataFields: (htmlString: string) => void;
};

const UnlayerEditor = ({
    asset,
    audienceAttributes,
    unlayerTemplateJson,
    handleUploadImageToS3,
    unlayerEditorRef,
    inProgressHtml,
    campaignAudiences,
    validateHtmlFileDataFields,
}: UnlayerEditorProps) => {
    const classes = useStyles();
    const isEditorReady = useRef(false); //Cannot use useState as callbacks passed to ReactEmailEditor does not update when embeded editor is loaded
    const isDesignLoaded = useRef(false);
    const emailEditorCallbackRef = useCallback((node) => {
        if (node != null) {
            unlayerEditorRef.current = node;
        }
    }, []);

    // Not able to use useState hooks as there's only one render cycle from mounting of editor to design loaded event
    const mergeTags: any = {};
    if (audienceAttributes) {
        audienceAttributes.forEach((audienceAttribute) => {
            if (audienceAttribute.name) {
                mergeTags[audienceAttribute.name] = {
                    name: audienceAttribute.displayName,
                    value: `$\{data.${audienceAttribute.name}}`,
                };
            }
        });
    }

    const handleUploadImage = (file: any, done: any) => {
        done({ progress: 0 });
        // Only accept a single image file at a time
        const uploadedFile: File = file.attachments[0];
        uploadedFile.arrayBuffer().then((value) => {
            const decodedFileString = new TextDecoder().decode(value);
            if (decodedFileString !== 'undefined') {
                //Only upload if file is not an empty arrayBuffer/"undefined" string
                handleUploadImageToS3(uploadedFile).then((imageUrl) => {
                    const doneObj: { progress: number; url?: string } = {
                        progress: 100,
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                    };
                    if (imageUrl) {
                        doneObj.url = imageUrl;
                    }
                    done(doneObj);
                });
            }
        });
    };

    const handleEditorReady = () => {
        if (unlayerTemplateJson.current != null) {
            unlayerEditorRef.current.editor.loadDesign(unlayerTemplateJson.current);
        }
        isEditorReady.current = true;
    };

    const handleDesignLoaded = () => {
        // This event is triggered once even before editor:ready is called
        if (isEditorReady.current) {
            isDesignLoaded.current = true;
        }
    };

    const handleDesignUpdated = (updates: any) => {
        if (unlayerEditorRef.current != null && (isDesignLoaded.current || !unlayerTemplateJson.current)) {
            unlayerEditorRef.current.editor.exportHtml(({ html }: { html: string; design: any }) => {
                validateHtmlFileDataFields(html);
            });
        }
    };

    const registerCallbacksAndEventListeners = () => {
        if (unlayerEditorRef.current && unlayerEditorRef.current.editor) {
            unlayerEditorRef.current.editor.addEventListener('editor:ready', handleEditorReady);
            unlayerEditorRef.current.editor.addEventListener('design:loaded', handleDesignLoaded);
            unlayerEditorRef.current.editor.addEventListener('design:updated', handleDesignUpdated);
            unlayerEditorRef.current.editor.registerCallback('image', handleUploadImage);
        }
    };

    useEffect(() => {
        registerCallbacksAndEventListeners();
    }, [unlayerEditorRef]);

    const onLoad = () => {
        registerCallbacksAndEventListeners();
    };

    return inProgressHtml ? (
        <div className={classes.loadingContainer}>
            <CircularLoader size={80} thickness={2} />
            <div style={{ paddingTop: '10px' }}>Uploading asset</div>
        </div>
    ) : (
        <Box
            style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            }}
        >
            {checkAssetHasInvalidSyntaxAttributeErrors(asset) && (
                <div className={classes.alertBarContainer}>
                    <AssetModalInvalidAttributeErrorAlertBar
                        errors={asset.validationErrors!.invalidSyntaxAttributeErrors!}
                    />
                </div>
            )}
            {!checkAssetHasInvalidSyntaxAttributeErrors(asset) && checkAssetHasMismatchedAttributeErrors(asset) && (
                <div className={classes.alertBarContainer}>
                    <AssetModalMismatchedAttributeWarningAlertBar
                        campaignAudiences={campaignAudiences!}
                        errors={asset.validationErrors!.mismatchedAttributeErrors!}
                    />
                </div>
            )}
            <React.StrictMode>
                <ReactEmailEditor
                    ref={emailEditorCallbackRef}
                    projectId={71157}
                    options={{
                        displayMode: 'email',
                        mergeTags,
                        features: {
                            undoRedo: false,
                        },
                        appearance: {
                            panels: {
                                tools: {
                                    dock: 'right',
                                },
                            },
                        },
                    }}
                    onLoad={onLoad}
                    style={{
                        paddingBottom: 38,
                    }}
                    minHeight={250}
                />
            </React.StrictMode>
        </Box>
    );
};

export default UnlayerEditor;

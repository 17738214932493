import React from 'react';
import { IPortDefaultProps } from '@mrblenny/react-flow-chart';
import colors from 'colors';
import { Typography } from '@material-ui/core';

const Port = (props: IPortDefaultProps) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <>
                {props.port.properties && (
                    <Typography
                        style={{
                            textTransform: 'capitalize',
                            fontSize: 12,
                            color: colors.darkGrey,
                        }}
                    >
                        {props.port.properties.value}
                    </Typography>
                )}
                <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <title>background</title>
                        <rect fill="none" id="canvas_background" height="14" width="14" y="-1" x="-1" />
                        <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
                            <rect fill="url(#gridpattern)" strokeWidth="0" y="0" x="0" height="100%" width="100%" />
                        </g>
                    </g>
                    <g>
                        <title>Dot</title>
                        <ellipse
                            stroke="null"
                            ry="5.06061"
                            rx="5.13637"
                            id="svg_1"
                            cy="6.05919"
                            cx="5.87879"
                            strokeWidth="2"
                            fill={colors.darkGrey}
                        />
                    </g>
                </svg>
            </>
        </div>
    );
};

export default Port;

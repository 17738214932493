export interface AlertMessage {
    title: string;
    content: string;
}

export interface IAssetsModalBackendErrors {
    [key: string]: AlertMessage;
}

export const assetsModalErrors = {
    HTML_NOT_FOUND: {
        title: '.HTML file not found',
        content:
            'Your email asset could not be uploaded as a .HTML file could not be found. Please ensure the .HTML file is in the root folder and the folder is unzipped.',
    },
    MORE_THAN_ONE_HTML: {
        title: 'More than one .HTML file found',
        content:
            'Your email asset could not be uploaded as it contains multiple .html files. Please ensure the asset only contains one .html file and it is in the root folder.',
    },
    SIZE_EXCEEDED: {
        title: 'Email size limit exceeded',
        content: 'Your email asset could not be uploaded as the allowable email size limit of 7 MB has been exceeded',
    },
    UNSUPPORTED_FILE_FORMATS: {
        title: 'Upload includes unsupported file formats',
        content:
            'Your email asset could not be uploaded as it includes files in unsupported formats. Please ensure only .html, .png, .jpg, .jpeg, .gif files are uploaded.',
    },
    INVALID_FILE_NAME: {
        title: 'Upload includes files with invalid filenames',
        content:
            'Your email asset could not be uploaded as it includes files in with invalid filenames. Please ensure filenames only contain alphanumeric and -_()[]. special characters.',
    },
};

export const assetsModalBackendErrors: IAssetsModalBackendErrors = {
    '400 FAILED_SCANNING': {
        title: 'File failed virus scanning',
        content: 'Your email asset could not be uploaded as it failed the virus scan. Please try again.',
    },
    '400 MORE_THAN_ONE_TEMPLATE': assetsModalErrors.MORE_THAN_ONE_HTML,
    '400 SIZE_EXCEEDED': assetsModalErrors.SIZE_EXCEEDED,
    '400 UNSUPPORTED_FILE_TYPE': assetsModalErrors.UNSUPPORTED_FILE_FORMATS,
    '400 INVALID_FILE_NAME': assetsModalErrors.INVALID_FILE_NAME,
};

import React, { useState, useEffect, useRef } from 'react';
import api from 'api';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography, TextField, InputLabel } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import Message from 'components/message';
import { SUPPORT_EMAIL_MSG } from 'common/constants';
import { EApiIssue } from 'types';
import { updateApiIssue } from 'reducers/userSlice';

import { getEmailHelperText, isEmailValid } from 'utils/email';

interface ForgetPasswordProps {
    prefillEmail: string;
    apiIssue?: EApiIssue;
    updateApiIssue?: (apiIssue: EApiIssue) => void;
}

const ForgetPassword = ({ prefillEmail, apiIssue, updateApiIssue }: ForgetPasswordProps) => {
    const history = useHistory() as any;
    const sourcePath = history.location.state ? history.location.state.from : '/signin';
    const [email, setEmail] = useState<string>(prefillEmail);
    const [showError, setShowError] = useState<boolean>(false);
    const [serverError, setServerError] = useState<string>('');
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [requestPasswordEmail, setRequestPasswordEmail] = useState<string>('');
    const getHelperText = () => {
        if (!isEmailValid(email)) return getEmailHelperText(email);
    };
    const isMounted = useRef(false);
    const handleButtonClick = () => {
        setShowError(true);
        if (isEmailValid(email)) setRequestPasswordEmail(email);
    };

    useEffect(() => {
        if (updateApiIssue) updateApiIssue(EApiIssue.NONE);
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            api.getResetPasswordLink({ email: requestPasswordEmail }).then((response) => {
                if (response.ok) {
                    setServerError('');
                    setShowSuccess(true);
                } else {
                    setServerError(SUPPORT_EMAIL_MSG);
                }
            });
        } else {
            isMounted.current = true;
        }
    }, [requestPasswordEmail]);

    useEffect(() => {
        setServerError(apiIssue === EApiIssue.SERVER_500 ? SUPPORT_EMAIL_MSG : '');
    }, [apiIssue]);

    return (
        <Box>
            {!showSuccess && (
                <>
                    <Button
                        component={RouterLink}
                        variant="text"
                        onClick={() => {
                            if (updateApiIssue) updateApiIssue(EApiIssue.NONE);
                        }}
                        to={sourcePath}
                    >
                        <ArrowRightIcon style={{ marginRight: 8 }} />
                        Back
                    </Button>
                    <Box display="flex" flexDirection="column" justifyContent="center" style={{ margin: '40px 44px' }}>
                        <Box textAlign="center">
                            <Typography variant="h1">Reset password</Typography>
                            <Typography style={{ marginTop: 8, marginBottom: 32 }}>
                                Instructions on how to reset the password will be sent to your registered email address
                            </Typography>
                        </Box>
                        <InputLabel>Registered email address</InputLabel>
                        <TextField
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={showError && !isEmailValid(email)}
                            helperText={showError && getHelperText()}
                        />
                        {serverError && <Message message={serverError} style={{ margin: '24px 0 0 0' }} />}
                        <Button
                            onClick={handleButtonClick}
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ marginTop: !serverError ? 40 : 24 }}
                        >
                            Continue
                        </Button>
                    </Box>
                </>
            )}
            {showSuccess && (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    style={{ margin: '24px 44px' }}
                >
                    <Typography variant="h1">Thank you!</Typography>
                    <Typography style={{ marginTop: 24 }}>
                        The password reset instructions have been sent to your registered email address. If you do not
                        receive the email soon, check your spam folder or try again.
                    </Typography>
                    <Button
                        onClick={() => {
                            history.push('/signin');
                        }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: 40 }}
                    >
                        Return to login
                    </Button>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    apiIssue: state.userSlice.apiIssue,
});

const mapDispatchToProps = {
    updateApiIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);

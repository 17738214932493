import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { INode, REACT_FLOW_CHART } from '@mrblenny/react-flow-chart';
import { format } from 'date-fns';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import SvgIcon from '../../../components/svgIcons';
import { EMAIL_OPENED, CHECK_ATTRIBUTE, WAIT, SPECIFIC_DAY, IMMEDIATELY, FOR_A_PERIOD_OF } from 'common/constants';

import colors from 'colors';
import { Asset, WorkflowActionScheduleType, WorkflowConditionScheduleType } from 'types';
import { RootState } from 'reducers';
import { pluralise } from 'utils/pluralise';

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        borderTop: `1px solid ${colors.grey}`,
        borderRight: `1px solid ${colors.grey}`,
        borderBottom: `1px solid ${colors.grey}`,
        borderLeft: `3px solid ${colors.grey}`,
        borderRadius: 4,
        cursor: 'move',
        background: colors.white,
        position: 'relative',
        padding: 16,
        transform: 'translate(0, 0)',
        display: 'flex',
        alignItems: 'flex-start',
    },
    root: {
        marginTop: 0,
        marginBottom: 0,
    },
    primary: {
        fontSize: 14,
        lineHeight: '21px',
        margin: 0,
        color: colors.dark,
    },
    secondary: {
        fontSize: 12,
        lineHeight: '18px',
    },
    multiline: {
        margin: 0,
    },
}));

const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface ISidebarItemProps {
    node: {
        id?: string;
        position?: INode['position'];
        type: string;
        ports: INode['ports'];
        properties: {
            icon: string;
            name: string;
            secondary?: string;
            onStage?: boolean;
            subtype: string;
            assetId?: string;
            templateName?: string;
            scheduleType?: WorkflowActionScheduleType | WorkflowConditionScheduleType;
            attribute?: string;
            attributeDisplayName?: string;
            audienceId?: string;
            time?: string;
            hours?: number;
            minutes?: number;
            dayOfWeek?: string;
            isValid?: boolean;
            isNew?: boolean;
            daysToWait?: string;
            daysInPeriod?: string;
        };
    };
}

interface SideMenuItemProps extends ISidebarItemProps {
    assets: Asset[];
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const checkOnMap = {
    [WAIT]: 'After',
    [SPECIFIC_DAY]: 'Send on specific day',
    [IMMEDIATELY]: 'Immediately',
    [FOR_A_PERIOD_OF]: 'For a period of',
} as { [key: string]: string };

const SideMenuItem = React.forwardRef(function SideMenuItem(
    { node, assets }: SideMenuItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const { type, ports, properties } = node;
    const classes = useStyles();
    const isAction = type === 'action';
    const isValid = properties.isValid !== false;
    const themeColor = isAction ? colors.green : colors.yellow;
    const { icon, name, secondary, onStage, isNew } = properties;
    let meaningfulName = null;
    let checkOn = null;
    if (isAction && onStage) {
        let asset;
        if (properties.assetId) {
            asset = assets.find((a) => {
                return a.id === properties.assetId;
            });
        }
        const timeFormat = 'hh:mm a';
        meaningfulName = asset ? `Send '${asset.name}'` : null;
        if (properties.scheduleType === WorkflowActionScheduleType.WAIT) {
            if (!properties.daysToWait || !Number(properties.daysToWait)) {
                checkOn = checkOnMap[properties.scheduleType];
            } else {
                checkOn = `${checkOnMap[properties.scheduleType!]} ${properties.daysToWait} ${pluralise(
                    Number(properties.daysToWait),
                    'day',
                )} at ${format(new Date(properties.time!), timeFormat)}`;
            }
        } else if (properties.scheduleType === WorkflowActionScheduleType.SPECIFIC_DAY) {
            checkOn = properties.dayOfWeek
                ? `On ${dayOfWeek[Number(properties.dayOfWeek) - 1]} at ${format(
                      new Date(properties.time!),
                      timeFormat,
                  )}`
                : null;
        } else {
            checkOn = checkOnMap[properties.scheduleType!];
        }
    }
    if (!isAction && onStage) {
        if (properties.subtype === EMAIL_OPENED) {
            const asset = assets.find((a) => {
                return a.id === properties.assetId;
            });
            meaningfulName = asset ? `Check '${asset.name}' opened` : null;
        }
        if (properties.subtype === CHECK_ATTRIBUTE) {
            meaningfulName = properties.attribute ? `Check '${properties.attributeDisplayName}'` : 'Check attribute';
        }
        if (properties.scheduleType === WorkflowConditionScheduleType.WAIT) {
            properties.daysToWait && Number.isInteger(Number(properties.daysToWait))
                ? (checkOn = `${checkOnMap[properties.scheduleType]} ${properties.daysToWait} ${pluralise(
                      Number(properties.daysToWait),
                      'day',
                  )}`)
                : (checkOn = 'Wait');
        }
        if (properties.scheduleType === WorkflowConditionScheduleType.FOR_A_PERIOD_OF) {
            properties.daysInPeriod && Number.isInteger(Number(properties.daysInPeriod))
                ? (checkOn = `${checkOnMap[properties.scheduleType]} ${properties.daysInPeriod} ${pluralise(
                      Number(properties.daysInPeriod),
                      'day',
                  )}`)
                : (checkOn = 'For a period of');
        }
        if (properties.scheduleType === WorkflowConditionScheduleType.IMMEDIATELY) {
            checkOn = checkOnMap[properties.scheduleType];
        }
    }
    return (
        <ListItem
            ref={ref}
            className={classes.item}
            style={{ borderLeftColor: themeColor, marginBottom: onStage ? 0 : 12 }}
            draggable={!onStage}
            onDragStart={(event) => {
                if (!onStage) {
                    event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify({ type, ports, properties }));
                }
            }}
        >
            <ListItemAvatar style={{ color: themeColor, height: 21, minWidth: 24, marginRight: 8 }}>
                <SvgIcon type={icon} />
            </ListItemAvatar>
            <ListItemText
                classes={{
                    root: classes.root,
                    primary: classes.primary,
                    secondary: classes.secondary,
                    multiline: classes.multiline,
                }}
                primary={meaningfulName || name}
                secondary={checkOn || secondary}
            />
            {!isValid && !isNew && onStage && (
                <ErrorIcon
                    style={{ position: 'absolute', right: 9.6, top: 9.6 }}
                    htmlColor={colors.red}
                    fontSize="small"
                />
            )}
        </ListItem>
    );
});

const mapStateToProps = (state: RootState) => ({
    workflow: state.campaignSlice.draftCampaign.workflow,
    workflowDraft: state.campaignSlice.draftCampaign.workflowDraft,
    assets: state.campaignSlice.draftCampaign.assets,
});

export default connect(mapStateToProps)(SideMenuItem);

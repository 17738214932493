import { Category } from './index';
import { HeadCell } from './contactsTable';

const indexOfHeadCell = (headCells: HeadCell[], id: string) => headCells.findIndex((headCell) => headCell.id === id);

const statusHeadCell = { id: 'state', label: 'Status' };

const setEmailAsFirstHeadCell = (headCells: any) => {
    const email = headCells[indexOfHeadCell(headCells, 'email')];
    headCells.splice(indexOfHeadCell(headCells, 'email'), 1);
    headCells.unshift(email);
};

const setStatusAsSecondHeadCell = (headCells: any) => {
    headCells.splice(1, 0, statusHeadCell);
};

export const orderHeadCells = (schema: any, category: Category) => {
    const headCells = Object.keys(schema).map((key) => ({
        id: key,
        label: schema[key],
    }));

    setEmailAsFirstHeadCell(headCells);
    if (category === Category.ALL) setStatusAsSecondHeadCell(headCells);

    headCells.push({ id: 'createdOn', label: 'Date added' });
    headCells.push({ id: 'updatedOn', label: 'Last updated' });
    return headCells;
};

import { combineReducers } from 'redux';
import campaignSlice from 'features/campaigns/campaignSlice';
import teamSlice from 'features/team/teamSlice';
import userSlice from './userSlice';
import appSlice from './appSlice';
import audienceSlice from 'features/audience/audienceSlice';

const rootReducer = combineReducers({
    userSlice,
    appSlice,
    campaignSlice,
    teamSlice,
    audienceSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

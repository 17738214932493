import React from 'react';
import { INodeInnerDefaultProps } from '@mrblenny/react-flow-chart';
import Play from '@material-ui/icons/PlayCircleFilled';
import colors from 'colors';

import SideMenuItem from './sideMenuItem';

const NodeInner = ({ node, config }: INodeInnerDefaultProps) => {
    const { properties, type, ports, id, position } = node;
    const onStageProperties = {
        properties: { ...properties, onStage: true },
        id,
        position,
        type,
        ports,
    };
    if (type === 'start') {
        return <Play style={{ fontSize: 48, color: colors.blue, position: 'absolute' }} />;
    }
    return <SideMenuItem node={onStageProperties} />;
};

export default NodeInner;

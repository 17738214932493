import { Table, TableContainer, TableBody, TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';
import zIndex from 'common/constants/zIndex';
import clsx from 'clsx';
import { getComparator, stableSort } from 'utils/sort';
import { useState } from 'react';

export type Order = 'asc' | 'desc';

export interface Row {
    id?: string;
    [key: string]: string | number | boolean | undefined;
}
export interface HeadCell {
    id: string;
    label: any;
    isNotSortable?: boolean;
}

const useStyles = makeStyles({
    tableContainer: {
        padding: '0 32px',
        '& tbody tr:last-child th, & tbody tr:last-child td': {
            border: 0,
        },
        '& tbody tr td': {
            display: 'inline-block',
            marginRight: 80,
            paddingRight: 0,
            '&:first-child, &:last-child': {
                marginRight: 0,
            },
        },
    },
    table: {
        fontSize: 14,
        minWidth: 750,
        backgroundColor: colors.white,
        borderTop: '1px solid #E1E3E6',
        borderBottom: '1px solid #E1E3E6',
        borderCollapse: 'separate',
    },
    frozenColumn: {
        position: 'sticky',
        left: 0,
        zIndex: zIndex.tableFrozenColumn,
        backgroundColor: colors.white,
        borderLeft: `1px solid ${colors.grey}`,
    },
    headCell: {
        color: colors.dark,
        backgroundColor: colors.lighterGrey,
        textAlign: 'left',
    },
    cell: {
        fontSize: 14,
        padding: '12px 24px !important',
        borderRight: `1px solid ${colors.grey}`,
        backgroundClip: 'padding-box',
    },
    sortIcon: {
        marginLeft: '0.5rem',
        fill: colors.blue,
    },
});

interface EnhancedTableHeadProps {
    classes: ReturnType<typeof useStyles>;
    headCells: HeadCell[];
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
}

const EnhancedTableHead = ({ classes, headCells, order, orderBy, onRequestSort }: EnhancedTableHeadProps) => {
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells &&
                    headCells.map((headCell, index) => (
                        <TableCell
                            key={`${headCell.id}_${index}`}
                            padding="normal"
                            className={clsx({ [classes.frozenColumn]: index === 0 }, classes.cell, classes.headCell)}
                            style={{ minWidth: index === 0 ? 288 : 236 }}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.isNotSortable ? (
                                <>{headCell.label}</>
                            ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                    classes={{ icon: classes.sortIcon }}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
};

interface SortableTableProps {
    orderedHeadCells: HeadCell[];
    data: any[];
    defaultOrderBy: string;
    formatRowCell: (rowData: Row, columnId: string) => React.ReactNode;
}

export const SortableTable = ({ orderedHeadCells, data, defaultOrderBy, formatRowCell }: SortableTableProps) => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>(defaultOrderBy);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <TableContainer>
            <Table className={classes.table} size="medium" aria-label="table">
                <EnhancedTableHead
                    classes={classes}
                    headCells={orderedHeadCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {stableSort(data, getComparator(order, orderBy)).map((rowData: Row, i) => {
                        const columnIds = orderedHeadCells.map((cell: HeadCell) => cell.id);

                        return (
                            <TableRow role="checkbox" tabIndex={-1} key={`customTable_row_${rowData.id}_${i}`}>
                                {columnIds.map((columnId: string, j) => {
                                    return (
                                        <TableCell
                                            key={`customTable_cell_${rowData.id}_${columnId}_${j}`}
                                            className={clsx({ [classes.frozenColumn]: j === 0 }, classes.cell)}
                                        >
                                            {formatRowCell(rowData, columnId)}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

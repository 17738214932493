import { FlowNode, UserAttributeBackend } from 'types';
import { INode, IChart } from '@mrblenny/react-flow-chart';
export class Node {
    id: string;
    name: string;
    properties: any;
    type: string;
    options?: { [key: string]: FlowNode } | null;
    next?: FlowNode | null = null;
    constructor(
        id: string,
        type: string,
        name: string,
        properties: any,
        next: FlowNode | null,
        options?: { [key: string]: FlowNode } | null,
    ) {
        this.id = id;
        this.name = name;
        this.properties = properties;
        this.type = type;
        this.next = next;
        this.options = options;
    }
}

export const convertWorkflowToTree = (
    workflow: IChart,
    campaignAttributes: { [key: string]: UserAttributeBackend[] },
) => {
    const { nodes, links } = workflow;
    const actions: FlowNode[] = [];
    const conditions: FlowNode[] = [];
    let start: FlowNode | null = null;
    for (const [id, link] of Object.entries(links)) {
        if (link.from.nodeId === 'root') {
            const node = nodes[link.to.nodeId!];
            start = new Node(node.id, node.type, node.properties.name, node.properties, null, {}) as FlowNode;
            break;
        }
    }
    if (!start) {
        return { actions, conditions, start };
    }
    const queue = [start];
    while (queue.length) {
        const cur = queue.shift();
        const isAction = cur?.type === 'action';
        let elseNode: INode | undefined = undefined;
        if (isAction) {
            actions.push(cur!);
        } else {
            conditions.push(cur!);
        }
        for (const [id, link] of Object.entries(links)) {
            if (link.from.nodeId === cur?.id) {
                const from = nodes[link.from.nodeId!];
                const to = nodes[link.to.nodeId!];
                const nextNode = new Node(to.id, to.type, to.properties.name, to.properties, null, {}) as FlowNode;
                queue.push(nextNode);
                if (isAction) {
                    cur.next = nextNode;
                } else {
                    const attributeValue = from.ports[link.from.portId].properties.value;
                    cur.options![attributeValue] = nextNode;
                    const isCheckAttributeAction = from.properties.audienceId;
                    if (isCheckAttributeAction && attributeValue === 'else') {
                        elseNode = from;
                    }
                }
            }
        }
        if (elseNode !== undefined && cur !== undefined) {
            const nextNode = cur.options!['else']!;
            delete cur.options!['else'];
            const audienceAttributes = campaignAttributes[elseNode.properties.audienceId];
            const audienceAttributeValues = audienceAttributes.find(
                (attribute: UserAttributeBackend) => attribute.name === elseNode!.properties.attribute,
            )?.values;
            if (audienceAttributeValues !== undefined) {
                audienceAttributeValues.forEach((attributeValue, _) => {
                    if (!(attributeValue in elseNode!.ports)) {
                        cur.options![attributeValue] = nextNode;
                    }
                });
            }
        }
    }
    return { actions, conditions, start };
};

import { User } from 'reducers/userSlice';
import { Team, CampaignBackend, CampaignStateType, UserAttributeBackend, Asset } from 'types';
import { AssetValidationErrors } from 'types';

export const isCampaignDeletable = (selectedCampaign?: CampaignBackend) => {
    if (
        !selectedCampaign ||
        selectedCampaign!.state === CampaignStateType.ACTIVE ||
        selectedCampaign!.state === CampaignStateType.SCHEDULED
    ) {
        return false;
    }
    return true;
};

export const checkUserRoleDeleteCampaignRights = (selectedCampaign?: CampaignBackend, user?: User, teams?: Team[]) => {
    if (!selectedCampaign) {
        return false;
    }
    // Personal campaigns
    if (!selectedCampaign!.teamId) {
        return true;
    }
    // Team campaigns
    const selectedTeam = teams && teams.find((team) => team.id === selectedCampaign!.teamId);
    if (selectedTeam && selectedCampaign && user) {
        // Own campaings
        if (selectedCampaign.createdBy && selectedCampaign!.createdBy.id === user.id) {
            return true;
        }
        // Role check
        const selectedUser = selectedTeam.users.find((u) => u.id === user.id);
        if (selectedUser && selectedUser.role === 'Team Admin') {
            return true;
        }
    }
    return false;
};

export const hasWhiteSpaceInBetween = (inputString: string) => {
    return /\S+\s\S+/.test(inputString);
};

export const getAudienceAttributesInUse = (htmlTemplate: string) => {
    const dataFieldRegex = /\$\{data\.[^}]+}/g;
    return new Set(
        htmlTemplate.match(dataFieldRegex)?.map((dataField) => dataField.substring(7, dataField.length - 1).trim()),
    );
};

export const getInvalidAndMismatchedAudienceAttributes = (
    htmlTemplate: string,
    audiencesAttributes?: UserAttributeBackend[],
): AssetValidationErrors => {
    if (!audiencesAttributes) {
        return {};
    }

    const audienceAttributeNames = Object();
    audiencesAttributes.forEach((audiencesAttribute) => (audienceAttributeNames[audiencesAttribute.name!] = 1));

    const attributesInUse = getAudienceAttributesInUse(htmlTemplate);

    const invalidSyntaxAttributeErrors: string[] = [];
    const mismatchedAttributeErrors: string[] = [];

    attributesInUse.forEach((attributeName) => {
        if (hasWhiteSpaceInBetween(attributeName)) {
            invalidSyntaxAttributeErrors.push(attributeName);
        }
        //Find mismatched audience attribute
        else if (!Object.prototype.hasOwnProperty.call(audienceAttributeNames, attributeName)) {
            mismatchedAttributeErrors.push(attributeName);
        }
    });
    return {
        invalidSyntaxAttributeErrors: invalidSyntaxAttributeErrors.sort(),
        mismatchedAttributeErrors: mismatchedAttributeErrors.sort(),
    };
};

export const shortenCloudfrontImageUrlsToLocalUrls = (htmlTemplate: string) => {
    const cloudfrontImageUrlRegex = new RegExp(`${process.env.REACT_APP_CLOUDFRONT_URL}\\S+/(images/\\S+['"])`, 'g');
    return htmlTemplate.replaceAll(cloudfrontImageUrlRegex, (matchedSubstring, groupSubstring) => groupSubstring);
};

export const checkAssetHasInvalidSyntaxAttributeErrors = (asset: Asset) => {
    return (
        asset.validationErrors &&
        asset.validationErrors.invalidSyntaxAttributeErrors &&
        asset.validationErrors.invalidSyntaxAttributeErrors.length > 0
    );
};

export const checkAssetHasMismatchedAttributeErrors = (asset: Asset) => {
    return (
        asset.validationErrors &&
        asset.validationErrors.mismatchedAttributeErrors &&
        asset.validationErrors.mismatchedAttributeErrors.length > 0
    );
};

export const checkAssetHasValidationErrors = (asset: Asset) => {
    return checkAssetHasInvalidSyntaxAttributeErrors(asset) || checkAssetHasMismatchedAttributeErrors(asset);
};

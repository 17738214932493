import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { ApiResponse } from 'apisauce';
import { Box, Typography, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import api from 'api';
import { formatNumberWithCommas } from 'utils/numbers';
import CampaignMetricsModel from './campaignMetricsModel';
import { BackendErrorMessagePhrase, Asset, CampaignStateType } from 'types';
import MetricsSummaryBox, { MetricsSummaryTwoColumnBox, MetricSummaryBoxContent } from './metricsSummaryBox';
import EmailMetrices from './emails';
import EmptyPage from 'components/emptyPage';
import CustomTooltip from 'components/customTooltip';
import datetime from 'utils/datetime';
import colors from 'colors';
import EquationBox from 'components/equationBox';
import { SortableTable, Row, HeadCell } from 'components/customTable';
import { WORDPRESS_USER_GUIDE_URL } from '../../../common/constants';
interface ReportProps {
    campaignId: string;
    assets: Asset[];
    state: CampaignStateType;
}

export const Report = ({ campaignId, assets, state }: ReportProps) => {
    const history = useHistory();
    const [metrics, setMetrics] = useState<CampaignMetricsModel>();
    const { pathname: path } = history.location;
    const { emailAssetId, id } = useParams<{ emailAssetId: string; id: string }>();
    const [isTransition, setIsTransition] = useState<boolean>(false);
    const [backendMessage, setBackendMessage] = useState<BackendErrorMessagePhrase>(BackendErrorMessagePhrase.NONE);

    const orderedHeadCells: HeadCell[] = [
        { id: 'name', label: 'Email Asset', isNotSortable: true },
        { id: 'sents', label: 'Email Sent' },
        { id: 'deliveries', label: 'Delivered Succesfully' },
        { id: 'bounces', label: 'Permanent Bounces' },
        { id: 'temporaryBounces', label: 'Temporary Bounces' },
        { id: 'unsubscribes', label: 'Unsubscribed' },
        { id: 'complaints', label: 'Reported Spam' },
        { id: 'uniqueOpens', label: 'Unique Opens' },
        { id: 'uniqueOpenRate', label: 'Unique Open Rate' },
        { id: 'opens', label: 'Total Opens' },
        // { id: 'totalOpenRate', label: 'Total Open Rate' },
        { id: 'uniqueClicks', label: 'Unique Clicks' },
        { id: 'uniqueClickRate', label: 'Unique Click Rate' },
        { id: 'clicks', label: 'Total Clicks' },
        // { id: 'totalClickRate', label: 'Total Click Rate' },
    ];

    useEffect(() => {
        api.getCampaignMetrics(campaignId).then((response: ApiResponse<any>) => {
            if (response.ok) {
                setMetrics(new CampaignMetricsModel(response.data));
            } else if (response.data.message === BackendErrorMessagePhrase.CAMPAIGN_METRICS_NOT_FOUND) {
                setBackendMessage(BackendErrorMessagePhrase.CAMPAIGN_METRICS_NOT_FOUND);
                setIsTransition(true);
            }
        });
    }, []);

    const getNextUpdateTime = (lastUpdated: string) => {
        const nextUpdateTime = datetime.getNextCampaignMetricsUpdateTime(lastUpdated);
        return formatDate(nextUpdateTime, 'h:mm a').toLowerCase();
    };

    if (emailAssetId) {
        const index = assets && assets.map((e) => e.id).indexOf(emailAssetId);
        if (index < 0 || backendMessage === BackendErrorMessagePhrase.CAMPAIGN_METRICS_NOT_FOUND) {
            history.push(`/campaigns/update/${id}`);
        }
        return <EmailMetrices />;
    }

    return isTransition ? (
        <Box py={5} px={3}>
            <EmptyPage
                title="Campaign report is pending..."
                subtitle="You can see information on how your campaign performed after we have finished sending your campaign emails"
                withBackground
            />
        </Box>
    ) : (
        <Box px={3} py={4} style={{ paddingBottom: 70 }}>
            {metrics && (
                <>
                    <Box mb={2} display="flex" alignItems="center">
                        <Typography variant="h2" style={{ marginRight: 8 }}>
                            Campaign report
                        </Typography>
                        <span>Updated {datetime.toFormatDateAtTime(metrics.updatedOn)}</span>
                        {state !== CampaignStateType.INACTIVE && (
                            <CustomTooltip
                                title={`The campaign report and individual email reports will be next updated at ${getNextUpdateTime(
                                    metrics.updatedOn,
                                )}`}
                                placement="bottom"
                                icon="help"
                                arrow
                            />
                        )}
                    </Box>
                    <Grid
                        container
                        direction="row"
                        spacing={3}
                        style={{ margin: '1rem - 12px', marginBottom: '0.75rem' }}
                    >
                        <MetricsSummaryBox
                            mainContent={
                                new MetricSummaryBoxContent(
                                    formatNumberWithCommas(metrics.contacts),
                                    'contacts',
                                    'This campaign will be sent to',
                                )
                            }
                            mainHelpContent={{
                                tooltipContents: (
                                    <>
                                        After the campaign starts, the number will increase when new contacts are added
                                        to your audience list.
                                        <br />
                                        <br />
                                        However, the number will{' '}
                                        <Typography style={{ fontSize: 12, fontWeight: 500, display: 'inline' }}>
                                            NOT
                                        </Typography>{' '}
                                        decrease when contacts are removed from your audience list.
                                    </>
                                ),
                            }}
                            leftSubContent={
                                new MetricSummaryBoxContent(
                                    formatNumberWithCommas(metrics.unsubscribes),
                                    'contacts unsubscribed',
                                    undefined,
                                    false,
                                )
                            }
                            leftSubHelpContent={{
                                tooltipContents: 'Contacts who opted to stop receiving emails in this campaign',
                            }}
                            rightSubContent={
                                new MetricSummaryBoxContent(
                                    formatNumberWithCommas(metrics.complaints),
                                    'reported spam',
                                    undefined,
                                    false,
                                )
                            }
                            rightSubHelpContent={{
                                tooltipContents: 'Contacts who reported emails sent as junk or spam in this campaign',
                            }}
                        />
                        <MetricsSummaryBox
                            mainContent={
                                new MetricSummaryBoxContent(
                                    metrics.deliveries_display,
                                    'emails were delivered successfully',
                                )
                            }
                            mainHelpContent={{
                                tooltipContents: (
                                    <div>
                                        This is the total number of emails that were delivered to the contacts’ mailbox.
                                        <br />
                                        <br />
                                        This number includes contacts who have{' '}
                                        <Typography style={{ fontSize: 12, fontWeight: 500, display: 'inline' }}>
                                            unsubscribed
                                        </Typography>{' '}
                                        or{' '}
                                        <Typography style={{ fontSize: 12, fontWeight: 500, display: 'inline' }}>
                                            reported
                                        </Typography>{' '}
                                        spam in this campaign.
                                    </div>
                                ),
                            }}
                            leftSubContent={
                                new MetricSummaryBoxContent(
                                    metrics.bounces_display,
                                    'permanent bounces',
                                    undefined,
                                    false,
                                )
                            }
                            rightSubContent={
                                new MetricSummaryBoxContent(
                                    metrics.temporaryBounces_display,
                                    'temporary bounces',
                                    undefined,
                                    false,
                                )
                            }
                            rightSubHelpContent={{
                                tooltipContents: (
                                    <>
                                        <ul style={{ paddingInlineStart: '1.5rem', marginBottom: '0.25rem' }}>
                                            <li>Hard/Permanent bounces (e.g. invalid address)</li>
                                            <li>Soft/Temporary bounces (e.g. full mailbox)</li>
                                        </ul>
                                        <a
                                            href={`${WORDPRESS_USER_GUIDE_URL}/user-guide/metrics/contact-type`}
                                            style={{ color: colors.darkGrey, textDecoration: 'none' }}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Learn more
                                        </a>
                                    </>
                                ),
                            }}
                            subcontentSeperator="·"
                        />
                        <MetricsSummaryTwoColumnBox
                            leftMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueOpenRate_display,
                                    undefined,
                                    undefined,
                                    true,
                                    `Unique open rate`,
                                )
                            }
                            leftSecondaryContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueOpens_display,
                                    'unique opens',
                                    undefined,
                                    false,
                                )
                            }
                            leftHelpContent={{
                                tooltipContents: (
                                    <EquationBox
                                        product="Unique open rate ="
                                        numerator="Unique opens from all email assets"
                                        denominator="No. of emails delivered succesfully"
                                    />
                                ),
                                isLarge: true,
                            }}
                            rightMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.opens_display,
                                    undefined,
                                    undefined,
                                    true,
                                    'Total opens',
                                )
                            }
                            rightHelpContent={{
                                tooltipContents: 'Total opens are the total number of times your email has been opened',
                            }}
                        />
                        <MetricsSummaryTwoColumnBox
                            leftMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueClickRate_display,
                                    undefined,
                                    undefined,
                                    true,
                                    'Unique click rate',
                                )
                            }
                            leftSecondaryContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueClicks_display,
                                    'unique clicks',
                                    undefined,
                                    false,
                                )
                            }
                            leftHelpContent={{
                                tooltipContents: (
                                    <EquationBox
                                        product="Unique click rate ="
                                        numerator="Unique clicks from all email assets"
                                        denominator="No. of emails delivered succesfully"
                                    />
                                ),
                                isLarge: true,
                            }}
                            rightMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.clicks_display,
                                    undefined,
                                    undefined,
                                    true,
                                    'Total clicks',
                                )
                            }
                            rightHelpContent={{
                                tooltipContents:
                                    'Total clicks are the total number of clicks received in your campaign',
                            }}
                        />
                    </Grid>
                    <Box>
                        <Typography variant="h2" style={{ marginBottom: '1rem' }}>
                            Email assets
                        </Typography>
                        <SortableTable
                            orderedHeadCells={orderedHeadCells}
                            data={metrics.emailAssets}
                            defaultOrderBy="sents"
                            formatRowCell={(rowData: Row, columnId: string) => {
                                let data;
                                if (columnId === 'name') {
                                    data = (
                                        <Typography
                                            variant="body1"
                                            style={{
                                                cursor: 'pointer',
                                                color: colors.blue,
                                            }}
                                            onClick={() => history.push(`${path}/${rowData.id}`)}
                                        >
                                            {rowData.name}
                                        </Typography>
                                    );
                                } else {
                                    data = rowData[columnId + '_display'];
                                }
                                return data;
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    campaignId: state.campaignSlice.draftCampaign.id!,
    assets: state.campaignSlice.draftCampaign.assets,
    state: state.campaignSlice.draftCampaign.state,
});

export default connect(mapStateToProps)(Report);

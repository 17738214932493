import React from 'react';
import { InputLabel, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Audience } from 'types';
import colors from 'colors';

interface DisplayAudienceProps {
    audiences: Audience[];
}

const styles = makeStyles({
    label: {
        marginBottom: 16,
        color: colors.darkGrey,
    },
    subHeader: {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 8,
    },
});

const DisplayAudience = ({ audiences }: DisplayAudienceProps) => {
    const classes = styles();
    return (
        <Box style={{ margin: '24px 0 0' }}>
            <Box>
                <InputLabel className={classes.label} style={{ marginBottom: 16 }}>
                    Audience
                </InputLabel>
                {audiences.map((audience) => (
                    <Box key={audience.id}>{audience.name}</Box>
                ))}
            </Box>
        </Box>
    );
};

export default DisplayAudience;

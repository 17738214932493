import React, { useEffect, CSSProperties } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Tab, Button, MenuItem, Menu, Box } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';
import {
    Campaign,
    CampaignStartType,
    CampaignStateType,
    CampaignFeatures,
    CampaignFeaturesDraftMode,
    CampaignAttributes,
} from 'types';
import { isCampaignValid } from 'features/campaigns/validate';
import api from 'api';
import ConfirmModal from 'components/confirmModal';
import ScheduleModal from '../scheduleModal';
import CustomTooltip from 'components/customTooltip';
import DeleteButton from 'components/deleteButton';
import EditableTitle from 'components/editableTitle';

import { LIVE, DRAFT, CAMPAIGN_STATE_TEXT } from 'common/constants';

import Status from './status';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        background: colors.white,
        padding: '0 24px',
        borderBottom: `1px solid ${colors.grey}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    link: {
        textDecoration: 'none',
    },
    tabs: {
        minHeight: 0,
    },
    tab: {
        minWidth: 0,
        textTransform: 'none',
        fontSize: 16,
        lineHeight: '24px',
        padding: '0 8px 16px 8px',
        minHeight: 0,
        marginRight: 32,
        fontWeight: 400,
    },
    selected: {
        fontWeight: 500,
    },
    buttonContainer: {
        display: 'flex',
        paddingLeft: 80,
        minWidth: 356,
        justifyContent: 'flex-end',
    },
    button: {
        padding: '16px 24px',
        minWidth: 195,
    },
    moreButton: {
        margin: 0,
        padding: '16px 24px',
    },
    moreActionsMenu: {
        width: 184,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    scheduleMenu: {
        width: 233,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    startMenu: {
        width: 206,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    disabledMenu: {
        color: colors.darkGrey,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    deleteIcon: {
        padding: '11px',
        border: `1px solid ${colors.blue}`,
        fontSize: '32px',
        '&:disabled': {
            color: colors.white,
            background: colors.grey,
            border: `1px solid ${colors.grey}`,
        },
    },
}));

type CreateCampaignProp = {
    draftCampaign: Campaign;
    campaignAttributes?: CampaignAttributes;
    setCampaignAttribute: ({ name, val }: { name: string; val: any }) => void;
    start: (type: CampaignStartType) => void;
    stop: () => void;
    currentTab: number;
    isCampaignDeletable: boolean;
    getTeams: () => void;
};

type DeleteCampaignButton = {
    style?: CSSProperties;
};

const CreateCampaignHeader = ({
    draftCampaign,
    campaignAttributes,
    setCampaignAttribute,
    start,
    stop,
    currentTab,
    isCampaignDeletable,
    getTeams,
}: CreateCampaignProp) => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const isReadyToStart = isCampaignValid(draftCampaign, draftCampaign.isStarted ? LIVE : DRAFT, campaignAttributes);
    const classes = useStyles();
    const [openSchedule, setOpenSchedule] = React.useState<boolean>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [startEl, setStartEl] = React.useState<null | HTMLElement>(null);
    const isDraftCampaign = draftCampaign.state === CampaignStateType.DRAFT;

    useEffect(() => {
        getTeams();
    }, []);

    const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
        let selectedTab;
        isDraftCampaign
            ? (selectedTab = CampaignFeaturesDraftMode[newValue])
            : (selectedTab = CampaignFeatures[newValue]);
        history.push(`/campaigns/update/${id}/${selectedTab}`);
    };

    const handleStartClick = (event: React.MouseEvent<HTMLElement>) => {
        setStartEl(event.currentTarget);
    };

    const handleStartClose = () => {
        setStartEl(null);
    };

    const campaignStatusLabel = () => {
        if (draftCampaign.state === CampaignStateType.SCHEDULED) {
            return (
                <Status variant="outlined">
                    {draftCampaign.isStarted ? 'Resuming on' : 'Starting on'}{' '}
                    {format(new Date(draftCampaign.scheduledOn!), 'dd/MM/yy, hh:mm a')}
                </Status>
            );
        } else if (draftCampaign.state === CampaignStateType.ACTIVE) {
            return <Status color="success">Active</Status>;
        } else if (draftCampaign.state === CampaignStateType.INACTIVE) {
            return <Status color="error">Inactive</Status>;
        } else {
            return <Status color="draft">Draft</Status>;
        }
    };

    const DeleteCampaignButton = ({ style }: DeleteCampaignButton) => {
        return (
            <>
                {isCampaignDeletable ? (
                    <CustomTooltip
                        title="Delete campaign"
                        placement={draftCampaign.state === CampaignStateType.INACTIVE ? 'bottom-end' : 'bottom'}
                        arrow={true}
                    >
                        <DeleteButton style={{ ...style }} onClick={() => setOpenDelete(true)} />
                    </CustomTooltip>
                ) : (
                    <CustomTooltip
                        title="You are not permitted to delete this campaign. Request for admin rights from your team admin."
                        placement="bottom-end"
                        arrow={true}
                    >
                        <DeleteButton disabled={true} style={{ ...style }} />
                    </CustomTooltip>
                )}
            </>
        );
    };

    return (
        <div className={classes.container}>
            <div style={{ overflow: 'hidden' }}>
                <Box display="flex" alignItems="center" style={{ marginTop: 24, marginBottom: 20, overflow: 'hidden' }}>
                    <EditableTitle
                        title={draftCampaign.name!}
                        onTitleChange={(title) => {
                            setCampaignAttribute({ name: 'name', val: title });
                        }}
                        style={{ overflow: 'hidden' }}
                        isDraft={draftCampaign.state === CampaignStateType.DRAFT}
                    />
                    <div style={{ marginLeft: 16 }}>{campaignStatusLabel()}</div>
                </Box>
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{ root: classes.tabs }}
                >
                    {!isDraftCampaign && (
                        <Tab classes={{ root: classes.tab, selected: classes.selected }} label="Report" />
                    )}
                    <Tab classes={{ root: classes.tab, selected: classes.selected }} label="Settings" />
                    <Tab classes={{ root: classes.tab, selected: classes.selected }} label="Audience" />
                    <Tab classes={{ root: classes.tab, selected: classes.selected }} label="Assets" />
                    <Tab classes={{ root: classes.tab, selected: classes.selected }} label="Workflow" />
                </Tabs>
            </div>
            <div className={classes.buttonContainer}>
                {draftCampaign.state === CampaignStateType.DRAFT && (
                    <div style={{ marginRight: 12 }}>
                        <DeleteCampaignButton />
                    </div>
                )}

                {draftCampaign.state === CampaignStateType.INACTIVE ||
                draftCampaign.state === CampaignStateType.DRAFT ? (
                    <>
                        <Button
                            aria-controls="start"
                            aria-haspopup="true"
                            variant="outlined"
                            className={classes.button}
                            color="primary"
                            disabled={!isReadyToStart}
                            onClick={handleStartClick}
                        >
                            {draftCampaign.state === CampaignStateType.INACTIVE ? 'Resume' : 'Start'} campaign{' '}
                            <ArrowIcon style={{ marginLeft: 6 }} />
                        </Button>
                        <Menu
                            classes={{
                                paper:
                                    draftCampaign.state === CampaignStateType.INACTIVE
                                        ? classes.scheduleMenu
                                        : classes.startMenu,
                            }}
                            id="campaign-schedule-actions"
                            anchorEl={startEl}
                            keepMounted={true}
                            open={Boolean(startEl)}
                            onClose={handleStartClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            style={{ marginTop: 8 }}
                        >
                            <MenuItem
                                onClick={() => {
                                    start('NOW');
                                    handleStartClose();
                                }}
                            >
                                {draftCampaign.state === CampaignStateType.INACTIVE ? 'Resume now' : 'Start now'}
                            </MenuItem>
                            <MenuItem
                                disabled={!isReadyToStart}
                                onClick={() => {
                                    handleStartClose();
                                    setOpenSchedule(true);
                                }}
                            >
                                {draftCampaign.state === CampaignStateType.INACTIVE
                                    ? 'Resume later'
                                    : 'Schedule start date'}
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <Button className={classes.button} variant="outlined" color="primary" onClick={stop}>
                        {draftCampaign.state === CampaignStateType.SCHEDULED && !draftCampaign.isStarted
                            ? 'Stop and edit'
                            : 'Stop campaign'}
                    </Button>
                )}
                {draftCampaign.state === CampaignStateType.INACTIVE && (
                    <div style={{ marginLeft: 12 }}>
                        <DeleteCampaignButton />
                    </div>
                )}
            </div>
            <ConfirmModal
                open={openDelete}
                title={`Delete ${CAMPAIGN_STATE_TEXT[draftCampaign.state]} campaign`}
                content={`“${draftCampaign.name}” will be deleted forever`}
                textNo={'Cancel'}
                textYes={'Delete'}
                onClose={() => {
                    setOpenDelete(false);
                }}
                confirm={() => {
                    api.deleteCampaign(draftCampaign.id!).then((response) => {
                        if (response.ok) {
                            setOpenDelete(false);
                            if (draftCampaign.teamId) {
                                history.push(`/campaigns/teams/${draftCampaign.teamId}`);
                            } else {
                                history.push('/campaigns');
                            }
                        }
                    });
                }}
                destructive
            />
            <ScheduleModal
                open={openSchedule}
                isInactive={draftCampaign.state === CampaignStateType.INACTIVE}
                handleClose={() => {
                    setOpenSchedule(false);
                }}
                date={draftCampaign.scheduledOn}
                onDateChange={(date) => {
                    setCampaignAttribute({ name: 'scheduledOn', val: date });
                }}
                onSchedule={() => {
                    start('LATER');
                    setOpenSchedule(false);
                }}
            />
        </div>
    );
};

export default CreateCampaignHeader;

import JSZip from 'jszip';
import { getFileExtension } from './file';
const MIME_TYPES = {
    html: 'text/html',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    json: 'application/json',
};

export const unzipFile = async (zippedFileArrayBuffer: ArrayBuffer) => {
    const zippedFiles = await JSZip.loadAsync(zippedFileArrayBuffer);
    //Get root path by finding html file and substring from last "/"
    const htmlFilesArray = zippedFiles.filter((relativePath) => {
        const lowerCaseRelativePath = relativePath.toLowerCase();
        //Ignore all files starting with "__MACOSX"
        return !lowerCaseRelativePath.includes('__macosx') && lowerCaseRelativePath.endsWith('.html');
    });

    if (htmlFilesArray.length < 1) return { files: [], paths: [] }; //return early & let validators handle no html file error
    const htmlFilePath = htmlFilesArray[0].name; //eg: "/hello/world/template.html"
    const indexOfLastSeperator = htmlFilePath.lastIndexOf('/');
    const rootPath = indexOfLastSeperator > -1 ? htmlFilePath.substring(0, indexOfLastSeperator + 1) : ''; //eg: "/hello/world/"

    // Get all non-directory files
    const zippedFilesArray = zippedFiles.filter(
        (relativePath, file) => !relativePath.includes('__MACOSX') && !file.dir,
    );

    // newFileNames in the form ["template.html","dog.jpg",...]
    const newFileNames: string[] = [];

    // newFilePaths in the form ["/template.html", "/images/dog.jpg", ...] to fit exisiting upload method on assetsModal
    const newFilePaths: string[] = [];
    const filePromisesArray: Promise<ArrayBuffer>[] = [];

    zippedFilesArray.forEach((zippedFile) => {
        newFilePaths.push('/' + zippedFile.name.replace(rootPath, '')); // Add '/' for some zip files that don't have a root folder
        const splitFilePath = zippedFile.name.split('/');
        newFileNames.push(splitFilePath[splitFilePath.length - 1]);
        filePromisesArray.push(zippedFile.async('arraybuffer'));
    });

    const fileArrayBuffers = await Promise.all(filePromisesArray);
    const fileArray = fileArrayBuffers.map((fileArrayBuffer, i) => {
        return new File([fileArrayBuffer], newFileNames[i], {
            type: MIME_TYPES[getFileExtension(newFileNames[i]) as keyof typeof MIME_TYPES],
        });
    });

    return { files: fileArray, paths: newFilePaths };
};

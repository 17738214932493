import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApiResponse } from 'apisauce';
import {
    FlowChart,
    IChart,
    IOnDragNode,
    IOnDragCanvas,
    IOnLinkStart,
    IOnLinkMove,
    IOnLinkComplete,
    IOnDragNodeStop,
    IOnDragCanvasStop,
    IOnLinkCancel,
    IOnLinkMouseEnter,
    IOnLinkMouseLeave,
    IOnLinkClick,
    IOnCanvasClick,
    IOnNodeMouseEnter,
    IOnNodeMouseLeave,
    IOnDeleteKey,
    IOnNodeClick,
    IOnNodeSizeChange,
    IOnPortPositionChange,
    IOnCanvasDrop,
    IOnCanvasDropInput,
    INodeBaseInput,
    IOnDragNodeInput,
} from '@mrblenny/react-flow-chart';

import flowChartCallbacks from './flowCharCallbacks';
import {
    updateWorkflowNode,
    updateWorkflowDraftNode,
    clearDraftChanges,
    replaceDraftCampaign,
    checkIfWorkflowComponentsAreValid,
    markAsNotNew,
    updateLiveWorkflowNode,
    updateWorkflowPosition,
} from 'features/campaigns/campaignSlice';
import Canvas from './canvas';
import ModeBoard from './modeBoard';
import SideMenu from './sideMenu';
import Node from './node';
import NodeInner from './nodeInner';
import Port from './port';
import Link from './link';
import Ports from './ports';
import { Asset, UpdateFlowNode, Campaign, CampaignStateType, CampaignAttributes } from 'types';
import { SEND_EMAIL, CHECK_ATTRIBUTE, LIVE, DRAFT } from 'common/constants';
import { Typography, Box, Button } from '@material-ui/core';
import { convertWorkflowToTree } from './workflowHelper';
import colors from 'colors';
import api from 'api';
import { isCampaignValid, isNewConnection, isConnected } from 'features/campaigns/validate';
import ConfirmModel from 'components/confirmModal';
import { RootState } from 'reducers';

interface WorkflowPosition {
    position: number;
    mode: string;
}

type WorkflowProps = {
    draftCampaign: Campaign;
    campaignAttributes?: CampaignAttributes;
    workflow: IChart;
    workflowDraft: IChart;
    isStarted: boolean;
    assets: Asset[];
    updateWorkflowNode: UpdateFlowNode;
    updateWorkflowDraftNode: UpdateFlowNode;
    updateLiveWorkflowNode: UpdateFlowNode;
    updateWorkflowPosition: (payload: WorkflowPosition) => void;
    clearDraftChanges: () => void;
    replaceDraftCampaign: (campaign: Campaign) => void;
    checkIfWorkflowComponentsAreValid: () => void;
    markAsNotNew: (id: string) => void;

    onDragNode: IOnDragNode;
    onDragNodeStop: IOnDragNodeStop;
    onDragCanvas: IOnDragCanvas;
    onDragCanvasStop: IOnDragCanvasStop;
    onLinkStart: IOnLinkStart;
    onLinkMove: IOnLinkMove;
    onLinkComplete: IOnLinkComplete;
    onLinkCancel: IOnLinkCancel;
    onLinkMouseEnter: IOnLinkMouseEnter;
    onLinkMouseLeave: IOnLinkMouseLeave;
    onLinkClick: IOnLinkClick;
    onCanvasClick: IOnCanvasClick;
    onNodeMouseEnter: IOnNodeMouseEnter;
    onNodeMouseLeave: IOnNodeMouseLeave;
    onDeleteKey: IOnDeleteKey;
    onNodeClick: IOnNodeClick;
    onNodeSizeChange: IOnNodeSizeChange;
    onPortPositionChange: IOnPortPositionChange;
    onCanvasDrop: IOnCanvasDrop;

    onDragNodeDraft: IOnDragNode;
    onDragNodeStopDraft: IOnDragNodeStop;
    onDragCanvasDraft: IOnDragCanvas;
    onDragCanvasStopDraft: IOnDragCanvasStop;
    onLinkStartDraft: IOnLinkStart;
    onLinkMoveDraft: IOnLinkMove;
    onLinkCompleteDraft: IOnLinkComplete;
    onLinkCancelDraft: IOnLinkCancel;
    onLinkMouseEnterDraft: IOnLinkMouseEnter;
    onLinkMouseLeaveDraft: IOnLinkMouseLeave;
    onLinkClickDraft: IOnLinkClick;
    onCanvasClickDraft: IOnCanvasClick;
    onNodeMouseEnterDraft: IOnNodeMouseEnter;
    onNodeMouseLeaveDraft: IOnNodeMouseLeave;
    onDeleteKeyDraft: IOnDeleteKey;
    onNodeClickDraft: IOnNodeClick;
    onNodeSizeChangeDraft: IOnNodeSizeChange;
    onPortPositionChangeDraft: IOnPortPositionChange;
    onCanvasDropDraft: IOnCanvasDrop;
};

const Workflow = ({
    draftCampaign,
    campaignAttributes,
    workflow,
    workflowDraft,
    assets,
    isStarted,
    // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
    updateWorkflowNode,
    updateWorkflowDraftNode,
    updateLiveWorkflowNode,
    updateWorkflowPosition,
    clearDraftChanges,
    replaceDraftCampaign,
    checkIfWorkflowComponentsAreValid,
    markAsNotNew,
    /* eslint-disable no-shadow,@typescript-eslint/no-shadow */
    onDragNode,
    onDragNodeStop,
    onDragCanvas,
    onDragCanvasStop,
    onLinkStart,
    onLinkMove,
    onLinkComplete,
    onLinkCancel,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onLinkClick,
    onCanvasClick,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onDeleteKey,
    onNodeClick,
    onNodeSizeChange,
    onPortPositionChange,
    onCanvasDrop,
    // draft
    onDragNodeDraft,
    onDragNodeStopDraft,
    onDragCanvasDraft,
    onDragCanvasStopDraft,
    onLinkStartDraft,
    onLinkMoveDraft,
    onLinkCompleteDraft,
    onLinkCancelDraft,
    onLinkMouseEnterDraft,
    onLinkMouseLeaveDraft,
    onLinkClickDraft,
    onCanvasClickDraft,
    onNodeMouseEnterDraft,
    onNodeMouseLeaveDraft,
    onDeleteKeyDraft,
    onNodeClickDraft,
    onNodeSizeChangeDraft,
    onPortPositionChangeDraft,
    onCanvasDropDraft,
}: WorkflowProps) => {
    const [mode, setMode] = useState<string>(isStarted ? LIVE : DRAFT);
    const [openConfirmApply, setOpenConfirmApply] = useState<boolean>(false);
    const [openClearChanges, setOpenClearChanges] = useState<boolean>(false);
    // It detects any change in workflow
    const [hasDraftChanges, setHasDraftChanges] = useState<boolean>(false);

    const isReadyToStart = useMemo(() => {
        return isCampaignValid(draftCampaign, DRAFT, campaignAttributes);
    }, [draftCampaign]);

    useEffect(() => {
        checkIfWorkflowComponentsAreValid();
    }, [workflowDraft]);
    const actions =
        mode === LIVE
            ? {
                  onDragNode,
                  onDragNodeStop,
                  onDragCanvas,
                  onDragCanvasStop,
                  onLinkStart: () => ({}),
                  onLinkMove,
                  onLinkComplete,
                  onLinkCancel,
                  onLinkMouseEnter,
                  onLinkMouseLeave,
                  onLinkClick,
                  onCanvasClick,
                  onNodeMouseEnter,
                  onNodeMouseLeave,
                  onDeleteKey: () => ({}),
                  onNodeClick,
                  onNodeSizeChange,
                  onPortPositionChange,
                  onCanvasDrop,
              }
            : {
                  onDragNode: (input: IOnDragNodeInput) => {
                      setHasDraftChanges(true);
                      onDragNodeDraft(input);
                  },
                  onDragNodeStop: onDragNodeStopDraft,
                  onDragCanvas: onDragCanvasDraft,
                  onDragCanvasStop: onDragCanvasStopDraft,
                  onLinkStart: onLinkStartDraft,
                  onLinkMove: onLinkMoveDraft,
                  onLinkComplete: onLinkCompleteDraft,
                  onLinkCancel: onLinkCancelDraft,
                  onLinkMouseEnter: onLinkMouseEnterDraft,
                  onLinkMouseLeave: onLinkMouseLeaveDraft,
                  onLinkClick: onLinkClickDraft,
                  onCanvasClick: onCanvasClickDraft,
                  onNodeMouseEnter: onNodeMouseEnterDraft,
                  onNodeMouseLeave: onNodeMouseLeaveDraft,
                  onDeleteKey: () => {
                      setHasDraftChanges(true);
                      onDeleteKeyDraft({});
                  },
                  onNodeClick: (input: INodeBaseInput) => {
                      onNodeClickDraft(input);
                  },
                  onNodeSizeChange: onNodeSizeChangeDraft,
                  onPortPositionChange: onPortPositionChangeDraft,
                  onCanvasDrop: (input: IOnCanvasDropInput) => {
                      setHasDraftChanges(true);
                      onCanvasDropDraft(input);
                  },
              };

    useEffect(() => {
        if (mode === LIVE) {
            const nodes = workflow && workflow.nodes;
            if (nodes) {
                for (const key of Object.keys(workflow.nodes)) {
                    if (workflow.nodes[key].id !== 'root') {
                        updateLiveWorkflowNode(workflow.nodes[key]);
                    }
                }
            }
        }
    }, [mode]);

    const workflowRef = React.createRef() as any;

    // Reset canvas offset
    useEffect(() => {
        const width = workflowRef.current.clientWidth;
        if (width) {
            updateWorkflowPosition({ position: width, mode });
        }
    }, [mode]);

    return (
        <>
            <div style={{ overflow: 'hidden', position: 'relative', display: 'flex' }}>
                {isStarted && draftCampaign.state !== CampaignStateType.SCHEDULED && (
                    <ModeBoard/>
                )}
                <div style={{ overflow: 'hidden', position: 'relative', display: 'flex' }} ref={workflowRef}>
                    <FlowChart
                        chart={mode === LIVE ? workflow : workflowDraft}
                        Components={{
                            Node,
                            NodeInner,
                            Port,
                            Link,
                            Ports,
                            CanvasOuter: Canvas,
                        }}
                        callbacks={actions}
                        config={{
                            readonly: mode === LIVE || draftCampaign.state === CampaignStateType.SCHEDULED,
                            validateLink: ({ linkId, fromNodeId, fromPortId, toNodeId, toPortId, chart }): boolean => {
                                // ports should have different type [input, output]
                                if (
                                    chart.nodes[fromNodeId].ports[fromPortId].type ===
                                        chart.nodes[toNodeId].ports[toPortId].type ||
                                    chart.nodes[toNodeId].ports[toPortId].type !== 'input' ||
                                    fromNodeId === toNodeId
                                ) {
                                    return false;
                                }
                                const links = Object.values(chart.links);
                                // ports can only be connected to 1 other port
                                if (
                                    links.some((link) => {
                                        return (
                                            !isNewConnection(fromNodeId, link.from.nodeId, link.to.nodeId) &&
                                            isConnected(
                                                fromNodeId,
                                                fromPortId,
                                                toNodeId,
                                                toPortId,
                                                link.from.nodeId,
                                                link.from.portId,
                                                link.to.nodeId!,
                                                link.to.portId!,
                                            )
                                        );
                                    })
                                ) {
                                    return false;
                                }
                                // root can only connect to action or check attributes
                                if (fromNodeId === 'root' || toNodeId === 'root') {
                                    const otherNode =
                                        fromNodeId === 'root' ? chart.nodes[toNodeId] : chart.nodes[fromNodeId];
                                    return (
                                        otherNode.properties.subtype === SEND_EMAIL ||
                                        otherNode.properties.subtype === CHECK_ATTRIBUTE
                                    );
                                }
                                // cannot be a circle
                                const alreadyConnected = (nodeId: string): boolean => {
                                    if (nodeId === fromNodeId) {
                                        return false;
                                    }
                                    for (const link of links) {
                                        if (link.from.nodeId === nodeId && !alreadyConnected(link.to.nodeId!)) {
                                            return false;
                                        }
                                    }
                                    return true;
                                };

                                return alreadyConnected(toNodeId);
                            },
                        }}
                    />
                    {mode === DRAFT && draftCampaign.state !== CampaignStateType.SCHEDULED && (
                        <SideMenu
                            workflow={workflowDraft}
                            campaignId={draftCampaign.id!}
                            assets={assets}
                            unselectNode={markAsNotNew}
                            updateWorkflowNode={mode === DRAFT ? updateWorkflowDraftNode : updateWorkflowNode}
                            setHasDraftChanges={setHasDraftChanges}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    workflow: state.campaignSlice.draftCampaign.workflow,
    workflowDraft: state.campaignSlice.draftCampaign.workflowDraft,
    draftCampaign: state.campaignSlice.draftCampaign,
    assets: state.campaignSlice.draftCampaign.assets,
    isStarted: state.campaignSlice.draftCampaign.isStarted,
    campaignAttributes: state.campaignSlice.campaignAttributes,
});

const mapDispatchToProps = {
    ...flowChartCallbacks,
    updateWorkflowNode,
    updateWorkflowDraftNode,
    clearDraftChanges,
    replaceDraftCampaign,
    checkIfWorkflowComponentsAreValid,
    markAsNotNew,
    updateLiveWorkflowNode,
    updateWorkflowPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);

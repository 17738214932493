import React, { PropsWithChildren, ReactElement } from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import colors from 'colors';

type CustomChipProps = PropsWithChildren<{
    variant?: 'outlined' | 'default';
    label: string;
    deleteIcon?: ReactElement;
}>;

const useStyles = makeStyles(() => ({
    root: {
        borderRadius: 4,
        borderColor: colors.blue,
        background: colors.whiteBlue,
        fontSize: '14px',
        color: colors.dark,
        margin: 4,
    },
}));

const CustomChip = ({
    variant = 'outlined',
    label,
    deleteIcon = <CloseIcon />,
    children,
    ...rest
}: CustomChipProps) => {
    const classes = useStyles();
    return (
        <Chip
            variant={variant}
            label={label}
            deleteIcon={deleteIcon}
            classes={{
                root: classes.root,
            }}
            {...rest}
        />
    );
};

export default CustomChip;

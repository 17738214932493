import { Box, Link, makeStyles, Paper } from '@material-ui/core';
import { MouseEvent, useState } from 'react';
import colors from 'colors';
import { Audience } from 'types';
import datetime from 'utils/datetime';
import { canManageAudience } from './audienceUtils';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import CustomTooltip from 'components/customTooltip';

const useStyles = makeStyles(() => ({
    audienceRow: {
        padding: '24px 32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        '&:last-of-type': {
            marginBottom: 0,
        },
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0px 0px 20px rgba(45, 51, 64, 0.12)',
        },
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

interface AudienceRowProps {
    audience: Audience;
    audiencesPending: string[];
    handleRowClick: (audience: Audience) => void;
    handleClick: (e: MouseEvent<HTMLAnchorElement>, audienceId: string) => void;
}

const renderImportedBy = (audience: Audience, audiencesPending: string[]) => {
    let date;
    if (!canManageAudience(audience, audiencesPending)) {
        date = 'Importing...';
    } else if (audience.importedOn) {
        date = datetime.toFormatDateAtTime(audience.importedOn);
    } else {
        date = '-';
    }

    if (audience.importedBy?.firstName || audience.importedBy?.lastName) {
        return `${date} (${audience.importedBy?.firstName || ''} ${audience.importedBy?.lastName || ''})`;
    } else {
        return date;
    }
};

const AudienceRow = ({ audience, audiencesPending, handleRowClick, handleClick }: AudienceRowProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [cursor, setCursor] = useState({ x: 0, y: 0 });

    const handleMouseOver = () => {
        setOpen(true);
    };

    const handleMouseOut = () => {
        setOpen(false);
    };

    const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
        setCursor({ x: event.clientX, y: event.clientY });
    };

    return (
        <Paper
            onMouseMove={handleMouseMove}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            className={classes.audienceRow}
            onClick={() => {
                handleRowClick(audience);
            }}
            style={{ color: canManageAudience(audience, audiencesPending) ? undefined : colors.darkGrey }}
        >
            <CustomTooltip
                title="You cannot manage this audience as import is in progress"
                arrow
                placement="right"
                open={!canManageAudience(audience, audiencesPending) && open}
                PopperProps={{
                    anchorEl: {
                        getBoundingClientRect: () => {
                            return new DOMRect(cursor.x, cursor.y, 0, 0);
                        },
                        clientHeight: 0,
                        clientWidth: 0,
                    },
                }}
            />
            <Box style={{ marginRight: 64, minWidth: '32%', width: '32%' }}>
                <Box mb={1} className={classes.ellipsis}>
                    {audience.name}
                </Box>
                <div style={{ color: colors.darkGrey, fontSize: 14 }} className={classes.ellipsis}>
                    Owned by {`${audience.createdBy!.firstName} ${audience.createdBy!.lastName}`}
                </div>
            </Box>
            <Box display="flex" width={'68%'} overflow={'hidden'}>
                <Box style={{ marginRight: 64, overflow: 'hidden' }} width={'100%'}>
                    <Box mb={1} color={colors.darkGrey} fontSize={14}>
                        Last imported
                    </Box>
                    <Box display={'flex'} minWidth={0}>
                        {renderImportedBy(audience, audiencesPending)}
                    </Box>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Link
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                            e.stopPropagation();
                            handleClick(e, audience.id);
                        }}
                    >
                        {canManageAudience(audience, audiencesPending) && <MoreIcon />}
                    </Link>
                </Box>
            </Box>
        </Paper>
    );
};
export default AudienceRow;

import React, { useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import colors from 'colors';
import TestModal from './testModal';

interface TestBannerProps {
    isDraft: boolean;
}

const TestBanner = ({ isDraft }: TestBannerProps) => {
    const [openTestModal, setOpenTestModal] = useState(false);
    return (
        <>
            {isDraft && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{
                        padding: '24px 32px',
                        margin: 24,
                        border: `1px solid ${colors.grey}`,
                        background: colors.white,
                    }}
                >
                    <Typography>Send test versions of selected email assets to yourself or a colleague</Typography>
                    <Button
                        color="primary"
                        onClick={() => {
                            setOpenTestModal(true);
                        }}
                    >
                        Send test emails
                    </Button>
                </Box>
            )}
            {openTestModal && (
                <TestModal
                    open
                    handleClose={() => {
                        setOpenTestModal(false);
                    }}
                />
            )}
        </>
    );
};

export default TestBanner;

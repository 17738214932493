import { Audience, CampaignBackend, UserAttributeBackend } from 'types';

export const getAttributeCampaigns = (
    campaigns: CampaignBackend[],
    audienceId: string,
    attribute: UserAttributeBackend,
) => {
    if (!campaigns || !campaigns.length) return [];

    return campaigns.filter((campaign) => {
        if (!campaign.audiences!.some((a) => a.id === audienceId)) return false;
        const nodes = JSON.parse(campaign.workflowUIDraft!).nodes;
        if (!nodes) return false;
        let isAttributeExist = false;
        for (const [key, value] of Object.entries(nodes)) {
            if (
                nodes[key].type === 'condition' &&
                nodes[key].properties.subtype === 'checkAttribute' &&
                nodes[key].properties.attribute === attribute.name &&
                !isAttributeExist
            ) {
                isAttributeExist = true;
            }
        }
        return isAttributeExist;
    });
};

export const getAttributeValueCampaigns = (
    campaigns: CampaignBackend[],
    audienceId: string,
    attribute: UserAttributeBackend,
    attributeValue: string,
) => {
    if (!campaigns || !campaigns.length) return [];

    return campaigns.filter((campaign) => {
        if (!campaign.audiences!.some((a) => a.id === audienceId)) return false;
        const nodes = JSON.parse(campaign.workflowUIDraft!).nodes;
        if (!nodes) return false;
        const isAttributeValueExist = false;
        for (const [key, value] of Object.entries(nodes)) {
            if (
                nodes[key].type === 'condition' &&
                nodes[key].properties.subtype === 'checkAttribute' &&
                nodes[key].properties.attribute === attribute.name &&
                Object.prototype.hasOwnProperty.call(nodes[key].ports, attributeValue) &&
                !isAttributeValueExist
            ) {
                return true;
            }
        }
        return isAttributeValueExist;
    });
};

export const getAttributesUsageData = (attributeCampaigns: CampaignBackend[]) => {
    if (!attributeCampaigns || !attributeCampaigns.length) return [];
    return attributeCampaigns.map((campaign) => {
        return {
            campaignName: campaign.name,
            ownedBy: `${campaign.createdBy?.firstName} ${campaign.createdBy?.lastName}`,
        };
    });
};

export const isAudienceValid = (audienceId: string, audiences: Audience[]) =>
    audiences.some((audience) => audience.id === audienceId);

export const isAudienceDeletable = (audienceId: string, campaigns: CampaignBackend[]) => {
    if (audienceId && campaigns && campaigns.length) {
        const filteredCampaigns = campaigns.filter((c) => c.audiences!.some((a) => a.id === audienceId));
        return !filteredCampaigns.length;
    }
    return true;
};

export const canManageAudience = (audience: Audience, audiencesPending: string[]) => {
    return (
        audience &&
        !isAudienceImporting(audience.state) &&
        isAudienceComplete(audience.state) &&
        audiencesPending.every((pending) => audience.id !== pending)
    );
};

export const isAudienceStarted = (audienceState?: string) => {
    return audienceState === 'SCHEDULED' || audienceState === 'STARTED';
};

export const isAudienceImporting = (audienceState?: string) => {
    return audienceState === 'SCHEDULED' || audienceState === 'STARTED' || audienceState == 'CONFLICTS';
};

export const isAudienceComplete = (audienceState?: string) => {
    return !audienceState || audienceState === 'COMPLETED';
};

export const getAudience = (audienceId: string, audiences: Audience[]) => {
    return audiences.find((a) => a.id === audienceId);
};

export const getAudienceName = (audienceId: string, audiences: Audience[]) => {
    if (audienceId && audiences && audiences.length) {
        const audienceObj = audiences.find((a) => a.id === audienceId);
        return audienceObj?.name;
    }
    return '';
};

export const getAudienceUsageData = (audienceId: string, campaigns: CampaignBackend[]) => {
    if (audienceId && campaigns && campaigns.length) {
        const filteredCampaigns = campaigns.filter((c) => c.audiences?.some((a) => a.id === audienceId));

        if (filteredCampaigns && filteredCampaigns.length) {
            return filteredCampaigns.map((c) => {
                return {
                    campaignName: c.name,
                    ownedBy: `${c.createdBy?.firstName} ${c.createdBy?.lastName}`,
                };
            });
        }
    }
    return null;
};

// Check duplicates case sensitive
export const checkDups = (arr: string[]) => {
    if (!arr || !arr.length) return false;
    const uniqueArr = Array.from(new Set(arr.map((e) => e.toLowerCase())));
    return arr.length !== uniqueArr.length;
};

export const validateAttributeName = (attributeName: string) => {
    const unsafeCharacterRegex = /[^\w ]/;
    return !unsafeCharacterRegex.test(attributeName);
};

const SPECIAL_CHARACTERS = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
const DIGITS = /^(?=.*\d)/;
const LOWER_CASE = /^(?=.*[a-z])/;
const UPPER_CASE = /^(?=.*[A-Z])/;

const REG_MATCHES_REQUIRED = 2;

export const isPasswordEqualEmail = (password: string, email: string): boolean =>
    password.toLowerCase() === email.toLowerCase();

export const isPasswordValid = (password: string): boolean => {
    if (password.length < 12) return false;
    const testRegs = [SPECIAL_CHARACTERS, DIGITS, LOWER_CASE, UPPER_CASE];
    let matches = 0;
    testRegs.forEach((testReg) => {
        if (matches === REG_MATCHES_REQUIRED) return;
        if (testReg.test(password)) matches++;
    });
    return matches >= REG_MATCHES_REQUIRED;
};

export const passwordHelperText = {
    complexity:
        'Password must contain at least 12 characters and two of the following: special characters, digits, upper or lower case letters',
    used: 'New password must be different from previous passwords',
    email: 'Password must be different from your registered email address',
};

import React, { ReactNode, useEffect, useState } from 'react';
import api from 'api';
import { User } from 'reducers/userSlice';
import { Redirect } from 'react-router-dom';

export interface SignUpGuardProps {
    children?: ReactNode;
}

const SignUpGuard = ({ children }: SignUpGuardProps) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        api.getUser().then(({ data }) => {
            setUser(data as User);
        });
    }, []);

    const isIncompleteProfile = (user: User) => !user?.organisation?.id;

    if (!user) {
        return null;
    } else if (isIncompleteProfile(user)) {
        return <> {children} </>;
    } else {
        return (
            <Redirect
                to={{
                    pathname: '/campaigns',
                }}
            />
        );
    }
};

export default SignUpGuard;

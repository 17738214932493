import React, { useEffect, useState, CSSProperties } from 'react';
import { connect } from 'react-redux';
import { ApiResponse } from 'apisauce';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import {
    setCampaignAttribute,
    getCampaignById,
    replaceDraftCampaign,
    replaceCampaignUpdatedOn,
    getCampaignAttributes,
    createOrUpdateAsset,
} from './campaignSlice';
import { User, getUser, updateDashboardSelected } from 'reducers/userSlice';
import { getTeams } from 'features/team/teamSlice';

import CreateCampaignHeader from './createCampaignHeader';
import { checkUserRoleDeleteCampaignRights, isCampaignDeletable } from './campaignUtils';
import { isRoleAllowed } from 'features/team/teamUtils';

import colors from 'colors';
import ConfirmModal from 'components/confirmModal';
import BaseHeader from 'components/baseHeader';
import SaveStatus, { ESaveStatus } from 'components/saveStatus';
import Report from './report';
import Settings from './settings';
import Assets from './assets';
import Audience from './audience';
import Workflow from './workflow';
import { convertWorkflowToTree } from './workflow/workflowHelper';
import api from 'api';
import {
    Asset,
    Campaign,
    CampaignStartType,
    Audience as AudienceType,
    CampaignStateType,
    CampaignFeaturesDraftMode,
    CampaignFeatures,
    Team,
    CampaignBackend,
    CampaignAttributes,
} from 'types';
import { INode } from '@mrblenny/react-flow-chart';
import { RootState } from 'reducers';
import datetime from 'utils/datetime';
import { compareSortedDistinctStringArraysEquals } from 'utils/compareSortedDistinctStringArraysEquals';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: 'calc(100% - 72px)',
        background: colors.backgroundGrey,
        display: 'flex',
        flexDirection: 'column',
    },
    workflow: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
}));

type CreateCampaignProps = {
    draftCampaign: Campaign;
    campaignAttributes?: CampaignAttributes;
    user: User;
    getUser: () => void;
    setCampaignAttribute: ({ name, val }: { name: string; val: any }) => void;
    getCampaignById: (id: string) => void;
    replaceDraftCampaign: (campaign: Campaign) => void;
    updateDashboardSelected: (dashboard: string) => void;
    replaceCampaignUpdatedOn: (updatedOn: string) => void;
    teams: Team[];
    getTeams: () => void;
    getCampaignAttributes: (id: string) => void;
    createOrUpdateAsset: (asset: Asset) => void;
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: CSSProperties;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, style = {}, className, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            className={className}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            style={{ flex: 1, overflow: 'auto', ...style }}
            {...other}
        >
            {value === index && children}
        </Typography>
    );
}

const parseAudience = (audiences: AudienceType[]) => {
    return audiences && audiences.filter((audience: AudienceType) => audience.id !== '');
};

const autoSave = debounce(async (draftCampaign, successCallback, failCallback) => {
    const role = draftCampaign.teamId ? ((await api.getUserRole(draftCampaign.teamId)) as any)?.data?.role : null;

    if (!isRoleAllowed(role)) {
        return;
    }

    const nodes: { [id: string]: INode } = {};
    for (const n of Object.keys(draftCampaign.workflowDraft.nodes)) {
        const node = { ...draftCampaign.workflowDraft.nodes[n] };
        node.properties = {
            ...draftCampaign.workflowDraft.nodes[n].properties,
        };
        node.properties.isNew = false;
        nodes[node.id] = node;
    }
    const draftWorkflow = { ...draftCampaign.workflowDraft, nodes };
    const response = await api.saveCampaign({
        id: draftCampaign.id,
        name: draftCampaign.name,
        assets: draftCampaign.assets,
        senderEmail: draftCampaign.senderEmail,
        replyToEmail: draftCampaign.replyToEmail,
        audiences: parseAudience(draftCampaign.audiences),
        scheduledOn: datetime.convertToLocalDateTime(draftCampaign.scheduledOn),
        workflowUI: JSON.stringify(draftCampaign.workflow),
        workflowUIDraft: JSON.stringify(draftWorkflow),
        createdBy: draftCampaign.createdBy,
        teamId: draftCampaign.teamId,
        state: draftCampaign.state,
    });

    if (response && response.ok) {
        successCallback();
    } else {
        // saveCampaign returns 304 if draft campaign not changed
        failCallback();
    }
}, 500);

const debounceStartCampaign = debounce(
    async (id, startType, draftCampaign, campaignAttributes) => {
        return api.startCampaign(id, {
            scheduledOn: startType === 'LATER' ? datetime.convertToLocalDateTime(draftCampaign.scheduledOn) : null,
            workflow: convertWorkflowToTree(draftCampaign.workflowDraft!, campaignAttributes),
        });
    },
    1000,
    { leading: true, trailing: false },
);

const CreateCampaign = ({
    draftCampaign,
    campaignAttributes,
    user,
    getUser,
    setCampaignAttribute,
    replaceDraftCampaign,
    getCampaignById,
    updateDashboardSelected,
    replaceCampaignUpdatedOn,
    teams,
    getTeams,
    getCampaignAttributes,
    createOrUpdateAsset,
}: CreateCampaignProps) => {
    const classes = useStyles();
    const { id, feature, emailAssetId } = useParams<{ id: string; feature: string; emailAssetId: string }>();
    const history = useHistory();
    const [openStartNow, setOpenStartNow] = useState<boolean>(false);
    const [openStopNow, setOpenStopNow] = useState<boolean>(false);
    const [saveStatus, setSaveStatus] = useState<ESaveStatus>(ESaveStatus.LAST_UPDATED);
    const [startType, setStartType] = useState<CampaignStartType>();
    const isInactiveCampaign = draftCampaign.state === CampaignStateType.INACTIVE;
    const isDraftCampaign = draftCampaign.state === CampaignStateType.DRAFT;
    const { teamId } = draftCampaign;
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [allowCampaignDelete, setAllowCampaignDelete] = React.useState<boolean>(false);

    useEffect(() => {
        if (id) {
            getCampaignById(id);
            getCampaignAttributes(id);
        }
    }, []);

    const fixEmptyOrWrongPath = (feature: string) => {
        if (!isDraftCampaign && !(feature in CampaignFeatures))
            return history.replace(`/campaigns/update/${id}/${CampaignFeatures[0]}`);

        if (isDraftCampaign && !(feature in CampaignFeaturesDraftMode))
            return history.replace(`/campaigns/update/${id}/${CampaignFeaturesDraftMode[0]}`);
    };

    const isPathValid = (feature: string): boolean => {
        if (emailAssetId) return true;
        if (!isDraftCampaign && !(feature in CampaignFeatures)) return false;
        if (isDraftCampaign && !(feature in CampaignFeaturesDraftMode)) return false;
        return true;
    };

    useEffect(() => {
        let tab = 0;
        if (draftCampaign.id) {
            if (!isPathValid(feature)) {
                fixEmptyOrWrongPath(feature);
            } else {
                // Dynamic accessing of enum by key: https://stackoverflow.com/a/66117678
                tab = isDraftCampaign
                    ? CampaignFeaturesDraftMode[feature as keyof typeof CampaignFeaturesDraftMode]
                    : CampaignFeatures[feature as keyof typeof CampaignFeatures];
            }
        }
        setSelectedTab(tab);
    }, [feature, isDraftCampaign, id, history, draftCampaign.id]);

    useEffect(() => {
        updateDashboardSelected(teamId ? teamId : 'personal');
    }, [teamId]);

    useEffect(() => {
        if (!user || !user.email) {
            getUser();
        }
    }, [getUser, user]);

    const updateDraftCampaign = () => {
        setSaveStatus(ESaveStatus.SAVED);
        api.getCampaignById(id).then((c: ApiResponse<any>) => {
            const campaign: CampaignBackend = { ...c.data };
            if (draftCampaign.updatedOn !== campaign.updatedOn) {
                replaceCampaignUpdatedOn(campaign.updatedOn!);
            }
            // Update asset if it's validation errors change
            if (campaign.assets) {
                campaign.assets.forEach((asset, i) => {
                    if (
                        !compareSortedDistinctStringArraysEquals(
                            asset.validationErrors?.invalidSyntaxAttributeErrors,
                            draftCampaign.assets![i].validationErrors?.invalidSyntaxAttributeErrors,
                        ) ||
                        !compareSortedDistinctStringArraysEquals(
                            asset.validationErrors?.mismatchedAttributeErrors,
                            draftCampaign.assets![i].validationErrors?.mismatchedAttributeErrors,
                        )
                    ) {
                        createOrUpdateAsset({ ...asset, isNew: false });
                    }
                });
            }
            setTimeout(() => {
                setSaveStatus(ESaveStatus.LAST_UPDATED);
            }, 1000);
        });
    };

    useEffect(() => {
        if (draftCampaign && draftCampaign.id) {
            setSaveStatus(ESaveStatus.SAVING);
            autoSave({ ...draftCampaign }, updateDraftCampaign, () => setSaveStatus(ESaveStatus.LAST_UPDATED));
        }
    }, [
        draftCampaign.id,
        draftCampaign.state,
        draftCampaign.name,
        draftCampaign.senderEmail,
        draftCampaign.replyToEmail,
        draftCampaign.audiences,
        draftCampaign.assets,
        draftCampaign.workflowDraft,
        draftCampaign.workflow,
    ]);

    const handleStart = (startType: CampaignStartType) => {
        if (startType === 'NOW') setOpenStartNow(true);
        if (startType === 'LATER') setStartType(startType);
    };

    useEffect(() => {
        if (startType !== undefined) {
            debounceStartCampaign(id, startType, draftCampaign, campaignAttributes)?.then(
                (response: ApiResponse<any>) => {
                    if (response && response.ok) {
                        getCampaignById(id);
                    }
                },
            );
        }
        setStartType(undefined);
    }, [startType]);

    useEffect(() => {
        setAllowCampaignDelete(
            isCampaignDeletable(draftCampaign as CampaignBackend) &&
                checkUserRoleDeleteCampaignRights(draftCampaign as CampaignBackend, user, teams),
        );
    }, [draftCampaign, user, teams]);

    const features = [
        { name: 'report', component: <Report /> },
        { name: 'settings', component: <Settings /> },
        { name: 'audience', component: <Audience /> },
        { name: 'assets', component: <Assets /> },
        {
            name: 'workflow',
            component: (
                <div className={classes.workflow}>
                    <Workflow />
                </div>
            ),
        },
    ];

    if (isDraftCampaign) features.shift();

    return (
        <>
            <BaseHeader
                title={draftCampaign!.name || ''}
                content={
                    draftCampaign.updatedOn ? (
                        <SaveStatus status={saveStatus} lastUpdated={new Date(draftCampaign.updatedOn)} />
                    ) : null
                }
                backButton
                buttonLink={() => {
                    if (teamId) {
                        history.push(`/campaigns/teams/${teamId}`);
                    } else {
                        history.push('/campaigns');
                    }
                }}
            />
            <Typography component="div" className={classes.container}>
                <CreateCampaignHeader
                    setCampaignAttribute={setCampaignAttribute}
                    currentTab={selectedTab}
                    draftCampaign={draftCampaign}
                    isCampaignDeletable={allowCampaignDelete}
                    campaignAttributes={campaignAttributes}
                    start={handleStart}
                    stop={() => {
                        setOpenStopNow(true);
                    }}
                    getTeams={getTeams}
                />
                {features.map((feature, index) => {
                    return (
                        <TabPanel value={selectedTab} index={index} key={feature.name}>
                            {feature.component}
                        </TabPanel>
                    );
                })}
                <ConfirmModal
                    title={isInactiveCampaign ? 'Resume campaign now' : 'Start campaign now'}
                    content={
                        isInactiveCampaign
                            ? 'Are you sure you wish to resume the campaign now?'
                            : 'Are you sure you wish to start the campaign now?'
                    }
                    open={openStartNow}
                    onClose={() => {
                        setOpenStartNow(false);
                    }}
                    confirm={() => {
                        setStartType('NOW');
                        setOpenStartNow(false);
                    }}
                />
                <ConfirmModal
                    title="Stop campaign"
                    content={
                        <>
                            <div>Are you sure you wish to stop the campaign now?</div>
                            <div>Once your campaign stops, the campaign won{`'`}t be updated.</div>
                        </>
                    }
                    open={openStopNow}
                    onClose={() => {
                        setOpenStopNow(false);
                    }}
                    confirm={() => {
                        api.stopCampaign(draftCampaign.id!).then((response: ApiResponse<any>) => {
                            if (response.ok) {
                                getCampaignById(draftCampaign.id!);
                                setOpenStopNow(false);
                            }
                        });
                    }}
                />
            </Typography>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    draftCampaign: state.campaignSlice.draftCampaign,
    campaignAttributes: state.campaignSlice.campaignAttributes,
    user: state.userSlice.user,
    teams: state.teamSlice.teams,
});

const mapDispatchToProps = {
    createOrUpdateAsset,
    setCampaignAttribute,
    getCampaignById,
    getCampaignAttributes,
    replaceDraftCampaign,
    getUser,
    updateDashboardSelected,
    replaceCampaignUpdatedOn,
    getTeams,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);

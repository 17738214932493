import React, { useState } from 'react';
import { Box, TextField, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from 'colors';
import { ImportType } from '../newContacts';
import FormModal from 'components/formModal';
import CustomTooltip from 'components/customTooltip';
import AlertDialog from 'components/alertDialog';
import { UploadError } from './selectImportType';

interface StepOneProps {
    fileName: string;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    importType: ImportType;
    uploadError: UploadError | null;
    handleCloseFileError: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            color: `${colors.dark} !important`,
            '&$disabled $notchedOutline': {
                borderColor: `${colors.coolGrey} !important`,
            },
            height: 48,
        },
        disabled: {},
        notchedOutline: {},
        emailContainer: {
            marginTop: 32,
            border: `1px dashed ${colors.darkGrey}`,
            padding: '20px 32px',
            fontSize: 14,
        },
    }),
);

const StepOne = ({ fileName, handleFileUpload, importType, uploadError, handleCloseFileError }: StepOneProps) => {
    const classes = useStyles();
    const [emailPasscode, setEmailPasscode] = useState<string>('');
    const [fileRecieved, setFileRecieved] = useState<boolean>(false);
    const [isPasscodeDialogOpen, setIsPasscodeDialogOpen] = useState<boolean>(true);
    const [isPasscodeWrong, setIsPasscodeWrong] = useState<boolean>(false);
    const [browseInput, setBrowseInput] = useState<string>('');

    const checkEmailPasscode = (passcode: string): boolean => {
        setFileRecieved(true);
        return true;
    };

    const handleFormOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailPasscode(e.currentTarget.value);
    };

    const handlePasscodeSubmit = () => {
        if (checkEmailPasscode(emailPasscode)) return setIsPasscodeDialogOpen(false);
        setIsPasscodeWrong(true);
    };

    return (
        <>
            {importType === ImportType.CSV ? (
                <Box mt={4}>
                    <TextField
                        variant="outlined"
                        value={fileName}
                        disabled
                        inputProps={{
                            style: {
                                padding: '12px 16px',
                                minWidth: 420,
                            },
                        }}
                        InputProps={{
                            classes: {
                                root: classes.root,
                                disabled: classes.disabled,
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                    />
                    <Button
                        color="secondary"
                        variant="outlined"
                        component="label"
                        style={{ marginLeft: 8 }}
                        size="small"
                    >
                        Browse
                        <input 
                            value={browseInput}
                            onChange={handleFileUpload} 
                            onClick={() => setBrowseInput('')}
                            type="file"
                            accept=".csv" 
                            style={{ display: 'none' }} 
                        />
                    </Button>
                    {uploadError && (
                        <AlertDialog
                            open
                            onClose={handleCloseFileError}
                            title={uploadError.title}
                            content={uploadError.content}
                        />
                    )}
                </Box>
            ) : (
                <>
                    <Box style={{ marginTop: 12, marginBottom: 4 }}>
                        <div style={{ width: 564 }}>
                            <span>
                                Send an email containing your contact list (in .csv file) to{' '}
                                <u>import@personalise.gov.sg</u>. Ensure that the file is sent from the email address
                                that you used to login to Percy.
                            </span>
                            <div className={classes.emailContainer}>
                                {fileRecieved ? (
                                    <div>
                                        <span style={{ fontWeight: 500 }}>File received</span>
                                        <Box mt={1} display="flex" justifyContent="space-between">
                                            <span>DRA_Participants.csv</span>
                                            <Button
                                                variant="text"
                                                onClick={() => {
                                                    setFileRecieved(false);
                                                }}
                                                size="small"
                                            >
                                                Remove file
                                            </Button>
                                        </Box>
                                    </div>
                                ) : (
                                    <span style={{ color: colors.darkGrey }}>
                                        The file will appear here once successfully sent and decrypted. If you do not
                                        receive the file in x minutes, please try sending the file again.
                                    </span>
                                )}
                            </div>
                        </div>
                    </Box>
                    <FormModal
                        open={isPasscodeDialogOpen}
                        inputPlaceholder="Enter passcode"
                        title="Retrieve contact list"
                        content="Please enter the passcode that you used to encrypt the email containing DRA_participants.csv"
                        inputErrorText="Incorrect password"
                        handleFormOnChange={handleFormOnChange}
                        handleSubmit={handlePasscodeSubmit}
                        onClose={() => {
                            setIsPasscodeDialogOpen(false);
                        }}
                        error={isPasscodeWrong}
                    />
                </>
            )}
            <div style={{ color: colors.darkGrey, fontSize: 14, marginTop: 8 }}>
                <span>
                    Ensure that your .csv file contains a{' '}
                    <CustomTooltip
                        title="The unique identifier is your contacts’ email. It is used to determine if
                     an existing contact record should be updated or to add a new contact record."
                        style={{ display: 'inline' }}
                        arrow
                    >
                        <u style={{ cursor: 'pointer' }}>unique identifier</u>
                    </CustomTooltip>{' '}
                    column
                </span>
            </div>
        </>
    );
};

export default StepOne;

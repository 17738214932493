import { createSlice } from '@reduxjs/toolkit';
import { SnackbarType } from 'components/snackBar';

const appSlice = createSlice({
    name: 'app',
    initialState: {
        snackbar: {
            message: '',
            type: 'success' as SnackbarType,
        },
    },
    reducers: {
        toggleSnackbar(state, action) {
            state.snackbar = action.payload;
        },
    },
});

export const { toggleSnackbar } = appSlice.actions;

export default appSlice.reducer;

// eslint-disable-next-line no-useless-escape
const EMAIL_REG =
    /^[-a-z0-9~!$%^&*_=+}{\'?"]+(\.[-a-z0-9~!$%^&*_=+}{\'?"]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|(\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

export const isEmailValid = (email: string): boolean => EMAIL_REG.test(email);

export const isGovEmailValid = (email: string): boolean => isEmailValid(email) && email.endsWith('.gov.sg');
export const isEduEmailValid = (email: string): boolean => isEmailValid(email) && email.endsWith('.edu.sg');
export const isReplyToEmailValid = (email: string): boolean => isEduEmailValid(email) || isGovEmailValid(email);

export const validateEmails = (emails: string[]): boolean => {
    if (!emails.length) {
        return false;
    }
    emails.forEach((email) => {
        if (!isEmailValid(email)) {
            return false;
        }
    });
    return true;
};

export const isEmailDuplicate = (newEmail: string, oldEmailAddressesArrays: string[][]): boolean => {
    const newEmailLowerCase = newEmail.toLowerCase();
    return oldEmailAddressesArrays.some((oldEmailAddresses) =>
        oldEmailAddresses.some((oldEmailAddress) => oldEmailAddress.toLowerCase() === newEmailLowerCase),
    );
};

export const getEmailHelperText = (email: string): string => {
    if (!email) return 'This field cannot be empty';
    if (!isEmailValid(email)) return 'The email address entered is not valid';
    return '';
};

export const getEmailInputFieldError = (
    newEmail: string,
    oldEmailAddressesArrays: string[][],
    checkIfGovEmail = false,
): string => {
    if (isEmailDuplicate(newEmail, oldEmailAddressesArrays)) return `"${newEmail}" has been added`;
    if (!isEmailValid(newEmail)) return `“${newEmail}” is not in the right email address format`;
    if (checkIfGovEmail && !isGovEmailValid(newEmail)) return `“${newEmail}” is not a valid government email address`;
    return '';
};

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory } from 'react-router-dom';
import { InputLabel, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Audience, AudienceDropdownOption } from 'types';
import { createTargetedAudience, getCampaignAttributes } from 'features/campaigns/campaignSlice';
import DropdownWithSearch from 'components/dropdownWithSearch';
import colors from 'colors';

interface SelectAudienceProps {
    audienceList: Audience[];
    audiences: Audience[];
    createTargetedAudience: (audience: Audience[]) => void;
    exclude?: boolean;
    isAllAudienceSelected?: boolean;
    teamId: string | null;
    campaignId: string | null;
    getCampaignAttributes: (id: string) => void;
}

const styles = makeStyles({
    iconButton: {
        display: 'flex',
        alignItems: 'center',
        margin: '52px 0 0 24px',
        color: colors.darkGrey,
        cursor: 'pointer',
        '&:hover': {
            color: colors.coolGrey,
        },
    },
    placeholder: {
        color: colors.coolGrey,
    },
});

const SelectAudience = ({
    audienceList,
    audiences,
    createTargetedAudience,
    exclude = false,
    isAllAudienceSelected = false,
    teamId,
    campaignId,
    getCampaignAttributes,
}: SelectAudienceProps) => {
    const classes = styles();
    const history = useHistory();
    const [selectedTargetedAudience, setSelectedTargetedAudience] = useState<Audience>({ name: '', group: '', id: '' });

    useEffect(() => {
        const audienceDraft: Audience = audiences[0];
        if (audienceDraft && audienceDraft.id !== '') {
            const matchesID = (item: Audience) => item.id === audienceDraft.id;
            const selectedAudienceIndex = (audienceList || []).findIndex(matchesID);

            if (selectedAudienceIndex >= 0) setSelectedTargetedAudience(audienceList[selectedAudienceIndex]);
        }
    }, [audiences, audienceList, exclude]);

    const handleAddTargetedAudience = (selectedAudience: Audience | null) => {
        setTimeout(() => {
            getCampaignAttributes(campaignId!);
        }, 2000); // Timeout required as campaign attributes take time to load after updating campaign
        if (selectedAudience && selectedAudience.id === AudienceDropdownOption.ALL)
            return createTargetedAudience(audienceList);

        if (isAllAudienceSelected) return createTargetedAudience([{ ...selectedAudience }] as Audience[]);

        const draft = [...audiences];
        const newSelectedAudience = { ...selectedAudience };
        draft.splice(0, 1, newSelectedAudience as Audience);
        createTargetedAudience(draft);
    };

    const isAudienceSelected = (audienceSelected: Audience): boolean => {
        const selectedTargetedList = audiences.filter((a) => a.id === audienceSelected.id);
        const isAudienceInTargetedList = selectedTargetedList.length > 0;
        return isAllAudienceSelected ? false : isAudienceInTargetedList;
    };

    return (
        <Box display="flex">
            <Box style={{ width: '60%' }}>
                <InputLabel style={{ marginTop: 24 }}>Audience</InputLabel>
                <DropdownWithSearch
                    selectType="outlined"
                    options={audienceList}
                    buttonText={
                        selectedTargetedAudience.name === '' ? (
                            <span className={classes.placeholder}>Select audience</span>
                        ) : (
                            <span>{selectedTargetedAudience.name}</span>
                        )
                    }
                    handleOptionChange={handleAddTargetedAudience}
                    isOptionSelected={isAudienceSelected}
                    noOptionsText={audienceList.length === 0 ? 'No audiences created' : undefined}
                    additionalOptions={[
                        {
                            name: 'Create new audience',
                            onClick: () => {
                                const sourceParam = `?campaignSource=${campaignId}`;
                                history.push(
                                    teamId
                                        ? `/audience/teams/${teamId}/new${sourceParam}`
                                        : `/audience/new${sourceParam}`,
                                );
                            },
                            buttonColor: 'primary',
                        },
                    ]}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    campaignId: state.campaignSlice.draftCampaign.id,
    audiences: state.campaignSlice.draftCampaign.audiences,
    teamId: state.campaignSlice.draftCampaign.teamId,
});

const mapDispatchToProps = { createTargetedAudience, getCampaignAttributes };

export default connect(mapStateToProps, mapDispatchToProps)(SelectAudience);

import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Button, Box, Typography } from '@material-ui/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ApiResponse } from 'apisauce';
import api from 'api';
import Footer from '../footer';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import { ImportType } from '../newContacts';
import { pluralise } from 'utils/pluralise';
import { UserAttributeGroup, UserAttribute, Audience, UserAttributeBackend, UniqueUserAttribute } from 'types';
import CustomTooltip from 'components/customTooltip';
import BaseHeader from 'components/baseHeader';
import { DropdownOption } from 'components/dropdownWithSearch';
import { addPendingAudience, removePendingAudience, putAudience } from '../audienceSlice';
import { getAudience, isAudienceComplete, isAudienceStarted } from '../audienceUtils';
import { ToggleSnackbarProps } from 'components/snackBar';
import { toggleSnackbar } from 'reducers/appSlice';
import { connect } from 'react-redux';
import EmptyPage from 'components/emptyPage';

interface SelectImportTypeProps {
    addPendingAudience: (audienceId: string) => void;
    removePendingAudience: (audienceId: string) => void;
    putAudience: (audience: any) => void;
    handleClickBack: () => void;
    importType: ImportType;
    audiences: Audience[];
    toggleSnackbar: (data: ToggleSnackbarProps) => void;
}

export interface ColumnMap {
    columnHeader: string;
    mappedAttribute: UserAttribute;
    isImporting: boolean;
}

const maxUploadSizeInMB = 150;
const maxUploadSize = maxUploadSizeInMB * 1000 * 1024;

export type ImportStatus = 'NONE' | 'UPLOADING' | 'SUCCESSFUL' | 'UNSUCCESSFUL';

export interface UploadError {
    title: string;
    content: string;
}

const SelectImportType = ({
    addPendingAudience,
    removePendingAudience,
    putAudience,
    handleClickBack,
    importType,
    audiences,
    toggleSnackbar,
}: SelectImportTypeProps) => {
    const [fileName, setFileName] = useState<string>('');
    const [file, setFile] = useState<File>();
    const [step, setStep] = useState<number>(1);
    const [contacts, setContacts] = useState<string[][]>([]);
    const [columnsMapped, setColumnsMapped] = useState<ColumnMap[]>([
        {
            columnHeader: '',
            mappedAttribute: { name: '', group: '', description: '', dataType: '', id: '' },
            isImporting: true,
        },
    ]);
    const [importStatus, setImportStatus] = useState<ImportStatus>('NONE');
    const history = useHistory();
    const [uploadError, setUploadError] = useState<UploadError | null>(null);
    const { audienceId } = useParams<{ audienceId: string }>();
    const [audienceName, setAudienceName] = useState<string>('');
    const [userAttributes, setUserAttributes] = useState<UserAttribute[]>();
    const [shouldFetchAttributes, setShouldFetchAttributes] = useState<boolean>(false);
    const [newAttributes, setNewAttributes] = useState<string[]>([]);
    const location = useLocation<{ isUpdate: boolean }>();
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const query = useQuery();
    const sourceCampaignId = query.get('campaignSource');

    const audience = getAudience(audienceId, audiences);

    useEffect(() => {
        setShouldFetchAttributes(false);
        const { name } = (audiences && audiences.find((audience) => audience.id === audienceId)) || { name: '' };
        setAudienceName(name);

        if (audienceId) {
            api.getAttributes(audienceId).then((response: ApiResponse<any>) => {
                if (response.ok) {
                    const uniqueIdentifiers = response
                        .data!.identifiers.filter((u: UserAttributeBackend) => u.name === UniqueUserAttribute.EMAIL)
                        .map((a: UserAttributeBackend) => {
                            return {
                                id: a.name,
                                name: a.displayName,
                                group: UserAttributeGroup.UNIQUE_IDENTIFIERS,
                                description: null,
                                dataType: a.type,
                                presetValues: null,
                            };
                        });
                    const audienceAttributes = response.data[`${audienceId}`].map((a: UserAttributeBackend) => {
                        return {
                            id: a.name,
                            name: a.displayName,
                            group: `${name} attributes`,
                            description: a.description,
                            dataType: a.type,
                            presetValues: a.values,
                        };
                    });
                    setUserAttributes([...uniqueIdentifiers, ...audienceAttributes]);
                }
            });
        }
    }, [audienceId, shouldFetchAttributes]);

    const validateFile = (files: File[]) => {
        if (files[0].size > maxUploadSize)
            return setUploadError({
                title: 'File size limit exceeded',
                content: `Your .csv file could not be uploaded as it exceeds the allowable file size limit of ${maxUploadSizeInMB} MB`,
            });
        return true;
    };

    const areColumnHeadersValid = (headers: string[]) => !headers.some((header) => header === '');

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (validateFile(e.target.files as any)) {
            if (e.target.files) {
                const file = e.target.files[0];

                Papa.parse(file, {
                    complete: (results) => {
                        const headers = results.data.shift() as string[];
                        if (!areColumnHeadersValid(headers))
                            return setUploadError({
                                title: 'File has missing column headers',
                                content: 'Please ensure all column headers are filled and upload your file again',
                            });
                        const headersObject = headers?.map((header: string) => ({
                            columnHeader: header.trim(),
                            mappedAttribute: { id: '', name: '', group: '', description: '', dataType: '' },
                            isImporting: true,
                        }));
                        setContacts(results.data as string[][]);
                        setColumnsMapped(headersObject);
                        setFileName(file.name);
                        setFile(file);
                    },
                });
            }
        }
    };

    const handleAttributeChange = (value: DropdownOption, index?: number) => {
        const newColumnsMapped = [...columnsMapped];
        newColumnsMapped.splice(index!, 1, {
            ...columnsMapped[index!],
            mappedAttribute: { ...(value as UserAttribute) },
            isImporting: true,
        });
        setColumnsMapped(newColumnsMapped);
    };

    const handleDoNotImport = (index: number) => {
        const newColumnsMapped = [...columnsMapped];
        newColumnsMapped.splice(index, 1, {
            ...columnsMapped[index],
            isImporting: false,
            mappedAttribute: { name: '', group: '', description: '', dataType: '', id: '' },
        });
        setColumnsMapped(newColumnsMapped);
    };

    const renderTitle = () => {
        switch (step) {
            case 1:
                return importType === ImportType.CSV
                    ? `Upload contacts to ${audienceName}`
                    : 'Send email containing .csv file';
            case 2:
                return 'Map file columns to existing attributes';
            default:
                return 'Review import';
        }
    };

    const renderSubtitle = () =>
        step === 1 ? (
            <Typography color="textPrimary" style={{ marginTop: 12 }}>
                Upload .csv file containing your contact list
            </Typography>
        ) : (
            ''
        );

    const countUnmappedColumns = () =>
        columnsMapped.filter((header) => header.isImporting && header.mappedAttribute.name === '').length;

    const isUniqueIdentifierSelected = () => {
        if (userAttributes) {
            const uniqueIdentifiers = userAttributes.filter(
                (attribute) => attribute.group === UserAttributeGroup.UNIQUE_IDENTIFIERS,
            );
            for (const u of uniqueIdentifiers) {
                const uniqueMapped = columnsMapped.filter((header) => header.mappedAttribute.name === u.name);
                if (uniqueMapped.length > 0) {
                    return true;
                }
            }
            return false;
        }
    };

    const getMapHelperText = () => {
        const count = countUnmappedColumns();
        if (count > 0) return `(${count}) ${pluralise(count, 'column')} not mapped`;
        if (!isUniqueIdentifierSelected())
            return (
                <>
                    Select one column to use as the{' '}
                    <CustomTooltip
                        title="The unique identifier is  your contacts’ email. It is used to determine
                            if an existing contact record should be updated or to add a new contact record."
                        arrow
                        placement="top"
                        style={{ display: 'inline' }}
                    >
                        <u style={{ cursor: 'pointer' }}>unique identifier</u>
                    </CustomTooltip>
                </>
            );
    };

    const formatAttributeToColumnMapping = (columnsMapped: ColumnMap[]) => {
        columnsMapped = columnsMapped.filter((col: ColumnMap) => col.isImporting);
        return columnsMapped.reduce(
            // eslint-disable-next-line no-sequences
            (obj: any, col: ColumnMap) => ((obj[col.mappedAttribute.id] = col.columnHeader), obj),
            {},
        );
    };

    const handleStartImport = () => {
        if (audience) {
            putAudience({ ...audience, state: 'SCHEDULED' });
            addPendingAudience(audience.id);
        }

        history.go(-2);

        const payload = { attributeToColumnMapping: formatAttributeToColumnMapping(columnsMapped) };
        const formDataPayload = new FormData();
        formDataPayload.append('file', file!);
        formDataPayload.append('data', JSON.stringify(payload));

        return api.addAudienceContacts(audienceId!, formDataPayload).then((response: ApiResponse<any>) => {
            if (response.ok) {
                const { name, state, importedOn, importedBy } = response.data;

                if (isAudienceStarted(state)) {
                    toggleSnackbar({
                        message: `${name} import has started. This may take a few minutes.`,
                        type: 'success',
                    });
                } else if (isAudienceComplete(state)) {
                    toggleSnackbar({ message: `${name} import complete.`, type: 'success' });
                }

                putAudience({ ...audience, importedOn, importedBy, state });
                removePendingAudience(audienceId);
            }
        });
    };

    const handleNextStepClick = () => {
        if (step === 3) {
            handleStartImport();
        }
        if (step < 3) return setStep((step) => step + 1);
    };

    const isNextButtonDisabled = () => {
        switch (step) {
            case 1:
                return contacts.length < 1;
            case 2:
                return !(isUniqueIdentifierSelected() && countUnmappedColumns() === 0);
            default:
                return false;
        }
    };

    const renderPage = (step: number) => {
        switch (step) {
            case 1:
                return (
                    <StepOne
                        fileName={fileName}
                        handleFileUpload={handleFileUpload}
                        importType={importType}
                        uploadError={uploadError}
                        handleCloseFileError={() => {
                            setUploadError(null);
                        }}
                    />
                );
            case 2:
                return (
                    <StepTwo
                        contacts={contacts}
                        columnsMapped={columnsMapped}
                        userAttributes={userAttributes!}
                        handleAttributeChange={handleAttributeChange}
                        handleDoNotImport={handleDoNotImport}
                        audienceId={audienceId!}
                        fetchNewAttributes={() => {
                            setShouldFetchAttributes(true);
                        }}
                        setNewAttribute={(newAttributeId: string) => {
                            setNewAttributes([...newAttributes, newAttributeId.toLowerCase()]);
                        }}
                    />
                );
            case 3:
            default:
                return (
                    <Box mt={4}>
                        <StepThree
                            fileName={fileName}
                            numberOfRecords={contacts.length}
                            columns={columnsMapped}
                            newAttributes={newAttributes}
                        />
                    </Box>
                );
        }
    };

    const renderRightFooterButton = () => {
        if (step === 2) {
            return (
                <>
                    <Typography color="textPrimary" style={{ marginRight: 24, display: 'inline' }}>
                        {getMapHelperText()}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextStepClick}
                        disabled={isNextButtonDisabled()}
                    >
                        Next
                    </Button>
                </>
            );
        } else if (importStatus === 'SUCCESSFUL' || importStatus === 'UNSUCCESSFUL') {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (location.state && location.state.isUpdate) {
                            history.goBack();
                        } else {
                            history.go(-2);
                        }
                    }}
                >
                    {sourceCampaignId ? ' Back to campaign' : 'Back to audience'}
                </Button>
            );
        } else {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextStepClick}
                    disabled={isNextButtonDisabled()}
                >
                    {step === 3 ? 'Start import' : 'Next'}
                </Button>
            );
        }
    };

    return (
        <>
            <BaseHeader
                title="Import contacts"
                backButton={importStatus === 'NONE'}
                buttonLink={() => {
                    history.goBack();
                }}
            />
            <Box mx={4} flexGrow={1} style={{ paddingBottom: 138, marginTop: 40 }}>
                {importStatus === 'NONE' ? (
                    <>
                        <Typography variant="body2" color="textSecondary" style={{ marginBottom: 12 }}>
                            Step {step} of 3
                        </Typography>
                        <Typography variant="h1" color="textPrimary">
                            {renderTitle()}
                        </Typography>
                        {renderSubtitle()}
                        {renderPage(step)}
                    </>
                ) : (
                    <EmptyPage title={'Page not found'} subtitle={'Unknown state'} />
                )}
            </Box>
            {importStatus !== 'UPLOADING' && (
                <Footer
                    left={
                        <>
                            {importStatus === 'NONE' && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ marginRight: 12 }}
                                    onClick={() => {
                                        step !== 1 ? setStep((step) => step - 1) : history.goBack();
                                    }}
                                >
                                    {step !== 1 ? 'Back' : 'Cancel'}
                                </Button>
                            )}
                        </>
                    }
                    right={renderRightFooterButton()}
                />
            )}
            {/* {importStatus === 'UPLOADING' && conflicts.length > 0 && <Footer right={renderRightFooterButton()} />} */}
        </>
    );
};

const mapDispatchToProps = {
    addPendingAudience,
    removePendingAudience,
    putAudience,
    toggleSnackbar,
};

export default connect(null, mapDispatchToProps)(SelectImportType);

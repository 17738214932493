import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    status: {
        fontSize: 12,
        fontWeight: 500,
        borderRadius: 4,
        padding: '4px 12px',
    },
    containedsucess: {
        background: colors.green,
        color: colors.white,
    },
    containederror: {
        background: colors.red,
        color: colors.white,
    },
    outlinedsucess: {
        border: `1px solid ${colors.green}`,
        color: colors.green,
    },
    outlineddraft: {
        background: colors.darkGrey,
        border: `1px solid ${colors.darkGrey}`,
        color: colors.white,
    },
    outlinederror: {
        border: `1px solid ${colors.red}`,
        color: colors.red,
    },
}));

interface StatusProps {
    variant?: 'contained' | 'outlined';
    color?: 'success' | 'error' | 'draft';
    children: ReactNode;
}

const Status = ({ variant = 'contained', color = 'success', children }: StatusProps) => {
    const classes = useStyles();
    let style = classes.containedsucess;

    switch (`${variant}${color}`) {
        case 'containedsuccess':
            style = classes.containedsucess;
            break;
        case 'containederror':
            style = classes.containederror;
            break;
        case 'outlinedsuccess':
            style = classes.outlinedsucess;
            break;
        case 'containeddraft':
            style = classes.outlineddraft;
            break;
        case 'outlinederror':
            style = classes.outlinederror;
            break;
        default:
            style = classes.containedsucess;
            break;
    }

    return <div className={`${classes.status} ${style}`}>{children}</div>;
};

export default Status;

import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import PrivateRoute from 'PrivateRoute';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import api from 'api';

import Login from 'features/login';
import PageNotFound from 'features/pageNotFound';
import Campaigns from 'features/campaigns';
import Audience from 'features/audience';
import TeamSettings from 'features/team';
import AccountSettings from 'features/accountSettings';
import UpdatePassword from 'features/login/updatePassword';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import './App.css';
import { expireIn, getAuthToken } from 'auth';
import { TrackingEvent } from 'types';
import { TRACKING } from 'common/constants';
import { wogaaExclusionList } from 'utils/wogaaExclusionList';
import TechPassLoginGuard from './features/login/TechPassLoginGuard';
import SignUp from './features/signUp';
import SignUpGuard from './features/signUp/SignUpGuard';

const App = () => {
    const { pathname } = useLocation();

    const { Track } = useTracking({}, { dispatch: (data: TrackingEvent) => api.postEvent(data) });
    const [isScriptIncluded, setIsScriptIncluded] = useState(false);

    useEffect(() => {
        const isWogaaExcludedPage = wogaaExclusionList.indexOf(pathname) > -1;

        if (!isWogaaExcludedPage && !isScriptIncluded) {
            const script = document.createElement('script');
            script.src = process.env.REACT_APP_WOGAA_SCRIPT_URL || '';
            document.head.appendChild(script);
            setIsScriptIncluded(true);
        }
    }, [pathname]);

    useEffect(() => {
        const renewTokenInterval = setInterval(() => {
            const token = getAuthToken();
            if (!token || token === 'null') {
                return;
            }
            const decodedToken = jwtDecode<JwtPayload>(token);
            const expireTime = expireIn(decodedToken.exp!);
            if (expireTime <= 600000 && expireTime > 10000) {
                api.renewToken();
            }
        }, 60000);
        return () => {
            clearInterval(renewTokenInterval);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Track>
                <Switch>
                    <PrivateRoute exact path={'/'}>
                        <Redirect to={'campaigns'} />
                    </PrivateRoute>
                    <PrivateRoute exact path={'/campaigns/teams/:teamId'} page={TRACKING.PAGES.CAMPAIGN}>
                        <Campaigns />
                    </PrivateRoute>
                    <PrivateRoute path={'/campaigns'} page={TRACKING.PAGES.CAMPAIGN}>
                        <Campaigns />
                    </PrivateRoute>
                    <PrivateRoute path="/audience" page={TRACKING.PAGES.AUDIENCE}>
                        <Audience />
                    </PrivateRoute>
                    <PrivateRoute exact path="/team-settings" page={TRACKING.PAGES.TEAM_SETTINGS}>
                        <TeamSettings />
                    </PrivateRoute>
                    <PrivateRoute exact path="/account-settings" page={TRACKING.PAGES.ACCOUNT_SETTINGS}>
                        <AccountSettings />
                    </PrivateRoute>
                    <PrivateRoute path="/signup">
                        <SignUpGuard>
                            <SignUp />
                        </SignUpGuard>
                    </PrivateRoute>
                    <Route exact path="/verifyEmail">
                        <Login />
                    </Route>
                    <Route path="/signin">
                        <Login />
                    </Route>
                    <Route path="/callback">
                        <TechPassLoginGuard />
                    </Route>
                    <Route exact path="/updatePassword">
                        <UpdatePassword />
                    </Route>
                    <Route exact path="/page-not-found">
                        <PageNotFound />
                    </Route>
                    <Redirect to="/page-not-found" />
                </Switch>
            </Track>
        </ThemeProvider>
    );
};

export default App;

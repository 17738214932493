import React, { useState, useMemo, ChangeEvent, useEffect, useRef } from 'react';
import { ApiResponse } from 'apisauce';
import debounce from 'lodash/debounce';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'reducers';
import { Box, Button, Typography, Tabs, Tab, Divider, Select, MenuItem, Paper, TextField } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import {
    AudienceContactsQueryParams,
    AudienceUsageData,
    UserAttributeBackend,
    EApiIssue,
    CampaignBackend,
} from 'types';
import { makeStyles } from '@material-ui/core/styles';
import api from 'api';
import { connect } from 'react-redux';
import { updateDashboardSelected, updateApiIssue } from 'reducers/userSlice';
import DeleteButton from 'components/deleteButton';
import EmptyPage from 'components/emptyPage';
import Tooltip from 'components/customTooltip';
import EditableTitle from 'components/editableTitle';
import ContactsTable, { Row } from './contactsTable';
import { categoryContent } from './categoryContent';
import { getPersonalAudiences, getTeamAudiences } from './../audienceSlice';
import Attributes from './../attributes';
import { formatNumberWithCommas } from 'utils/numbers';
import datetime from 'utils/datetime';
import ConfirmModal from 'components/confirmModal';
import { getPersonalCampaigns, getTeamCampaigns } from '../../campaigns/campaignSlice';
import DeleteTooltipContent from './../audienceDeleteTooltip';
import { isAudienceDeletable, getAudienceUsageData, isAudienceImporting } from './../audienceUtils';
import AudienceUsageModal from './../audienceUsageModal';
import LoadingSpinner from './loadingSpinner';
import { pluralise } from 'utils/pluralise';
import { isEmailValid } from 'utils/email';
import colors from 'colors';

const useStyles = makeStyles(() => ({
    tabs: {
        minHeight: 0,
    },
    tab: {
        fontSize: 16,
        lineHeight: '24px',
        minWidth: 100,
        textTransform: 'none',
        padding: '0 8px 24px 8px',
    },
    list: {
        minWidth: 112,
    },
    paper: {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    textField: {
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
        },
        '&  .MuiFormHelperText-root.Mui-error': {
            backgroundColor: 'transparent',
            '&::before': {
                content: 'none',
            },
        },
    },
}));

export enum Category {
    // 'ACTIVE' = 'ACTIVE',
    'UNSUBSCRIBED' = 'UNSUBSCRIBED',
    'BOUNCED' = 'BOUNCED',
    'ALL' = 'ALL',
    'COMPLAINED' = 'COMPLAINED',
    'SEARCH' = 'SEARCH',
}

interface User {
    userId: string;
    firstName: string;
    lastName: string;
}

interface ContactsAudience {
    id: string;
    name: string;
    importedOn: string;
    importedBy: User;
    totalCount: number;
    count: number;
    teamId: string;
    createdBy: User;
    createdOn: string;
    state: string;
}

type ContactTabs = 'contacts' | 'attributes';

interface ContactsProps {
    tabName: ContactTabs;
    updateDashboardSelected: (dashboard: string) => void;
    getPersonalAudiences: () => void;
    getTeamAudiences: (teamId: string) => void;
    updateApiIssue: (apiIssue: EApiIssue) => void;
    campaigns: CampaignBackend[];
    getPersonalCampaigns: () => void;
    getTeamCampaigns: (teamId: string) => void;
}

export const Contacts = ({
    tabName,
    updateDashboardSelected,
    getPersonalAudiences,
    getTeamAudiences,
    updateApiIssue,
    campaigns,
    getTeamCampaigns,
    getPersonalCampaigns,
}: ContactsProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [email, setEmail] = useState<string>('');
    const [showEmailError, setShowEmailError] = useState<boolean>(false);
    const [emailErrorHelperText, setEmailErrorHelperText] = useState<string>('');
    const [tab, setTab] = useState<ContactTabs>('contacts');
    const [category, setCategory] = useState<Category>(Category.ALL);
    const [audience, setAudience] = useState<ContactsAudience>();
    const { audienceId } = useParams<{ audienceId: string }>();
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [audienceUsageData, setAudienceUsageData] = useState<AudienceUsageData[]>([]);
    const [openAudienceUsage, setOpenAudienceUsage] = useState<boolean>(false);
    const [audienceName, setAudienceName] = useState<string>('');
    const [rows, setRows] = useState<Row[]>([]);
    const [schema, setSchema] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [attributes, setAttributes] = useState<UserAttributeBackend[]>();
    const [shouldFetchContacts, setShouldFetchContacts] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<number>(0);
    const [tableCount, setTableCount] = useState<number>(0);
    const debounceFetchData = useMemo(() => debounce(fetchContacts, 500), []);
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            debounceFetchData(email, pageNumber, rowsPerPage, category);
        } else {
            isMounted.current = true;
        }
    }, [pageNumber]);

    function fetchContacts(email: string, pageNumber: number, rowsPerPage: number, category: string) {
        setIsFetching((isFetching) => isFetching + 1);

        const queryParams: AudienceContactsQueryParams = {
            state: category.toLowerCase(),
            email: encodeURIComponent(email.trim()),
            pageSize: rowsPerPage,
            pageNumber,
        };

        if (category === Category.ALL) delete queryParams.state;
        if (!email) delete queryParams.email;

        const apiCalls = [
            api.getAudienceContacts(audienceId, queryParams),
            api.dismissNotifications(`${audienceId}.import.completed`),
        ];

        Promise.all(apiCalls)
            .then(([getAudienceContactsResponse]: ApiResponse<any>[]) => {
                if (getAudienceContactsResponse.status === 404) {
                    updateApiIssue(EApiIssue.NOT_FOUND_404);
                }

                if (getAudienceContactsResponse.ok && getAudienceContactsResponse.data) {
                    const { id, name, importedOn, importedBy, totalCount, count, teamId, createdBy, createdOn, state } =
                        getAudienceContactsResponse.data;

                    setAudience({
                        id,
                        name,
                        importedOn,
                        importedBy,
                        totalCount,
                        count,
                        teamId,
                        createdBy,
                        createdOn,
                        state,
                    });

                    setAudienceName(getAudienceContactsResponse.data.name);
                    setRows(getAudienceContactsResponse.data.data as Row[]);

                    if (queryParams.email) {
                        setTableCount(getAudienceContactsResponse.data.count);
                        setPageNumber(0);
                    } else {
                        setTableCount(getAudienceContactsResponse.data.totalCount);
                    }

                    if (getAudienceContactsResponse.data.teamId)
                        updateDashboardSelected(getAudienceContactsResponse.data.teamId);
                    setSchema(getAudienceContactsResponse.data.schema);
                }
            })
            .finally(() => {
                setIsFetching((isFetching) => isFetching - 1);
            });
    }

    useEffect(() => {
        setShouldFetchContacts(false);
        debounceFetchData(email, pageNumber, rowsPerPage, category);
    }, [category, rowsPerPage, shouldFetchContacts]);

    useEffect(() => {
        if (!validateEmail(email)) return;

        debounceFetchData(email, pageNumber, rowsPerPage, category);
    }, [email]);

    useEffect(() => {
        if (audience && audience.teamId) {
            updateDashboardSelected(audience.teamId);
            getTeamAudiences(audience.teamId);
            getTeamCampaigns(audience.teamId);
        } else if (audience && !audience.teamId) {
            updateDashboardSelected('personal');
            getPersonalAudiences();
            getPersonalCampaigns();
        }
    }, [audience]);

    useEffect(() => {
        if (audience && audience.id)
            setAudienceUsageData(getAudienceUsageData(audience.id, campaigns) as AudienceUsageData[]);
    }, [campaigns, audience]);

    useEffect(() => {
        if (audience && audience.id) {
            api.getAttributes(audience.id).then((response: ApiResponse<any>) => {
                setAttributes(response.data[audience.id]);
            });
        }
    }, [audience]);

    useEffect(() => {
        setTab(tabName);
    }, [tabName]);

    const handleTabChange = (event: React.ChangeEvent<any>, tabName: ContactTabs) => {
        history.push(`/audience/${audienceId}/${tabName}`);
    };

    const handleOpenAudienceUsage = () => {
        setOpenAudienceUsage(true);
    };

    const handleImportContactsClick = () => {
        if (audience) {
            const route = !audience.teamId
                ? `/audience/${audienceId}/contacts/new`
                : `/audience/teams/${audience.teamId}/${audienceId}/contacts/new`;
            history.push(route, { isUpdate: true });
        }
    };

    const handleBackToAudienceDashboard = () => {
        if (audience) {
            const route = !audience.teamId ? '/audience' : `/audience/teams/${audience.teamId}`;
            history.push(route);
        }
    };

    const handleOnTitleChange = (title: string) => {
        setAudienceName(title);
        api.editAudience({
            id: audienceId,
            name: title,
        });
    };

    const handleDeleteContacts = (contacts: string[]) => {
        if (audience && audience.id) {
            api.deleteAudienceContacts(audience.id, contacts).then((response) => {
                setShouldFetchContacts(true);
            });
        }
    };

    const handleDeleteAudience = () => {
        api.deleteAudience(audienceId).then((response) => {
            if (response.ok) {
                handleBackToAudienceDashboard();
            } else {
                setOpenDelete(false);
            }
        });
    };

    const handleAttributeUpdate = () => setShouldFetchContacts(true);

    const formatDateAndName = (importedOn: string, firstName: string, lastName: string) =>
        `${datetime.toFormatDateAtTime(importedOn)} ${firstName && lastName ? `(${firstName} ${lastName})` : ''}`;

    const validateEmail = (email: string) => {
        if (email.length < 1 || isEmailValid(email)) {
            setShowEmailError(false);
            setEmailErrorHelperText('');
            return true;
        } else {
            setShowEmailError(true);
            setEmailErrorHelperText('Enter a valid email address, e.g. “john@abc.com');
            return false;
        }
    };

    if (isAudienceImporting(audience?.state)) {
        return (
            <EmptyPage
                style={{ marginTop: 32 }}
                title={`You may not access ${audience?.name} at this time`}
                subtitle={'Import is in progress'}
                withBackground
            />
        );
    }
    return (
        <Box px={4} pt={5} mb={5} style={{ width: '100%', overflow: 'auto' }}>
            {isFetching !== 0 && !audience && <LoadingSpinner />}
            {audience && (
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button onClick={handleBackToAudienceDashboard} size="small" color="primary">
                            Back to audience dashboard
                        </Button>
                        <Typography color="textSecondary" variant="body2">
                            {audience.importedOn
                                ? `last imported ${formatDateAndName(
                                      audience.importedOn,
                                      audience.importedBy.firstName,
                                      audience.importedBy.lastName,
                                  )}`
                                : `last created ${formatDateAndName(
                                      audience.createdOn,
                                      audience.createdBy.firstName,
                                      audience.createdBy.lastName,
                                  )}`}
                        </Typography>
                    </Box>
                    <Box mt={2} mb={4} display="flex" justifyContent="space-between" style={{ maxWidth: 'inherit' }}>
                        <Box pr={10} width="100%" style={{ overflow: 'hidden' }}>
                            <EditableTitle title={audienceName} onTitleChange={handleOnTitleChange} isDraft={true} />
                            <Typography color="textPrimary" style={{ marginTop: 4 }}>
                                {`This audience has 
                                ${audience.totalCount > 0 ? formatNumberWithCommas(audience.totalCount) : 'no'}
                                ${pluralise(audience.totalCount, 'contact')}`}
                            </Typography>
                        </Box>
                        <Box display="flex" style={{ minWidth: 262 }}>
                            <Tooltip
                                title={
                                    isAudienceDeletable(audience.id, campaigns) ? (
                                        'Delete audience'
                                    ) : (
                                        <DeleteTooltipContent
                                            title="Audience cannot be deleted as it is used in at least one campaign"
                                            onClick={handleOpenAudienceUsage}
                                        />
                                    )
                                }
                                arrow
                                placement="bottom"
                                style={{ display: 'inline-block' }}
                                interactive
                            >
                                <DeleteButton
                                    disabled={!isAudienceDeletable(audience.id, campaigns)}
                                    onClick={() => {
                                        setOpenDelete(true);
                                    }}
                                />
                            </Tooltip>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ marginLeft: 12 }}
                                onClick={handleImportContactsClick}
                            >
                                Import contacts
                            </Button>
                        </Box>
                    </Box>
                    <Tabs
                        classes={{ root: classes.tabs }}
                        value={tab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            classes={{ root: classes.tab }}
                            style={{ marginRight: 40 }}
                            label="Contacts"
                            value={'contacts'}
                        />
                        <Tab classes={{ root: classes.tab }} label="Attributes" value={'attributes'} />
                    </Tabs>
                    <Divider />
                    {tab === 'contacts' ? (
                        <>
                            <Box display="flex" my="20px" alignItems="center">
                                <Typography color="textSecondary" style={{ marginRight: 12 }}>
                                    View:
                                </Typography>
                                <Select
                                    value={category}
                                    onChange={(event: ChangeEvent<any>) => {
                                        setCategory(event.target.value);
                                        setPageNumber(0);
                                    }}
                                    MenuProps={{
                                        classes: { list: classes.list, paper: classes.paper },
                                        style: { marginTop: 8 },
                                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                        transformOrigin: { vertical: 'top', horizontal: 'center' },
                                        getContentAnchorEl: null,
                                    }}
                                    IconComponent={ArrowIcon}
                                    renderValue={(value) =>
                                        `${categoryContent[value as Category].selectMenuItem} (${formatNumberWithCommas(
                                            audience.totalCount,
                                        )})`
                                    }
                                    disableUnderline
                                >
                                    {/* <MenuItem value={Category.ACTIVE}>Active contacts</MenuItem> */}
                                    <MenuItem value={Category.ALL}>
                                        {categoryContent[Category.ALL].selectMenuItem}
                                    </MenuItem>
                                    <MenuItem value={Category.BOUNCED}>
                                        {categoryContent[Category.BOUNCED].selectMenuItem}
                                    </MenuItem>
                                    <MenuItem value={Category.UNSUBSCRIBED}>
                                        {categoryContent[Category.UNSUBSCRIBED].selectMenuItem}
                                    </MenuItem>
                                    <MenuItem value={Category.COMPLAINED}>
                                        {categoryContent[Category.COMPLAINED].selectMenuItem}
                                    </MenuItem>
                                </Select>
                                {category !== Category.ALL && (
                                    <Tooltip title={categoryContent[category].tooltip} icon="help" arrow />
                                )}
                                <TextField
                                    classes={{ root: classes.textField }}
                                    variant="outlined"
                                    value={email}
                                    InputProps={{
                                        endAdornment: (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {email && (
                                                    <Button onClick={() => setEmail('')}>
                                                        <CloseIcon style={{ color: colors.darkGrey }} />
                                                    </Button>
                                                )}
                                                <SearchIcon color="primary" />
                                            </div>
                                        ),
                                    }}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                    error={showEmailError}
                                    helperText={emailErrorHelperText}
                                />
                            </Box>
                            {category === Category.BOUNCED && (
                                <p>Only permanently bounced contacts will be displayed here.</p>
                            )}
                            <ContactsTable
                                style={{ display: audience.count > 0 ? undefined : 'none' }}
                                rows={rows}
                                schema={schema}
                                category={category}
                                rowsPerPage={rowsPerPage}
                                pageNumber={pageNumber}
                                onChangeRowsPerPage={(newRowsPerPage) => {
                                    setRowsPerPage(newRowsPerPage);
                                }}
                                onChangePageNumber={(pageNumber) => {
                                    setPageNumber(pageNumber);
                                }}
                                count={tableCount}
                                attributes={attributes}
                                onDeleteContacts={handleDeleteContacts}
                                isFetching={isFetching}
                            />

                            <div style={{ display: audience.count > 0 ? 'none' : undefined }}>
                                {isFetching !== 0 ? (
                                    <Paper style={{ height: 390 }}>
                                        <LoadingSpinner />
                                    </Paper>
                                ) : (
                                    <EmptyPage
                                        title={
                                            email.length
                                                ? categoryContent[Category.SEARCH].emptyState.title
                                                : categoryContent[category].emptyState.title
                                        }
                                        subtitle={
                                            email.length
                                                ? categoryContent[Category.SEARCH].emptyState.subtitle
                                                : categoryContent[category].emptyState.subtitle
                                        }
                                        withBackground
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <Attributes audienceAttributes={attributes} onAttributeUpdate={handleAttributeUpdate} />
                    )}
                </>
            )}
            {openDelete && (
                <ConfirmModal
                    open
                    onClose={() => {
                        setOpenDelete(false);
                    }}
                    confirm={handleDeleteAudience}
                    title="Delete audience"
                    content={`“${audience!.name}” will be deleted forever`}
                    textYes="Delete"
                    textNo="Cancel"
                    destructive
                />
            )}
            {openAudienceUsage && (
                <AudienceUsageModal
                    open
                    title="Audience usage"
                    usedEntity={audience!.name}
                    usageData={audienceUsageData}
                    handleClose={() => {
                        setOpenAudienceUsage(false);
                    }}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    audiences: state.audienceSlice.audiences,
    campaigns: state.campaignSlice.campaigns,
});

const mapDispatchToProps = {
    updateDashboardSelected,
    getPersonalAudiences,
    getTeamAudiences,
    updateApiIssue,
    getTeamCampaigns,
    getPersonalCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);

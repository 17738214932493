import React, { useState, useEffect, useRef, CSSProperties, ChangeEvent } from 'react';
import { Box, Typography, InputBase, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PenIcon from '@material-ui/icons/CreateRounded';
import colors from 'colors';

interface EditableTitleProps {
    title: string;
    onTitleChange: (title: string) => void;
    style?: CSSProperties;
    isDraft: boolean;
}

const useStyles = makeStyles(() => ({
    title: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        color: colors.dark,
        maxWidth: '100%',
        height: 36,
    },
    input: {
        background: 'transparent',
        border: 'none',
        fontSize: 24,
        lineHeight: '36px',
        height: 36,
        fontWeight: 500,
        display: 'inline-block',
        '&:focus': {
            background: colors.lightGrey,
        },
        padding: 0,
        textOverflow: 'ellipsis',
    },
    inputBase: {
        height: '36px !important',
    },
}));

const EditableTitle = ({ title, onTitleChange, style, isDraft }: EditableTitleProps) => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editableTitle, setEditableTitle] = useState<string>('');

    const titleInput = useRef<HTMLElement>();
    const titleRef = React.createRef() as any;

    useEffect(() => {
        setEditableTitle(title);
    }, [title]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEditableTitle(e.target.value);
    };

    const handleClick = () => {
        if (isDraft) {
            setIsEditing(true);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        onTitleChange(editableTitle);
    };

    return (
        <Box display="flex" alignItems="center" style={style}>
            {!isEditing ? (
                <Typography variant="h1" onClick={handleClick} ref={titleRef} className={classes.title}>
                    {editableTitle}
                </Typography>
            ) : (
                <InputBase
                    inputProps={{
                        className: classes.input,
                    }}
                    classes={{
                        root: classes.inputBase,
                    }}
                    value={editableTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputRef={titleInput}
                    autoFocus
                    style={{ width: `${editableTitle.length + 1 || 1}em` }}
                />
            )}
            {isDraft ? (
                <Button color="primary" onClick={handleClick}>
                    <PenIcon style={{ marginLeft: 12 }} />
                </Button>
            ) : null}
        </Box>
    );
};

export default EditableTitle;

import React, { useState, useEffect } from 'react';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { ApiResponse } from 'apisauce';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Typography, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api from 'api';
import Footer from './footer';
import { User, updateDashboardSelected, updateApiIssue } from 'reducers/userSlice';
import { EApiIssue } from 'types';
import colors from 'colors';
import { getPersonalAudiences, getTeamAudiences } from './audienceSlice';
import { isTeamValid } from 'features/team/teamUtils';

interface NewAudienceProps {
    user: User;
    selectedTeamId?: string;
    getPersonalAudiences: () => void;
    getTeamAudiences: (teamId: string) => void;
    updateDashboardSelected: (dashboard: string) => void;
    updateApiIssue: (apiIssue: EApiIssue) => void;
}

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        background: colors.white,
        padding: '40px 32px',
    },
    innerContainer: {
        width: 560,
    },
}));

const NewAudience = ({
    user,
    selectedTeamId,
    getPersonalAudiences,
    getTeamAudiences,
    updateDashboardSelected,
    updateApiIssue,
}: NewAudienceProps) => {
    const classes = useStyles();
    const [name, setName] = useState<string>('');
    const history = useHistory();
    const { teamId = '' } = useParams<{ teamId: string }>();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
    const campaignSource = query.get('campaignSource');

    useEffect(() => {
        if (teamId) {
            api.getAllTeams().then((response: any) => {
                if (response.ok && !isTeamValid(teamId, response.data)) {
                    updateApiIssue(EApiIssue.NOT_FOUND_404);
                }
            });
        }
        if (campaignSource) {
            api.getCampaignById(campaignSource).then((response: ApiResponse<any>) => {
                if (response.status === 404) updateApiIssue(EApiIssue.NOT_FOUND_404);
            });
        }
        updateDashboardSelected(teamId ? teamId : 'personal');
        teamId ? getTeamAudiences(teamId) : getPersonalAudiences();
    }, [teamId]);

    return (
        <>
            <div className={classes.container}>
                <div className={classes.innerContainer}>
                    <Typography style={{ marginBottom: 32 }} variant="h1" color="textPrimary">
                        Give your audience a name
                    </Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Audience name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        fullWidth
                    />
                </div>
            </div>
            <Footer
                left={
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: 12 }}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Cancel
                    </Button>
                }
                right={
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!name}
                        onClick={() => {
                            api.addAudience({
                                name,
                                createdBy: user,
                                teamId: selectedTeamId,
                            }).then((response) => {
                                if (response.ok) {
                                    selectedTeamId ? getTeamAudiences(selectedTeamId) : getPersonalAudiences();
                                    let queryParam;
                                    campaignSource
                                        ? (queryParam = `?campaignSource=${campaignSource}`)
                                        : (queryParam = '');
                                    history.push(
                                        selectedTeamId
                                            ? `/audience/teams/${selectedTeamId}/${response.data}/contacts/new${queryParam}`
                                            : `/audience/${response.data}/contacts/new${queryParam}`,
                                    );
                                }
                            });
                        }}
                    >
                        Create audience
                    </Button>
                }
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.userSlice.user,
    selectedTeamId: state.userSlice.dashboardSelected !== 'personal' ? state.userSlice.dashboardSelected : undefined,
});

const mapDispatchToProps = {
    getPersonalAudiences,
    getTeamAudiences,
    updateDashboardSelected,
    updateApiIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAudience);

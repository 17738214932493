import React, { useState, useEffect, ReactNode } from 'react';
import api from 'api';
import datetime from 'utils/datetime';
import ConfirmModal from 'components/confirmModal';
import { PrivateRouteProps } from './../PrivateRoute';

let idleStateInterval: ReturnType<typeof setInterval>;
let logoutSoonStateInterval: ReturnType<typeof setInterval>;

const WithSessionManagement = (WrappedComponent: any) => {
    const SessionManagement = (props: PrivateRouteProps) => {
        const [content, setContent] = useState<string | ReactNode>('');
        const [showDialog, setShowDialog] = useState<boolean>(false);
        const [autoLogout, setAutoLogout] = useState<boolean>(false);
        const [userLogout, setUserLogout] = useState<boolean>(false);

        const IDLE_STATE_TIME = 20; // MINUTES, 20 Minutes
        const LOGOUT_SOON_TIME_COUTER = 60 * 4 + 59; // SECONDS 4 Min 59 Seconds
        let logoutTimeLeft: number = LOGOUT_SOON_TIME_COUTER;
        let idleStateTime = 0;
        let logoutSoonStateTime = 0;

        useEffect(() => {
            idleState();
            document.addEventListener('click', setIdleStateTimeout);
            document.addEventListener('keypress', setIdleStateTimeout);
            document.addEventListener('mousedown', setIdleStateTimeout);
            document.addEventListener('scroll', setIdleStateTimeout);

            // cleanup this listener
            return function cleanup() {
                document.removeEventListener('click', setIdleStateTimeout);
                document.removeEventListener('keypress', setIdleStateTimeout);
                document.removeEventListener('mousedown', setIdleStateTimeout);
                document.removeEventListener('scroll', setIdleStateTimeout);
                clearIntervals();
                clearTimeSoonInterval();
            };
        }, []);

        const getLeftTime = () => {
            if (logoutTimeLeft > 0) {
                const timeLeft = datetime.getTimeLeftFormat(logoutTimeLeft);
                logoutTimeLeft = logoutTimeLeft - 1;
                return timeLeft;
            }
            return '1 second';
        };

        /********* IDLE STATE *********/
        const idleStateDialog = () => {
            idleStateTime = idleStateTime + 1;
            if (idleStateTime >= IDLE_STATE_TIME) {
                logoutTimeLeft = LOGOUT_SOON_TIME_COUTER;
                setContent(
                    `You will be logged out if you do not respond in 5 minutes. We do this to keep your information secure.`,
                );
                setShowDialog(true);
                if (idleStateInterval) clearInterval(idleStateInterval);
                clearTimeSoonInterval();
                logoutSoonState();
            }
        };

        const idleState = () => {
            idleStateInterval = setInterval(idleStateDialog, 1000 * 60);
        };

        const setIdleStateTimeout = () => {
            clearIntervals();
            idleState();
        };

        /********* LOGOUT SOON STATE STARTS *********/
        const logoutSoonState = () => {
            logoutSoonStateInterval = setInterval(logoutSoonStateDialog, 1000);
        };

        const logoutSoonStateDialog = () => {
            setContent(
                `You will be logged out if you do not respond in ${getLeftTime()}. We do this to keep your information secure.`,
            );
            setShowDialog(true);
            logoutSoonStateTime = logoutSoonStateTime + 1;
            if (logoutSoonStateTime >= LOGOUT_SOON_TIME_COUTER) {
                logout('auto');
            }
        };
        /********* LOGOUT SOON STATE ENDS *********/

        const clearIntervals = () => {
            if (idleStateInterval) clearInterval(idleStateInterval);
            idleStateTime = 0;
        };

        const clearTimeSoonInterval = () => {
            if (logoutSoonStateInterval) clearInterval(logoutSoonStateInterval);
            logoutSoonStateTime = 0;
        };

        const logout = (type: string) => {
            api.signOut()
                .then((response) => {
                    cleanUpSession(type);
                })
                .catch((e) => {
                    cleanUpSession(type);
                });
        };

        const cleanUpSession = (type: string) => {
            clearIntervals();
            clearTimeSoonInterval();
            if (type === 'auto') {
                setAutoLogout(true);
            } else {
                setUserLogout(true);
            }
            setShowDialog(false);
        };

        const sessionTimeout = () => (
            <ConfirmModal
                title={'Your session will time out soon'}
                content={content || ''}
                open={showDialog}
                textNo={'Log out'}
                textYes={'Continue session'}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                onClose={() => {
                    logout('user');
                }}
                confirm={() => {
                    setIdleStateTimeout();
                    clearTimeSoonInterval();
                    setShowDialog(false);
                    api.renewToken();
                }}
            />
        );
        return (
            <WrappedComponent
                {...props}
                isAutoLogout={autoLogout}
                isUserLogout={userLogout}
                sessionTimeout={sessionTimeout}
            />
        );
    };
    return SessionManagement;
};

export default WithSessionManagement;

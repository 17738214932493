import React from 'react';
import { format, isToday } from 'date-fns';

export enum ESaveStatus {
    'NO_STATUS', // TO_DO will remove in future as there should not be an empty case
    'LAST_UPDATED',
    'SAVING',
    'SAVED',
}

interface SaveStatusProps {
    status: ESaveStatus;
    lastUpdated: number | Date;
}

const SaveStatus = ({ status, lastUpdated }: SaveStatusProps) => {
    const getSaveStatus = () => {
        switch (status) {
            case ESaveStatus.SAVING:
                return 'Saving...';
            case ESaveStatus.SAVED:
                return 'Saved';
            case ESaveStatus.NO_STATUS:
                return '';
            default: {
                const formattedDate = isToday(lastUpdated)
                    ? `today, ${format(lastUpdated, 'hh:mm a')}`
                    : format(lastUpdated, 'dd MMM yyyy');
                return `Last updated ${formattedDate}`;
            }
        }
    };
    return <span style={{ textDecoration: 'underline', fontSize: 14, lineHeight: '21px' }}>{getSaveStatus()}</span>;
};

export default SaveStatus;

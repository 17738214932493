export const categoryContent = {
    // ACTIVE: {
    //     tooltip: 'Email campaigns will only be sent to active contacts in this audience',
    //     emptyState: {
    //         title: 'No active contacts',
    //         subtitle: 'Import a contact list to add contacts',
    //     },
    // },
    UNSUBSCRIBED: {
        selectMenuItem: 'Unsubscribed contacts',
        tooltip: 'Contacts who opted to stop receiving emails that are sent to this audience',
        emptyState: {
            title: 'No unsubscribed contacts',
            subtitle: 'Contacts who opt to stop receiving emails sent to this audience will be added to this list',
        },
    },
    BOUNCED: {
        selectMenuItem: 'Permanently bounced contacts',
        tooltip: 'Contacts who cannot receive emails permanently due to e.g. invalid email address',
        emptyState: {
            title: 'No bounced contacts',
            subtitle: 'Contacts who are unable to receive emails permanently will be added to this list',
        },
    },
    ALL: {
        selectMenuItem: 'All contacts',
        tooltip: null,
        emptyState: {
            title: 'No contacts',
            subtitle: 'Import a contact list to add contacts',
        },
    },
    COMPLAINED: {
        selectMenuItem: 'Reported spam contacts',
        tooltip:
            'Contacts who reported emails sent to this audience as junk or spam. Your emails are blocked if there are too many spam reports.',
        emptyState: {
            title: 'No reported spam contacts',
            subtitle: 'Contacts who reported emails sent to this audience as junk or spam will be added to this list',
        },
    },
    SEARCH: {
        selectMenuItem: '',
        tooltip: '',
        emptyState: {
            title: 'No results found',
            subtitle: 'Please try another search',
        },
    },
};

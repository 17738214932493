import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { toggleSnackbar } from 'reducers/appSlice';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, Typography } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRounded from '@material-ui/icons/ErrorRounded';
import colors from 'colors';

const useStyles = makeStyles((theme) => ({
    bar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.dark,
        padding: '12px 16px',
        color: colors.white,
        borderRadius: 4,
    },
}));

export type SnackbarType = 'success' | 'info' | 'warning' | 'error';

export interface ToggleSnackbarProps {
    message: string;
    type?: SnackbarType;
}

interface SnackBarProps {
    message?: string | React.ReactNode;
    toggleSnackbar: (data: ToggleSnackbarProps) => void;
    type?: SnackbarType;
    vertical?: 'bottom' | 'top';
    horizontal?: 'center' | 'left' | 'right';
}

const SnackBar = ({
    message,
    toggleSnackbar,
    type = 'success',
    vertical = 'top',
    horizontal = 'center',
}: SnackBarProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);

    useEffect(() => {
        message ? setOpen(true) : setOpen(false);
    }, [message]);

    const handleClose = () => {
        toggleSnackbar({ message: '', type: 'success' });
        setOpen(false);
    };

    const Icon = () => {
        switch (type) {
            case 'success':
                return <CheckCircleRoundedIcon />;
            case 'error':
                return <ErrorRounded />;
            default:
                return null;
        }
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                key={'snackbarkey'}
            >
                <div className={classes.bar}>
                    <Icon />
                    <Typography variant="body2" style={{ paddingLeft: 10 }}>
                        {message}
                    </Typography>
                </div>
            </Snackbar>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    message: state.appSlice.snackbar.message,
    type: state.appSlice.snackbar.type,
});

const mapDispatchToProps = {
    toggleSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);

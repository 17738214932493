import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography, Box, Button } from '@material-ui/core';
import CopyRight from 'components/copyRight';
import { SUPPORT_EMAIL_MSG } from 'common/constants';
import { Error } from './index';
import { GenericServerFailureMessage } from 'components/messageContent';

import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 60,
        backgroundColor: colors.backgroundGrey,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 16,
        },
    },
    innerContainer: {
        width: 'calc(100% - 32px)',
        maxWidth: 814,
        [theme.breakpoints.down('sm')]: {
            padding: '28px',
        },
    },
    content: {
        textAlign: 'left',
        padding: '32px 48px',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    subTitle: {
        padding: '16px 0 0 0',
        color: colors.dark,
    },
    link: {
        padding: '24px 0 0 0',
    },
}));

const getTitle = (error?: Error) => {
    if (error && error === '500') {
        return 'Something went wrong';
    }
    return 'Page not found';
};

const getSubTitle = (error?: Error) => {
    if (error && error === '500') {
        return SUPPORT_EMAIL_MSG;
    }
    return 'You may have entered an incorrect web address';
};

interface PageProps {
    error?: Error;
}

const ErrorPage = ({ error }: PageProps) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.container}>
            <Paper className={classes.innerContainer} elevation={2}>
                <Box className={classes.content}>
                    <Typography variant="h1">{getTitle(error)}</Typography>
                    <Typography variant="body1" className={classes.subTitle}>
                        <GenericServerFailureMessage />
                    </Typography>
                    <Button color="primary" className={classes.link} onClick={() => history.push('/campaigns')}>
                        Back to Dashboard
                    </Button>
                </Box>
            </Paper>
            <CopyRight placement={'preLogin'} />
        </div>
    );
};

export default ErrorPage;

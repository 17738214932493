import React, { useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, TextField, Typography, InputLabel, Box, Link, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/SearchRounded';
import { ApiResponse } from 'apisauce';
import api from 'api';
import { Organisation } from 'types';
import PageNotFound from 'features/pageNotFound';
import { isEmailValid, isGovEmailValid } from 'utils/email';

import colors from 'colors';
import { User } from 'reducers/userSlice';
import CopyRight from '../../components/copyRight';

const useStyles = makeStyles(() => ({
    wrapper: {
        minHeight: '100vh',
        width: '100%',
        backgroundColor: colors.lightGrey,
        padding: '40px',
        display: 'flex',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    formContainer: {
        padding: 40,
        width: 604,
    },
    form: {
        width: '100%',
        padding: '16px 44px',
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        '&:hover': {
            color: colors.mediumBlue,
        },
    },
    textInput: {
        marginBottom: 24,
    },
    clearIcon: {
        color: colors.darkGrey,
        cursor: 'pointer',
        '&:hover': {
            color: colors.coolGrey,
        },
    },
    autocompleteInputRoot: {
        minHeight: '48px !important',
        '&&[class*="MuiOutlinedInput-root"]': {
            padding: '4px 12px',
        },
        '&&[class*="MuiOutlinedInput-root"] $input': {
            padding: '8px 4px !important',
        },
    },
    autocompleteOption: {
        fontSize: 14,
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: '12px 24px !important',
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: colors.lightGrey,
        },
    },
    autocompletePopupIndicatorOpen: {
        transform: 'none',
    },
    autocompletePaper: {
        margin: '8px 0',
        border: `1px solid ${colors.grey}`,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
}));

const SignUp = () => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const history = useHistory();
    const searchPlaceholder = 'Type to search';
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [jobTitles, setJobTitles] = useState<string[]>([]);
    const [jobCategories, setJobCategories] = useState<string[]>([]);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [jobTitle, setJobTitle] = useState<string>('');
    const [jobCategory, setJobCategory] = useState<string>('');
    const [organisation, setOrganisation] = useState<Organisation | null>(null);
    const [email, setEmail] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [isAccountExists, setIsAccountExists] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<{ [key: string]: boolean }>({
        organisations: true,
        jobTitles: true,
        jobCategories: true,
    });

    useEffect(() => {
        api.getUser().then((response: ApiResponse<any>) => {
            const user = response.data as User;
            const isMissingAttributes = !(response.data as User).organisation?.id;

            if (!isMissingAttributes) {
                history.push('/campaigns');
                return;
            }

            if (response.ok) {
                if (user?.firstName) {
                    setFirstName(user.firstName);
                }
                if (user?.lastName) {
                    setLastName(user.lastName);
                }
                if (user?.email) {
                    setEmail(user.email);
                }
            }
        });
        api.getOrganisations().then((response: ApiResponse<any>) => {
            if (response.ok) {
                setOrganisations(response.data as Organisation[]);
                setIsFetching((isFetching) => ({ ...isFetching, organisation: false }));
            }
        });

        api.getJobTitles().then((response: ApiResponse<any>) => {
            if (response.ok) {
                setJobTitles(response.data);
                setIsFetching((isFetching) => ({ ...isFetching, jobTitles: false }));
            }
        });

        api.getJobCategories().then((response: ApiResponse<any>) => {
            if (response.ok) {
                setJobCategories(response.data);
                setIsFetching((isFetching) => ({ ...isFetching, jobCategories: false }));
            }
        });
    }, []);

    const handleCreateAccount = async () => {
        setShowError(true);
        if (email) {
            if (jobTitle && jobCategory && organisation) {
                api.updateUser({
                    jobTitle,
                    jobCategory,
                    organisation,
                }).then((response: ApiResponse<any>) => {
                    if (response.ok) {
                        history.push({ pathname: `/campaigns` });
                    }
                });
            }
        }
    };

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <Paper className={classes.formContainer} elevation={1}>
                    <Switch>
                        <Route exact path={path}>
                            <Box mb={4}>
                                <Typography variant="h1" align="center">
                                    Complete your Personalise profile
                                </Typography>
                                <Typography style={{ marginTop: 8 }} align="center">
                                    Complete your profile to enhance your experience
                                </Typography>
                            </Box>
                            <form className={classes.form}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel>First name</InputLabel>
                                        <TextField
                                            disabled
                                            value={firstName}
                                            className={classes.textInput}
                                            variant="outlined"
                                            autoComplete="given-name"
                                            onChange={(e) => setFirstName(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel>Last name</InputLabel>
                                        <TextField
                                            disabled
                                            value={lastName}
                                            className={classes.textInput}
                                            variant="outlined"
                                            autoComplete="family-name"
                                            onChange={(e) => setLastName(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <InputLabel>Organisation</InputLabel>
                                <Autocomplete
                                    value={organisation}
                                    classes={{
                                        paper: classes.autocompletePaper,
                                        inputRoot: classes.autocompleteInputRoot,
                                        option: classes.autocompleteOption,
                                        popupIndicator: classes.autocompletePopupIndicatorOpen,
                                    }}
                                    onChange={(event, newValue) => {
                                        setOrganisation(newValue);
                                    }}
                                    options={organisations.sort((a, b) => (a.name > b.name ? 1 : -1))}
                                    getOptionLabel={(option) => option.name}
                                    noOptionsText={
                                        isFetching.organisations ? 'Loading...' : 'No matching options found'
                                    }
                                    popupIcon={<SearchIcon color="primary" />}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            ref={params.InputProps.ref}
                                            fullWidth
                                            variant="outlined"
                                            placeholder={searchPlaceholder}
                                            className={classes.textInput}
                                            error={showError && !organisation}
                                            helperText={showError && !organisation && 'This field cannot be empty'}
                                        />
                                    )}
                                    data-testid="organisation"
                                />
                                <InputLabel>Designation / Job title</InputLabel>
                                <Autocomplete
                                    value={jobTitle}
                                    classes={{
                                        paper: classes.autocompletePaper,
                                        inputRoot: classes.autocompleteInputRoot,
                                        option: classes.autocompleteOption,
                                        popupIndicator: classes.autocompletePopupIndicatorOpen,
                                    }}
                                    onChange={(event, newValue) => {
                                        setJobTitle(newValue || '');
                                    }}
                                    options={jobTitles}
                                    noOptionsText={isFetching.jobTitles ? 'Loading...' : 'No matching options found'}
                                    popupIcon={<SearchIcon color="primary" />}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            ref={params.InputProps.ref}
                                            fullWidth
                                            variant="outlined"
                                            placeholder={searchPlaceholder}
                                            className={classes.textInput}
                                            error={showError && !jobTitle}
                                            helperText={showError && !jobTitle && 'This field cannot be empty'}
                                        />
                                    )}
                                />
                                <InputLabel>Job category</InputLabel>
                                <Autocomplete
                                    value={jobCategory}
                                    classes={{
                                        paper: classes.autocompletePaper,
                                        inputRoot: classes.autocompleteInputRoot,
                                        option: classes.autocompleteOption,
                                        popupIndicator: classes.autocompletePopupIndicatorOpen,
                                    }}
                                    onChange={(event, newValue) => {
                                        setJobCategory(newValue || '');
                                    }}
                                    options={jobCategories}
                                    noOptionsText={
                                        isFetching.jobCategories ? 'Loading...' : 'No matching options found'
                                    }
                                    popupIcon={<SearchIcon color="primary" />}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            ref={params.InputProps.ref}
                                            fullWidth
                                            variant="outlined"
                                            placeholder={searchPlaceholder}
                                            className={classes.textInput}
                                            error={showError && !jobCategory}
                                            helperText={showError && !jobCategory && 'This field cannot be empty'}
                                        />
                                    )}
                                />
                                <InputLabel>Email address</InputLabel>
                                <TextField
                                    disabled
                                    inputProps={{ 'aria-label': 'email' }}
                                    data-testid="email"
                                    value={email}
                                    className={classes.textInput}
                                    variant="outlined"
                                    autoComplete="email"
                                    onChange={(e) => {
                                        setIsAccountExists(false);
                                        setEmail(e.target.value);
                                    }}
                                    error={
                                        (showError && !email) ||
                                        isAccountExists ||
                                        (!!email && (!isEmailValid(email) || !isGovEmailValid(email)))
                                    }
                                    helperText={
                                        (showError && !email && 'This field cannot be empty') ||
                                        (isAccountExists && (
                                            <span>
                                                An account with this email address already exists.{' '}
                                                <Link component={RouterLink} to="/signin" className={classes.link}>
                                                    Log in
                                                </Link>{' '}
                                                with this email address or{' '}
                                                <Link
                                                    component={RouterLink}
                                                    to={{ pathname: '/signin/forget-password', state: { from: path } }}
                                                    className={classes.link}
                                                >
                                                    reset the password
                                                </Link>{' '}
                                                if you have forgotten it.
                                            </span>
                                        )) ||
                                        (!!email &&
                                            !isEmailValid(email) &&
                                            'Email address entered is not in the correct format') ||
                                        (!!email &&
                                            !isGovEmailValid(email) &&
                                            'Email address is not a valid government email address')
                                    }
                                />
                                <Button
                                    data-testid="create-account"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreateAccount}
                                    style={{ marginTop: 40 }}
                                >
                                    Complete your profile
                                </Button>
                            </form>
                        </Route>
                        <Route exact path={`${path}/verify-email`}>
                            <Box style={{ padding: '24px 44px' }}>
                                <Typography variant="h1" align="center">
                                    Verify your email
                                </Typography>
                                <Box>
                                    <Typography style={{ marginTop: 24 }} align="center">
                                        An email with a verification link has been sent to{' '}
                                        <span style={{ fontWeight: 500 }}>{email}</span>{' '}
                                        <Link component={RouterLink} to="/signup" className={classes.link}>
                                            (Change)
                                        </Link>
                                    </Typography>
                                </Box>
                                <Typography style={{ marginTop: 24 }} align="center">
                                    If you did not receive the email, check your spam folder or contact us at
                                    help@personalise.gov.sg
                                </Typography>
                            </Box>
                        </Route>
                        <Route path="*">
                            <PageNotFound />
                        </Route>
                    </Switch>
                </Paper>
                <CopyRight placement="preLogin" />
            </div>
        </div>
    );
};

export default SignUp;

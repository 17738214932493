import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Typography,
    TextField,
} from '@material-ui/core';
import { INode } from '@mrblenny/react-flow-chart';
import {
    UpdateFlowNode,
    WorkflowConditionScheduleType,
    WorflowConditionRuleType,
    WorkflowScheduleErrorType,
} from 'types';
import colors from 'colors';
import { getConditionTimeVariableError } from 'features/campaigns/validate';

const getWaitInfo = (ruleType: WorflowConditionRuleType) => {
    if (ruleType === WorflowConditionRuleType.ATTRIBUTE) {
        return 'Wait for a pre-defined period before checking whether your contact has the selected attribute';
    } else if (ruleType === WorflowConditionRuleType.EMAIL_OPENED) {
        return 'Wait for a pre-defined period before checking whether your contact has opened the selected email';
    }
    return '';
};

const getPeriodInfo = (ruleType: WorflowConditionRuleType) => {
    if (ruleType === WorflowConditionRuleType.ATTRIBUTE) {
        return 'Check daily over a pre-defined period whether your contact has the selected attribute';
    } else if (ruleType === WorflowConditionRuleType.EMAIL_OPENED) {
        return 'Check daily over a pre-defined period whether your contact has opened the selected email';
    }
    return '';
};
interface ScheduleTypeRadioFormRadioFormProps {
    node: INode;
    updateWorkflowNode: UpdateFlowNode;
    ruleType: WorflowConditionRuleType;
    setHasDraftChanges: (value: boolean) => void;
}

const useStyles = makeStyles(() => ({
    formGroup: {
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginBottom: 12,
        },
    },
}));

const ScheduleTypeRadioForm = ({
    node,
    updateWorkflowNode,
    ruleType,
    setHasDraftChanges,
}: ScheduleTypeRadioFormRadioFormProps) => {
    const classes = useStyles();

    const getHelperText = () => {
        switch (getConditionTimeVariableError(node, true)) {
            case WorkflowScheduleErrorType.EMPTY_VALUE:
                return 'Enter the number of days';
            case WorkflowScheduleErrorType.INVALID_FORMAT:
                return 'Enter a valid number format';
            case WorkflowScheduleErrorType.MIN_VALUE:
                return 'Minimum value is 1';
        }
    };

    return (
        <FormControl component="fieldset" style={{ marginTop: 32 }}>
            <FormLabel style={{ marginBottom: 16 }}>When to check?</FormLabel>
            <RadioGroup
                value={
                    node.properties.scheduleType ||
                    (ruleType === WorflowConditionRuleType.ATTRIBUTE
                        ? WorkflowConditionScheduleType.IMMEDIATELY
                        : WorkflowConditionScheduleType.WAIT)
                }
                onChange={(event: ChangeEvent<any>) => {
                    const newNode = { ...node };
                    newNode.properties = { ...node.properties };
                    newNode.properties.scheduleType = event.target.value;
                    updateWorkflowNode(newNode);
                    setHasDraftChanges(true);
                }}
                classes={{ root: classes.formGroup }}
            >
                {ruleType === WorflowConditionRuleType.ATTRIBUTE && (
                    <FormControlLabel
                        value={WorkflowConditionScheduleType.IMMEDIATELY}
                        control={<Radio size="small" color="primary" />}
                        label="Immediately"
                    />
                )}
                <FormControlLabel
                    value={WorkflowConditionScheduleType.WAIT}
                    control={<Radio size="small" color="primary" />}
                    label="Wait"
                />
                {node.properties.scheduleType === WorkflowConditionScheduleType.WAIT && (
                    <>
                        <TextField
                            variant="outlined"
                            type="number"
                            value={node.properties.daysToWait}
                            onChange={(event: ChangeEvent<any>) => {
                                const newNode = { ...node };
                                newNode.properties = { ...node.properties };
                                newNode.properties.daysToWait = event.target.value;
                                updateWorkflowNode(newNode);
                                setHasDraftChanges(true);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <span
                                            style={{
                                                fontSize: 14,
                                                color: !node.properties.daysToWait ? colors.coolGrey : colors.dark,
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {'day(s)'}
                                        </span>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{ min: 1 }}
                            error={getConditionTimeVariableError(node) !== WorkflowScheduleErrorType.NO_ERROR}
                            helperText={getHelperText()}
                        />
                        <Typography style={{ margin: '8px 0 12px', fontSize: 14, color: colors.darkGrey }}>
                            {getWaitInfo(ruleType)}
                        </Typography>
                    </>
                )}
                <FormControlLabel
                    value={WorkflowConditionScheduleType.FOR_A_PERIOD_OF}
                    control={<Radio size="small" color="primary" />}
                    label="For a period of"
                />
                {node.properties.scheduleType === WorkflowConditionScheduleType.FOR_A_PERIOD_OF && (
                    <>
                        <TextField
                            variant="outlined"
                            type="number"
                            value={node.properties.daysInPeriod}
                            onChange={(event: ChangeEvent<any>) => {
                                const newNode = { ...node };
                                newNode.properties = { ...node.properties };
                                newNode.properties.daysInPeriod = event.target.value;
                                updateWorkflowNode(newNode);
                                setHasDraftChanges(true);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <span
                                            style={{
                                                fontSize: 14,
                                                color: !node.properties.daysInPeriod ? colors.coolGrey : colors.dark,
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {`day(s)`}
                                        </span>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{ min: 1 }}
                            error={getConditionTimeVariableError(node) !== WorkflowScheduleErrorType.NO_ERROR}
                            helperText={getHelperText()}
                        />
                        <Typography style={{ marginTop: 8, fontSize: 14, color: colors.darkGrey }}>
                            {getPeriodInfo(ruleType)}
                        </Typography>
                    </>
                )}
            </RadioGroup>
        </FormControl>
    );
};

export default ScheduleTypeRadioForm;

import React, { useState, DragEvent, RefObject, ChangeEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Button, Box } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import AlertBar from 'components/alertBar';
import CircularLoader from 'components/circularLoader';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        flex: 1,
        background: colors.backgroundGrey,
        padding: 0,
    },
    addDropAnimation: {
        animation: '$dropZone 0.3s ease alternate infinite',
    },
    dropZone: {
        margin: '48px 32px',
        height: 'calc(100% - 134px)',
        background: colors.white,
        borderRadius: 4,
        border: `1px dashed ${colors.coolGrey}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 0,
        outlineColor: colors.blue,
    },
    error: {
        borderColor: colors.red,
        marginTop: 16,
        height: 'calc(100% - 205px)',
    },
    inProgress: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontWeight: 500,
        color: colors.dark,
    },
    subTitle: {
        color: colors.darkGrey,
        marginTop: 12,
        textAlign: 'left',
    },
    subTitleList: {
        margin: 0,
    },
    '@keyframes dropZone': {
        from: { padding: 0 },
        to: { padding: 24 },
    },
}));

type DropZoneProps = {
    handleDropFolder: (e: DragEvent<HTMLDivElement>) => void;
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleUploadFolder: (e: ChangeEvent<HTMLInputElement>) => void;
    handleUploadZipFile: (e: ChangeEvent<HTMLInputElement>) => void;
    dropZoneRef: RefObject<HTMLDivElement>;
    fileInputRef: RefObject<HTMLInputElement>;
    folderInputRef: RefObject<HTMLInputElement>;
    zipFileInputRef: RefObject<HTMLInputElement>;
    error: boolean;
    inProgressHtml: boolean;
};

const DropZone = ({
    handleDropFolder,
    handleFileChange,
    handleUploadFolder,
    handleUploadZipFile,
    dropZoneRef,
    fileInputRef,
    folderInputRef,
    zipFileInputRef,
    error,
    inProgressHtml,
}: DropZoneProps) => {
    const classes = useStyles();
    const [isDragOver, setIsDragOver] = useState(false);
    return (
        <div className={`${classes.content} ${isDragOver ? classes.addDropAnimation : ''}`}>
            {error && (
                <div style={{ margin: '48px 32px 0px 32px' }}>
                    <AlertBar message={'Upload an email asset'} type="error" />
                </div>
            )}
            <div
                ref={dropZoneRef}
                className={`${classes.dropZone} ${error ? classes.error : ''}`}
                onDrop={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsDragOver(false);
                    handleDropFolder(e);
                }}
                onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsDragOver(true);
                }}
                onDragLeave={(e) => {
                    setIsDragOver(false);
                }}
            >
                {inProgressHtml ? (
                    <div className={classes.inProgress}>
                        <CircularLoader size={80} thickness={2} />
                        <div style={{ paddingTop: '10px' }}>Uploading asset</div>
                    </div>
                ) : (
                    <>
                        <UploadIcon style={{ fontSize: 129, color: colors.grey, marginBottom: 12 }} />
                        <div>
                            <label htmlFor="upload-html-file">
                                <input
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    id="upload-html-file"
                                    name="upload-html-file"
                                    type="file"
                                    accept=".html"
                                    onChange={handleFileChange}
                                />
                                <Button color="primary" component="span">
                                    Upload HTML file
                                </Button>
                            </label>{' '}
                            |{' '}
                            <label htmlFor="upload-folder">
                                <input
                                    ref={folderInputRef}
                                    style={{ display: 'none' }}
                                    id="upload-folder"
                                    name="upload-folder"
                                    type="file"
                                    onChange={handleUploadFolder}
                                    /* @ts-expect-error Non-standard input attribute webitkitdiretory used here to support directory upload*/
                                    webkitdirectory=""
                                />
                                <Button color="primary" component="span">
                                    folder
                                </Button>
                            </label>{' '}
                            |{' '}
                            <label htmlFor="upload-zip-file">
                                <input
                                    ref={zipFileInputRef}
                                    style={{ display: 'none' }}
                                    id="upload-zip-file"
                                    name="upload-zip-file"
                                    type="file"
                                    accept=".zip"
                                    onChange={handleUploadZipFile}
                                />
                                <Button color="primary" component="span">
                                    ZIP file
                                </Button>
                            </label>
                        </div>
                        <Box fontWeight={500}>or drop email asset here</Box>
                        <Box className={classes.subTitle}>
                            Your email asset must be uploaded in one of the following formats:
                            <ol className={classes.subTitleList}>
                                <li>{'HTML file (.html)'}</li>
                                <li>{'Folder containing HTML file and relevant images'}</li>
                                <li>{'ZIP file (.zip) containing HTML file and relevant images'}</li>
                            </ol>
                        </Box>
                    </>
                )}
            </div>
        </div>
    );
};

export default DropZone;

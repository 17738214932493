import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { INodeDefaultProps } from '@mrblenny/react-flow-chart';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        position: 'absolute',
        width: 296,
        transition: '0.3s ease box-shadow, 0.3s ease margin-top',
        borderRadius: 4,
    },
    startContainer: {
        width: 48,
        height: 48,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.05s ease-out box-shadow, 0.05s ease-out margin-top',
    },
    shadow: {
        boxShadow: '0 10px 20px rgba(0,0,0,.1)',
    },
}));

const Node = React.forwardRef(function Node(
    { node, children, config, isSelected, onClick, ...otherProps }: INodeDefaultProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const classes = useStyles();
    const { type } = node;
    if (type === 'start') {
        return (
            <div ref={ref} {...otherProps} className={classes.startContainer}>
                {children}
            </div>
        );
    }
    return (
        <div
            ref={ref}
            onClick={(e) => {
                e.stopPropagation();
                setTimeout(() => onClick(e), 100);
            }}
            {...otherProps}
            className={`${classes.container} ${isSelected && classes.shadow}`}
        >
            {children}
        </div>
    );
});

export default Node;

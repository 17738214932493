import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';
import { List, Typography } from '@material-ui/core';
import { IChart, INode } from '@mrblenny/react-flow-chart';
import { Asset, UpdateFlowNode, WorkflowConditionScheduleType, WorkflowActionScheduleType } from 'types';

import { SEND_EMAIL, EMAIL_OPENED, EMAIL_LINK_CLICKED, CHECK_ATTRIBUTE } from 'common/constants';
import { SendEmailPanel, EmailOpenedPanel, EmailLinkClickedPanel, CheckAttributePanel } from './configPanel';
import SideMenuItem from './sideMenuItem';
import Header from './configPanel/header';

const useStyles = makeStyles(() => ({
    container: {
        height: 'auto',
        minWidth: 314,
        maxWidth: 314,
        background: colors.white,
        borderLeft: `1px solid ${colors.grey}`,
        paddingBottom: 38,
    },
}));

const SectionTitle = ({
    type,
    title,
    subtitle,
    style,
}: {
    type: string;
    title: string;
    subtitle: string;
    style?: any;
}) => {
    const themeColor = type === 'action' ? colors.green : colors.yellow;
    return (
        <div style={{ marginBottom: 16, ...style }}>
            <Typography style={{ color: themeColor, fontSize: 14, lineHeight: '21px', fontWeight: 500 }}>
                {title}
            </Typography>
            <Typography style={{ color: colors.darkGrey, fontSize: 14 }}>{subtitle}</Typography>
        </div>
    );
};

interface OpenPanelProps {
    node: INode;
    campaignId: string;
    assets: Asset[];
    availableAssets: Asset[];
    updateWorkflowNode: UpdateFlowNode;
    unselectNode: (id: string) => void;
    usedAssets: { [key: string]: Asset };
    setHasDraftChanges: (value: boolean) => void;
}

const OpenPanel = ({
    node,
    campaignId,
    availableAssets,
    usedAssets,
    assets,
    unselectNode,
    updateWorkflowNode,
    setHasDraftChanges,
}: OpenPanelProps) => {
    const { properties } = node;
    useEffect(() => {
        return () => unselectNode(node.id);
    }, []);
    switch (properties.subtype) {
        case SEND_EMAIL:
            return (
                <SendEmailPanel
                    campaignId={campaignId}
                    assets={properties.assetId ? [...availableAssets, usedAssets[properties.assetId]] : availableAssets}
                    node={node}
                    updateWorkflowNode={updateWorkflowNode}
                    setHasDraftChanges={setHasDraftChanges}
                />
            );
        case EMAIL_OPENED:
            return (
                <EmailOpenedPanel
                    assets={assets}
                    node={node}
                    updateWorkflowNode={updateWorkflowNode}
                    campaignId={campaignId}
                    setHasDraftChanges={setHasDraftChanges}
                />
            );
        case EMAIL_LINK_CLICKED:
            return <EmailLinkClickedPanel />;
        case CHECK_ATTRIBUTE:
            return (
                <CheckAttributePanel
                    node={node}
                    updateWorkflowNode={updateWorkflowNode}
                    setHasDraftChanges={setHasDraftChanges}
                />
            );
        default:
            return null;
    }
};

const SideMenu = ({
    workflow,
    campaignId,
    assets,
    updateWorkflowNode,
    unselectNode,
    setHasDraftChanges,
}: {
    workflow: IChart;
    assets: Asset[];
    campaignId: string;
    updateWorkflowNode: UpdateFlowNode;
    unselectNode: (id: string) => void;
    setHasDraftChanges: (value: boolean) => void;
}) => {
    const classes = useStyles();
    const { selected, nodes } = workflow;
    const usedAssets: { [key: string]: Asset } = {};
    for (const key of Object.keys(nodes)) {
        if (nodes[key].type === 'action') {
            const assetId = nodes[key].properties.assetId;
            if (assetId) {
                usedAssets[assetId] = assets.find((asset) => asset.id === assetId)!;
            }
        }
    }
    const availableAssets = assets.filter((asset) => !usedAssets[asset.id]);
    return (
        <div className={classes.container}>
            {selected.type === 'node' && selected.id !== 'root' ? (
                <OpenPanel
                    campaignId={campaignId}
                    node={nodes[selected.id!]}
                    assets={assets}
                    availableAssets={availableAssets}
                    usedAssets={usedAssets}
                    updateWorkflowNode={updateWorkflowNode}
                    unselectNode={unselectNode}
                    setHasDraftChanges={setHasDraftChanges}
                />
            ) : (
                <div style={{ overflow: 'auto', height: '100%' }}>
                    <Header
                        title="Set up automated journey"
                        subtitle="Drag and drop actions or rules to the canvas to send an email or build an automated campaign
                            journey"
                    />
                    <List style={{ padding: 24 }}>
                        <SectionTitle type="action" title="ACTIONS" subtitle="Do something" />
                        <SideMenuItem
                            node={{
                                type: 'action',
                                ports: {
                                    input: {
                                        id: 'input',
                                        type: 'input',
                                    },
                                },
                                properties: {
                                    icon: 'email',
                                    name: 'Send email',
                                    subtype: SEND_EMAIL,
                                    assetId: '',
                                    scheduleType: WorkflowActionScheduleType.IMMEDIATELY,
                                    time: '2020-03-24T00:00:16.690Z',
                                    isNew: true,
                                    dayOfWeek: '2',
                                    hours: 0,
                                    minutes: 0,
                                    daysToWait: '',
                                },
                            }}
                        />
                        <SectionTitle
                            type="condition"
                            title="RULES"
                            subtitle="Check for something"
                            style={{ marginTop: 24 }}
                        />
                        <SideMenuItem
                            node={{
                                type: 'condition',
                                ports: {
                                    input: {
                                        id: 'input',
                                        type: 'input',
                                    },
                                },
                                properties: {
                                    icon: 'emailOpened',
                                    name: 'Check email opened',
                                    subtype: EMAIL_OPENED,
                                    isNew: true,
                                    scheduleType: WorkflowConditionScheduleType.WAIT,
                                    daysToWait: '',
                                    daysInPeriod: '',
                                },
                            }}
                        />
                        <SideMenuItem
                            node={{
                                type: 'condition',
                                ports: {
                                    input: {
                                        id: 'input',
                                        type: 'input',
                                    },
                                },
                                properties: {
                                    icon: 'attribute',
                                    name: 'Check attribute',
                                    subtype: CHECK_ATTRIBUTE,
                                    attribute: '',
                                    isNew: true,
                                    scheduleType: WorkflowConditionScheduleType.IMMEDIATELY,
                                    daysToWait: '',
                                    daysInPeriod: '',
                                },
                            }}
                        />
                    </List>
                </div>
            )}
        </div>
    );
};

export default SideMenu;

import React, { ChangeEvent, useState, useEffect, forwardRef } from 'react';
import Header from './header';
import { UpdateFlowNode, Audience, UserAttributeBackend, WorflowConditionRuleType } from 'types';
import {
    FormControl,
    InputLabel,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Checkbox,
    FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { INode } from '@mrblenny/react-flow-chart';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import api from 'api';
import ScheduleTypeRadioForm from './scheduleTypeRadioForm';
import DropdownWithSearch, { DropdownOption } from 'components/dropdownWithSearch';
import colors from 'colors';

const useStyles = makeStyles(() => ({
    formGroup: {
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginBottom: 12,
        },
    },
}));

type CheckAttributeProps = {
    node: INode;
    updateWorkflowNode: UpdateFlowNode;
    audiencesTargeted: Audience[];
    campaignId: string | null;
    setHasDraftChanges: (value: boolean) => void;
};

const CheckAttribute = forwardRef<HTMLDivElement, CheckAttributeProps>(function CheckAttribute(
    { node, updateWorkflowNode, audiencesTargeted, campaignId, setHasDraftChanges }: CheckAttributeProps,
    ref,
) {
    const classes = useStyles();
    const createPort = (value: string) => ({
        id: value,
        type: 'output',
        properties: {
            value,
        },
    });
    const [attributeVals, setAttributeVals] = useState<string[]>([]);
    const [attributeArray, setAttributeArray] = useState<DropdownOption[]>([]);
    const [attributesAudienceId, setAttributesAudienceId] = useState<string>('');
    const isAudiencesTargeted = !(
        !audiencesTargeted.length ||
        (audiencesTargeted[0] !== undefined && audiencesTargeted[0].id === '')
    );
    const [isAttributeExistsInAudience, setIsAttributeExistsInAudience] = useState<boolean>(true);

    const getAudienceName = (audienceId: string) =>
        audiencesTargeted.filter((audience) => audience.id === audienceId)[0].name;

    useEffect(() => {
        if (campaignId && isAudiencesTargeted) {
            api.getCampaignAttributes(campaignId).then((response) => {
                if (response.ok) {
                    const audience = response.data as { [key: string]: UserAttributeBackend[] };
                    const audienceId = Object.keys(audience)[0];
                    setAttributesAudienceId(audienceId);
                    const audienceName = getAudienceName(audienceId);
                    const attributes = audience[audienceId].map((attribute: UserAttributeBackend) => {
                        return {
                            id: attribute.name,
                            name: attribute.displayName,
                            group: audienceName,
                        };
                    });
                    setAttributeArray(attributes as DropdownOption[]);

                    const attributeName = node.properties.attribute as string;
                    const nodeAudienceId = node.properties.audienceId as string;
                    if (attributeName) {
                        const selectedAttribute = audience[audienceId].find(
                            (attribute: UserAttributeBackend) => attribute.name === attributeName,
                        );
                        if (selectedAttribute && selectedAttribute.name !== undefined) {
                            setIsAttributeExistsInAudience(
                                attributes.some(
                                    (attribute: { id?: string }) => attribute.id === selectedAttribute.name,
                                ),
                            );
                        } else if (nodeAudienceId !== audienceId) {
                            setIsAttributeExistsInAudience(false);
                        } else {
                            setIsAttributeExistsInAudience(false);
                        }

                        if (selectedAttribute) setAttributeVals(selectedAttribute.values as string[]);
                    }
                }
            });
        }
    }, [node, campaignId, audiencesTargeted, isAudiencesTargeted]);

    const handleAttributeChange = (selectedAttribute: DropdownOption) => {
        if (selectedAttribute && selectedAttribute.name !== node.properties.attribute) {
            const newNode = { ...node };
            newNode.properties = { ...node.properties };
            newNode.properties.attribute = selectedAttribute.id;
            newNode.properties.attributeDisplayName = selectedAttribute.name;
            newNode.properties.audienceId = attributesAudienceId;
            newNode.ports = { input: node.ports.input };
            updateWorkflowNode(newNode);
            setHasDraftChanges(true);
        }
    };

    const getNoOptionsText = () => {
        if (!isAudiencesTargeted) return 'Audience not selected';
        if (attributeArray.length === 0) return 'No attributes available';
    };

    return (
        <div ref={ref} style={{ overflow: 'auto', height: '100%' }}>
            <Header
                title="Check attribute"
                subtitle="Indicate what citizen attributes to check for and when to check"
            />
            <div style={{ padding: 24 }}>
                <InputLabel style={{ marginBottom: 4 }}>Attribute name</InputLabel>
                <DropdownWithSearch
                    selectType="standard"
                    options={attributeArray}
                    buttonText={
                        node.properties.attribute ? (
                            <span>{node.properties.attributeDisplayName}</span>
                        ) : (
                            <span style={{ color: colors.coolGrey }}>Select attribute</span>
                        )
                    }
                    handleOptionChange={handleAttributeChange}
                    isOptionSelected={() => false}
                    noOptionsText={getNoOptionsText()}
                />
                {!isAttributeExistsInAudience && node.properties.attribute !== '' && (
                    <FormHelperText error>This attribute cannot be found in the selected audience</FormHelperText>
                )}
                {!!attributeVals.length && (
                    <FormControl component="fieldset" style={{ width: '100%', marginTop: 32 }}>
                        <FormLabel component="legend" style={{ marginBottom: 16 }}>
                            What to check for?
                        </FormLabel>
                        <FormGroup classes={{ root: classes.formGroup }}>
                            {attributeVals.map((val: string) => {
                                return (
                                    <FormControlLabel
                                        key={val}
                                        control={
                                            <Checkbox
                                                size="small"
                                                color="primary"
                                                checked={!!node.ports[val]}
                                                onChange={(event: ChangeEvent<any>) => {
                                                    const value = event.target.value;
                                                    if (node.ports[value]) {
                                                        const newNode = { ...node };
                                                        newNode.properties = { ...node.properties };
                                                        newNode.ports = {
                                                            ...node.ports,
                                                        };
                                                        delete newNode.ports[value];
                                                        const hasOutputPort = Object.entries(newNode.ports).some(
                                                            (port) =>
                                                                port[1].type === 'output' &&
                                                                port[1].properties.value !== 'else', // TODO: Update with special value
                                                        );
                                                        if (!hasOutputPort) {
                                                            delete newNode.ports.else;
                                                        }
                                                        delete newNode.ports[value];
                                                        updateWorkflowNode(newNode);
                                                        setHasDraftChanges(true);
                                                    } else {
                                                        const newNode = { ...node };
                                                        newNode.properties = { ...node.properties };
                                                        newNode.ports = {
                                                            ...node.ports,
                                                            [value]: createPort(value),
                                                            else: createPort('else'), // TODO: Update with special value
                                                        };
                                                        updateWorkflowNode(newNode);
                                                        setHasDraftChanges(true);
                                                    }
                                                }}
                                                value={val}
                                            />
                                        }
                                        label={val}
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                )}
                <ScheduleTypeRadioForm
                    node={node}
                    updateWorkflowNode={updateWorkflowNode}
                    ruleType={WorflowConditionRuleType.ATTRIBUTE}
                    setHasDraftChanges={setHasDraftChanges}
                />
            </div>
        </div>
    );
});

const mapStateToProps = (state: RootState) => ({
    audiencesTargeted: state.campaignSlice.draftCampaign.audiences,
    campaignId: state.campaignSlice.draftCampaign.id,
});

export default connect(mapStateToProps)(CheckAttribute);

import store from './../store';
import { ApiResponse, create } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import {
    CampaignBackend,
    TeamPayload,
    SendTestEmailsPayload,
    UserAttributeBackend,
    CampaignStartPayload,
    CampaignApplyPayload,
    EApiIssue,
    AdminRightsPayload,
    AudienceContactsQueryParams,
    BackendErrorMessage,
    TrackingEvent,
} from 'types';
import { User, updateApiIssue } from 'reducers/userSlice';
import { toggleSnackbar } from 'reducers/appSlice';
import { getAuthToken, setAuthToken, removeCookies, isSignedIn } from 'auth';

// Dashboard Web API
const dashboardApi = create({
    baseURL: process.env.REACT_APP_API_END_POINT || 'http://localhost:8080',
    headers: {
        Accept: 'application/json',
    },
});

// Account API
const accountApi = create({
    baseURL: process.env.REACT_APP_ACCOUNT_API_END_POINT || 'http://localhost:8081',
    headers: {
        Accept: 'application/json',
    },
});

const NO_AUTH_URLS = [
    '/job/categories',
    '/job/titles',
    '/login',
    '/organisations',
    '/user/authorisationEndpoint',
    '/user/otp',
    '/user/resetPassword',
    '/user/signup',
    '/user/logoutEndpoint',
];

[accountApi, dashboardApi].forEach((api) => {
    api.addRequestTransform((request) => {
        if (request && request?.url && NO_AUTH_URLS.indexOf(request.url) >= 0) {
            api.axiosInstance.defaults.withCredentials = false;
            request.headers.Authorization = null;
        } else {
            api.axiosInstance.defaults.withCredentials = true;
            request.headers.Authorization = getAuthToken();
        }
    });
});

[accountApi, dashboardApi].forEach((it) => {
    it.addResponseTransform((response) => {
        if (response.status === 401 && isSignedIn()) {
            store.dispatch(updateApiIssue(EApiIssue.UNAUTHORIZED));
        }
        if (response.status === 403) {
            store.dispatch(
                toggleSnackbar({
                    message: 'You are not authorised to perform this action or view this resource',
                    type: 'error',
                }),
            );
        }
        if (response.status === 500) {
            store.dispatch(updateApiIssue(EApiIssue.SERVER_500));
        }
    });
});

function apiPost<Type>(
    url: string,
    data?: any,
    axiosConfig?: AxiosRequestConfig | undefined,
): Promise<ApiResponse<Type, BackendErrorMessage>> {
    return dashboardApi.post(url, data, axiosConfig);
}

const setHeaders = (headers: any) => {
    [accountApi, dashboardApi].forEach((it) => {
        it.setHeaders(headers);
    });
};

const getOrganisations = () => {
    return accountApi.get('/organisations');
};

const getJobTitles = () => {
    return accountApi.get('/job/titles');
};

const getJobCategories = () => {
    return accountApi.get('/job/categories');
};

const requestForOtp = (data: { email: string; password: string }) => {
    return accountApi.post('/user/otp', data);
};

const signIn = (data: { type: string; value: any }) => {
    return accountApi.post('/login', data);
};

const signOut = () => {
    return accountApi.post('/logout');
};

const getUser = () => {
    return accountApi.get('/user');
};

const getNotifications = () => {
    return accountApi.get(`/notifications`);
};

const dismissNotifications = (topic: string) => {
    return accountApi.delete(`/notifications/${topic}`);
};

const updateUser = (data: User) => {
    return accountApi.put('/user', data);
};

const renewToken = () => {
    return accountApi.post('/user/renewToken').then((response) => {
        if (response.ok) {
            const { authorization } = response.headers as any;
            if (authorization) {
                setHeaders({ Authorization: authorization });
                setAuthToken(authorization);
            } else {
                removeCookies();
            }
            return !!authorization;
        }
    });
};

const getTechPassUrl = () => {
    return accountApi.get('/user/authorizationEndpoint');
};

const getTechPassLogoutUrl = () => {
    return accountApi.get('/user/logoutEndpoint');
};

const getResetPasswordLink = (data: { email: string }) => {
    return accountApi.post('/user/resetPassword', data);
};

const updatePassword = (data: { resetPasswordToken: string; newPassword: string }) => {
    return accountApi.post('/user/updatePassword', data);
};

const uploadCampaignUnlayerAsset = (campaignId: string, assetId: string, data: FormData) => {
    return dashboardApi.post(`/campaigns/${campaignId}/assets/${assetId}/unlayer`, data);
};

const uploadCampaignAsset = (campaignId: string, assetId: string, data: FormData) => {
    return dashboardApi.post(`/campaigns/${campaignId}/assets/${assetId}`, data);
};

const uploadAttachments = (data: FormData, campaignId: string, assetId: string) => {
    return dashboardApi.post(`/campaigns/${campaignId}/assets/${assetId}/attachments`, data);
};

const deleteAttachment = (campaignId: string, assetId: string, fileName: string) => {
    return dashboardApi.delete(`/campaigns/${campaignId}/assets/${assetId}/attachments/${fileName}`);
};

const getUnlayerTemplateJson = (campaignId: string, assetId: string) => {
    return dashboardApi.get(`/campaigns/${campaignId}/assets/${assetId}/templates`);
};

const uploadImages = (campaignId: string, assetId: string, data: FormData) => {
    return apiPost<string[]>(`/campaigns/${campaignId}/assets/${assetId}/images`, data);
};

const duplicateAssets = (campaignId: string, assetId: string) => {
    return dashboardApi.post(`/campaigns/${campaignId}/assets/${assetId}/duplicate`);
};

const deleteAsset = (campaignId: string, assetId: string) => {
    return dashboardApi.delete(`/campaigns/${campaignId}/assets/${assetId}`);
};

const saveCampaign = (campaign: CampaignBackend) => {
    return dashboardApi.post(`/campaigns`, campaign);
};

const deleteCampaign = (id: string) => {
    return dashboardApi.delete(`/campaigns/${id}`);
};

const duplicateCampaign = (campaign: CampaignBackend) => {
    return dashboardApi.post(`/campaigns/duplicate`, campaign);
};

const applyChanges = (campaignId: string, campaign: CampaignApplyPayload) => {
    return dashboardApi.post(`/campaigns/${campaignId}/apply`, campaign);
};

const startCampaign = (campaignId: string, campaignStart: CampaignStartPayload) => {
    return dashboardApi.post(`/campaigns/${campaignId}/start`, campaignStart);
};

const stopCampaign = (campaignId: string) => {
    return dashboardApi.post(`/campaigns/${campaignId}/stop`);
};

const getCampaignById = (id: string) => {
    return dashboardApi.get(`/campaigns/${id}`);
};

const sendTestEmail = (id: string, payload: SendTestEmailsPayload) => {
    return dashboardApi.post(`/campaigns/${id}/sendTestEmails`, payload);
};

const getPersonalCampaigns = () => {
    return dashboardApi.get(`/campaigns`);
};

const getTeamCampaigns = (teamId: string) => {
    return dashboardApi.get(`/campaigns/teams/${teamId}`);
};

const getCampaignAttributes = (campaignId: string) => {
    return dashboardApi.get(`/campaigns/${campaignId}/attributes`);
};

const getCampaignMetrics = (campaignId: string) => {
    return dashboardApi.get(`/campaigns/${campaignId}/metrics`);
};

// Teams
const getAllTeams = () => {
    return accountApi.get(`/teams`);
};

const createTeam = (payload: TeamPayload) => {
    return accountApi.post(`/teams`, payload);
};

const addTeamMembers = (id: string, payload: string[]) => {
    return accountApi.post(`/teams/${id}/members`, payload);
};

const updateTeamName = (id: string, name: string) => {
    return accountApi.put(`/teams/${id}`, { name });
};

const deleteTeam = (id: string) => {
    return accountApi.delete(`/teams/${id}`);
};

const updateTeamUserRights = (teamId: string, payload: AdminRightsPayload) => {
    return accountApi.put(`/teams/${teamId}/role`, payload);
};

const getUserRole = (teamId: string) => {
    return accountApi.get(`/teams/${teamId}/role`);
};

const removeTeamUser = (teamId: string, payload: string[]) => {
    return accountApi.delete(`/teams/${teamId}/members`, {}, { data: payload });
};

// Audience

const getPersonalAudiences = () => {
    return dashboardApi.get(`/audiences`);
};

const getTeamAudiences = (teamId: string) => {
    return dashboardApi.get(`/audiences/teams/${teamId}`);
};

const addAudience = (payload: { createdBy: User; name: string; teamId?: string }) => {
    return dashboardApi.post(`/audiences`, payload);
};

const editAudience = (payload: { id: string; name: string }) => {
    return dashboardApi.post(`/audiences`, payload);
};

const getImportSummary = (audienceId: string) => {
    return dashboardApi.get(`/audiences/${audienceId}/import-summary`);
};

const getAttributes = (audienceId: string) => {
    return dashboardApi.get(`/audiences/${audienceId}/attributes`);
};

const deleteAttribute = (audienceId: string, attributeId: string) => {
    return dashboardApi.delete(`/audiences/${audienceId}/attributes/${attributeId}`);
};

const addAttribute = (audienceId: string, payload: UserAttributeBackend) => {
    return dashboardApi.post(`/audiences/${audienceId}/attributes`, payload);
};

const updateAttribute = (audienceId: string, payload: UserAttributeBackend) => {
    return dashboardApi.put(`/audiences/${audienceId}/attributes`, payload);
};

const getAudience = (audienceId: string) => {
    return dashboardApi.get(`/audiences/${audienceId}`);
};

const getAudienceConflicts = (audienceId: string) => {
    return dashboardApi.get(`/audiences/${audienceId}/conflicts`);
};

const getAudienceIssuesFile = (audienceId: string) => {
    return dashboardApi.get(`/audiences/${audienceId}/issues-csv`);
};

const addAudienceContacts = (audienceId: string, data: FormData) => {
    return dashboardApi.post(`/audiences/${audienceId}/contacts`, data);
};

const deleteAudienceContacts = (audienceId: string, contacts: string[]) => {
    return dashboardApi.delete(`/audiences/${audienceId}/contacts`, {}, { data: contacts });
};

const getAudienceContacts = (audienceId: string, params?: AudienceContactsQueryParams) => {
    let queryString;
    if (params)
        queryString = Object.keys(params)
            .map((key) => {
                return key + '=' + params[key as keyof AudienceContactsQueryParams];
            })
            .join('&');
    return dashboardApi.get(`/audiences/${audienceId}/contacts?${queryString}`);
};

const deleteAudience = (audienceId: string) => {
    return dashboardApi.delete(`/audiences/${audienceId}`);
};

const getCampaignAssetMetrics = (campaignId: string, assetId: string) => {
    return dashboardApi.get(`/campaigns/${campaignId}/assets/${assetId}/metrics`);
};

const trackingApis = {
    postEvent: (trackingEvent: TrackingEvent) => apiPost(`/track`, trackingEvent),
};

export default {
    getOrganisations,
    getJobTitles,
    getJobCategories,
    requestForOtp,
    getTechPassLogoutUrl,
    getTechPassUrl,
    signIn,
    signOut,
    getUser,
    getNotifications,
    dismissNotifications,
    updateUser,
    setHeaders,
    renewToken,
    getResetPasswordLink,
    updatePassword,
    uploadCampaignUnlayerAsset,
    uploadCampaignAsset,
    uploadAttachments,
    deleteAttachment,
    getUnlayerTemplateJson,
    uploadImages,
    duplicateAssets,
    deleteAsset,
    saveCampaign,
    deleteCampaign,
    duplicateCampaign,
    applyChanges,
    getCampaignById,
    getPersonalCampaigns,
    getTeamCampaigns,
    startCampaign,
    stopCampaign,
    getCampaignAttributes,
    getCampaignMetrics,
    getAllTeams,
    createTeam,
    addTeamMembers,
    updateTeamName,
    deleteTeam,
    updateTeamUserRights,
    removeTeamUser,
    sendTestEmail,
    getPersonalAudiences,
    getTeamAudiences,
    // getOrganisationAudiences,
    editAudience,
    addAudience,
    getImportSummary,
    getAttributes,
    addAttribute,
    updateAttribute,
    deleteAttribute,
    getAudience,
    getAudienceConflicts,
    getAudienceIssuesFile,
    addAudienceContacts,
    deleteAudienceContacts,
    getAudienceContacts,
    deleteAudience,
    getCampaignAssetMetrics,
    getUserRole,
    ...trackingApis,
};

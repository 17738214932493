import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Box, TextField, Divider, Button, Checkbox, ListSubheader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import { Autocomplete, AutocompleteRenderGroupParams } from '@material-ui/lab';

import { RootState } from 'reducers';
import api from 'api';
import { Asset, SendTestEmailsPayload } from 'types';
import { getEmailInputFieldError } from 'utils/email';
import { pluralise } from 'utils/pluralise';
import BaseModal from 'components/baseModal';
import colors from 'colors';
import CustomChip from 'components/customChip';
import AlertDialog from 'components/alertDialog';
import AutocompleteMultiple from 'components/autocompleteMultiple';
import { ApiErrorResponse } from 'apisauce';

interface TestModalProps {
    open: boolean;
    handleClose: () => void;
    assets: Asset[];
    id: string | null;
}

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 500,
    },
    content: {
        fontSize: 14,
        color: colors.dark,
        marginTop: 8,
    },
    outlinedInputRoot: {
        minHeight: '48px',
    },
    listbox: {
        padding: '16px 0',
    },
    paper: {
        marginTop: 8,
        border: `1px solid ${colors.grey}`,
    },
    option: {
        padding: '8px 14px',
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        color: colors.blue,
        right: 4,
    },
    autocompleteInputRoot: {
        minHeight: `48px !important`,
        '&&[class*="MuiOutlinedInput-root"]': {
            padding: '4px 12px',
        },
        '&&[class*="MuiOutlinedInput-root"] $input': {
            padding: '8px 4px !important',
        },
    },
    noOptions: {
        color: colors.darkGrey,
        fontSize: 14,
        lineHeight: '21px',
    },
}));

interface AlertMessage {
    title: string;
    content: string;
    success: boolean;
}

const TestModal = ({ open, handleClose, assets, id }: TestModalProps) => {
    const classes = useStyles();
    const [emails, setEmails] = useState<string[]>([]);
    const [templates, setTemplates] = useState<Asset[]>([]);
    const [showAssetError, setShowAssetError] = useState<boolean>(false);
    const [assetErrorHelperText, setAssetErrorHelperText] = useState<string>('');
    const [showEmailError, setShowEmailError] = useState<boolean>(false);
    const [emailErrorHelperText, setEmailErrorHelperText] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);
    const [sendEmail, setSendEmail] = useState<boolean>(false);
    const [sendEmailPayload, setSendEmailPayload] = useState<SendTestEmailsPayload>({ assetIds: [], emailIds: [] });

    useEffect(() => {
        if (sendEmail && id) {
            setSendEmail(false);
            api.sendTestEmail(id, sendEmailPayload).then((response) => {
                if (response.ok) {
                    setEmails([]);
                    setTemplates([]);
                    setAlertMessage({
                        title: 'Test emails successfully sent',
                        content: `A test version of ${templates.length} email ${pluralise(
                            templates.length,
                            'asset',
                        )} has been successfully sent to ${emails.length} test ${pluralise(
                            emails.length,
                            'recipient',
                        )}`,
                        success: true,
                    });
                } else if ((response as ApiErrorResponse<any>).data.message === '400 SPACES_IN_AUDIENCE_ATTRIBUTES') {
                    setAlertMessage({
                        title: 'Test emails not sent',
                        content: `Invalid user attribute(s) found in the selected email assets. Please fix the invalid user attribute(s) first.`,
                        success: false,
                    });
                } else {
                    setAlertMessage({
                        title: 'Test emails not sent',
                        content: `We have encountered an error. Please check your email addresses or try again later`,
                        success: false,
                    });
                }
            });
        }
    }, [id, sendEmail, sendEmailPayload, handleClose, setSendEmail, setAlertMessage]);

    const getMessage = () => {
        if (!emails.length) {
            return 'Add at least one test recipient to send test emails to';
        }
        if (!templates.length) {
            return 'Select at least one email asset to send test versions of';
        }
        return `Send a test version of ${templates.length} email ${templates.length > 1 ? 'assets' : 'asset'} to ${
            emails.length
        } test ${emails.length > 1 ? 'recipients' : 'recipient'}`;
    };
    const renderGroup = (params: AutocompleteRenderGroupParams) => [
        <ListSubheader key={params.key} component="div">
            {params.group}
        </ListSubheader>,
        params.children,
    ];

    const parseInputValueIntoEmails = (newEmailAddress: string) => {
        if (newEmailAddress.length < 1) return;

        const errorMessage = getEmailInputFieldError(newEmailAddress, [emails]);
        if (errorMessage) {
            setShowEmailError(true);
            setEmailErrorHelperText(errorMessage);
        } else {
            setEmailErrorHelperText('');
            setEmails([...emails, newEmailAddress]);
        }
    };

    const isAssetOrEmailEmpty = () => {
        let isEmpty = false;
        if (!templates.length) {
            setShowAssetError(true);
            setAssetErrorHelperText('Select at least one email asset');
            isEmpty = true;
        }
        if (!emails.length) {
            setShowEmailError(true);
            setEmailErrorHelperText('Enter at least one test email address');
            isEmpty = true;
        }
        return isEmpty;
    };

    const handleSendEmails = () => {
        if (emailErrorHelperText || assetErrorHelperText || isAssetOrEmailEmpty()) return false;
        const assets: string[] = [];
        templates.forEach((template) => assets.push(template.id));
        const payload = {
            assetIds: assets,
            emailIds: emails,
        };

        setSendEmail(true);
        setSendEmailPayload(payload);
    };
    return (
        <>
            <BaseModal title="Send test emails" open={open} handleClose={handleClose} maxWidth="md">
                <Box style={{ padding: 32, paddingBottom: 92 }}>
                    <Typography className={classes.title}>Select email assets</Typography>
                    <Typography className={classes.content}>
                        Select email assets to send to yourself or a colleague before your campaign goes live. Test
                        emails are sent from noreply@personalise.gov.sg.
                    </Typography>
                    <Autocomplete
                        classes={{
                            listbox: classes.listbox,
                            paper: classes.paper,
                            option: classes.option,
                            inputRoot: classes.autocompleteInputRoot,
                            noOptions: classes.noOptions,
                            popupIndicator: classes.icon,
                        }}
                        style={{ marginTop: 24 }}
                        multiple
                        value={templates}
                        onChange={(event, value: Asset[]) => {
                            setTemplates(value);
                            setShowAssetError(false);
                            setAssetErrorHelperText('');
                        }}
                        options={assets}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name!}
                        renderGroup={renderGroup}
                        groupBy={() => 'Email assets'}
                        renderTags={(value: Asset[], getTagProps) =>
                            value.map((option: Asset, index: number) => (
                                <CustomChip key={option.id} label={option.name!} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <form autoComplete="off">
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select email assets"
                                    error={showAssetError}
                                    helperText={showAssetError && assetErrorHelperText}
                                />
                            </form>
                        )}
                        renderOption={(option, { selected }) => (
                            <>
                                <Checkbox color="primary" style={{ marginRight: 8 }} checked={selected} size="small" />
                                <Typography variant="body2">{option.name}</Typography>
                            </>
                        )}
                        noOptionsText="No search results"
                        popupIcon={<ArrowIcon />}
                    />
                    <Typography className={classes.title} style={{ marginTop: 32 }}>
                        Input email addresses of test recipients
                    </Typography>
                    <Typography className={classes.content} style={{ marginBottom: 24 }}>
                        Enter the email addresses of one or more recipients you would like to send test emails to
                    </Typography>
                    <AutocompleteMultiple
                        value={emails}
                        onChange={(event, value: unknown) => {
                            setEmails(value as string[]);
                        }}
                        onInputChange={(event, value) => {
                            if (!value) setShowEmailError(false);
                        }}
                        placeholder="Enter email addresses"
                        error={showEmailError}
                        errorHelperText={emailErrorHelperText}
                        onSubmitCallback={parseInputValueIntoEmails}
                    />
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{ padding: '24px 32px' }}>
                    <Typography>{getMessage()}</Typography>
                    <Button variant="contained" color="primary" onClick={handleSendEmails}>
                        Send
                    </Button>
                </Box>
            </BaseModal>
            {alertMessage && (
                <AlertDialog
                    open
                    onClose={handleClose}
                    title={alertMessage.title}
                    content={alertMessage.content}
                    success={alertMessage.success}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    assets: state.campaignSlice.draftCampaign.assets,
    id: state.campaignSlice.draftCampaign.id,
});

export default connect(mapStateToProps)(TestModal);

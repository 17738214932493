import React, { FC, CSSProperties, useState, MouseEvent } from 'react';
import { ApiResponse } from 'apisauce';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { validateEmails, getEmailInputFieldError } from 'utils/email';
import api from 'api';
import {
    Paper,
    Typography,
    Box,
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    TextField,
    Button,
    Divider,
    MenuItem,
    Link,
    Popper,
    Grow,
    ClickAwayListener,
    MenuList,
} from '@material-ui/core';
import { isUserAdmin } from './teamUtils';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { Autocomplete } from '@material-ui/lab';
import AlertDialog from 'components/alertDialog';
import ConfirmModal from 'components/confirmModal';
import colors from 'colors';
import BaseModal from 'components/baseModal';
import CustomChip from 'components/customChip';
import Avatar from 'components/avatar';
import { User } from 'reducers/userSlice';
import { SUPPORT_EMAIL_MSG, SUPPORT_EMAIL } from 'common/constants';
import { pluralise } from 'utils/pluralise';

import { Team, TeamUser } from 'types';

const useStyles = makeStyles((theme: Theme) => ({
    cell: {
        borderBottom: `1px solid ${colors.grey}`,
        padding: '24px 0',
        width: '47%',
    },
    tooltip: {
        padding: '6px 8px',
        background: colors.dark,
    },
    cellRow: {
        '&:last-child > td': {
            borderBottom: 'none',
        },
    },
    paper: {
        padding: '4px 0',
        background: colors.dark,
        color: colors.white,
        position: 'absolute',
        right: 0,
        top: 0,
    },
}));

interface TeamMembersProps {
    style?: CSSProperties;
    selectedTeam: Team;
    updateTeamList: (team: Team) => void;
    updateSelectedTeam: (team: Team) => void;
    selectedUser: User;
    getTeams: (id: string) => void;
}

interface AlertMessage {
    title: string;
    content: string;
}

const TeamMembers = ({
    style,
    selectedTeam,
    updateTeamList,
    updateSelectedTeam,
    selectedUser,
    getTeams,
}: TeamMembersProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [values, setValues] = useState<string[]>([]);
    const [showError, setShowError] = useState<boolean>(false);
    const [errorHelperText, setErrorHelperText] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedTeamUser, setSelectedTeamUser] = useState<User>({});
    const [openRemoveUser, setOpenRemoveUser] = React.useState<boolean>(false);
    const { email } = selectedUser || {};

    const { id, users } = selectedTeam || {};

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleServerError = (error: boolean, errorMessage = '') => {
        if (!errorMessage) {
            errorMessage = SUPPORT_EMAIL_MSG;
        }
        setShowError(error);
        setErrorHelperText(error ? errorMessage : '');
    };

    const handleAddMembersClick = () => {
        if (values.length === 0) {
            setShowError(true);
            setErrorHelperText('Enter at least one email address');
            return;
        }

        if (values && values.length && validateEmails(values)) {
            if (!errorHelperText) {
                api.addTeamMembers(id, values).then((response: ApiResponse<any>) => {
                    if (response.ok) {
                        handleServerError(false);
                        updateTeamList(response.data);
                        updateSelectedTeam(response.data);
                    } else {
                        setAlertMessage({
                            title: `${pluralise(values.length, 'User')} not added`,
                            content: `${values.length} ${pluralise(
                                values.length,
                                'user',
                            )} could not be added due to an error. Try again later or contact us at ${SUPPORT_EMAIL} for assistance.`,
                        });
                    }
                    setOpen(false);
                });
            }
        }
    };

    const handleCloseAlert = () => {
        setAlertMessage(null);
    };

    const parseInputValueIntoEmails = (inputElement: HTMLInputElement) => {
        const newEmailAddress = inputElement.value;
        if (newEmailAddress.length < 1) return;

        const selectedTeamUserEmails = selectedTeam.users.map((user) => user.email);
        const errorMessage = getEmailInputFieldError(newEmailAddress, [selectedTeamUserEmails, values], true);
        if (errorMessage) {
            setShowError(true);
            setErrorHelperText(errorMessage);
        } else {
            setShowError(false);
            setErrorHelperText('');
            setValues([...values, newEmailAddress]);
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setShowError(false);
        setErrorHelperText('');
        parseInputValueIntoEmails(event.target as HTMLInputElement);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        setShowError(false);
        setErrorHelperText('');
        switch (event.key) {
            case 'Enter':
            case 'Tab':
            case ';':
            case ',':
            case ' ': {
                event.preventDefault();
                event.stopPropagation();
                parseInputValueIntoEmails(event.target as HTMLInputElement);
                break;
            }
            default:
        }
    };

    const handleClick = (event: MouseEvent<HTMLAnchorElement>, id: string) => {
        setAnchorEl(event.currentTarget as any);
        const user = selectedTeam.users.find((u) => u.id === id);
        if (user && user.id) setSelectedTeamUser(user);
    };
    const removeFromTeam = () => {
        setOpenRemoveUser(true);
    };
    const changeAdminRights = () => {
        if (!selectedTeamUser!.id) return false;
        const payload = {
            id: selectedTeamUser!.id,
            role: isUserAdmin(selectedTeam, selectedTeamUser) ? 'Manager' : 'Team Admin',
        };
        api.updateTeamUserRights(id, payload).then((response: any) => {
            if (response.ok) {
                handleClose();
                getTeams(id);
            }
        });
    };

    return (
        <Paper style={{ width: '100%', display: 'inline-block', marginBottom: 'calc(38px + 32px)', ...style }}>
            <Box
                display="flex"
                style={{ padding: '24px 32px 0', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
            >
                <Typography style={{ margin: 0, fontSize: 20, fontWeight: 500 }}>
                    Team members ({(users || []).length || 0})
                </Typography>
                {isUserAdmin(selectedTeam, selectedUser) && (
                    <Button
                        color="primary"
                        onClick={() => {
                            setValues([]);
                            handleServerError(false);
                            setOpen(true);
                        }}
                        data-testid="add-new-members"
                    >
                        <PersonAddOutlinedIcon style={{ marginRight: 12 }} />
                        Add new members
                    </Button>
                )}
            </Box>
            <Box style={{ borderBottom: `1px solid ${colors.grey}` }}>
                <Box style={{ margin: '0 32px' }}>
                    <Table style={{ fontSize: 14 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.cell}
                                    style={{ color: colors.darkGrey, borderBottom: 'none' }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    style={{ color: colors.darkGrey, borderBottom: 'none' }}
                                >
                                    Role
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    style={{ color: colors.darkGrey, borderBottom: 'none', width: '6%' }}
                                >
                                    &nbsp;
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Box>
            </Box>
            <Box style={{ margin: '0 32px' }}>
                <Table style={{ fontSize: 14 }}>
                    <TableBody>
                        {users &&
                            users.map((user: any, index: number) => {
                                const name =
                                    user.firstName && user.lastName
                                        ? `${user.firstName} ${user.lastName}`
                                        : 'Unregistered user';
                                return (
                                    <TableRow key={index} className={classes.cellRow}>
                                        <TableCell className={classes.cell}>
                                            <Box display="flex" alignItems="center">
                                                <Avatar
                                                    firstName={user.firstName}
                                                    lastName={user.lastName}
                                                    rounded
                                                    style={{ marginRight: 12 }}
                                                />
                                                <div>
                                                    <Typography style={{ fontSize: 16 }}>
                                                        {name} {email === user.email ? ' (You) ' : ''}
                                                    </Typography>
                                                    <Typography style={{ fontSize: 14, color: colors.darkGrey }}>
                                                        {user.email}
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </TableCell>
                                        <TableCell className={classes.cell} style={{ fontSize: 16, paddingTop: 0 }}>
                                            {user.role === 'Team Admin' ? 'Team Admin' : 'Member'}
                                        </TableCell>
                                        <TableCell
                                            className={classes.cell}
                                            style={{
                                                color: colors.darkGrey,
                                                width: '6%',
                                                paddingTop: 0,
                                                textAlign: 'right',
                                            }}
                                        >
                                            {email !== user.email && isUserAdmin(selectedTeam, selectedUser) && (
                                                <Link
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                                        e.stopPropagation();
                                                        handleClick(e, user.id);
                                                    }}
                                                >
                                                    <MoreIcon />
                                                </Link>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Box>
            <BaseModal title="Add new members" open={open} handleClose={() => setOpen(false)} maxWidth="lg">
                <Box p={4} width={704} height={419}>
                    <Typography>Enter the email addresses of the people you wish to add</Typography>
                    <Autocomplete
                        style={{ marginTop: 24 }}
                        value={values}
                        onChange={(event, value: string[]) => {
                            setValues(value);
                        }}
                        renderTags={(value: string[], getTagProps) => {
                            return value.map((option: string, index: number) => (
                                <CustomChip key={option} label={option} {...getTagProps({ index })} />
                            ));
                        }}
                        multiple
                        freeSolo
                        options={[]}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'data-testid': 'emails-field' }}
                                    variant="outlined"
                                    placeholder="Enter email addresses"
                                    error={showError}
                                    helperText={errorHelperText}
                                    onKeyDown={handleKeyDown}
                                    onBlur={handleOnBlur}
                                />
                            );
                        }}
                    />
                </Box>
                <Divider />
                <Box style={{ padding: 24, display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button variant="contained" color="primary" onClick={handleAddMembersClick} data-testid="submit">
                        Add members
                    </Button>
                </Box>
            </BaseModal>
            {alertMessage && (
                <AlertDialog
                    open
                    onClose={handleCloseAlert}
                    title={alertMessage.title}
                    content={alertMessage.content}
                />
            )}
            <ConfirmModal
                open={openRemoveUser}
                title="Remove team member"
                content="The team member will no longer have access to all team campaigns and audiences including those he or she owns"
                onClose={() => {
                    setOpenRemoveUser(false);
                }}
                textNo={'Cancel'}
                textYes={'Remove'}
                confirm={() => {
                    if (!selectedTeamUser!.id) return false;
                    const paylaod = [selectedTeamUser!.id];
                    api.removeTeamUser(id, paylaod).then((response: any) => {
                        if (response.ok) {
                            setOpenRemoveUser(false);
                            getTeams(id);
                        }
                    });
                }}
            />
            <Popper
                style={{ zIndex: 999 }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
                placement="top-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper className={classes.paper} style={{ transformOrigin: 'right top' }} elevation={2}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList style={{ padding: 0 }}>
                                    <MenuItem onClick={removeFromTeam}>Remove from team</MenuItem>
                                    <MenuItem onClick={changeAdminRights}>
                                        {isUserAdmin(selectedTeam, selectedTeamUser)
                                            ? 'Remove admin rights'
                                            : 'Make team admin'}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Paper>
    );
};

export default TeamMembers;

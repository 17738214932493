import React, { useRef, useState, useEffect, MouseEvent, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Popper,
    ClickAwayListener,
    TextField,
    InputAdornment,
    Box,
    Typography,
    Button,
    Divider,
    Grid,
    MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/ClearRounded';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import colors from 'colors';

export interface DropdownOption {
    group: string;
    name: string;
    id: string;
}

interface AdditionalOption {
    name: string;
    onClick: () => void;
    buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary';
}

interface DropdownWithSearchProps {
    options: DropdownOption[];
    searchPlaceholder?: string;
    buttonText: string | ReactNode;
    handleOptionChange: (newOption: DropdownOption, index?: number) => void;
    index?: number;
    isOptionSelected: (option: DropdownOption) => boolean;
    selectType: SelectType;
    additionalOptions?: AdditionalOption[];
    noSearchResultsText?: string;
    noOptionsText?: string;
}

const useStyles = makeStyles(() => ({
    outlinedButton: {
        width: '100%',
        textAlign: 'left',
        padding: '12px 12px 12px 16px',
        color: colors.dark,
        fontWeight: 'normal',
        border: `1px solid ${colors.coolGrey}`,
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
        '& span': {
            width: '100%',
        },
        '& svg': {
            color: colors.blue,
        },
        '&:hover': {
            color: colors.dark,
            background: 'transparent',
        },
    },
    standardButton: {
        width: '100%',
        textAlign: 'left',
        color: colors.dark,
        borderBottom: `1px solid ${colors.coolGrey}`,
        fontWeight: 'normal',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'space-between',
        '& span': {
            width: '100%',
        },
        '& svg': {
            color: colors.blue,
        },
        '&:hover': {
            color: colors.dark,
            background: 'transparent',
            borderBottom: `1px solid ${colors.dark}`,
        },
        paddingBottom: 4,
    },
    disabled: {
        border: `1px solid ${colors.grey}`,
    },
    rotateIcon: {
        transform: 'rotate(180deg)',
    },
    popper: {
        border: `1px solid ${colors.grey}`,
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        zIndex: 1,
        fontSize: 13,
        backgroundColor: colors.white,
        margin: '8px 0',
    },
    inputBase: {
        padding: '8px 24px 12px 24px',
        width: '100%',
        '& input': {
            padding: '8px 0',
            borderBottom: 'transparent',
            fontSize: 16,
            '&::placeholder': { color: colors.coolGrey },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: colors.dark,
        fontSize: 14,
        paddingBottom: 12,
    },
    listbox: {
        maxHeight: 220,
        overflow: 'scroll',
        overflowX: 'hidden',
        padding: 0,
    },
    group: {
        color: colors.dark,
        fontWeight: 500,
        marginRight: 9,
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: '12px 40px !important',
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: colors.lightGrey,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconButton: {
        color: colors.darkGrey,
        cursor: 'pointer',
        '&:hover': {
            color: colors.coolGrey,
        },
    },
    footer: {
        padding: '16px 24px',
    },
    footerItem: {
        padding: 0,
        backgroundColor: colors.white,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

type SelectType = 'standard' | 'outlined';

const DropdownWithSearch = ({
    options,
    searchPlaceholder = 'Search',
    buttonText = 'Select',
    handleOptionChange,
    index,
    isOptionSelected,
    selectType,
    additionalOptions,
    noSearchResultsText = 'No search results',
    noOptionsText,
}: DropdownWithSearchProps) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [pendingValue, setPendingValue] = useState<DropdownOption>({
        name: '',
        group: '',
        id: '',
    });
    const ref = useRef<HTMLInputElement>(null);
    const [buttonWidth, setButtonWidth] = useState<number>(0);
    const [size, setSize] = useState<number>(0);

    useEffect(() => {
        setButtonWidth(ref.current ? ref.current.offsetWidth : 0);
        window.addEventListener('resize', () => setSize(window.innerWidth));
    }, [size]);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const groupBy = (item: DropdownOption) => String(item.group);

    const handleClearSearch = () => {
        setPendingValue({ name: '', group: '', id: '' });
    };

    return (
        <>
            <div ref={ref} style={{ width: 'inherit' }}>
                <Button
                    disableRipple
                    onClick={handleClick}
                    className={selectType === 'outlined' ? classes.outlinedButton : classes.standardButton}
                >
                    {buttonText}
                    <ArrowIcon className={open ? classes.rotateIcon : ''} />
                </Button>
            </div>
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popper}
                onMouseDown={(event) => {
                    event.stopPropagation();
                }}
                style={{ width: buttonWidth }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        {noOptionsText ? (
                            <Box p={3}>
                                <Typography variant="body2" style={{ color: colors.darkGrey }}>
                                    {noOptionsText}
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <Autocomplete
                                    open
                                    classes={{
                                        paper: classes.paper,
                                        listbox: classes.listbox,
                                        option: classes.option,
                                        popperDisablePortal: classes.popperDisablePortal,
                                        groupLabel: classes.group,
                                    }}
                                    onChange={(event, newValue) => {
                                        setPendingValue({ name: '', group: '', id: '' });
                                        if (newValue) {
                                            handleOptionChange(newValue, index);
                                            handleClose();
                                        }
                                    }}
                                    disableCloseOnSelect
                                    disablePortal
                                    noOptionsText={noSearchResultsText}
                                    options={options}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            ref={params.InputProps.ref}
                                            InputProps={{
                                                ...params.inputProps,
                                                endAdornment: (
                                                    <InputAdornment
                                                        className={classes.iconButton}
                                                        position="end"
                                                        onClick={handleClearSearch}
                                                    >
                                                        <ClearIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            autoFocus
                                            className={classes.inputBase}
                                            placeholder={searchPlaceholder}
                                        />
                                    )}
                                    value={pendingValue}
                                    groupBy={groupBy}
                                    getOptionDisabled={(option) => {
                                        return isOptionSelected(option);
                                    }}
                                    getOptionSelected={(option, value) => {
                                        if (value.group === '' && value.name === '' && value.id === '') return true;
                                        return false;
                                    }}
                                />
                            </>
                        )}
                        {additionalOptions && (
                            <div style={{ position: 'sticky' }}>
                                <Divider />
                                <Grid container spacing={2} direction="column" className={classes.footer}>
                                    {additionalOptions.map((option: AdditionalOption) => {
                                        return (
                                            <Grid item key={option.name}>
                                                <MenuItem
                                                    disableRipple
                                                    className={classes.footerItem}
                                                    onClick={() => {
                                                        option.onClick();
                                                        handleClose();
                                                    }}
                                                >
                                                    <Button color={option.buttonColor} size="small">
                                                        {option.name}
                                                    </Button>
                                                </MenuItem>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </div>
                        )}
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

export default DropdownWithSearch;

import React, { ChangeEvent, useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    InputAdornment,
    FormLabel,
    OutlinedInput,
    FormHelperText,
    Box,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import Header from './header';
import { Asset, UpdateFlowNode, WorkflowActionScheduleType, WorkflowScheduleErrorType } from 'types';
import { INode } from '@mrblenny/react-flow-chart';
import colors from 'colors';
import EmailAssetPreview from './emailAssetPreview';
import SelectEmailAsset from './selectEmailAsset';
import { getActionTimeVariableError } from 'features/campaigns/validate';

const useStyles = makeStyles(() => ({
    outlinedInputRoot: {
        padding: '12px 16px',
    },
    paper: {
        marginTop: 8,
        border: `1px solid ${colors.grey}`,
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        '& ul': {
            color: colors.dark,
            background: colors.white,
        },
        '& li': {
            color: colors.dark,
            background: colors.white,
            '&:hover': {
                background: colors.lightGrey,
            },
        },
        '& .Mui-selected': {
            background: 'transparent',
            '&:hover': {
                background: colors.lightGrey,
            },
        },
    },
}));

type SendEmailProps = {
    assets: Asset[];
    campaignId: string;
    node: INode;
    updateWorkflowNode: UpdateFlowNode;
    setHasDraftChanges: (value: boolean) => void;
};

const SendEmail = forwardRef<HTMLDivElement, SendEmailProps>(function SendEmailComponent(
    { assets, campaignId, node, updateWorkflowNode, setHasDraftChanges }: SendEmailProps,
    ref,
) {
    const classes = useStyles();
    const [templateUrl, setTemplateUrl] = useState('');

    const getHelperText = () => {
        switch (getActionTimeVariableError(node, true)) {
            case WorkflowScheduleErrorType.EMPTY_VALUE:
                return 'Indicate number';
            case WorkflowScheduleErrorType.INVALID_FORMAT:
                return 'Enter valid number';
            case WorkflowScheduleErrorType.MIN_VALUE:
                return 'Minimum value is 1';
        }
    };

    useEffect(() => {
        const asset = assets.find((el) => el.id === node.properties.assetId);
        if (asset) {
            setTemplateUrl(
                `${process.env.REACT_APP_CLOUDFRONT_URL}/${campaignId}/assets/${asset.id}/${asset.template}.html`,
            );
        } else {
            setTemplateUrl('');
        }
    });
    return (
        <div ref={ref} style={{ overflow: 'auto', height: '100%' }}>
            <Header title="Send email" subtitle="Select which email to send to your target audience" />
            <div style={{ padding: 24 }}>
                <InputLabel style={{ marginBottom: 4 }}>Email asset</InputLabel>
                <SelectEmailAsset
                    node={node}
                    updateWorkflowNode={updateWorkflowNode}
                    assets={assets}
                    setHasDraftChanges={setHasDraftChanges}
                />
                {templateUrl && <EmailAssetPreview templateUrl={templateUrl} />}
                <FormControl component="fieldset" style={{ marginTop: 32, width: '100%' }}>
                    <FormLabel component="legend" style={{ marginBottom: 16 }}>
                        When to send?
                    </FormLabel>
                    <RadioGroup
                        style={{ width: '100%' }}
                        value={node.properties.scheduleType}
                        onChange={(event: ChangeEvent<any>) => {
                            const newNode = { ...node };
                            newNode.properties = { ...node.properties };
                            newNode.properties.scheduleType = event.target.value;
                            updateWorkflowNode(newNode);
                            setHasDraftChanges(true);
                        }}
                    >
                        <FormControlLabel
                            value={WorkflowActionScheduleType.IMMEDIATELY}
                            control={<Radio size="small" color="primary" />}
                            label="Immediately"
                        />
                        <FormControlLabel
                            value={WorkflowActionScheduleType.WAIT}
                            control={<Radio size="small" color="primary" />}
                            label="Wait"
                            style={{ marginTop: 12 }}
                        />
                        {node.properties.scheduleType === WorkflowActionScheduleType.WAIT && (
                            <>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    style={{ marginTop: 12 }}
                                >
                                    <OutlinedInput
                                        value={node.properties.daysToWait}
                                        style={{ width: 117, height: 48 }}
                                        inputProps={{ style: { padding: '12px 16px' } }}
                                        onChange={(event: ChangeEvent<any>) => {
                                            const newNode = { ...node };
                                            newNode.properties = { ...node.properties };
                                            newNode.properties.daysToWait = event.target.value;
                                            updateWorkflowNode(newNode);
                                            setHasDraftChanges(true);
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        color: !node.properties.daysToWait
                                                            ? colors.coolGrey
                                                            : colors.dark,
                                                        pointerEvents: 'none',
                                                    }}
                                                >
                                                    {'day(s)'}
                                                </span>
                                            </InputAdornment>
                                        }
                                        error={
                                            getActionTimeVariableError(node, true) !==
                                            WorkflowScheduleErrorType.NO_ERROR
                                        }
                                    />
                                    <span>at</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimePicker
                                            value={node.properties.time}
                                            style={{ width: 117 }}
                                            onChange={(date) => {
                                                const newNode = { ...node };
                                                newNode.properties = { ...node.properties };
                                                newNode.properties.time = date;
                                                newNode.properties.minutes = date?.getUTCMinutes();
                                                newNode.properties.hours = date?.getUTCHours();
                                                updateWorkflowNode(newNode);
                                                setHasDraftChanges(true);
                                            }}
                                            inputVariant="outlined"
                                            inputProps={{
                                                style: { padding: '12px 16px' },
                                            }}
                                            InputProps={{ style: { padding: 0, height: 48 } }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                                {getActionTimeVariableError(node, true) !== WorkflowScheduleErrorType.NO_ERROR && (
                                    <FormHelperText error>{getHelperText()}</FormHelperText>
                                )}
                            </>
                        )}
                        <FormControlLabel
                            value={WorkflowActionScheduleType.SPECIFIC_DAY}
                            control={<Radio size="small" color="primary" />}
                            label="Specific day"
                            style={{ marginTop: 12 }}
                        />
                        {node.properties.scheduleType === WorkflowActionScheduleType.SPECIFIC_DAY && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                style={{ marginTop: 12 }}
                            >
                                <FormControl style={{ width: 117 }}>
                                    <Select
                                        MenuProps={{
                                            classes: { paper: classes.paper },
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                            transformOrigin: { vertical: 'top', horizontal: 'center' },
                                            getContentAnchorEl: null,
                                        }}
                                        value={node.properties.dayOfWeek}
                                        onChange={(event: ChangeEvent<any>) => {
                                            if (event.target.value) {
                                                const newNode = { ...node };
                                                newNode.properties = { ...node.properties };
                                                newNode.properties.dayOfWeek = event.target.value;
                                                updateWorkflowNode(newNode);
                                                setHasDraftChanges(true);
                                            }
                                        }}
                                        variant="outlined"
                                        IconComponent={ArrowIcon}
                                    >
                                        <MenuItem value="2">Mon</MenuItem>
                                        <MenuItem value="3">Tue</MenuItem>
                                        <MenuItem value="4">Wed</MenuItem>
                                        <MenuItem value="5">Thu</MenuItem>
                                        <MenuItem value="6">Fri</MenuItem>
                                        <MenuItem value="7">Sat</MenuItem>
                                        <MenuItem value="1">Sun</MenuItem>
                                    </Select>
                                </FormControl>
                                <span>at</span>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                        value={node.properties.time}
                                        style={{ width: 117 }}
                                        onChange={(date) => {
                                            const newNode = { ...node };
                                            newNode.properties = { ...node.properties };
                                            newNode.properties.time = date;
                                            newNode.properties.minutes = date?.getUTCMinutes();
                                            newNode.properties.hours = date?.getUTCHours();
                                            updateWorkflowNode(newNode);
                                            setHasDraftChanges(true);
                                        }}
                                        inputVariant="outlined"
                                        inputProps={{
                                            style: { padding: '12px 16px' },
                                        }}
                                        InputProps={{ style: { padding: 0, height: 48 } }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                        )}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
});

export default SendEmail;

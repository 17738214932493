import React, { CSSProperties, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Alert from '@material-ui/lab/Alert';
import colors from 'colors';
import { Box } from '@material-ui/core';
import { Audience } from 'types';
import { WORDPRESS_USER_GUIDE_URL } from '../common/constants';

const useAlertBarStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > div': {
            padding: '8px 16px 0 px 16px',
            fontSize: 14,
            lineHeight: '21px',
            borderRadius: 4,
            fontWeight: 400,
            overflow: 'hidden',
        },
        '& .error': {
            color: colors.red,
            background: colors.lightestRed,
            border: `1px solid ${colors.red}`,
        },
        '& .info': {
            color: colors.skyBlue100,
            background: colors.skyBlue,
        },
        '& .warning': {
            color: colors.yellow,
            background: colors.lightestRed,
            border: `1px solid ${colors.yellow}`,
        },
        '& .success': {
            color: colors.green,
            background: colors.lightGreen,
            border: `1px solid ${colors.green}`,
        },
    },
}));

interface AlertBarProps {
    message: string | React.ReactNode;
    type: 'success' | 'info' | 'warning' | 'error';
    variant?: 'standard' | 'filled' | 'outlined';
    style?: CSSProperties;
}

const AlertBar = ({ message, type = 'success', variant = 'outlined', style }: AlertBarProps) => {
    const classes = useAlertBarStyles();
    return (
        <div className={classes.root} style={style}>
            <Alert
                iconMapping={{
                    warning: <ErrorOutlineRoundedIcon fontSize="inherit" style={{ color: colors.yellow }} />,
                    success: <CheckCircleOutlineIcon fontSize="inherit" style={{ color: colors.green }} />,
                }}
                severity={type}
                variant={variant}
                className={type}
            >
                {message}
            </Alert>
        </div>
    );
};

const useAssetModalErrorAlertBarStyles = makeStyles((theme) => ({
    errorFoldingButton: {
        fontWeight: 500,
        fontSize: 16,
        margin: '4px 0px',
        cursor: 'pointer',
        width: 90,
    },
}));

interface AssetModalAlertBarProps {
    type: 'error' | 'warning';
    title: string;
    helpLinkLabel?: string;
    helpLinkUrl?: string;
    listHeading: string;
    errors: string[];
}

export const AssetModalAlertBar = ({
    type,
    title,
    listHeading,
    errors,
    helpLinkLabel,
    helpLinkUrl,
}: AssetModalAlertBarProps) => {
    const classes = useAssetModalErrorAlertBarStyles();
    const [showMoreErrors, setShowMoreErrors] = useState(false);
    return (
        <AlertBar
            type={type}
            message={
                <div>
                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <p style={{ margin: 0, fontSize: '16px', fontWeight: 500 }}>{title}</p>
                        {helpLinkLabel && helpLinkUrl && (
                            <a
                                style={{ paddingLeft: 10, color: colors.darkGrey, textDecoration: 'none' }}
                                href={helpLinkUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {helpLinkLabel}
                            </a>
                        )}
                    </Box>
                    <Box>
                        <p style={{ margin: 0 }}>{listHeading}</p>
                        <ul style={{ margin: 0 }}>
                            {errors.map(
                                (error, i) =>
                                    (i < 3 || showMoreErrors) && (
                                        <li key={`AssetModalAlertBar-${type}-${error}`}>
                                            data.
                                            {error.length > 95 ? error.substring(0, 95) + '...' : error}
                                        </li>
                                    ),
                            )}
                        </ul>
                        {errors.length > 3 &&
                            (showMoreErrors ? (
                                <div className={classes.errorFoldingButton} onClick={() => setShowMoreErrors(false)}>
                                    View less
                                </div>
                            ) : (
                                <div className={classes.errorFoldingButton} onClick={() => setShowMoreErrors(true)}>
                                    View more
                                </div>
                            ))}
                    </Box>
                </div>
            }
        />
    );
};

interface AssetModalMismatchedAttributeWarningAlertBarProps {
    campaignAudiences: Audience[];
    errors: string[];
}

export const AssetModalMismatchedAttributeWarningAlertBar = ({
    campaignAudiences,
    errors,
}: AssetModalMismatchedAttributeWarningAlertBarProps) => {
    return (
        <AssetModalAlertBar
            type="warning"
            title="Mismatched user attribute(s) found"
            helpLinkUrl={`${WORDPRESS_USER_GUIDE_URL}/user-guide/troubleshooting#mismatched-user-attributes-found-in-assets`}
            helpLinkLabel="How to fix this?"
            listHeading={`The following user attribute(s) could not be found in ${campaignAudiences
                ?.flatMap((audience) => audience.name)
                .join(',')}:`}
            errors={errors}
        />
    );
};

interface AssetModalInvalidAttributeErrorAlertBarProps {
    errors: string[];
}

export const AssetModalInvalidAttributeErrorAlertBar = ({ errors }: AssetModalInvalidAttributeErrorAlertBarProps) => {
    return (
        <AssetModalAlertBar
            type="error"
            title="Invalid user attribute(s) found"
            listHeading="Remove the white space in the following attribute(s):"
            errors={errors}
        />
    );
};

export default AlertBar;

import { formatISO, add, compareAsc, addSeconds, format as formatDate } from 'date-fns';

const createLocalDateTime = () => {
    return formatISO(new Date()).split('+')[0];
};

const toFormatDate = (date: Date | null, format = 'MM/dd/yyyy') => {
    if (!date) return '';
    return formatDate(date, format);
};

const convertToLocalDateTime = (datetime?: string | null) => {
    if (!datetime) {
        return datetime;
    }
    return formatISO(new Date(datetime)).split('+')[0];
};

const toFormatDateAtTime = (datetime: string | null) => {
    if (!datetime) {
        return datetime;
    }
    const date = new Date(datetime);
    return `${formatDate(date, 'dd MMM yyyy')} at ${formatDate(date, 'h:mm a')}`;
};

const addMinutes = (date: Date, minutes: number) => {
    return add(new Date(date), {
        minutes,
    });
};

const addSecondsToDate = (date: Date, seconds: number) => {
    return addSeconds(new Date(date), seconds);
};

const compareDates = (date1: Date, date2: Date) => {
    const result = compareAsc(new Date(date1), new Date(date2));
    return result < 0 ? false : true;
};

const getTimeLeftFormat = (argSeconds: number) => {
    const minutes = Math.floor(argSeconds / 60); // minutes
    const seconds = argSeconds % 60; // seconds

    const minTitle = minutes > 1 ? 'minutes' : 'minute';
    const secTitle = seconds > 1 ? 'seconds' : 'second';

    if (minutes >= 1) {
        return seconds < 1 ? minutes + ' ' + minTitle : minutes + ' ' + minTitle + ' ' + seconds + ' ' + secTitle;
    } else {
        return seconds + ' ' + secTitle;
    }
};

const roundUpToNextSixHourInterval = (hour: number) => {
    if (hour < 6) {
        return 6;
    } else if (hour >= 6 && hour < 12) {
        return 12;
    } else if (hour >= 12 && hour < 18) {
        return 18;
    } else {
        return 0;
    }
};

/**
 * Round up current hour to the next 3 hour interval
 * @param hour
 * @returns 0,3,6,9,12,15,18,21
 */
const roundUpToNextThreeHourInterval = (hour: number) => {
    return ((Math.ceil(hour / 3) * 3) % 24) + (hour % 3 ? 0 : 3);
};

const getNextCampaignMetricsUpdateTime = (date: string) => {
    const newDate = new Date(date);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setHours(roundUpToNextThreeHourInterval(newDate.getHours()));
    return newDate;
};

export default {
    createLocalDateTime,
    convertToLocalDateTime,
    toFormatDateAtTime,
    addMinutes,
    compareDates,
    addSecondsToDate,
    getTimeLeftFormat,
    toFormatDate,
    getNextCampaignMetricsUpdateTime,
};

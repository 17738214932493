import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from 'colors';

interface FooterProps {
    left?: React.ReactNode;
    right?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
    container: {
        background: colors.lightGrey,
        borderTop: `1px solid ${colors.coolGrey}`,
        padding: '24px 32px',
        position: 'absolute',
        bottom: '33px',
        width: '100%',
    },
}));

const Footer = ({ left, right }: FooterProps) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {left && <div style={{ float: 'left' }}>{left}</div>}
            {right && <div style={{ float: 'right' }}>{right}</div>}
        </div>
    );
};

export default Footer;

import Metrics, { IMetrics } from '../metricsModel';
export interface LinkClicks {
    [key: string]: number;
}
export interface LinkMetrics {
    link: string;
    totalClicks: number;
}
export interface EmailMetrics extends IMetrics {
    name: string;
    id: string;
    inUse: boolean;
    links: LinkClicks;
}
class EmailMetricsModel extends Metrics {
    name: string;
    id: string;
    inUse: boolean;
    links: LinkMetrics[];

    constructor(emailMetrics: EmailMetrics) {
        super(emailMetrics);

        this.name = emailMetrics.name;
        this.id = emailMetrics.id;
        this.inUse = emailMetrics.inUse;
        this.links = Object.entries(emailMetrics.links).map((linkClicks) => {
            return { link: linkClicks[0], totalClicks: linkClicks[1] };
        });
    }
}

export default EmailMetricsModel;

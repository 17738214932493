import { useEffect } from 'react';
import { setAuthToken } from 'auth';
import api from 'api';
import { useHistory } from 'react-router-dom';
import { ApiResponse } from 'apisauce';
import { User } from '../../reducers/userSlice';

const TechPassLoginGuard = () => {
    const history = useHistory();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const code = urlSearchParams.get('code');

        if (!code) history.push('/signin');

        api.signIn({ type: 'OPEN_ID_CONNECT', value: { code } })
            .then((response) => {
                if (!response.ok) throw new Error('Failed to authenticate');

                const { authorization } = response.headers as any;
                api.setHeaders({ Authorization: authorization });
                setAuthToken(authorization);
                return api.getUser();
            })
            .then((response: ApiResponse<any>) => {
                const isMissingAttributes = !(response.data as User).organisation?.id;

                if (isMissingAttributes) {
                    history.push('/signup');
                } else {
                    history.push('/campaigns');
                }
            })
            .catch(() => {
                history.push('/signin');
            });
    }, []);
    return null;
};

export default TechPassLoginGuard;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Box, ButtonBase } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import colors from 'colors';

type AlertDialogProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    content: string;
    success?: boolean;
};

const AlertDialog = ({ open, onClose, title, content, success = false }: AlertDialogProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <ButtonBase onClick={onClose} style={{ position: 'absolute', top: 22, right: 22, color: colors.darkGrey }}>
                <CloseIcon />
            </ButtonBase>
            <Box display="flex" flexDirection="column" alignItems="center" style={{ padding: '40px 56px', width: 538 }}>
                {success ? (
                    <CheckIcon htmlColor={colors.green} style={{ fontSize: 54, margin: 'auto' }} />
                ) : (
                    <ErrorIcon htmlColor={colors.red} style={{ fontSize: 54, margin: 'auto' }} />
                )}
                <DialogTitle style={{ textAlign: 'center', marginTop: 24, marginBottom: 12, padding: 0 }}>
                    {title}
                </DialogTitle>
                <DialogContent style={{ marginBottom: 0, padding: 0 }}>
                    <DialogContentText style={{ textAlign: 'center', marginBottom: 0 }}>{content}</DialogContentText>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

export default AlertDialog;

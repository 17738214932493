import React from 'react';
import { Typography, Button } from '@material-ui/core';

interface AudienceDeleteTipProps {
    title: string;
    onClick: () => void;
}

const DeleteTooltip = ({ title, onClick }: AudienceDeleteTipProps) => {
    return (
        <>
            <Typography variant="body2" style={{ fontSize: 12, lineHeight: '18px' }}>
                {title}
            </Typography>
            <Button
                color="primary"
                component="span"
                style={{ marginTop: 8, fontSize: 12, fontWeight: 500 }}
                onClick={onClick}
            >
                See where it is used
            </Button>
        </>
    );
};

export default DeleteTooltip;

import React from 'react';
import { Typography, Divider, Box } from '@material-ui/core';
type HeaderProps = {
    title: string;
    subtitle: string;
};
const Header = ({ title, subtitle }: HeaderProps) => {
    return (
        <>
            <Box p={3}>
                <Typography color="textPrimary" style={{ fontWeight: 500, marginBottom: 8 }}>
                    {title}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                    {subtitle}
                </Typography>
            </Box>
            <Divider />
        </>
    );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { Box, TextField, InputLabel, Select, MenuItem, FormHelperText, Divider, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import BaseModal from 'components/baseModal';
import CustomTooltip from 'components/customTooltip';
import AutocompleteMultiple from 'components/autocompleteMultiple';

import { AttributeDataType, UserAttributeBackend } from 'types';
import colors from 'colors';
import { validateAttributeName } from '../audienceUtils';

interface CreateNewAttributeModalProps {
    open: boolean;
    handleClose: () => void;
    handleSave: (attribute: UserAttributeBackend) => Promise<any>;
}

const useStyles = makeStyles(() => ({
    menu: {
        border: `1px solid ${colors.grey}`,
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        '& ul': {
            color: colors.dark,
            background: colors.white,
        },
        '& li': {
            color: colors.dark,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        marginTop: 8,
    },
    select: {
        '& #helper': {
            display: 'none',
        },
    },
    menuItem: {
        '&:hover': {
            backgroundColor: `${colors.lightGrey} !important`,
        },
    },
    selected: {
        backgroundColor: 'transparent !important',
    },
    icon: {
        fill: colors.blue,
        right: 13,
    },
}));

const CreateNewAttributeModal = ({ open, handleClose, handleSave }: CreateNewAttributeModalProps) => {
    const classes = useStyles();
    const [name, setName] = useState<string>('');
    const [dataType, setDataType] = useState<AttributeDataType | ''>('');
    const [presetValues, setPresetValues] = useState<string[]>([]);
    const [description, setDescription] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [nameErrorText, setNameErrorText] = useState<string>('');
    const [showDuplicateNameError, setShowDuplicateNameError] = useState<boolean>(false);
    const [showPresetValueError, setShowPresetValueError] = useState<boolean>(false);
    const [presetValuesErrorText, setPresetValuesErrorText] = useState<string>('');
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDataType(event.target.value as AttributeDataType);
    };

    useEffect(() => {
        if (!name) {
            setNameErrorText('Give your attribute a name');
        } else if (!validateAttributeName(name)) {
            setNameErrorText('Only use _ and alphanumerical characters');
        } else {
            setNameErrorText('');
        }
    }, [name]);

    const parseInputValueIntoPresetValues = (newPresetValue: string) => {
        if (newPresetValue.length < 1) {
            return;
        } else if (presetValues.includes(newPresetValue)) {
            setShowPresetValueError(true);
            setPresetValuesErrorText(`${newPresetValue} has been entered`);
            return;
        }
        setPresetValuesErrorText('');
        setPresetValues([...presetValues, newPresetValue]);
    };

    const clearFields = () => {
        setName('');
        setDataType('');
        setPresetValues([]);
        setDescription('');
        setShowError(false);
        setShowPresetValueError(false);
        setPresetValuesErrorText('');
    };
    return (
        <BaseModal
            title="Create new attribute"
            open={open}
            handleClose={() => {
                clearFields();
                handleClose();
            }}
            maxWidth="lg"
        >
            <Box p={4} width={700}>
                <InputLabel>Attribute name</InputLabel>
                <TextField
                    autoFocus
                    variant="outlined"
                    placeholder="Name of attribute"
                    fullWidth
                    value={name}
                    error={(showError && !!nameErrorText) || showDuplicateNameError}
                    helperText={
                        (showError && nameErrorText) ||
                        (showDuplicateNameError &&
                            'An attribute with this name already exists. Please try another name.')
                    }
                    onChange={(e) => {
                        setShowDuplicateNameError(false);
                        setName(e.target.value);
                    }}
                />
                <InputLabel style={{ marginTop: 32 }}>Data type</InputLabel>
                <Select
                    variant="outlined"
                    fullWidth
                    MenuProps={{
                        classes: { paper: classes.menu },
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        transformOrigin: { vertical: 'top', horizontal: 'center' },
                        getContentAnchorEl: null,
                    }}
                    value={dataType}
                    onChange={handleChange}
                    classes={{ select: classes.select }}
                    displayEmpty
                    renderValue={
                        dataType !== ''
                            ? undefined
                            : () => <div style={{ color: colors.coolGrey }}>Select data type</div>
                    }
                    error={showError && !dataType}
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                        },
                    }}
                    IconComponent={ArrowIcon}
                >
                    <MenuItem
                        value={AttributeDataType.TEXT}
                        classes={{ root: classes.menuItem, selected: classes.selected }}
                    >
                        <span>Free text</span>
                        <span id="helper" style={{ color: colors.darkGrey, fontSize: 12, marginTop: 4 }}>
                            For attributes with no pre-set values (e.g. date of birth)
                        </span>
                    </MenuItem>
                    <MenuItem
                        value={AttributeDataType.FIXED_TEXT}
                        classes={{ root: classes.menuItem, selected: classes.selected }}
                    >
                        <span>Fixed values</span>
                        <span id="helper" style={{ color: colors.darkGrey, fontSize: 12, marginTop: 4 }}>
                            For attributes with fixed, pre-set values (e.g. gender)
                        </span>
                    </MenuItem>
                </Select>
                {showError && !dataType && (
                    <FormHelperText error>Indicate the data type of your attribute</FormHelperText>
                )}
                {dataType === AttributeDataType.FIXED_TEXT && (
                    <>
                        <InputLabel style={{ marginTop: 32, display: 'flex', alignItems: 'center' }}>
                            <div>Pre-set values</div>
                            <CustomTooltip
                                title="Pre-set the available data values for this attribute"
                                placement="right"
                                arrow
                                style={{ height: 21, display: 'flex', alignItems: 'center' }}
                                icon="help"
                            />
                        </InputLabel>
                        <AutocompleteMultiple
                            value={presetValues}
                            onChange={(event, value: unknown) => {
                                setPresetValues(value as string[]);
                            }}
                            placeholder={presetValues.length < 1 ? 'Enter pre-set data values' : 'Add more values...'}
                            error={showPresetValueError}
                            errorHelperText={presetValuesErrorText}
                            onSubmitCallback={parseInputValueIntoPresetValues}
                            onInputChange={(event, value) => {
                                if (!value) setShowPresetValueError(false);
                            }}
                        />
                        <FormHelperText
                            style={{ marginTop: 8, marginBottom: 32, fontSize: 14, color: colors.darkGrey }}
                        >
                            Pre-set values are case sensitive and should match your imported data values exactly
                        </FormHelperText>
                    </>
                )}
                <InputLabel style={{ marginTop: 32 }}>Description (optional)</InputLabel>
                <TextField
                    variant="outlined"
                    placeholder="Add a description"
                    fullWidth
                    style={{ marginBottom: 16 }}
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value);
                    }}
                />
            </Box>
            <Divider />
            <Box px={4} py={3} display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setShowError(true);
                        if (dataType === AttributeDataType.FIXED_TEXT && presetValues.length < 1) {
                            setShowPresetValueError(true);
                            setPresetValuesErrorText('Enter at least one pre-set value for this attribute');
                            return;
                        } else if (nameErrorText || !dataType || presetValuesErrorText) {
                            return;
                        }
                        handleSave({ displayName: name, type: dataType, values: presetValues, description })
                            .then((response: boolean) => {
                                if (response) {
                                    clearFields();
                                    setShowDuplicateNameError(false);
                                }
                            })
                            .catch((error: Error) => {
                                if (error.message === 'Conflict') {
                                    setShowDuplicateNameError(true);
                                }
                            })
                            .finally(() => {
                                setShowPresetValueError(false);
                                setShowError(false);
                            });
                    }}
                >
                    Save
                </Button>
            </Box>
        </BaseModal>
    );
};

export default CreateNewAttributeModal;

import React, { FC, CSSProperties, ChangeEvent, useState, useEffect } from 'react';
import { Paper, Typography, Divider, TextField, Box, InputLabel, Button } from '@material-ui/core';
import { User } from 'reducers/userSlice';
import { Team } from 'types';
import { isUserAdmin } from './teamUtils';
import ConfirmModal from 'components/confirmModal';
import CustomTooltip from 'components/customTooltip';
import colors from 'colors';

interface TeamProfileProps {
    style?: CSSProperties;
    setTeamName: (id: string, name: string) => void;
    deleteTeam: (id: string) => void;
    selectedTeam: Team;
    teamName: string;
    user: User;
    isTeamDeletable: boolean;
}

const TeamProfile = ({
    style,
    setTeamName,
    selectedTeam,
    teamName,
    deleteTeam,
    user,
    isTeamDeletable,
}: TeamProfileProps) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        setIsAdmin(isUserAdmin(selectedTeam, user));
    }, [selectedTeam, user]);

    const handleDeleteClick = () => {
        setShowDialog(true);
    };

    const handleDelete = () => {
        deleteTeam(selectedTeam.id);
        setShowDialog(false);
    };

    return (
        <Paper style={{ width: '100%', ...style }}>
            <Typography style={{ width: '100%', fontSize: 20, fontWeight: 500, padding: '24px 32px' }}>
                Team profile
            </Typography>
            <Divider />
            <Box style={{ padding: '24px 32px' }}>
                <InputLabel style={{ marginBottom: 4, color: colors.darkGrey }}>Team name</InputLabel>
                <TextField
                    style={{ minWidth: 266 }}
                    value={teamName}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setTeamName(selectedTeam.id, event.target.value);
                    }}
                    disabled={!isAdmin}
                />
            </Box>
            {isAdmin && (
                <>
                    <Divider />
                    <Box px={4} py={3}>
                        <Typography style={{ fontWeight: 500 }}>Delete team</Typography>
                        <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                            Deleting the team will permanently disband the team
                        </Typography>
                        <Box mt={2} display="flex" alignItems="center">
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={handleDeleteClick}
                                disabled={!isTeamDeletable}
                            >
                                Delete
                            </Button>
                            {!isTeamDeletable && (
                                <CustomTooltip
                                    title="All campaigns and audiences in this team must be deleted first before the team can be deleted"
                                    icon="info"
                                    arrow
                                />
                            )}
                        </Box>
                    </Box>
                    <ConfirmModal
                        open={showDialog}
                        onClose={() => {
                            setShowDialog(false);
                        }}
                        confirm={handleDelete}
                        title={`Delete “${teamName}” team`}
                        content="Deleting the team will permanently disband the team"
                        textNo="Cancel"
                        textYes="Delete"
                        destructive
                    />
                </>
            )}
        </Paper>
    );
};

export default TeamProfile;

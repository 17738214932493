import React from 'react';
import {
    ILinkDefaultProps,
    generateCurvePath,
    generateRightAnglePath,
    generateSmartPath,
} from '@mrblenny/react-flow-chart';
import colors from 'colors';

const Link = ({
    config,
    link,
    startPos,
    endPos,
    fromPort,
    toPort,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onLinkClick,
    isHovered,
    isSelected,
    matrix,
}: ILinkDefaultProps) => {
    const newStartPos =
        fromPort.properties && fromPort.properties.value
            ? {
                  ...startPos,
                  y: startPos.y + 10,
              }
            : startPos;
    const newEndPos =
        toPort && toPort.properties && toPort.properties.value
            ? {
                  ...endPos,
                  y: endPos.y + 10,
              }
            : endPos;
    const points = config.smartRouting
        ? !!toPort && !!matrix
            ? generateSmartPath(matrix, newStartPos, newEndPos, fromPort, toPort)
            : generateRightAnglePath(newStartPos, newEndPos)
        : generateCurvePath(newStartPos, newEndPos);
    return (
        <svg style={{ overflow: 'visible', position: 'absolute', cursor: 'pointer', left: 0, right: 0 }}>
            <circle r="2" cx={newStartPos.x} cy={newStartPos.y} fill={colors.darkGrey} />
            {/* Main line */}
            <path d={points} stroke={colors.darkGrey} strokeWidth="2" fill="none" />
            {/* Thick line to make selection easier */}
            <path
                d={points}
                stroke={colors.darkGrey}
                strokeWidth="20"
                fill="none"
                strokeLinecap="round"
                strokeOpacity={isHovered || isSelected ? 0.1 : 0}
                onMouseEnter={() => onLinkMouseEnter({ config, linkId: link.id })}
                onMouseLeave={() => onLinkMouseLeave({ config, linkId: link.id })}
                onClick={(e) => {
                    onLinkClick({ config, linkId: link.id });
                    e.stopPropagation();
                }}
            />
            <circle r="2" cx={newEndPos.x} cy={newEndPos.y} fill={colors.darkGrey} />
        </svg>
    );
};

export default Link;

import React from 'react';
import Mail from '@material-ui/icons/MailOutline';

const SvgIcon = ({ type }: { type: string }) => {
    switch (type) {
        case 'email':
            return <img src="/icons/EmailSend.svg" alt="send email" />;
        case 'emailOpened':
            return <img src="/icons/EmailOpen.svg" alt="check email opened" />;
        case 'attribute':
            return <img src="/icons/Rules.svg" alt="check attribute" />;
        default:
            return <Mail />;
    }
};

export default SvgIcon;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { User, getUser } from 'reducers/userSlice';
import { RootState } from 'reducers';
import { Box, Button, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import SnackBar from 'components/snackBar';

import colors from 'colors';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        background: colors.darkBlue,
        padding: 24,
        color: colors.white,
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 1,
    },
}));

interface BaseHeaderProps {
    title: string;
    content?: React.ReactNode;
    backButton?: boolean;
    buttonLink?: () => void;
    user: User;
    getUser: () => void;
}

const BaseHeader = ({ title, content, backButton = false, buttonLink, user, getUser }: BaseHeaderProps) => {
    const classes = useStyles();
    const history = useHistory();
    const goBack = () => history.goBack();

    useEffect(() => {
        if (!user || !user.email) {
            getUser();
        }
    }, [getUser, user]);

    return (
        <>
            <Box className={classes.container}>
                <SnackBar />
                {backButton ? (
                    <Button onClick={buttonLink || goBack} style={{ color: colors.white }}>
                        <ArrowRightAltIcon style={{ marginRight: 16 }} />
                        {title}
                    </Button>
                ) : (
                    <Typography style={{ fontWeight: 500 }}>{title}</Typography>
                )}
                {content && <Box>{content}</Box>}
            </Box>
            <div style={{ height: 73, minHeight: 73 }} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.userSlice.user,
});

const mapDispatchToProps = {
    getUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseHeader);

import React, { useState, useEffect } from 'react';
import { Box, Button, Menu, MenuItem, Divider, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import GroupAddIcon from '@material-ui/icons/GroupAddOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ConfirmModal from 'components/confirmModal';
import colors from 'colors';
import { useHistory } from 'react-router-dom';
import { User, getUser, updateDashboardSelected } from 'reducers/userSlice';
import { getTeams, showCreateTeamDialog } from 'features/team/teamSlice';
import { Team, TrackingEvent } from 'types';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { useTracking } from 'react-tracking';
import Avatar from './avatar';
import HeaderTeamsList from './headerTeamsList';
import api from 'api';
import { removeCookies } from 'auth';
import SnackBar from 'components/snackBar';
import { TRACKING, WORDPRESS_USER_GUIDE_URL } from 'common/constants';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        background: colors.darkBlue,
        padding: 20,
        color: colors.white,
        minHeight: 72,
    },
    teamMenu: {
        minWidth: 213,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    userMenu: {
        minWidth: 291,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    list: {
        padding: 0,
    },
}));

interface HeaderProps {
    user: User;
    getUser: () => void;
    teams: Team[];
    selectedTeam: Team;
    getTeams: () => void;
    showCreateTeamDialog: (isShow: boolean) => void;
    updateDashboardSelected: (dashboard: string) => void;
    dashboardSelected: string;
}

const Header = ({
    user,
    getUser,
    teams,
    getTeams,
    updateDashboardSelected,
    showCreateTeamDialog,
    selectedTeam,
    dashboardSelected,
}: HeaderProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [teamAnchorEl, setTeamAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openSignOut, setOpenSignOut] = useState<boolean>(false);
    const { firstName, lastName, email } = user || {};
    const { location } = history;

    const { trackEvent } = useTracking<TrackingEvent>({ components: [TRACKING.COMPONENTS.HEADER] });

    useEffect(() => {
        // if (!user.email || !user.organisation!.id) {
        if (!(user || {}).email) {
            getUser();
        }
    }, [getUser, user]);

    useEffect(() => {
        getTeams();
    }, [getTeams]);

    const handleTeamClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTeamAnchorEl(event.currentTarget);
    };

    const handleTeamClose = () => {
        setTeamAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickTeam = (id: string) => {
        const selectedTeam = teams.find((team) => team.id === id);
        if (selectedTeam) {
            updateDashboardSelected(selectedTeam.id);
            handleTeamClose();
            history.push(
                location.pathname.indexOf('campaigns') >= 0 ? `/campaigns/teams/${id}` : `/audience/teams/${id}`,
            );
        }
    };

    const getDashboardSelectedName = () => {
        if (dashboardSelected === 'personal') {
            return 'Personal';
        }
        const selectedTeam = teams && teams.find((team) => team.id === dashboardSelected);
        if (selectedTeam) {
            return selectedTeam.name;
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box className={classes.container}>
            <SnackBar />
            <Box>
                <Button
                    style={{ padding: 4, color: colors.white }}
                    aria-controls="team-menu"
                    aria-haspopup="true"
                    onClick={handleTeamClick}
                >
                    {getDashboardSelectedName()}
                    <ArrowIcon style={{ marginLeft: 8 }} />
                </Button>
                <Button
                    style={{
                        padding: 4,
                        marginLeft: '2.5em',
                    }}
                    onClick={() => {
                        trackEvent({ action: 'UserGuideClick' });
                        window.open(WORDPRESS_USER_GUIDE_URL, '_blank');
                    }}
                >
                    <a
                        style={{
                            color: colors.white,
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontWeight: 500,
                        }}
                        href={WORDPRESS_USER_GUIDE_URL}
                        onClick={(event) => {
                            event.preventDefault();
                        }}
                    >
                        User Guide
                    </a>
                </Button>
            </Box>
            <Avatar
                firstName={firstName || ''}
                lastName={lastName || ''}
                rounded
                cursor="pointer"
                onClick={handleClick}
                size={30}
                fontSize={14}
            />
            <Menu
                id="user-menu"
                classes={{ paper: classes.userMenu, list: classes.list }}
                anchorEl={anchorEl}
                keepMounted={true}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Box display="flex" alignItems="center" p={2}>
                    <Avatar
                        firstName={firstName || ''}
                        lastName={lastName || ''}
                        rounded
                        style={{ marginRight: 12 }}
                        size={40}
                        fontSize={18}
                    />
                    <div>
                        <Typography style={{ fontWeight: 500 }}>{`${firstName} ${lastName}`}</Typography>
                        <Typography variant="body2" color="textSecondary" style={{ marginTop: 2 }}>
                            {email}
                        </Typography>
                    </div>
                </Box>
                <Divider style={{ backgroundColor: colors.darkGrey }} />
                <Box style={{ padding: '4px 0' }}>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            history.push('/account-settings');
                        }}
                    >
                        <SettingsIcon style={{ marginRight: 12, fontSize: 18 }} />
                        Account settings
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            setOpenSignOut(true);
                        }}
                    >
                        <img src="/icons/Logout.svg" alt="logout" style={{ marginRight: 12 }} />
                        Log out
                    </MenuItem>
                </Box>
            </Menu>
            <Menu
                id="team-menu"
                classes={{ paper: classes.teamMenu, list: classes.list }}
                anchorEl={teamAnchorEl}
                keepMounted={true}
                open={Boolean(teamAnchorEl)}
                onClose={handleTeamClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                getContentAnchorEl={null}
            >
                <Box style={{ padding: '4px 0' }}>
                    <MenuItem
                        onClick={() => {
                            updateDashboardSelected('personal');
                            handleTeamClose();
                            history.push(location.pathname.indexOf('campaigns') >= 0 ? `/campaigns` : `/audience`);
                        }}
                    >
                        <DotIcon
                            style={{
                                color: dashboardSelected === 'personal' ? colors.blue : colors.white,
                                width: 16,
                                marginRight: 16,
                            }}
                        />
                        <span>Personal</span>
                    </MenuItem>
                    <HeaderTeamsList
                        teams={teams}
                        onClickTeam={handleClickTeam}
                        dashboardSelected={dashboardSelected}
                    />
                </Box>
                <Divider style={{ backgroundColor: colors.darkGrey }} />
                <Box style={{ padding: '4px 0' }}>
                    <MenuItem
                        onClick={() => {
                            handleTeamClose();
                            showCreateTeamDialog(true);
                            history.push('/team-settings');
                        }}
                    >
                        <GroupAddIcon style={{ marginRight: 12, fontSize: 18 }} />
                        <span>Create a new team</span>
                    </MenuItem>
                    {teams && teams.length > 0 && (
                        <MenuItem
                            onClick={() => {
                                handleTeamClose();
                                history.push('/team-settings');
                            }}
                            style={{ padding: '12px 16px' }}
                        >
                            <SettingsIcon style={{ marginRight: 12, fontSize: 18 }} />
                            <span>Team settings</span>
                        </MenuItem>
                    )}
                </Box>
            </Menu>
            <ConfirmModal
                open={openSignOut}
                title="Log out"
                content="Are you sure you want to log out?"
                textNo="Cancel"
                textYes="Log out"
                onClose={() => {
                    setOpenSignOut(false);
                }}
                confirm={() => {
                    api.signOut().finally(() => {
                        removeCookies();

                        if (user.isTechPassUser === false) {
                            history.push('/signin');
                            return;
                        }

                        api.getTechPassLogoutUrl().then(({ data }) => {
                            window.open(data as string, '_self');
                        });
                    });
                }}
            />
        </Box>
    );
};
const mapStateToProps = (state: RootState) => ({
    user: state.userSlice.user,
    teams: state.teamSlice.teams,
    selectedTeam: state.teamSlice.selectedTeam,
    dashboardSelected: state.userSlice.dashboardSelected,
});

const mapDispatchToProps = {
    getUser,
    getTeams,
    showCreateTeamDialog,
    updateDashboardSelected,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);

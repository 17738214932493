import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BaseModal from 'components/baseModal';
import { UserAttribute, AttributeDataType } from 'types';

interface AttributeInfoModalProps {
    open: boolean;
    handleClose: () => void;
    attribute: UserAttribute;
}

const useStyles = makeStyles(() => ({
    label: {
        fontSize: 14,
        marginBottom: 12,
        fontWeight: 500,
    },
}));

const AttributeInfoModal = ({ open, handleClose, attribute }: AttributeInfoModalProps) => {
    const classes = useStyles();
    const dataTypeDisplayNameMap = {
        TEXT: 'Free text',
        FIXED_TEXT: 'Fixed values (single)',
    };
    return (
        <BaseModal open={open} title={attribute.name} handleClose={handleClose} maxWidth="lg">
            <Box mx={4} mt={4} mb={3} width={700}>
                <Box mb={4}>
                    <Typography className={classes.label}>Attribute name</Typography>
                    <Typography>{attribute.name}</Typography>
                </Box>
                <Box mb={4}>
                    <Typography className={classes.label}>Data type</Typography>
                    <Typography>{dataTypeDisplayNameMap[attribute.dataType as AttributeDataType]}</Typography>
                </Box>
                {attribute.dataType === AttributeDataType.FIXED_TEXT && (
                    <Box mb={4}>
                        <Typography className={classes.label}>Pre-set values</Typography>
                        <Typography>{attribute.presetValues!.join(', ')}</Typography>
                    </Box>
                )}
                <Box mb={4}>
                    <Typography className={classes.label}>Description</Typography>
                    <Typography>{attribute.description || '-'}</Typography>
                </Box>
            </Box>
        </BaseModal>
    );
};

export default AttributeInfoModal;

import React, { ChangeEvent } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowDropDownRounded';
import { INode } from '@mrblenny/react-flow-chart';
import { Asset, UpdateFlowNode } from 'types';
import colors from 'colors';

interface SelectEmailAssetProps {
    node: INode;
    updateWorkflowNode: UpdateFlowNode;
    assets: Asset[];
    setHasDraftChanges: (value: boolean) => void;
}

const useStyles = makeStyles(() => ({
    menu: {
        marginTop: 8,
        border: `1px solid ${colors.grey}`,
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        background: 'none',
        '& ul': {
            color: colors.dark,
            background: colors.white,
        },
        '& li': {
            color: colors.dark,
            background: colors.white,
            '&:hover': {
                background: colors.lightGrey,
            },
        },
        '& .Mui-selected': {
            background: colors.lightGrey,
            '&:hover': {
                background: colors.lightGrey,
            },
        },
    },
    selected: {
        backgroundColor: 'transparent !important',
    },
}));

const SelectEmailAsset = ({ node, updateWorkflowNode, assets, setHasDraftChanges }: SelectEmailAssetProps) => {
    const classes = useStyles();
    const createPort = (value: string) => ({
        id: value,
        type: 'output',
        properties: {
            value,
        },
    });
    const createPortFromSubtype = (subtype: 'emailOpened' | 'sendEmail'): any => {
        switch (subtype) {
            case 'emailOpened':
                return {
                    opened: createPort('opened'),
                    else: createPort('else'),
                };
            case 'sendEmail':
                return {
                    output: {
                        id: 'output',
                        type: 'output',
                    },
                };
        }
    };
    return (
        <Select
            value={node.properties.assetId}
            displayEmpty
            renderValue={() => {
                if (!node.properties.assetId) {
                    return <span style={{ color: colors.coolGrey }}>Select email</span>;
                } else {
                    if (assets.length) {
                        const asset = assets.find((a) => a.id === node.properties.assetId);
                        return asset!.name;
                    }
                }
            }}
            onChange={(event: ChangeEvent<any>) => {
                if (event.target.value) {
                    const newNode = { ...node };
                    newNode.properties = { ...node.properties };
                    newNode.properties.assetId = event.target.value;
                    if (!newNode.ports.output) {
                        const newPorts = createPortFromSubtype(newNode.properties.subtype);
                        newNode.ports = {
                            ...node.ports,
                            ...newPorts,
                        };
                    }
                    updateWorkflowNode(newNode);
                    setHasDraftChanges(true);
                }
            }}
            style={{ width: '100%' }}
            MenuProps={{
                classes: { paper: classes.menu },
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                getContentAnchorEl: null,
            }}
            IconComponent={ArrowIcon}
        >
            {assets.length ? (
                assets.map((asset) => {
                    const id = asset.id;
                    return (
                        <MenuItem key={id} value={id} classes={{ selected: classes.selected }}>
                            {asset.name}
                        </MenuItem>
                    );
                })
            ) : (
                <MenuItem disabled>No email assets created</MenuItem>
            )}
        </Select>
    );
};

export default SelectEmailAsset;

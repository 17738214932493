import React, { CSSProperties } from 'react';
import { Typography, Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'colors';
import { GenericServerFailureMessage } from './messageContent';
import { SUPPORT_EMAIL_MSG } from 'common/constants';

const useStyles = makeStyles((theme: Theme) => ({
    errorText: {
        marginBottom: 8,
        fontSize: 14,
        lineHeight: '21px',
        textAlign: 'left',
        '& span': {
            textDecoration: 'underline',
        },
    },
    icon: {
        margin: '2px 4px 0 0',
        color: colors.red,
        fontSize: 16,
    },
}));

interface MessageProps {
    message: string;
    messageType?: 'success' | 'info' | 'warning' | 'error';
    className?: string;
    style?: CSSProperties;
}

const Message = ({ message, messageType = 'error', className, style = {} }: MessageProps) => {
    const classes = useStyles();

    const Icon = () => {
        const classes = useStyles();
        switch (messageType) {
            case 'error':
                return <ErrorIcon className={classes.icon} />;
            default:
                return null;
        }
    };
    return (
        <Box display="flex" alignItems="flex-start" className={className || ''} style={{ ...style }}>
            <Icon />
            <Typography className={classes.errorText} color="error">
                {message === SUPPORT_EMAIL_MSG ? <GenericServerFailureMessage /> : message}
            </Typography>
        </Box>
    );
};

export default Message;

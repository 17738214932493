import React, { CSSProperties } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'colors';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: colors.lighterGrey,
    },
    top: {
        animationDuration: '950ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

interface CircularLoaderProps {
    thickness?: number;
    size?: number;
    style?: CSSProperties;
    color?: string;
}

const CircularLoader = ({ style = {}, size = 48, thickness = 4, color = colors.blue }: CircularLoaderProps) => {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{ ...style }}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={size}
                thickness={thickness}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                style={{ color }}
                classes={{
                    circle: classes.circle,
                }}
                size={size}
                thickness={thickness}
            />
        </div>
    );
};

export default CircularLoader;

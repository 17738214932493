import { User } from 'reducers/userSlice';
import { Team, Audience, Campaign } from 'types';

export const isUserAdmin = (selectedTeam: Team, user: User) => {
    if (!selectedTeam || !user) return false;
    const { users } = selectedTeam;
    const userTeamRole = users.find((u) => u.id === user.id)?.role;
    return userTeamRole === 'Team Admin'; // TODO: Create / update role enum when backend has made the changes
};

export const getTeamNameById = (teamId: string, teams: Team[]) => teams.find((team) => team.id === teamId)?.name;

export const isTeamValid = (teamId: string, teams: Team[]) => teams.some((team) => team.id === teamId);

export const teamHasAudienceOrCampaign = (teamAudiences: Audience[], teamCampaigns: Campaign[]) => {
    if (!teamAudiences || !teamCampaigns) return false;
    return teamAudiences.length > 0 || teamCampaigns.length > 0;
};

export const isRoleAllowed: (role: string) => boolean = (role: string) => {
    switch (role) {
        case 'Viewer':
            return false;
        default:
            return true;
    }
};

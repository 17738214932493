import React, { forwardRef, useState, useEffect } from 'react';
import Header from './header';
import { Asset, UpdateFlowNode, WorflowConditionRuleType, WorkflowConditionScheduleType } from 'types';
import { InputLabel } from '@material-ui/core';
import { INode } from '@mrblenny/react-flow-chart';
import ScheduleTypeRadioForm from './scheduleTypeRadioForm';
import EmailAssetPreview from './emailAssetPreview';
import SelectEmailAsset from './selectEmailAsset';

type EmailOpenedProps = {
    assets: Asset[];
    node: INode;
    updateWorkflowNode: UpdateFlowNode;
    campaignId: string;
    setHasDraftChanges: (value: boolean) => void;
};

const EmailOpened = forwardRef<HTMLDivElement, EmailOpenedProps>(function EmailOpenedComponent(
    { assets, node, updateWorkflowNode, campaignId, setHasDraftChanges }: EmailOpenedProps,
    ref,
) {
    const [templateUrl, setTemplateUrl] = useState('');
    useEffect(() => {
        const asset = assets.find((el) => el.id === node.properties.assetId);
        if (asset) {
            setTemplateUrl(
                `${process.env.REACT_APP_CLOUDFRONT_URL}/${campaignId}/assets/${asset.id}/${asset.template}.html`,
            );
        } else {
            setTemplateUrl('');
        }
    });
    return (
        <div ref={ref} style={{ overflow: 'auto', height: '100%' }}>
            <Header title="Check email opened" subtitle="Check if selected email has been opened by the recipient" />
            <div style={{ padding: 24 }}>
                <InputLabel style={{ marginBottom: 4 }}>Email asset</InputLabel>
                <SelectEmailAsset
                    node={node}
                    updateWorkflowNode={updateWorkflowNode}
                    assets={assets}
                    setHasDraftChanges={setHasDraftChanges}
                />
                {templateUrl && <EmailAssetPreview templateUrl={templateUrl} />}
                <ScheduleTypeRadioForm
                    node={node}
                    updateWorkflowNode={updateWorkflowNode}
                    ruleType={WorflowConditionRuleType.EMAIL_OPENED}
                    setHasDraftChanges={setHasDraftChanges}
                />
            </div>
        </div>
    );
});

export default EmailOpened;

import React, { useState, useReducer, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory, Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Paper, Typography, Box, Link } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import CopyRight from 'components/copyRight';
import Message from 'components/message';
import { ApiResponse } from 'apisauce';
import api from 'api';
import { isSignedIn, removeCookies } from 'auth';
import { EApiIssue } from 'types';
import { updateApiIssue } from 'reducers/userSlice';
import { SUPPORT_EMAIL, SUPPORT_EMAIL_MSG } from 'common/constants';
import AlertBar from 'components/alertBar';
import ForgetPassword from './forgetPassword';
import PageNotFound from 'features/pageNotFound';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        minHeight: '100vh',
        padding: '32px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.lightGrey,
    },
    formContainer: {
        padding: 40,
        width: 'calc(100% - 32px)',
        maxWidth: 604,
    },
    form: {
        width: '100%',
        padding: '16px 44px',
        display: 'flex',
        flexDirection: 'column',
    },
    textInput: {
        marginBottom: 24,
    },
    errorText: {
        marginBottom: 8,
        fontSize: 14,
        lineHeight: '21px',
    },
    link: {
        '&:hover': {
            color: colors.mediumBlue,
        },
    },
    warningLink: {
        color: colors.yellow,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
            color: colors.darkYellow,
        },
    },
}));

function reducer(state: { email: string; password: string }, action: { type: string; payload: any }) {
    switch (action.type) {
        case 'email':
            return { ...state, email: action.payload };
        case 'password':
            return { ...state, password: action.payload };
        default:
            throw new Error();
    }
}

type Pages = 'Login' | 'OTP';
export type Issue = 'accountLocked' | '401' | 'sessionTimeout';

interface LoginProps {
    apiIssue?: EApiIssue;
    updateApiIssue?: (apiIssue: EApiIssue) => void;
}

const Login = ({ apiIssue, updateApiIssue }: LoginProps) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, { email: '', password: '' });
    const [showError, setShowError] = useState(false);
    const [currentPage, setCurrentPage] = useState<Pages>('Login');
    const [serverError, setServerError] = useState('');
    const [showServerError, setShowServerError] = useState<boolean>(false);

    const [issue, setIssue] = useState<Issue>();

    useEffect(() => {
        if (apiIssue === EApiIssue.UNAUTHORIZED && isSignedIn()) {
            setIssue('401');
            removeCookies();
        } else if (apiIssue === EApiIssue.SESSION_TIMEOUT || apiIssue === EApiIssue.LOGOUT) {
            if (apiIssue === EApiIssue.SESSION_TIMEOUT) {
                setIssue('sessionTimeout');
            }
            removeCookies();
        }
    }, []);

    useEffect(() => {
        if (apiIssue === EApiIssue.SERVER_500) {
            setServerError(SUPPORT_EMAIL_MSG);
            setShowServerError(true);
        } else {
            setServerError('');
            setShowServerError(false);
        }
    }, [apiIssue]);

    useEffect(() => {
        if (updateApiIssue) updateApiIssue(EApiIssue.NONE);
    }, []);

    useEffect(() => {
        if (isSignedIn()) {
            history.push({ pathname: '/campaigns' });
        }
    }, []);

    const handleGoToLogin = (withIssue?: Issue) => {
        setCurrentPage('Login');
        if (withIssue) setIssue(withIssue);
    };

    const renderAlertBar = () => {
        const MAIL_TO = `mailto:${SUPPORT_EMAIL}`;
        const message = () => {
            switch (issue) {
                case '401':
                    return 'To help keep your account secure, we need to verify it’s you. Please log in again to continue.';
                case 'sessionTimeout':
                    return 'We have logged you out as you were inactive for 25 minutes. We do this to keep your information secure. Please log in to continue.';
                case 'accountLocked':
                    return (
                        <>
                            You have exceeded the maximum login attempts.
                            <Box style={{ marginTop: 12, marginBottom: 12 }} display="flex">
                                <Button
                                    component={RouterLink}
                                    variant="text"
                                    size="small"
                                    color="secondary"
                                    style={{ textDecoration: 'underline', fontWeight: 'normal', marginRight: 4 }}
                                    to={`${path}/forget-password`}
                                >
                                    Reset password
                                </Button>
                                <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                            </Box>
                            If you have not logged into your account for more than 90 days, please contact us at
                            <address style={{ display: 'inline', margin: '0 0.3rem' }}>
                                <a href={MAIL_TO} style={{ color: colors.red }}>
                                    {SUPPORT_EMAIL}
                                </a>
                            </address>
                            to unlock your account.
                        </>
                    );
                default:
                    return '';
            }
        };

        const type = () => {
            switch (issue) {
                case '401':
                    return 'info';
                case 'sessionTimeout':
                    return 'info';
                case 'accountLocked':
                    return 'error';
                default:
                    return 'info';
            }
        };

        return <AlertBar style={{ padding: '0 0 24px' }} type={type()} message={message()} />;
    };

    return (
        <div className={classes.container}>
            <Paper className={classes.formContainer} elevation={1}>
                <Switch>
                    <Route exact={true} path={path}>
                        <>
                            {currentPage === 'Login' && (
                                <form className={classes.form} autoComplete="off">
                                    <Box mb={4}>
                                        <Typography variant="h1" align="center">
                                            Welcome to Personalise
                                        </Typography>
                                    </Box>
                                    {issue && renderAlertBar()}
                                    <Box mb={4}>
                                        <Typography align="center" style={{ marginTop: 8 }}>
                                            To use Personalise, you need to{' '}
                                            <Link
                                                className={classes.link}
                                                href={
                                                    'https://docs.developer.tech.gov.sg/docs/techpass-user-guide/onboard-to-techpass'
                                                }
                                                target={'_blank'}
                                            >
                                                onboard to TechPass
                                            </Link>{' '}
                                            first.
                                        </Typography>
                                    </Box>
                                    <Button
                                        onClick={() => {
                                            api.getTechPassUrl().then((response: ApiResponse<any>) => {
                                                if (response.ok) {
                                                    window.open(response.data as string, '_self');
                                                }
                                            });
                                        }}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                    >
                                        <img
                                            src="/icons/techpass.svg"
                                            alt="techpass logo"
                                            style={{ marginRight: '8px' }}
                                        />
                                        Continue with TechPass
                                    </Button>
                                    {((showError && serverError) || showServerError) && (
                                        <Message message={serverError} />
                                    )}
                                </form>
                            )}
                        </>
                    </Route>
                    <Route path={`${path}/forget-password`}>
                        <ForgetPassword prefillEmail={state.email} />
                    </Route>
                    <Route path="*">
                        <PageNotFound />
                    </Route>
                </Switch>
            </Paper>
            <CopyRight placement={'preLogin'} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    apiIssue: state.userSlice.apiIssue,
});

const mapDispatchToProps = {
    updateApiIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

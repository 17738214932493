import { createSlice } from '@reduxjs/toolkit';
import api from 'api';
import { ApiResponse } from 'apisauce';
import { AppThunk, EApiIssue } from 'types';

export interface User {
    id?: string;
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
    jobCategory?: string;
    email?: string;
    password?: string;
    newPassword?: string;
    organisation?: {
        id: string;
        name: string;
    };
    apiIssue?: EApiIssue;
    isSideMenuExpanded?: boolean;
    isTechPassUser?: boolean;
}

const userSlice = createSlice({
    name: 'campaign',
    initialState: {
        user: {
            firstName: '',
            lastName: '',
            email: '',
            // organisation: {
            //     id: '',
            //     name: '',
            //     valid: false,
            // },
        },
        apiIssue: EApiIssue.NONE,
        dashboardSelected: 'personal', // personal or teamid
        isSideMenuExpanded: true,
        notifications: [],
    },
    reducers: {
        replaceUser(state, action) {
            state.user = action.payload;
        },
        updateDashboardSelected(state, action) {
            state.dashboardSelected = action.payload;
        },
        updateApiIssue(state, action) {
            state.apiIssue = action.payload;
        },
        updateSideMenuExpanded(state, action) {
            state.isSideMenuExpanded = action.payload;
        },
        updateNotifications(state, action) {
            state.notifications = action.payload;
        },
    },
});

export const { replaceUser, updateDashboardSelected, updateApiIssue, updateSideMenuExpanded, updateNotifications } =
    userSlice.actions;

export const getUser = (): AppThunk => async (dispatch) => {
    api.getUser().then((response: any) => {
        if (response.ok) {
            dispatch(replaceUser(response.data));
        }
    });
};

export const getNotifications = (): AppThunk => async (dispatch) => {
    api.getNotifications().then((response: any) => {
        if (response.ok) {
            dispatch(updateNotifications(response.data));
        }
    });
};

export const updateUser =
    (user: User, callback?: (data: { ok: boolean; status: number; message: string }) => void): AppThunk =>
    async (dispatch) => {
        api.updateUser(user).then((response: ApiResponse<any>) => {
            if (response.ok) {
                api.getUser().then((response: ApiResponse<any>) => {
                    if (response.ok) {
                        dispatch(replaceUser(response.data));
                    }
                });
            }
            if (callback) {
                callback({ ...response.data, ok: response.ok });
            }
        });
    };

export default userSlice.reducer;

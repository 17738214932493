import React, { useReducer, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { ApiResponse } from 'apisauce';
import { Box, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Asset, Campaign, CampaignStateType } from 'types';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';

import AssetsModal from './assetsModal';
import AssetsList from './assetsList';

import { createOrUpdateAsset, duplicateAsset, deleteAsset } from 'features/campaigns/campaignSlice';
import api from 'api';
import { IChart, INode } from '@mrblenny/react-flow-chart';
import EmptyPage from 'components/emptyPage';
import { RootState } from 'reducers';
import TestBanner from './testBanner';

import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    addAssetCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 0,
        border: `2px dashed ${colors.blue}`,
        width: 290,
        height: 315,
        borderRadius: 4,
        cursor: 'pointer',
        backgroundColor: colors.white,
        padding: '0 20px',
    },
    iconContainer: {
        position: 'relative',
    },
    iconBlue: {
        color: colors.blue,
    },
    iconMain: {
        fontSize: 60,
        margin: '0 5px',
    },
    iconAdd: {
        position: 'absolute',
        fontSize: 32,
        bottom: 0,
        right: 0,
        backgroundColor: colors.white,
        borderRadius: '50%',
        border: 0,
        margin: 0,
        padding: 0,
    },
    title: {
        marginTop: 16,
        fontWeight: 500,
        fontSize: 16,
        color: colors.blue,
    },
    subtitle: {
        color: colors.darkGrey,
        marginTop: 8,
        maxWidth: 378,
        textAlign: 'center',
    },
}));

type AssetsProps = {
    workflow: IChart;
    workflowDraft: IChart;
    assets: Asset[];
    createOrUpdateAsset: (asset: Asset) => void;
    duplicateAsset: (asset: Asset) => void;
    deleteAsset: (key: string) => void;
    isLive: boolean;
    draftCampaign: Campaign;
};

const mapDispatchToProps = { createOrUpdateAsset, duplicateAsset, deleteAsset };

// eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
const Assets = ({
    workflow,
    workflowDraft,
    assets,
    createOrUpdateAsset,
    duplicateAsset,
    deleteAsset,
    isLive,
    draftCampaign,
}: AssetsProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [useUnlayerEditor, setUseUnlayerEditor] = useState(false);
    const history = useHistory();
    const { id, emailAssetId } = useParams<{ id: string; emailAssetId: string }>();

    const getUsedAssets = (nodes: { [key: string]: INode }, used: { [key: string]: boolean }) => {
        for (const key of Object.keys(nodes)) {
            if (nodes[key].type === 'action' && nodes[key].properties.assetId) {
                used[nodes[key].properties.assetId] = true;
            }
        }
    };
    const usedAssets = useMemo(() => {
        const used: { [key: string]: boolean } = {};
        const nodes = workflow.nodes;
        const nodesDraft = workflowDraft.nodes;
        getUsedAssets(nodes, used);
        getUsedAssets(nodesDraft, used);
        return used;
    }, [workflow, workflowDraft]);

    const initState = {
        id: uuidv4(),
        name: '',
        senderName: '',
        subject: '',
        template: '',
        attachments: [],
        isNew: true,
    };
    const createAssetObj = () => {
        return {
            ...initState,
            id: uuidv4(),
        };
    };
    const reducer = (state: Asset, { type, payload }: { type: string; payload: any }) => {
        if (type === 'replaceAsset') {
            return payload;
        }
        return {
            ...state,
            [type]: payload,
        };
    };

    const [asset, dispatch] = useReducer(reducer, initState);

    const updateAsset = (type: string, payload: any) => {
        dispatch({ type, payload });
    };

    const handleEditAsset = (payload: Asset) => {
        updateAsset('replaceAsset', payload);
        setOpen(true);
    };

    const handleDuplicateAsset = (payload: Asset) => {
        const campaignId = draftCampaign.id ? draftCampaign.id : '';
        api.duplicateAssets(campaignId, payload.id!).then((response: any) => {
            if (response.ok) {
                const template = payload.template?.replace(payload.id!, response.data);
                const id = response.data;
                const newAsset = {
                    ...payload,
                    template,
                    id,
                };
                newAsset.hasMetrics = false;
                duplicateAsset(newAsset);
            }
        });
    };
    const handleDeleteAsset = (campaignId: string, assetId: string) => {
        api.deleteAsset(campaignId, assetId).then((response: ApiResponse<any>) => {
            if (response.ok) {
                deleteAsset(assetId);
            }
        });
    };

    const handleClickOpen = () => {
        updateAsset('replaceAsset', createAssetObj());
        setOpen(true);
    };

    const handleClickOpenUnlayerEditor = () => {
        updateAsset('replaceAsset', createAssetObj());
        setOpen(true);
        setUseUnlayerEditor(true);
    };

    const handleClose = () => {
        setOpen(false);
        setUseUnlayerEditor(false);
        if (emailAssetId) {
            history.push(`/campaigns/update/${id}/report/${emailAssetId}`);
        } else {
            updateAsset('replaceAsset', createAssetObj());
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: e.target.name, payload: e.target.value });
    };

    const getAsset = (id: string): Asset => assets.filter((asset) => asset.id === id)[0];

    const hasChanges = (key: string): boolean => isLive && usedAssets[key] && !isEqual(asset, getAsset(key));

    return (
        <>
            {!assets.length ? (
                <EmptyPage title="You have not created any email assets yet.">
                    <Typography variant="h2">Start creating your first email assets by:</Typography>
                    <Box style={{ marginTop: 24, marginBottom: 60 }}>
                        <Grid container spacing={3} style={{ width: 'auto' }}>
                            <Grid item>
                                <Box className={classes.addAssetCard} onClick={handleClickOpenUnlayerEditor}>
                                    <Box className={classes.iconContainer}>
                                        <SettingsIcon className={`${classes.iconBlue} ${classes.iconMain}`} />
                                        <AddIcon className={`${classes.iconBlue} ${classes.iconAdd}`} />
                                    </Box>
                                    <Typography className={classes.title}>Use email editor</Typography>
                                    <Typography className={classes.subtitle}>
                                        Use drag & drop to create emails in minutes. No coding skills are required.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box className={classes.addAssetCard} onClick={handleClickOpen}>
                                    <Box className={classes.iconContainer}>
                                        <ListAltOutlinedIcon className={`${classes.iconBlue} ${classes.iconMain}`} />
                                        <AddIcon className={`${classes.iconBlue} ${classes.iconAdd}`} />
                                    </Box>
                                    <Typography className={classes.title}>Upload file / folder</Typography>
                                    <Typography className={classes.subtitle}>
                                        Upload HTML file, folder or ZIP file containing HTML file and relevant images.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </EmptyPage>
            ) : (
                <>
                    <TestBanner isDraft={draftCampaign.state === CampaignStateType.DRAFT} />
                    <AssetsList
                        assets={assets}
                        editAsset={handleEditAsset}
                        duplicateAsset={handleDuplicateAsset}
                        deleteAsset={handleDeleteAsset}
                        addAsset={handleClickOpen}
                        addAssetUnlayerEditor={handleClickOpenUnlayerEditor}
                        campaignState={draftCampaign.state}
                        campaignId={draftCampaign.id!}
                        isUsedInWorkflow={(asset: Asset) => !!usedAssets[asset.id]}
                    />
                </>
            )}
            <AssetsModal
                campaignId={draftCampaign.id!}
                asset={asset}
                open={open}
                handleClose={handleClose}
                createOrUpdateAsset={createOrUpdateAsset}
                updateAsset={updateAsset}
                handleOnChange={handleOnChange}
                hasChanges={hasChanges}
                campaignState={draftCampaign.state}
                campaignAssets={assets}
                isUsedInWorkflow={(asset: Asset) => !!usedAssets[asset.id]}
                campaignAudiences={draftCampaign.audiences}
                useUnlayerEditor={useUnlayerEditor}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    assets: state.campaignSlice.draftCampaign.assets,
    workflow: state.campaignSlice.draftCampaign.workflow,
    workflowDraft: state.campaignSlice.draftCampaign.workflowDraft,
    isLive:
        state.campaignSlice.draftCampaign.isStarted &&
        state.campaignSlice.draftCampaign.state === CampaignStateType.ACTIVE,
    draftCampaign: state.campaignSlice.draftCampaign,
});

export default connect(mapStateToProps, mapDispatchToProps)(Assets);

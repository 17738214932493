import { Paper, Box, Typography } from '@material-ui/core';
import colors from 'colors';


const ModeBoard = () => {
    return (
        <Paper style={{ position: 'absolute', top: 16, left: 16, zIndex: 1, padding: 24, maxWidth: 300 }}>
            <Box display="flex" alignItems="center">
                <Typography style={{ fontWeight: 500 }}>View mode</Typography>
            </Box>
            <Typography style={{ marginTop: 12, marginBottom: 16, color: colors.darkGrey, fontSize: 14 }}>
                <>View the live workflow for this campaign</>
            </Typography>
        </Paper>
    );
 
};

export default ModeBoard;

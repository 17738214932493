import React, { ReactNode } from 'react';
import { Box, Paper, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTooltip from 'components/customTooltip';
import colors from 'colors';

const useStyles = makeStyles((theme) => ({
    contentEmphasis: {
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '48px',
        color: colors.mediumDarkBlue,
    },
    subContentEmphasis: {
        fontWeight: 500,
    },
}));
export class MetricSummaryBoxContent {
    contentEmphasis: string;
    isMainContent: boolean;
    contentPrefix?: string;
    contentPostfix?: string;
    title?: string;
    constructor(
        contentEmphasis: string,
        contentPostfix?: string,
        contentPrefix?: string,
        isMainContent = true,
        title?: string,
    ) {
        this.contentEmphasis = contentEmphasis;
        this.isMainContent = isMainContent;
        this.contentPrefix = contentPrefix;
        this.contentPostfix = contentPostfix;
        this.title = title;
    }
}

interface MetricsHelpContent {
    tooltipContents: NonNullable<ReactNode>;
    isLarge?: boolean;
}
interface MetricsSummaryBoxProps {
    mainContent: MetricSummaryBoxContent;
    leftSubContent: MetricSummaryBoxContent;
    rightSubContent: MetricSummaryBoxContent;
    mainHelpContent?: MetricsHelpContent;
    leftSubHelpContent?: MetricsHelpContent;
    rightSubHelpContent?: MetricsHelpContent;
    numberOfContactsHelpContent?: boolean;
    emailsDeliveredHelpContent?: boolean;
    columnLayout?: boolean | 4 | 'auto' | 1 | 2 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
    subcontentSeperator?: string;
}

interface MetricsSummaryTwoColumnBoxProps {
    leftMainContent: MetricSummaryBoxContent;
    leftHelpContent: MetricsHelpContent;
    leftSecondaryContent?: MetricSummaryBoxContent;
    rightMainContent: MetricSummaryBoxContent;
    rightHelpContent: MetricsHelpContent;
    rightSecondaryContent?: MetricSummaryBoxContent;
    columnLayout?: boolean | 4 | 'auto' | 1 | 2 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
    delay?: number;
}

interface MetricsSummaryBoxContainerProps {
    children: ReactNode;
    columnLayout: boolean | 4 | 'auto' | 1 | 2 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const MetricsSummaryBoxContainer = ({ children, columnLayout }: MetricsSummaryBoxContainerProps) => {
    return (
        <Grid item xs={columnLayout}>
            <Paper elevation={1} style={{ height: '100%', padding: '24px 32px' }}>
                {children}
            </Paper>
        </Grid>
    );
};

const MetricsSummaryBox = ({
    mainContent,
    leftSubContent,
    rightSubContent,
    mainHelpContent,
    leftSubHelpContent,
    rightSubHelpContent,
    columnLayout = 6,
    subcontentSeperator = ' ',
}: MetricsSummaryBoxProps) => {
    const classes = useStyles();
    const subcontentSeperatorHorizontalMargin = subcontentSeperator.trim() ? '0.3rem' : '0.8rem';

    function renderFormattedContent(boxContent: MetricSummaryBoxContent, helpContent?: MetricsHelpContent) {
        const typographyVariant = boxContent.isMainContent ? 'h2' : 'body2';
        const typographyColor = boxContent.isMainContent ? 'textPrimary' : 'textSecondary';
        return (
            <>
                {boxContent.contentPrefix && (
                    <Typography variant={typographyVariant} color={typographyColor} style={{ marginRight: '0.5rem' }}>
                        {boxContent.contentPrefix}
                    </Typography>
                )}
                <Typography
                    variant={typographyVariant}
                    className={boxContent.isMainContent ? classes.contentEmphasis : classes.subContentEmphasis}
                >
                    {boxContent.contentEmphasis}
                </Typography>
                {boxContent.contentPostfix && (
                    <Typography variant={typographyVariant} color={typographyColor} style={{ marginLeft: '0.5rem' }}>
                        {boxContent.contentPostfix}
                    </Typography>
                )}
                {helpContent && (
                    <CustomTooltip
                        title={helpContent.tooltipContents}
                        placement="top"
                        icon={boxContent.isMainContent ? 'help' : 'secondaryHelp'}
                        arrow
                        interactive
                        maxWidth={helpContent.isLarge ? 440 : undefined}
                    />
                )}
            </>
        );
    }

    return (
        <MetricsSummaryBoxContainer columnLayout={columnLayout}>
            <Box display="flex" mb={0.5} alignItems="center">
                {renderFormattedContent(mainContent, mainHelpContent)}
            </Box>
            <Box display="flex" mt={1} mb={0.5} alignItems="center">
                {renderFormattedContent(leftSubContent, leftSubHelpContent)}
                <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ margin: `0 ${subcontentSeperatorHorizontalMargin}` }}
                >
                    {subcontentSeperator}
                </Typography>
                {renderFormattedContent(rightSubContent, rightSubHelpContent)}
            </Box>
        </MetricsSummaryBoxContainer>
    );
};

export const MetricsSummaryTwoColumnBox = ({
    leftMainContent,
    leftHelpContent,
    leftSecondaryContent,
    rightMainContent,
    rightHelpContent,
    rightSecondaryContent,
    columnLayout = 6,
    delay = 1000,
}: MetricsSummaryTwoColumnBoxProps) => {
    const classes = useStyles();

    function renderOneColumnBox(
        mainContent: MetricSummaryBoxContent,
        helpContent: MetricsHelpContent,
        secondaryContent?: MetricSummaryBoxContent,
    ) {
        return (
            <Box flex="1">
                <Box display="flex" alignItems="center" mb={0.5}>
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                        {mainContent.title}
                    </Typography>
                    <CustomTooltip
                        title={helpContent.tooltipContents}
                        placement="top"
                        icon="help"
                        arrow
                        leaveDelay={delay}
                        interactive
                        maxWidth={helpContent.isLarge ? 440 : undefined}
                    />
                </Box>
                <Typography style={{ fontSize: 32, lineHeight: '48px', fontWeight: 500 }}>
                    {mainContent.contentEmphasis}
                </Typography>
                <Box display="flex" alignItems="center" mb={0.5}>
                    {secondaryContent && (
                        <>
                            <Typography variant="body2" className={classes.subContentEmphasis}>
                                {secondaryContent.contentEmphasis}
                            </Typography>
                            {secondaryContent.contentPostfix && (
                                <Typography variant="body2" color="textSecondary" style={{ marginLeft: '0.5rem' }}>
                                    {secondaryContent.contentPostfix}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        );
    }

    return (
        <MetricsSummaryBoxContainer columnLayout={columnLayout}>
            <Box display="flex">
                {renderOneColumnBox(leftMainContent, leftHelpContent, leftSecondaryContent)}
                {renderOneColumnBox(rightMainContent, rightHelpContent, rightSecondaryContent)}
            </Box>
        </MetricsSummaryBoxContainer>
    );
};

export default MetricsSummaryBox;

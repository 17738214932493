import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, Audience } from 'types';
import { ApiResponse } from 'apisauce';
import api from 'api';

const audienceSlice = createSlice({
    name: 'audience',
    initialState: {
        audiences: Array<Audience>(),
        audiencesPending: Array<string>(),
    },
    reducers: {
        updateAudiences(state, action) {
            state.audiences = action.payload;
        },
        putAudience(state, action) {
            const index = state.audiences.findIndex((audience) => audience.id === action.payload.id);

            if (index < 0) {
                state.audiences.push(action.payload);
            } else {
                state.audiences.splice(index, 1, action.payload);
            }
        },
        addPendingAudience(state, action) {
            state.audiencesPending = [...state.audiencesPending, action.payload];
        },
        removePendingAudience(state, action) {
            state.audiencesPending = [...state.audiencesPending].filter((audienceId) => action.payload !== audienceId);
        },
    },
});

export const { putAudience, updateAudiences, addPendingAudience, removePendingAudience } = audienceSlice.actions;

export const getPersonalAudiences = (): AppThunk => async (dispatch) => {
    api.getPersonalAudiences().then((response: ApiResponse<any>) => {
        dispatch(updateAudiences(response.data));
    });
};

export const getTeamAudiences =
    (teamId: string): AppThunk =>
    async (dispatch) => {
        api.getTeamAudiences(teamId).then((response: ApiResponse<any>) => {
            dispatch(updateAudiences(response.data));
        });
    };

export default audienceSlice.reducer;

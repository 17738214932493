import React from 'react';

interface EmailAssetPreviewProps {
    templateUrl: string;
}

const EmailAssetPreview = ({ templateUrl }: EmailAssetPreviewProps) => {
    return (
        <div style={{ width: '100%', height: 200, marginTop: 16 }}>
            <iframe
                title="Email Asset Preview"
                style={{
                    width: '300%',
                    height: 600,
                    transform: 'scale(0.33)',
                    transformOrigin: 'top left',
                    border: 0,
                }}
                src={templateUrl}
            />
        </div>
    );
};

export default EmailAssetPreview;

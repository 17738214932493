import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { ApiResponse } from 'apisauce';
import api from 'api';
import isEmpty from 'lodash/isEmpty';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    Button,
} from '@material-ui/core';
import { Asset } from 'types';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { formatNumberWithCommas } from 'utils/numbers';
import EmailMetricsModel from './emailMetricsModel';
import { MetricsSummaryTwoColumnBox, MetricSummaryBoxContent } from './../metricsSummaryBox';
import EquationBox from 'components/equationBox';
import { HeadCell, Row, SortableTable } from 'components/customTable';
import CustomTooltip from 'components/customTooltip';

const useStyles = makeStyles(() => ({
    tableContainer: {
        padding: '32px',
        display: 'flex',
        flexDirection: 'row',
        '& tbody tr:first-child td': {
            paddingTop: 24,
        },
        '& tbody td': {
            border: 0,
            width: '25%',
            padding: '16px 0 0 0',
        },
    },
    assetImg: {
        width: 310,
        height: 'auto',
        marginRight: 40,
        overflow: 'hidden',
    },
}));

type EmailMetricesProps = {
    assets?: Asset[];
    campaignState?: string;
    campaignId?: string;
};

export const EmailMetrices = ({ assets, campaignState, campaignId }: EmailMetricesProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [metrics, setMetrics] = useState<EmailMetricsModel>();
    const [emailAsset, setEmailAsset] = useState<Asset>();
    const [templateUrl, setTemplateUrl] = useState('');
    const { id, emailAssetId, feature } = useParams<{ id: string; emailAssetId: string; feature: string }>();

    const orderedHeadCells: HeadCell[] = [
        { id: 'link', label: 'Link', isNotSortable: true },
        { id: 'totalClicks', label: 'Total Clicks' },
    ];

    useEffect(() => {
        if (id && emailAssetId) {
            api.getCampaignAssetMetrics(id, emailAssetId).then((response: ApiResponse<any>) => {
                const emailAssetObj = assets && assets.find((asset: Asset) => asset.id === emailAssetId);
                if (emailAssetObj) {
                    setEmailAsset(emailAssetObj);
                    setTemplateUrl(
                        `${process.env.REACT_APP_CLOUDFRONT_URL}/${id}/assets/${emailAssetObj.id}/${emailAssetObj.template}.html`,
                    );
                }
                setMetrics(new EmailMetricsModel(response.data));
            });
        }
    }, []);

    useEffect(() => {
        if (
            (campaignId && campaignState === 'DRAFT') ||
            (emailAsset && emailAsset.id && emailAsset.id !== emailAssetId) ||
            feature !== 'report'
        ) {
            history.push(`/campaigns/update/${id}`);
        }
    }, [emailAsset, emailAssetId]);

    return (
        <Box px={3} py={3} style={{ paddingBottom: 70 }}>
            <Button
                color="primary"
                size="small"
                onClick={() => {
                    history.push(`/campaigns/update/${id}/report`);
                }}
                style={{ marginBottom: 12 }}
            >
                Back to campaign report
            </Button>
            <Typography variant="h2" style={{ marginBottom: 24 }}>
                {emailAsset && emailAsset.name}
            </Typography>
            <Paper style={{ margin: '24px 0' }}>
                <TableContainer component="div" className={classes.tableContainer}>
                    <div className={classes.assetImg}>
                        {templateUrl && (
                            <div style={{ width: '100%', height: 160 }}>
                                <iframe
                                    title="Email Asset Preview"
                                    style={{
                                        width: '300%',
                                        height: 680,
                                        transform: 'scale(0.33)',
                                        transformOrigin: 'top left',
                                        border: 0,
                                    }}
                                    src={templateUrl}
                                />
                            </div>
                        )}
                    </div>
                    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                        <Box display={'flex'} justifyContent={'space-between'} marginBottom={3}>
                            <Box display="flex" flex="1">
                                <Box style={{ maxWidth: 279, marginRight: 64 }}>
                                    <Typography variant="body2" color="textSecondary">
                                        Sender name
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        style={{ marginTop: 4, fontWeight: 500 }}
                                        color="textPrimary"
                                    >
                                        {emailAsset?.senderName}
                                    </Typography>
                                </Box>
                                <Box flex="1">
                                    <Typography variant="body2" color="textSecondary">
                                        Subject
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        style={{ marginTop: 4, fontWeight: 500 }}
                                        color="textPrimary"
                                    >
                                        {emailAsset?.subject}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Button
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        history.push(`/campaigns/update/${id}/assets/${emailAssetId}`);
                                    }}
                                    style={{ marginLeft: 64, whiteSpace: 'nowrap', overflow: 'hidden' }}
                                >
                                    View email
                                </Button>
                            </Box>
                        </Box>
                        <Divider />
                        {metrics && (
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ width: '30%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                Total emails sent
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right', paddingRight: 44, width: '20%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                {metrics.sents_display}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ paddingLeft: 44, width: '30%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                Delivered successfully
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right', width: '20%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                {metrics.deliveries_display}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ width: '30%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                No. of permanent bounces
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right', paddingRight: 44, width: '20%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                {metrics.bounces_display}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ paddingLeft: 44, width: '30%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                No. of temporary bounces
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right', width: '20%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                {metrics.temporaryBounces_display}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ width: '30%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                No. of unsubscribes
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right', paddingRight: 44, width: '20%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                {metrics.unsubscribes_display}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ paddingLeft: 44, width: '30%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                No. of reported spam
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right', width: '20%' }}>
                                            <Typography variant="body1" color="textPrimary">
                                                {metrics.complaints_display}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </Box>
                </TableContainer>
            </Paper>
            {metrics && (
                <>
                    <Grid container direction="row" spacing={3}>
                        <MetricsSummaryTwoColumnBox
                            leftMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueOpenRate_display,
                                    undefined,
                                    undefined,
                                    true,
                                    'Unique open rate',
                                )
                            }
                            leftHelpContent={{
                                tooltipContents: (
                                    <EquationBox
                                        product="Unique open rate ="
                                        numerator="Unique opens from this asset"
                                        denominator="No. of emails delivered succesfully for this asset"
                                    />
                                ),
                                isLarge: true,
                            }}
                            leftSecondaryContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueOpens_display,
                                    'unique opens',
                                    undefined,
                                    false,
                                )
                            }
                            rightMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.opens_display,
                                    undefined,
                                    undefined,
                                    true,
                                    'Total opens',
                                )
                            }
                            rightHelpContent={{
                                tooltipContents: 'Total opens are the total number of times your email has been opened',
                            }}
                        />
                        <MetricsSummaryTwoColumnBox
                            leftMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueClickRate_display,
                                    undefined,
                                    undefined,
                                    true,
                                    'Unique click rate',
                                )
                            }
                            leftHelpContent={{
                                tooltipContents: (
                                    <EquationBox
                                        product="Unique click rate ="
                                        numerator="Unique clicks from this asset"
                                        denominator="No. of emails delivered succesfully for this asset"
                                    />
                                ),
                                isLarge: true,
                            }}
                            leftSecondaryContent={
                                new MetricSummaryBoxContent(
                                    metrics.uniqueClicks_display,
                                    'unique clicks',
                                    undefined,
                                    false,
                                )
                            }
                            rightMainContent={
                                new MetricSummaryBoxContent(
                                    metrics.clicks_display,
                                    undefined,
                                    undefined,
                                    true,
                                    'Total clicks',
                                )
                            }
                            rightHelpContent={{
                                tooltipContents: 'Total clicks are the total number of clicks received in your email.',
                            }}
                        />
                    </Grid>
                </>
            )}
            {metrics && !isEmpty(metrics.links) && (
                <Box marginTop={3}>
                    <Typography variant="h2" style={{ marginBottom: '1rem' }}>
                        No. of link clicks
                        <CustomTooltip
                            title={`Shows the unique and total clicks for each individual link that has been clicked on`}
                            icon="help"
                            arrow
                        />
                    </Typography>
                    <SortableTable
                        orderedHeadCells={orderedHeadCells}
                        data={metrics.links}
                        defaultOrderBy="totalClicks"
                        formatRowCell={(rowData: Row, columnId: string) => {
                            let data;
                            if (columnId === 'totalClicks') {
                                data = formatNumberWithCommas(rowData[columnId] ? Number(rowData[columnId]) : 0);
                            } else {
                                data = rowData[columnId];
                            }
                            return data;
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    assets: state.campaignSlice.draftCampaign.assets,
    campaignState: state.campaignSlice.draftCampaign.state,
    campaignId: state.campaignSlice.draftCampaign.id,
});

export default connect(mapStateToProps, null)(EmailMetrices);

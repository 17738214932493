import { INode, IChart } from '@mrblenny/react-flow-chart';
import { ThunkAction } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { RootState } from 'reducers';
import { User } from 'reducers/userSlice';
import { UserCampaign } from 'features/campaigns/campaignSlice';

export enum EApiIssue {
    'FORBIDDEN_403' = 'FORBIDDEN_403',
    'UNAUTHORIZED' = 'UNAUTHORIZED',
    'SESSION_TIMEOUT' = 'SESSION_TIMEOUT',
    'LOGOUT' = 'LOGOUT',
    'SERVER_500' = 'SERVER_500',
    'NONE' = 'NONE',
    'NOT_FOUND_404' = 'NOT_FOUND_404',
}

export enum EUploadStatus {
    'UPLOADING',
    'UPLOADED',
    'DELETED',
}
export interface Attachments {
    name: string;
    status: EUploadStatus;
}
export interface Asset {
    id: string;
    name?: string;
    senderName?: string;
    subject?: string;
    template?: string;
    attachments: Attachments[];
    isNew: boolean;
    hasMetrics: boolean;
    validationErrors?: AssetValidationErrors;
}

export interface AssetValidationErrors {
    invalidSyntaxAttributeErrors?: string[];
    mismatchedAttributeErrors?: string[];
}

interface AudienceImportedBy {
    id: string;
    firstName: string;
    lastName: string;
}

export interface Audience {
    name: string;
    id: string;
    group: string;
    teamId?: string;
    importedOn?: string;
    importedBy?: AudienceImportedBy;
    contacts?: number;
    state?: string;
    createdBy?: User;
}

export interface AudienceUsageData {
    campaignName?: string;
    ownedBy?: string;
}

export interface NotificationData {
    topic: string;
    content?: ImportSummary;
    createdOn?: string;
    audienceId?: string;
    audience?: Audience;
}

export interface ImportSummary {
    addCount?: number;
    updateCount?: number;
    issuesCount?: number;
}
export interface ImportInfo {
    uploadCount?: number;
    issueCount?: number;
}

export interface AudienceContactsQueryParams {
    state?: string;
    email?: string;
    pageSize: number;
    pageNumber: number;
}

export interface FlowNode {
    id?: string;
    name?: string;
    properties?: any;
    type?: string;
    options?: { [key: string]: FlowNode };
    next?: FlowNode;
}

export interface Workflow {
    actions?: FlowNode[];
    conditions?: FlowNode[];
    start?: FlowNode | null;
}

export enum CampaignStateType {
    'DRAFT' = 'DRAFT',
    'ACTIVE' = 'ACTIVE',
    'INACTIVE' = 'INACTIVE',
    'SCHEDULED' = 'SCHEDULED',
}
export interface Campaign {
    assets?: Asset[];
    senderEmail?: string;
    replyToEmail?: string;
    startedOn?: string | null;
    scheduledOn?: string | null;
    audiences?: Audience[];
    workflow?: IChart;
    workflowDraft?: IChart;
    name?: string;
    isActive?: boolean;
    id?: string | null;
    isStarted?: boolean;
    updatedOn?: string | null;
    createdOn?: string;
    state: CampaignStateType;
    isScheduled?: boolean;
    teamId?: string | null;
}

export interface CampaignBackend {
    assets?: Asset[];
    audiences?: Audience[];
    workflow?: Workflow;
    senderEmail?: string;
    replyToEmail?: string;
    startedOn?: string | null;
    scheduledOn?: string | null;
    workflowUI?: string;
    workflowUIDraft?: string;
    name?: string;
    isActive?: boolean;
    id?: string | null;
    createdOn?: string;
    isStarted?: boolean;
    createdBy?: UserCampaign;
    teamId?: string | null;
    updatedOn?: string | null;
    state: CampaignStateType;
    isScheduled?: boolean;
}

export interface CampaignStartPayload {
    scheduledOn: string | null | undefined;
    workflow: Workflow;
}

export interface CampaignApplyPayload {
    workflow: Workflow;
}

export type CampaignStartType = 'NOW' | 'LATER';

export enum CampaignFeaturesDraftMode {
    settings,
    audience,
    assets,
    workflow,
}

export enum CampaignFeatures {
    report,
    settings,
    audience,
    assets,
    workflow,
}

export interface Organisation {
    id: string;
    name: string;
}

export interface TeamPayload {
    id?: string;
    name: string;
}

export interface TeamUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
}

export interface TeamPayload {
    id?: string;
    name: string;
}

export interface TeamUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
}

export interface Team {
    id: string;
    name: string;
    createdOn: string;
    updatedOn?: string;
    users: TeamUser[];
}

export interface Segment {
    id: string;
    name: string;
}

export type UpdateFlowNode = (node: INode) => void;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export enum UniqueUserAttributeDisplayName {
    EMAIL = 'Email address',
    // NRIC_FIN = 'NRIC / FIN',
}

export enum UniqueUserAttribute {
    EMAIL = 'email',
    NRIC_FIN = 'nric',
}

export enum UserAttributeGroup {
    UNIQUE_IDENTIFIERS = 'Unique identifiers',
}

export interface UserAttribute {
    name: string;
    dataType: string;
    presetValues?: string[];
    description: string;
    group: string;
    id: string;
}

export interface UserAttributeBackend {
    id?: string;
    name?: string;
    displayName: string;
    values?: string[];
    description?: string;
    type: AttributeDataType;
}

export enum AudienceDropdownOption {
    ALL = 'All',
}

export interface SendTestEmailsPayload {
    assetIds: string[];
    emailIds: string[];
}

export enum WorkflowActionScheduleType {
    IMMEDIATELY = 'immediately',
    WAIT = 'wait',
    SPECIFIC_DAY = 'specificDay',
}

export enum WorflowConditionRuleType {
    ATTRIBUTE = 'ATTRIBUTE',
    EMAIL_OPENED = 'EMAIL_OPENED',
}

export enum WorkflowConditionScheduleType {
    IMMEDIATELY = 'immediately',
    WAIT = 'wait',
    FOR_A_PERIOD_OF = 'forAPeriodOf',
}

export enum WorkflowScheduleErrorType {
    NO_ERROR = 0,
    EMPTY_VALUE,
    INVALID_FORMAT,
    MIN_VALUE,
}

export enum AttributeDataType {
    TEXT = 'TEXT',
    FIXED_TEXT = 'FIXED_TEXT',
}

export interface BackendErrorMessage {
    message: string;
    path: string;
    status: number;
    timestamp: string;
}

export enum BackendErrorMessagePhrase {
    TECHPASS_USER = '400 TECHPASS_ACCOUNT',
    PASSWORD_REUSED = '400 PASSWORD_REUSED',
    PASSWORD_SAME_EMAIL = '400 PASSWORD_SAME_EMAIL',
    CAMPAIGN_METRICS_NOT_FOUND = '404 CAMPAIGN_METRICS_NOT_FOUND',
    NONE = 'None',
}

export interface AdminRightsPayload {
    role: string;
    id: string;
}

export interface CampaignAttributes {
    [key: string]: UserAttributeBackend[];
}

export interface TechPassAuthentication {
    code: string;
}

export interface MultiFactorAuthentication {
    email: string;
    password: string;
    otp: string;
}

export interface NewUser {
    firstName: string;
    lastName: string;
    organisation: { id: string };
    jobTitle: string;
    jobCategory: string;
    email: string;
    password: string;
}

export interface UnlayerTemplateJsonPayload {
    body: any;
    counters: any;
    schemaVersion: number;
}

export enum AppPage {
    CAMPAIGN = 'Campaign',
    AUDIENCE = 'Audience',
    TEAM_SETTINGS = 'TeamSettings',
    ACCOUNT_SETTINGS = 'AccountSettings',
}

export enum AppComponent {
    HEADER = 'Header',
    DASHBOARD = 'Dashboard',
}

export interface TrackingEvent {
    page: AppPage;
    components: AppComponent[];
    action: string;
}

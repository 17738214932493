import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CirclesLoadingSpinner from 'components/circlesLoadingSpinner';

const LoadingSpinner = () => (
    <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" height="100%">
        <CirclesLoadingSpinner />
        <Typography variant="h2" style={{ marginTop: 24 }}>
            Loading contacts...
        </Typography>
    </Box>
);

export default LoadingSpinner;

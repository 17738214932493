import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { updateSideMenuExpanded } from 'reducers/userSlice';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Link as RLink } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PeopleIcon from '@material-ui/icons/PeopleAltOutlined';
import colors from 'colors';

const SIDE_MENU_WIDTH = 244;

const useStyles = makeStyles(() => ({
    container: {
        width: SIDE_MENU_WIDTH,
        flexShrink: 0,
        height: 'calc(100vh - 106px)',
        paddingTop: 16,
        backgroundColor: colors.white,
        transition: 'width 500ms',
        position: 'relative',
        boxShadow: `2px 2px 8px #E6EAF2`,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #F2F4FF',
        padding: 24,
        height: 55,
    },
    logo: {
        width: 24,
    },
    title: {
        fontSize: 20,
        color: colors.lightBlue,
        marginLeft: 16,
    },
    avatar: {
        height: 24,
        width: 24,
    },
    listContainer: {
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: '16px 24px',
        color: colors.darkGrey,
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            color: colors.blue,
        },
    },
    userName: {
        marginLeft: 16,
        color: colors.dark,
    },
    signOut: {
        fontSize: 12,
    },
    listText: {
        marginLeft: 16,
    },
    bottomContainer: {
        borderTop: `1px solid ${colors.whiteBlue}`,
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        background: colors.white,
        padding: 24,
    },
}));

type SideMenuProps = {
    active: string;
    teamId?: string | null;
    isSideMenuExpanded: boolean;
    updateSideMenuExpanded: (toggle: boolean) => void;
};

const SideMenu = ({ active, teamId, isSideMenuExpanded, updateSideMenuExpanded }: SideMenuProps) => {
    const classes = useStyles();
    const toggleMenu = () => {
        setTimeout(() => updateSideMenuExpanded(!isSideMenuExpanded), isSideMenuExpanded ? 0 : 300);
    };
    return (
        <Typography
            className={classes.container}
            component="div"
            style={{ width: isSideMenuExpanded ? SIDE_MENU_WIDTH : '72px' }}
        >
            <RLink
                className={classes.listContainer}
                style={{ color: active === 'campaigns' ? colors.blue : colors.darkGrey }}
                to={teamId ? `/campaigns/teams/${teamId}` : '/campaigns'}
            >
                <EmailIcon />
                {isSideMenuExpanded && (
                    <Typography
                        className={classes.listText}
                        style={{ fontWeight: active === 'campaigns' ? 500 : 'normal' }}
                    >
                        Campaigns
                    </Typography>
                )}
            </RLink>
            <RLink
                className={classes.listContainer}
                style={{ color: active === 'audience' ? colors.blue : colors.darkGrey }}
                to={teamId ? `/audience/teams/${teamId}` : '/audience'}
            >
                <PeopleIcon />
                {isSideMenuExpanded && (
                    <Typography
                        className={classes.listText}
                        style={{ fontWeight: active === 'audience' ? 500 : 'normal' }}
                    >
                        Audience
                    </Typography>
                )}
            </RLink>
            <div className={classes.bottomContainer}>
                <Button variant="text" onClick={toggleMenu}>
                    <img src="/icons/Toggle.svg" alt="toggle sidebar" />
                    {isSideMenuExpanded && <div className={classes.listText}>Toggle sidebar</div>}
                </Button>
            </div>
        </Typography>
    );
};

const mapStateToProps = (state: RootState) => ({
    isSideMenuExpanded: state.userSlice.isSideMenuExpanded,
});

const mapDispatchToProps = {
    updateSideMenuExpanded,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IConfig } from '@mrblenny/react-flow-chart';

export interface IPortsDefaultProps {
    config: IConfig;
    side: string;
    children: React.ReactNode;
    offsets: {
        top: number;
        bottom: number;
        left: number;
        right: number;
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        '& div': {
            margin: '0 6px',
        },
    },
}));

const PortsGroup = ({ config, side, children, offsets }: IPortsDefaultProps) => {
    const classes = useStyles();
    let style = {};
    switch (side) {
        case 'top':
            style = { left: 0, top: -28 + offsets.top, flexDirection: 'row' };
            break;
        case 'bottom':
            style = { left: 0, bottom: -28 + offsets.bottom, flexDirection: 'row' };
            break;
        case 'left':
            style = { left: -28, top: 0 + offsets.left, flexDirection: 'column' };
            break;
        case 'right':
            style = { right: -28, top: 0 + offsets.right, flexDirection: 'column' };
            break;
    }

    return (
        <div
            style={{ position: 'absolute', display: 'flex', justifyContent: 'center', ...style }}
            className={classes.container}
        >
            {children}
        </div>
    );
};

export default PortsGroup;

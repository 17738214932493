import { createSlice } from '@reduxjs/toolkit';
import { Asset, AppThunk, Audience, CampaignStateType, EApiIssue } from 'types';
import { INode, ILink, IChart } from '@mrblenny/react-flow-chart';
import workflowActions, {
    onCanvasDropAutoSelect,
    onCanvasClickCustom,
    onNodeClickWithMarkAsNotNew,
} from './workflowActions';
import api from 'api';
import { ApiResponse } from 'apisauce';
import { isActionValid, isConditionValid } from './validate';
import { LIVE } from 'common/constants';
import datetime from 'utils/datetime';
import { updateApiIssue } from 'reducers/userSlice';

export interface DefaultDraftCampaign {
    id: string | null;
    isStarted: boolean;
    name: string;
    isActive: boolean;
    assets: Asset[];
    audiences: Audience[];
    senderEmail: string;
    replyToEmail: string;
    workflow: IChart;
    workflowDraft: IChart;
    createdBy: string;
    teamId: string | null;
    state: CampaignStateType;
    updatedOn: string;
}

export const defaultDraftCampaign: DefaultDraftCampaign = {
    id: null,
    name: 'Untitled campaign',
    state: CampaignStateType.DRAFT,
    isActive: false,
    assets: [],
    audiences: [],
    senderEmail: '',
    replyToEmail: '',
    isStarted: false,
    createdBy: '',
    teamId: '',
    updatedOn: '',
    workflow: {
        offset: {
            x: 0,
            y: 0,
        },
        nodes: {
            root: {
                id: 'root',
                type: 'start',
                position: {
                    x: 0,
                    y: 90,
                },
                ports: {
                    root: {
                        id: 'root',
                        type: 'bottom',
                        properties: {
                            value: 'start',
                        },
                    },
                },
                properties: {
                    name: 'start',
                    onStage: true,
                    isValid: true,
                },
            },
        },
        links: {},
        selected: {},
        hovered: {},
    },
    workflowDraft: {
        offset: {
            x: 0,
            y: 0,
        },
        nodes: {
            root: {
                id: 'root',
                type: 'start',
                position: {
                    x: 0,
                    y: 90,
                },
                ports: {
                    root: {
                        id: 'root',
                        type: 'bottom',
                        properties: {
                            value: 'start',
                        },
                    },
                },
                properties: {
                    name: 'start',
                    onStage: true,
                },
            },
        },
        links: {},
        selected: {},
        hovered: {},
    },
};

export interface UserCampaign {
    id?: string;
    firstName?: string;
    lastName?: string;
}

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        campaigns: [],
        draftCampaign: defaultDraftCampaign,
        campaignAttributes: {},
    },
    reducers: {
        setCampaignAttribute(state, action) {
            const currentState = state.draftCampaign as { [key: string]: any };
            currentState[action.payload.name] = action.payload.val;
        },
        createOrUpdateAsset(state, action) {
            const assetData = action.payload;
            const { id } = assetData;
            let hasAsset = false;
            const assetsList = state.draftCampaign.assets as Asset[];
            for (let i = 0; i < assetsList.length; i++) {
                if (assetsList[i].id === id) {
                    assetsList[i] = assetData;
                    hasAsset = true;
                    break;
                }
            }
            if (!hasAsset) {
                assetsList.push(assetData);
            }
        },
        duplicateAsset(state, action) {
            const assetData = action.payload;
            const assetsList = state.draftCampaign.assets as Asset[];
            assetsList.push(assetData);
        },
        deleteAsset(state, action) {
            const assetsList = state.draftCampaign.assets as Asset[];
            const id = action.payload;
            for (let i = 0; i < assetsList.length; i++) {
                if (assetsList[i].id === id) {
                    assetsList.splice(i, 1);
                    break;
                }
            }
        },
        createTargetedAudience(state, action) {
            state.draftCampaign.audiences = action.payload;
        },
        updateWorkflowPosition(state, action) {
            // Only Node x position changes on switch (draft/live)
            const SIDE_BAR_HALF_WIDTH = 157;

            const draftNodes = state.draftCampaign.workflowDraft.nodes as { [key: string]: INode };
            const draftNode = draftNodes.root;
            if (draftNode.id === 'root') {
                draftNodes[draftNode.id] = {
                    ...draftNode,
                    position: { ...draftNode.position, x: action.payload.position / 2 - SIDE_BAR_HALF_WIDTH },
                };
            }
            const nodes = state.draftCampaign.workflow.nodes as { [key: string]: INode };
            for (const n of Object.keys(nodes)) {
                const node = nodes[n];
                if (node.id === 'root') {
                    nodes[node.id] = { ...node, position: { ...node.position, x: action.payload.position / 2 } };
                } else {
                    // switch the positions of nodes to right and left as sidebar show/hide
                    const dNode = draftNodes[node.id!];
                    nodes[node.id!] = {
                        ...node,
                        position: {
                            ...node.position,
                            x:
                                action.payload.mode === 'live'
                                    ? dNode.position.x + SIDE_BAR_HALF_WIDTH
                                    : dNode.position.x - SIDE_BAR_HALF_WIDTH,
                        },
                    };
                }
            }
        },
        updateLiveWorkflowNode(state, action) {
            const nodes = state.draftCampaign.workflow.nodes as { [key: string]: INode };
            const links = state.draftCampaign.workflow.links as { [key: string]: ILink };
            const node = action.payload as INode;
            const prevNode = nodes[node.id!];

            for (const key of Object.keys(node.ports)) {
                const linkIds = Object.keys(links);
                let isDeleted = true;
                for (const linkId of linkIds) {
                    const link = links[linkId];
                    if (
                        (link.from.portId === key && node.id === link.from.nodeId) ||
                        (link.to.portId === key && node.id === link.to.nodeId)
                    ) {
                        isDeleted = false;
                    }
                }
                if (isDeleted) {
                    prevNode.ports = { ...prevNode.ports, [key]: { ...prevNode.ports[key], type: 'none' } };
                }
            }
            nodes[node.id!] = prevNode;
        },
        updateWorkflowNode(state, action) {
            const nodes = state.draftCampaign.workflow.nodes as { [key: string]: INode };
            const links = state.draftCampaign.workflow.links as { [key: string]: ILink };
            const node = action.payload as INode;
            const prevNode = nodes[node.id!];
            // delete links
            for (const key of Object.keys(prevNode.ports)) {
                if (!node.ports[key]) {
                    const linkIds = Object.keys(links);
                    for (const linkId of linkIds) {
                        const link = links[linkId];
                        if (link.from.portId === key && link.from.nodeId === node.id) {
                            delete links[linkId];
                        }
                    }
                }
            }
            nodes[node.id!] = node;
        },
        updateWorkflowDraftNode(state, action) {
            const nodes = state.draftCampaign.workflowDraft.nodes as { [key: string]: INode };
            const links = state.draftCampaign.workflowDraft.links as { [key: string]: ILink };
            const node = action.payload as INode;
            const prevNode = nodes[node.id!];
            // delete links
            for (const key of Object.keys(prevNode.ports)) {
                if (!node.ports[key]) {
                    const linkIds = Object.keys(links);
                    for (const linkId of linkIds) {
                        const link = links[linkId];
                        if (link.from.portId === key && link.from.nodeId === node.id) {
                            delete links[linkId];
                        }
                    }
                }
            }
            nodes[node.id!] = node;
        },
        updateCampaigns(state, action) {
            state.campaigns = action.payload;
        },
        replaceDraftCampaign(state, action) {
            state.draftCampaign = action.payload;
        },
        replaceCampaignUpdatedOn(state, action) {
            state.draftCampaign.updatedOn = action.payload;
        },
        clearDraftChanges(state) {
            state.draftCampaign.workflowDraft = state.draftCampaign.workflow;
        },
        setCampaignAttributes(state, action) {
            state.campaignAttributes = action.payload;
        },
        checkIfWorkflowComponentsAreValid(state) {
            const { nodes, links } = state.draftCampaign.workflowDraft;
            const linkedNodes = new Set();
            linkedNodes.add('root');
            const q = ['root'];
            while (q.length) {
                const id = q.shift();
                const keys = Object.keys(links);
                for (const key of keys) {
                    const link = links[key];
                    if (link.from.nodeId === id) {
                        linkedNodes.add(link.to.nodeId);
                        q.push(link.to.nodeId!);
                    }
                }
            }
            for (const n of Object.keys(nodes)) {
                const node = nodes[n];
                if (!linkedNodes.has(n)) {
                    node.properties.isValid = false;
                    continue;
                }
                node.properties.isValid =
                    node.type === 'action' ? isActionValid(node) : isConditionValid(node, state.campaignAttributes);
            }
        },
        markAsNotNew(state, action) {
            const chart = state.draftCampaign.workflowDraft;
            if (chart.selected.id && chart.selected.type === 'node') {
                chart.nodes[chart.selected.id].properties.isNew = false;
                chart.selected = {};
            }
        },
        onDragNode: workflowActions('onDragNode', LIVE),
        onDragNodeStop: workflowActions('onDragNodeStop', LIVE),
        onDragCanvas: workflowActions('onDragCanvas', LIVE),
        onDragCanvasStop: workflowActions('onDragCanvasStop', LIVE),
        onLinkStart: workflowActions('onLinkStart', LIVE),
        onLinkMove: workflowActions('onLinkMove', LIVE),
        onLinkComplete: workflowActions('onLinkComplete', LIVE),
        onLinkCancel: workflowActions('onLinkCancel', LIVE),
        onLinkMouseEnter: workflowActions('onLinkMouseEnter', LIVE),
        onLinkMouseLeave: workflowActions('onLinkMouseLeave', LIVE),
        onLinkClick: workflowActions('onLinkClick', LIVE),
        onCanvasClick: workflowActions('onCanvasClick', LIVE),
        onNodeMouseEnter: workflowActions('onNodeMouseEnter', LIVE),
        onNodeMouseLeave: workflowActions('onNodeMouseLeave', LIVE),
        onDeleteKey: workflowActions('onDeleteKey', LIVE),
        onNodeClick: workflowActions('onNodeClick', LIVE),
        onNodeSizeChange: workflowActions('onNodeSizeChange', LIVE),
        onPortPositionChange: workflowActions('onPortPositionChange', LIVE),
        onCanvasDrop: workflowActions('onCanvasDrop', LIVE),
        onDragNodeDraft: workflowActions('onDragNode'),
        onDragNodeStopDraft: workflowActions('onDragNodeStop'),
        onDragCanvasDraft: workflowActions('onDragCanvas'),
        onDragCanvasStopDraft: workflowActions('onDragCanvasStop'),
        onLinkStartDraft: workflowActions('onLinkStart'),
        onLinkMoveDraft: workflowActions('onLinkMove'),
        onLinkCompleteDraft: workflowActions('onLinkComplete'),
        onLinkCancelDraft: workflowActions('onLinkCancel'),
        onLinkMouseEnterDraft: workflowActions('onLinkMouseEnter'),
        onLinkMouseLeaveDraft: workflowActions('onLinkMouseLeave'),
        onLinkClickDraft: workflowActions('onLinkClick'),
        onCanvasClickDraft: onCanvasClickCustom,
        onNodeMouseEnterDraft: workflowActions('onNodeMouseEnter'),
        onNodeMouseLeaveDraft: workflowActions('onNodeMouseLeave'),
        onDeleteKeyDraft: workflowActions('onDeleteKey'),
        onNodeClickDraft: onNodeClickWithMarkAsNotNew,
        onNodeSizeChangeDraft: workflowActions('onNodeSizeChange'),
        onPortPositionChangeDraft: workflowActions('onPortPositionChange'),
        onCanvasDropDraft: onCanvasDropAutoSelect,
    },
});

export const {
    setCampaignAttribute,
    createOrUpdateAsset,
    duplicateAsset,
    deleteAsset,
    createTargetedAudience,
    updateWorkflowNode,
    updateWorkflowDraftNode,
    updateCampaigns,
    replaceDraftCampaign,
    replaceCampaignUpdatedOn,
    clearDraftChanges,
    checkIfWorkflowComponentsAreValid,
    markAsNotNew,
    // flow char actions
    onDragNode,
    onDragNodeStop,
    onDragCanvas,
    onDragCanvasStop,
    onLinkStart,
    onLinkMove,
    onLinkComplete,
    onLinkCancel,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onLinkClick,
    onCanvasClick,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onDeleteKey,
    onNodeClick,
    onNodeSizeChange,
    onPortPositionChange,
    onCanvasDrop,

    onDragNodeDraft,
    onDragNodeStopDraft,
    onDragCanvasDraft,
    onDragCanvasStopDraft,
    onLinkStartDraft,
    onLinkMoveDraft,
    onLinkCompleteDraft,
    onLinkCancelDraft,
    onLinkMouseEnterDraft,
    onLinkMouseLeaveDraft,
    onLinkClickDraft,
    onCanvasClickDraft,
    onNodeMouseEnterDraft,
    onNodeMouseLeaveDraft,
    onDeleteKeyDraft,
    onNodeClickDraft,
    onNodeSizeChangeDraft,
    onPortPositionChangeDraft,
    onCanvasDropDraft,
    updateLiveWorkflowNode,
    updateWorkflowPosition,
    setCampaignAttributes,
} = campaignSlice.actions;

export const getCampaignAttributes =
    (campaignId: string): AppThunk =>
    async (dispatch) => {
        api.getCampaignAttributes(campaignId).then((response: ApiResponse<any>) => {
            dispatch(setCampaignAttributes(response.data));
        });
    };

export const getPersonalCampaigns = (): AppThunk => async (dispatch) => {
    api.getPersonalCampaigns().then((campaigns: ApiResponse<any>) => {
        dispatch(updateCampaigns(campaigns.data));
    });
};
export const getTeamCampaigns =
    (teamId: string): AppThunk =>
    async (dispatch) => {
        api.getTeamCampaigns(teamId).then((campaigns: ApiResponse<any>) => {
            dispatch(updateCampaigns(campaigns.data));
        });
    };
export const getCampaignById =
    (id: string): AppThunk =>
    async (dispatch) => {
        api.getCampaignById(id).then((response: ApiResponse<any>) => {
            if (response.ok) {
                const campaignData = {
                    ...response.data,
                    startDate: response.data.startDate || datetime.createLocalDateTime(),
                    startDateType: response.data.startDateType,
                    audiences: response.data.audiences,
                    workflow: JSON.parse(response.data.workflowUI),
                    workflowDraft: JSON.parse(response.data.workflowUIDraft),
                };
                dispatch(replaceDraftCampaign(campaignData));
            } else if (response.status === 404) {
                dispatch(updateApiIssue(EApiIssue.NOT_FOUND_404));
            }
        });
    };

export default campaignSlice.reducer;

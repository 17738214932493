import EmailMetricsModel, { EmailMetrics } from './emails/emailMetricsModel';
import Metrics, { IMetrics } from './metricsModel';
export interface CampaignMetrics extends IMetrics {
    contacts: number;
    emailAssets: EmailMetrics[];
    updatedOn: string;
}
class CampaignMetricsModel extends Metrics {
    contacts: number;
    emailAssets: EmailMetricsModel[];
    updatedOn: string;

    constructor(public campaignMetrics: CampaignMetrics) {
        super(campaignMetrics);

        this.contacts = campaignMetrics.contacts;
        this.emailAssets = campaignMetrics.emailAssets.map((emailAsset) => new EmailMetricsModel(emailAsset));
        this.updatedOn = campaignMetrics.updatedOn;
    }
}

export default CampaignMetricsModel;

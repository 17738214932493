import React, { MouseEvent, KeyboardEvent, useState, useEffect } from 'react';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Accordion,
    Grow,
    Popper,
    AccordionSummary,
    AccordionDetails,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Link,
    Tabs,
    Tab,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowIcon from '@material-ui/icons/ArrowDropDownCircleRounded';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format, isToday } from 'date-fns';
import { useTracking } from 'react-tracking';

import ConfirmModal from 'components/confirmModal';
import CustomTooltip from 'components/customTooltip';

import api from 'api';
import { Campaign, CampaignBackend, Team, TrackingEvent } from 'types';
import { User } from 'reducers/userSlice';
import { CAMPAIGN_STATE_TEXT, TRACKING } from 'common/constants';
import { checkUserRoleDeleteCampaignRights, isCampaignDeletable } from './campaignUtils';

import colors from 'colors';

type DashboardPropTypes = {
    campaigns: CampaignBackend[];
    getCampaigns: () => void;
    replaceDraftCampaign: (campaign: Campaign) => void;
    user: User;
    teamId: string | null;
    teams: Team[];
};

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        overflow: 'auto',
        '& > div:last-child': {
            marginBottom: 78,
        },
    },
    tabs: {
        minHeight: 0,
    },
    tab: {
        minWidth: 100,
        textTransform: 'none',
        fontSize: 16,
        lineHeight: '24px',
        padding: '0 8px 24px 8px',
        minHeight: 0,
    },
    paper: {
        padding: '4px 0',
        background: colors.dark,
        color: colors.white,
        position: 'absolute',
        right: 0,
        minWidth: 112,
    },
    tableHead: {
        '& th, td': {
            fontSize: 14,
            lineHeight: '21px',
            paddingBottom: 24,
        },
        '& th:first-child, td:first-child': {
            paddingLeft: 0,
        },
    },
    body: {
        '& th, td': {
            paddingTop: 24,
            paddingBottom: 24,
        },
        '& tr': {
            borderBottom: `1px solid ${colors.grey}`,
        },
        '& td:first-child': {
            paddingLeft: 0,
        },
        '& td:last-child': {
            paddingRight: 0,
        },
        '& tr:last-child': {
            border: 0,
        },
    },
    accordionDetails: {
        padding: '0 32px',
    },
    disabledMenu: {
        color: colors.darkGrey,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const Dashboard = ({ campaigns, getCampaigns, replaceDraftCampaign, user, teamId, teams }: DashboardPropTypes) => {
    const classes = useStyles();
    const [tab, setTab] = useState<number>(0);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [showMore, setShowMore] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedCampaign, setSelectedCampaign] = React.useState<CampaignBackend>();
    const [selectedTeam, setSelectedTeam] = React.useState<Team | null>(null);
    const [allowDelete, setAllowDelete] = React.useState<boolean>(false);
    const [showDelete, setShowDelete] = React.useState<boolean>(false);

    const { trackEvent } = useTracking<TrackingEvent>({ components: [TRACKING.COMPONENTS.DASHBOARD] });

    useEffect(() => {
        if (teams) setSelectedTeam(teams.find((team) => team.id === teamId) as Team);
    }, [teams, teamId, campaigns]);

    useEffect(() => {
        setShowDelete(isCampaignDeletable(selectedCampaign));
        setAllowDelete(checkUserRoleDeleteCampaignRights(selectedCampaign, user, teams));
    }, [selectedCampaign, user, teams]);

    const handleClick = (event: MouseEvent<HTMLAnchorElement>, id: string) => {
        if (campaigns && campaigns.length && id) {
            const campaign = campaigns.find((c) => c.id === id);
            setSelectedCampaign(campaign);
            setAnchorEl(event.currentTarget as any);
            setShowMore(true);
        }
    };
    const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
        setTab(newValue);
    };
    const handleChange = (panel: string) => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDelete = () => {
        setOpenDelete(true);
    };

    const handleDuplicate = () => {
        if (selectedCampaign) {
            trackEvent({ action: 'DuplicateCampaignClick' });
            api.duplicateCampaign(selectedCampaign).then((response) => {
                if (response.ok) {
                    history.push(`/campaigns/update/${response.data}`);
                }
            });
        }
    };

    const handleListKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setShowMore(false);
        }
    };

    const getCreatedBy = (campaign: CampaignBackend) =>
        `${campaign.createdBy!.firstName} ${campaign.createdBy!.lastName}`;

    const formatDate = (date: string) =>
        isToday(Date.parse(date)) ? 'Today' : format(Date.parse(date), 'dd MMM yyyy');

    const history = useHistory();

    const active = campaigns ? campaigns.filter((c) => c.state === 'ACTIVE') : [];
    const scheduled = campaigns ? campaigns.filter((c) => c.state === 'SCHEDULED') : [];
    const inactive = campaigns ? campaigns.filter((c) => c.state === 'INACTIVE') : [];
    const draft = campaigns ? campaigns.filter((c) => c.state === 'DRAFT') : [];

    return (
        <div className={classes.container}>
            <Tabs
                classes={{ root: classes.tabs }}
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab classes={{ root: classes.tab }} label="My campaigns" />
            </Tabs>
            <Divider />
            <div style={{ marginTop: 24 }}>
                <ConfirmModal
                    open={openDelete}
                    title={
                        (selectedCampaign || {}).state
                            ? `Delete ${CAMPAIGN_STATE_TEXT[selectedCampaign!.state]} campaign`
                            : 'Delete campaign'
                    }
                    content={`“${(selectedCampaign || {}).name}” will be deleted forever`}
                    textNo={'Cancel'}
                    textYes={'Delete'}
                    onClose={() => {
                        setOpenDelete(false);
                    }}
                    confirm={() => {
                        if (selectedCampaign!.id) {
                            api.deleteCampaign(selectedCampaign!.id).then((response) => {
                                if (response.ok) {
                                    getCampaigns();
                                    setOpenDelete(false);
                                }
                            });
                        }
                    }}
                    destructive
                />
                <Popper
                    style={{ zIndex: 999 }}
                    open={showMore}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition={true}
                    disablePortal={true}
                    placement="top-end"
                >
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps}>
                            <Paper className={classes.paper} style={{ transformOrigin: 'right top' }} elevation={2}>
                                <ClickAwayListener onClickAway={() => setShowMore(false)}>
                                    <MenuList
                                        style={{ padding: 0 }}
                                        autoFocusItem={showMore}
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
                                        {showDelete &&
                                            (allowDelete ? (
                                                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                                            ) : (
                                                <CustomTooltip
                                                    title="You are not permitted to delete this campaign. Request for admin rights from your team admin."
                                                    placement="right"
                                                    arrow={true}
                                                >
                                                    <MenuItem className={classes.disabledMenu}>Delete</MenuItem>
                                                </CustomTooltip>
                                            ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <Accordion
                    style={{ marginBottom: 16 }}
                    expanded={expanded === 'active' && active.length > 0}
                    onChange={handleChange('active')}
                    disabled={active.length === 0}
                >
                    <AccordionSummary
                        style={{ height: 72, padding: '0 32px' }}
                        expandIcon={active.length > 0 ? <ArrowIcon color="primary" /> : null}
                    >
                        <Typography style={{ fontWeight: 500 }}>{`Active Campaigns (${active.length})`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                        <Table>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell style={{ width: '50%' }}>Campaign name</TableCell>
                                    <TableCell style={{ width: '20%' }}>Started on</TableCell>
                                    <TableCell style={{ width: '20%' }}>{selectedTeam && 'Owned by'}</TableCell>
                                    <TableCell style={{ width: '10%' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.body}>
                                {active.map((c) => (
                                    <TableRow
                                        key={c.id!}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            const campaign = {
                                                ...c,
                                                workflow: JSON.parse(c.workflowUI!),
                                                workflowDraft: JSON.parse(c.workflowUIDraft!),
                                            };
                                            replaceDraftCampaign(campaign);
                                            history.push(`/campaigns/update/${campaign.id}`);
                                        }}
                                    >
                                        <TableCell style={{ border: 0 }}>{c.name}</TableCell>
                                        <TableCell style={{ border: 0 }}>
                                            {c.startedOn && formatDate(c.startedOn)}
                                        </TableCell>
                                        <TableCell style={{ border: 0 }}>{selectedTeam && getCreatedBy(c)}</TableCell>
                                        <TableCell style={{ border: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Link
                                                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                                    e.stopPropagation();
                                                    handleClick(e, c.id!);
                                                }}
                                            >
                                                <MoreIcon color="primary" />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    style={{ marginBottom: 16 }}
                    expanded={expanded === 'scheduled' && scheduled.length > 0}
                    onChange={handleChange('scheduled')}
                    disabled={scheduled.length === 0}
                >
                    <AccordionSummary
                        style={{ height: 72, padding: '0 32px' }}
                        expandIcon={scheduled.length > 0 ? <ArrowIcon color="primary" /> : null}
                    >
                        <Typography
                            style={{ fontWeight: 500 }}
                        >{`Scheduled Campaigns (${scheduled.length})`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                        <Table>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell style={{ width: '50%' }}>Campaign name</TableCell>
                                    <TableCell style={{ width: '20%' }}>Starting on</TableCell>
                                    <TableCell style={{ width: '20%' }}>{selectedTeam && 'Owned by'}</TableCell>
                                    <TableCell style={{ width: '10%' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.body}>
                                {scheduled.map((c) => (
                                    <TableRow
                                        key={c.id!}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            const campaign = {
                                                ...c,
                                                workflow: JSON.parse(c.workflowUI!),
                                                workflowDraft: JSON.parse(c.workflowUIDraft!),
                                            };
                                            replaceDraftCampaign(campaign);
                                            history.push(`/campaigns/update/${campaign.id}`);
                                        }}
                                    >
                                        <TableCell style={{ border: 0 }}>{c.name}</TableCell>
                                        <TableCell style={{ border: 0 }}>
                                            {c.scheduledOn && formatDate(c.scheduledOn)}
                                        </TableCell>
                                        <TableCell style={{ border: 0 }}>{selectedTeam && getCreatedBy(c)}</TableCell>
                                        <TableCell style={{ border: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Link
                                                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                                    e.stopPropagation();
                                                    handleClick(e, c.id!);
                                                }}
                                            >
                                                <MoreIcon color="primary" />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    style={{ marginBottom: 16 }}
                    expanded={expanded === 'saved' && draft.length > 0}
                    onChange={handleChange('saved')}
                    disabled={draft.length === 0}
                >
                    <AccordionSummary
                        style={{ height: 72, padding: '0 32px' }}
                        expandIcon={draft.length > 0 ? <ArrowIcon color="primary" /> : null}
                    >
                        <Typography style={{ fontWeight: 500 }}>{`Draft Campaigns (${draft.length})`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                        <Table>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell style={{ width: '50%' }}>Campaign name</TableCell>
                                    <TableCell style={{ width: '20%' }}>Last updated</TableCell>
                                    <TableCell style={{ width: '20%' }}>{selectedTeam && 'Owned by'}</TableCell>
                                    <TableCell style={{ width: '10%' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.body}>
                                {draft.map((c) => (
                                    <TableRow
                                        key={c.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            const campaign = {
                                                ...c,
                                                workflow: JSON.parse(c.workflowUI!),
                                                workflowDraft: JSON.parse(c.workflowUIDraft!),
                                            };
                                            replaceDraftCampaign(campaign);
                                            history.push(`/campaigns/update/${campaign.id}`);
                                        }}
                                    >
                                        <TableCell style={{ border: 0 }}>{c.name}</TableCell>
                                        <TableCell style={{ border: 0 }}>
                                            {formatDate(c.updatedOn || c.createdOn!)}
                                        </TableCell>
                                        <TableCell style={{ border: 0 }}>{selectedTeam && getCreatedBy(c)}</TableCell>
                                        <TableCell style={{ border: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Link
                                                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                                    e.stopPropagation();
                                                    handleClick(e, c.id!);
                                                }}
                                            >
                                                <MoreIcon color="primary" />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'inactive' && inactive.length > 0}
                    onChange={handleChange('inactive')}
                    disabled={inactive.length === 0}
                >
                    <AccordionSummary
                        style={{ height: 72, padding: '0 32px' }}
                        expandIcon={inactive.length > 0 ? <ArrowIcon color="primary" /> : null}
                    >
                        <Typography style={{ fontWeight: 500 }}>{`Inactive Campaigns (${inactive.length})`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                        <Table>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell style={{ width: '50%' }}>Campaign name</TableCell>
                                    <TableCell style={{ width: '20%' }}>Started on</TableCell>
                                    <TableCell style={{ width: '20%' }}>{selectedTeam && 'Owned by'}</TableCell>
                                    <TableCell style={{ width: '10%' }} />
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.body}>
                                {inactive.map((c) => (
                                    <TableRow
                                        key={c.id!}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            const campaign = {
                                                ...c,
                                                workflow: JSON.parse(c.workflowUI!),
                                                workflowDraft: JSON.parse(c.workflowUIDraft!),
                                            };
                                            replaceDraftCampaign(campaign);
                                            history.push(`/campaigns/update/${campaign.id}`);
                                        }}
                                    >
                                        <TableCell style={{ border: 0 }}>{c.name}</TableCell>
                                        <TableCell style={{ border: 0 }}>
                                            {c.startedOn && formatDate(c.startedOn)}
                                        </TableCell>
                                        <TableCell style={{ border: 0 }}>{selectedTeam && getCreatedBy(c)}</TableCell>
                                        <TableCell style={{ border: 0, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Link
                                                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                                    e.stopPropagation();
                                                    handleClick(e, c.id!);
                                                }}
                                            >
                                                <MoreIcon color="primary" />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.userSlice.user,
    teamId: state.userSlice.dashboardSelected !== 'personal' ? state.userSlice.dashboardSelected : null,
    teams: state.teamSlice.teams,
});

export default connect(mapStateToProps)(Dashboard);

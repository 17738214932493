import React from 'react';
import { connect } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import { RootState } from 'reducers';

import colors from 'colors';

import SideMenu from 'components/sideMenu';
import Header from 'components/header';
import BaseHeader from 'components/baseHeader';
import NewAudience from './newAudience';
import NewContacts from './newContacts';
import Contacts from './contacts/index';
import Dashboard from './dashboard';
import PageNotFound from 'features/pageNotFound';
import ResolveConflicts from './import/resolveConflicts';
import ImportComplete from './import/importComplete';
import CopyRight from '../../components/copyRight';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        background: colors.backgroundGrey,
    },
    subcontainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        height: '100vh',
    },
}));

interface AudienceProps {
    selectedTeamId: string | null;
}

const Audience = ({ selectedTeamId }: AudienceProps) => {
    const classes = useStyles();

    return (
        <>
            <Typography component="div" className={classes.container}>
                <div>
                    <div className={classes.subcontainer}>
                        <Switch>
                            {/* Personal audience new */}
                            <Route exact={true} path={`/audience/new`}>
                                <BaseHeader title="Create an audience" backButton />
                                <NewAudience />
                            </Route>
                            {/* Team audience new */}
                            <Route exact={true} path={`/audience/teams/:teamId/new`}>
                                <BaseHeader title="Create an audience" backButton />
                                <NewAudience />
                            </Route>
                            {/* Personal contacts */}
                            <Route exact={true} path={`/audience/:audienceId/contacts/new`}>
                                <NewContacts />
                            </Route>
                            {/* Team contacts */}
                            <Route exact={true} path={`/audience/teams/:teamId/:audienceId/contacts/new`}>
                                <NewContacts />
                            </Route>

                            <Route path={`/audience/:audienceId/contacts`}>
                                <Header />
                                <Box display="flex" overflow="auto">
                                    <SideMenu active="audience" teamId={selectedTeamId} />
                                    <Contacts tabName={'contacts'} />
                                </Box>
                            </Route>

                            <Route path={'/audience/:audienceId/attributes'}>
                                <Header />
                                <Box display="flex" overflow="auto">
                                    <SideMenu active="audience" teamId={selectedTeamId} />
                                    <Contacts tabName={'attributes'} />
                                </Box>
                            </Route>

                            <Route path={'/audience/:audienceId/conflicts'}>
                                <Header />
                                <Box display="flex" overflow="auto">
                                    <ResolveConflicts />
                                </Box>
                            </Route>

                            <Route path={'/audience/:audienceId/import-complete'}>
                                <Header />
                                <Box display="flex" overflow="auto">
                                    <ImportComplete />
                                </Box>
                            </Route>

                            <Route exact={true} path={'/audience/teams/:teamId'}>
                                <>
                                    <Header />
                                    <Box display="flex" overflow="auto">
                                        <SideMenu active="audience" teamId={selectedTeamId} />
                                        <Dashboard />
                                    </Box>
                                </>
                            </Route>

                            <Route exact={true} path={'/audience'}>
                                <>
                                    <Header />
                                    <Box display="flex" overflow="auto">
                                        <SideMenu active="audience" />
                                        <Dashboard />
                                    </Box>
                                </>
                            </Route>
                            <Route path="*">
                                <PageNotFound />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Typography>
            <CopyRight placement={'postLogin'} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTeamId: state.userSlice.dashboardSelected !== 'personal' ? state.userSlice.dashboardSelected : null,
});

export default connect(mapStateToProps)(Audience);

import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import api from 'api';
import { ApiResponse } from 'apisauce';
import { Paper, Typography, Box, InputLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isPasswordValid, passwordHelperText } from 'utils/password';
import { BackendErrorMessagePhrase, EApiIssue } from 'types';
import CopyRight from 'components/copyRight';
import Message from 'components/message';
import PasswordField from 'components/passwordField';
import { SUPPORT_EMAIL_MSG } from 'common/constants';
import colors from 'colors';
import { updateApiIssue } from 'reducers/userSlice';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.lightGrey,
    },
    formContainer: {
        padding: '64px 84px',
        width: 'calc(100% - 32px)',
        maxWidth: 604,
    },
}));

interface UpdatePasswordProps {
    apiIssue?: EApiIssue;
    updateApiIssue?: (apiIssue: EApiIssue) => void;
}

const UpdatePassword = ({ apiIssue, updateApiIssue }: UpdatePasswordProps) => {
    const classes = useStyles();
    const history = useHistory();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const [password, setPassword] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [serverError, setServerError] = useState<string>('');
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [updatePassword, setUpdatePassword] = useState<string>('');
    const [serverError500, setServerError500] = useState<string>('');
    const resetPasswordToken = query.get('token');
    const isMounted = useRef(false);
    const handleButtonClick = () => {
        setShowError(true);
        if (password && resetPasswordToken && isPasswordValid(password)) setUpdatePassword(password);
    };
    useEffect(() => {
        if (isMounted.current) {
            api.updatePassword({
                newPassword: password,
                resetPasswordToken: resetPasswordToken!,
            }).then((response: ApiResponse<any>) => {
                if (response.ok) return setShowSuccess(true);
                if (response.data && response.data.status === 401)
                    return setServerError('Password reset link is no longer active. Request for a new link.');
                if (response.data && response.data.status === 400) {
                    switch (response.data.message) {
                        case BackendErrorMessagePhrase.PASSWORD_REUSED:
                            return setServerError(passwordHelperText.used);
                        case BackendErrorMessagePhrase.PASSWORD_SAME_EMAIL:
                            return setServerError(passwordHelperText.email);
                        default:
                            setServerError500(SUPPORT_EMAIL_MSG);
                    }
                }
                if (!response.ok) return setServerError500(SUPPORT_EMAIL_MSG);
            });
        } else {
            isMounted.current = true;
        }
    }, [updatePassword]);

    useEffect(() => {
        setServerError500(apiIssue === EApiIssue.SERVER_500 ? SUPPORT_EMAIL_MSG : '');
    }, [apiIssue]);

    return (
        <div className={classes.container}>
            <Paper className={classes.formContainer} elevation={1}>
                {!showSuccess && (
                    <>
                        <Box textAlign="center">
                            <Typography variant="h1">Update password</Typography>
                            <Typography style={{ marginTop: 8, marginBottom: 32 }}>
                                Set a new password for your Personalise account
                            </Typography>
                        </Box>
                        <InputLabel>New password</InputLabel>
                        <form>
                            <PasswordField
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setServerError('');
                                    setShowError(false);
                                }}
                                password={password}
                                showError={showError}
                                serverError={serverError}
                            />
                            {serverError500 && <Message message={serverError500} style={{ margin: '24px 0 0 0' }} />}
                            <Button
                                onClick={handleButtonClick}
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: !serverError500 ? 40 : 24 }}
                            >
                                Update password
                            </Button>
                        </form>
                    </>
                )}
                {showSuccess && (
                    <Box textAlign="center">
                        <Typography variant="h1">Success!</Typography>
                        <Typography style={{ marginTop: 24 }}>
                            Your password has been updated. Use the new password to login to your Personalise account
                        </Typography>
                        <Button
                            onClick={() => {
                                if (updateApiIssue) updateApiIssue(EApiIssue.NONE);
                                history.push('/signin');
                            }}
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ marginTop: 40 }}
                        >
                            Return to login
                        </Button>
                    </Box>
                )}
            </Paper>
            <CopyRight placement={'preLogin'} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    apiIssue: state.userSlice.apiIssue,
});

const mapDispatchToProps = {
    updateApiIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);

import React, { useState } from 'react';
import { Box, InputLabel, Typography, TextField, Button, Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ApiResponse } from 'apisauce';
import api from 'api';
import BaseModal from 'components/baseModal';
import { pluralise } from 'utils/pluralise';
import { AttributeFixedText } from './attributeFixedText';
import { UserAttributeBackend, CampaignBackend, AttributeDataType, CampaignStateType, Campaign } from 'types';
import { checkDups, getAttributeValueCampaigns } from './../audienceUtils';
import CustomTooltip from 'components/customTooltip';
import DeleteTooltip from './../audienceDeleteTooltip';

import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    textField: {
        marginBottom: 32,
    },
    subTitle: {
        textAlign: 'right',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: colors.white,
    },
    footer: {
        padding: '24px 32px',
        borderTop: `1px solid ${colors.grey}`,
        textAlign: 'right',
    },
}));

type AttributeUsage = 'attribute' | 'value';
interface AttributeEditModalProps {
    title: string;
    open: boolean;
    audienceId: string | null;
    attributeCampaigns: CampaignBackend[];
    selectedAttribute: UserAttributeBackend;
    campaigns: CampaignBackend[];
    attributes: UserAttributeBackend[];
    selectedTeamId: string | null;
    setAttributeUsageType: (value: AttributeUsage) => void;
    setAttributeValueCampaigns: (campaigns: CampaignBackend[]) => void;
    setSelectedAttribute: (attribute: UserAttributeBackend) => void;
    setAttributes: (data: UserAttributeBackend[]) => void;
    handleClose: () => void;
    openDelete: (value: boolean) => void;
    setShowUsage: (isShow: boolean) => void;
    setDeleteValue: (value: string) => void;
    getPersonalCampaigns: () => void;
    getTeamCampaigns: (teamId: string) => void;
}

const AttributeEditModal = ({
    title,
    open,
    audienceId,
    attributeCampaigns,
    selectedAttribute,
    campaigns,
    selectedTeamId,
    setAttributeUsageType,
    setAttributeValueCampaigns,
    setSelectedAttribute,
    attributes,
    setAttributes,
    handleClose,
    openDelete,
    setShowUsage,
    setDeleteValue,
    getPersonalCampaigns,
    getTeamCampaigns,
}: AttributeEditModalProps) => {
    const classes = useStyles();
    const [anchorUsageEl, setAnchorUsageEl] = useState(null);
    const [hasError, setHasError] = React.useState<boolean>(false);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAttribute({ ...selectedAttribute, [e.target.name]: e.target.value });
    };

    const handleCloseUsage = () => {
        setAnchorUsageEl(null);
    };

    const updateCampaign = async (campaign: CampaignBackend) => {
        try {
            const { workflowUIDraft, workflowUI } = campaign;
            const parsedWorkflowUIDraft = JSON.parse(workflowUIDraft!);
            const parsedWorkflowUI = JSON.parse(workflowUI!);
            const { nodes } = parsedWorkflowUIDraft;
            const { nodes: nodesWorkFlowUI } = parsedWorkflowUI;
            const attribute = attributes.find((attribute) => attribute.name === selectedAttribute.name);
            if (!attribute || attribute.displayName === selectedAttribute.displayName) {
                return Promise.resolve('ok');
            }

            // workflowUIDraft
            for (const [key, value] of Object.entries(nodes)) {
                if (
                    nodes[key].type === 'condition' &&
                    nodes[key].properties.subtype === 'checkAttribute' &&
                    nodes[key].properties.attribute === selectedAttribute.name
                ) {
                    // Update display name
                    nodes[key].properties.attributeDisplayName = selectedAttribute.displayName;
                    // workflowUI
                    if (campaign.isStarted && campaign.state !== CampaignStateType.SCHEDULED) {
                        nodesWorkFlowUI[key].properties.attributeDisplayName = selectedAttribute.displayName;
                    }
                }
            }

            const updatedCampaign = {
                ...campaign,
                workflowUI: JSON.stringify({
                    ...parsedWorkflowUI,
                    nodes: nodesWorkFlowUI,
                }),
                workflowUIDraft: JSON.stringify({
                    ...parsedWorkflowUIDraft,
                    nodes,
                }),
            };
            api.saveCampaign(updatedCampaign).then((response: ApiResponse<any>) => {
                return Promise.resolve('ok');
            });
        } catch (err) {
            return Promise.resolve('ok');
        }
    };

    const handleCampaigns = (campaigns: CampaignBackend[]) => {
        const campaignsList = campaigns.filter((campaign: CampaignBackend) =>
            campaign.audiences!.some((a: any) => a.id === audienceId),
        );
        const promise = Promise.all(
            campaignsList.map((campaign: CampaignBackend) => {
                updateCampaign(campaign);
            }),
        );
        promise.then(() => {
            setTimeout(() => {
                selectedTeamId ? getTeamCampaigns(selectedTeamId) : getPersonalCampaigns();
            }, 500);
        });
    };

    const isAttributeValueUsed = (campaigns: CampaignBackend[]) => {
        let isUsed = false;
        const attribute = attributes.find((a) => a.name === selectedAttribute.name);
        if (!attribute) return false;
        attribute!.values!.map((value, index) => {
            const attributeValueCampaigns = getAttributeValueCampaigns(campaigns, audienceId!, attribute, value);
            const valueExists =
                attributeValueCampaigns.length > 0 && selectedAttribute!.values![index] !== attribute!.values![index];

            if (valueExists) {
                isUsed = true;
                selectedAttribute.values![index] = attribute!.values![index];
                selectedAttribute = { ...selectedAttribute, values: [...(selectedAttribute.values as string[])] };
                setSelectedAttribute(selectedAttribute);
            }
        });
        return isUsed;
    };

    const isValidForm = () => {
        setHasError(!selectedAttribute.displayName);
        return (
            (selectedAttribute.displayName !== '' && selectedAttribute.type === AttributeDataType.TEXT) ||
            (selectedAttribute.displayName !== '' &&
                selectedAttribute.type === AttributeDataType.FIXED_TEXT &&
                selectedAttribute.values!.length > 0 &&
                !selectedAttribute.values?.some((value) => value === '') &&
                !checkDups(selectedAttribute.values!))
        );
    };

    const updateAttribute = () => {
        if (audienceId) {
            api.updateAttribute(audienceId, selectedAttribute).then((response: ApiResponse<any>) => {
                if (response.ok) {
                    api.getAttributes(audienceId).then((response: ApiResponse<any>) => {
                        if (response.ok) {
                            setAttributes(response.data[audienceId]);
                            handleClose();
                        }
                    });

                    if (selectedAttribute.type === AttributeDataType.FIXED_TEXT) {
                        if (selectedTeamId) {
                            api.getTeamCampaigns(selectedTeamId).then((response: ApiResponse<any>) => {
                                if (response.ok) handleCampaigns(response.data);
                            });
                        } else {
                            api.getPersonalCampaigns().then((response: ApiResponse<any>) => {
                                if (response.ok) handleCampaigns(response.data);
                            });
                        }
                    }
                }
            });
        }
    };

    const handleSubmit = async () => {
        if (selectedAttribute.type === AttributeDataType.TEXT) {
            if (isValidForm()) updateAttribute();
        } else {
            selectedTeamId ? getTeamCampaigns(selectedTeamId) : getPersonalCampaigns();
            let valueUsed = false;
            selectedTeamId
                ? await api.getTeamCampaigns(selectedTeamId).then((response: ApiResponse<any>) => {
                      if (response.ok) {
                          valueUsed = isAttributeValueUsed(response.data);
                      }
                  })
                : await api.getPersonalCampaigns().then((response: ApiResponse<any>) => {
                      if (response.ok) {
                          valueUsed = isAttributeValueUsed(response.data);
                      }
                  });

            if (audienceId && isValidForm() && !valueUsed) {
                updateAttribute();
            }
        }
    };

    const SecondaryAction = ({ onClick }: { onClick?: () => void }) => {
        const classes = useStyles();
        return (
            <>
                {selectedAttribute && !attributeCampaigns.length ? (
                    <Link onClick={onClick}>
                        <Typography variant="body1" className={classes.subTitle}>
                            Delete attribute
                        </Typography>
                    </Link>
                ) : (
                    <CustomTooltip
                        title={
                            <DeleteTooltip
                                title={`This attribute cannot be deleted as it is being used to personalise ${
                                    attributeCampaigns.length
                                } campaign ${pluralise(attributeCampaigns.length, 'workflow')}`}
                                onClick={() => {
                                    handleCloseUsage();
                                    setShowUsage(true);
                                }}
                            />
                        }
                        placement={'bottom'}
                        arrow={true}
                        interactive={true}
                    >
                        <Link aria-disabled={true}>
                            <Typography variant="body1" className={classes.subTitle} style={{ color: colors.coolGrey }}>
                                Delete attribute
                            </Typography>
                        </Link>
                    </CustomTooltip>
                )}
            </>
        );
    };

    return (
        <>
            <BaseModal
                title={title}
                open={open}
                handleClose={handleClose}
                maxWidth="lg"
                scroll="body"
                secondaryAction={<SecondaryAction onClick={() => openDelete(true)} />}
            >
                <Box width={704} minHeight={680} className={classes.container}>
                    <Box px={4} pt={4} pb={4}>
                        <InputLabel>Attribute name</InputLabel>
                        <TextField
                            autoComplete="attributeName"
                            variant="outlined"
                            className={classes.textField}
                            fullWidth={true}
                            name="displayName"
                            type="text"
                            placeholder={'Name of attribute'}
                            value={selectedAttribute.displayName}
                            onChange={handleOnChange}
                            error={hasError && !selectedAttribute.displayName}
                            helperText={hasError && !selectedAttribute.displayName ? 'Give this attribute a name' : ''}
                        />
                        {selectedAttribute?.type === 'FIXED_TEXT' && (
                            <AttributeFixedText
                                selectedAttribute={selectedAttribute}
                                campaigns={campaigns}
                                setAttributeValueCampaigns={setAttributeValueCampaigns}
                                setAttributeUsageType={setAttributeUsageType}
                                setShowUsage={setShowUsage}
                                setSelectedAttribute={setSelectedAttribute}
                                setDeleteValue={setDeleteValue}
                                attributes={attributes}
                            />
                        )}
                        <InputLabel>Description (optional)</InputLabel>
                        <TextField
                            autoComplete="attributeDescription"
                            variant="outlined"
                            className={classes.textField}
                            fullWidth={true}
                            name="description"
                            type="text"
                            placeholder={'Add a description'}
                            value={selectedAttribute.description}
                            onChange={handleOnChange}
                        />
                    </Box>
                    <Box className={classes.footer}>
                        <Button variant={'contained'} color={'primary'} onClick={handleSubmit}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </BaseModal>
        </>
    );
};

export default AttributeEditModal;

import React, { useState } from 'react';
import { Box, TextField, Divider, Button, Typography } from '@material-ui/core';
import BaseModal from 'components/baseModal';

interface CreateTeamModalProps {
    open: boolean;
    handleClose: () => void;
    createTeam: (name: string) => void;
}

const CreateTeamModal = ({ open, handleClose, createTeam }: CreateTeamModalProps) => {
    const [showError, setShowError] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    return (
        <BaseModal title="Create new team" open={open} handleClose={handleClose} maxWidth="lg">
            <Box width={493}>
                <Box px={4} pt={4} pb={15}>
                    <Typography variant="body1" style={{ paddingBottom: 24, fontWeight: 500 }}>
                        Give your team a name
                    </Typography>
                    <TextField
                        autoFocus
                        variant="outlined"
                        placeholder="Team name"
                        fullWidth
                        value={name}
                        error={showError && !name}
                        helperText={showError && !name && 'Enter a team name'}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Box>
                <Divider />
                <Box px={4} py={3} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setShowError(true);
                            if (name) {
                                createTeam(name);
                                handleClose();
                                setName('');
                                setShowError(false);
                            }
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </BaseModal>
    );
};

export default CreateTeamModal;

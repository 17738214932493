import * as React from 'react';
import { IConfig, INode } from '@mrblenny/react-flow-chart';
import PortsGroup from './portsGroup';

export interface IPortsDefaultProps {
    config: IConfig;
    node: INode;
    children: React.ReactElement<any>[];
}

const sortBottomPorts = (bottomPorts: React.ReactElement<any>[]) => {
    const elsePortIndex = bottomPorts.findIndex((bottomPort) => bottomPort.key === 'else');
    if (elsePortIndex > -1) {
        const elsePort = bottomPorts.splice(elsePortIndex, 1);
        bottomPorts.push(elsePort as unknown as React.ReactElement<any>);
    }
};

const Ports = ({ children, config }: IPortsDefaultProps) => {
    const topPorts = children.filter((child) => ['input', 'top'].includes(child.props.port.type));
    const bottomPorts = children.filter((child) => ['output', 'bottom'].includes(child.props.port.type));
    const leftPorts = children.filter((child) => ['left'].includes(child.props.port.type));
    const rightPorts = children.filter((child) => ['right'].includes(child.props.port.type));
    const offsets = {
        top: topPorts.some((p) => p.props.port.properties && p.props.port.properties.value) ? -12 : 0,
        bottom: bottomPorts.some((p) => p.props.port.properties && p.props.port.properties.value) ? -12 : 0,
        left: leftPorts.some((p) => p.props.port.properties && p.props.port.properties.value) ? -12 : 0,
        right: rightPorts.some((p) => p.props.port.properties && p.props.port.properties.value) ? -12 : 0,
    };
    sortBottomPorts(bottomPorts);
    return (
        <div>
            <PortsGroup config={config} side="top" offsets={offsets}>
                {topPorts}
            </PortsGroup>
            <PortsGroup config={config} side="bottom" offsets={offsets}>
                {bottomPorts}
            </PortsGroup>
            <PortsGroup config={config} side="left" offsets={offsets}>
                {leftPorts}
            </PortsGroup>
            <PortsGroup config={config} side="right" offsets={offsets}>
                {rightPorts}
            </PortsGroup>
        </div>
    );
};

export default Ports;

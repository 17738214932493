import React, { useEffect, useState, MouseEvent } from 'react';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Box, Paper, Button, MenuItem, Popper, Grow, ClickAwayListener, MenuList, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Team, Audience, EApiIssue, CampaignBackend, AudienceUsageData, NotificationData } from 'types';
import api from 'api';
import { getTeamNameById, isTeamValid } from '../team/teamUtils';
import colors from 'colors';
import { getPersonalAudiences, getTeamAudiences } from './audienceSlice';
import { updateDashboardSelected, updateApiIssue, User } from 'reducers/userSlice';
import EmptyPage from 'components/emptyPage';
import DashboardTitle from 'components/dashboardTitle';
import AudienceUsageModal from './audienceUsageModal';
import ConfirmModal from 'components/confirmModal';
import DeleteTooltip from './audienceDeleteTooltip';

import {
    isAudienceDeletable,
    getAudienceUsageData,
    getAudienceName,
    getAudience,
    isAudienceImporting,
    canManageAudience,
} from './audienceUtils';
import CustomTooltip from 'components/customTooltip';
import { getPersonalCampaigns, getTeamCampaigns } from './../campaigns/campaignSlice';
import { ApiResponse } from 'apisauce';
import { pluralise, pluraliseVerb } from 'utils/pluralise';
import { formatNumberWithCommas as formatNumber } from 'utils/numbers';
import AlertBar from 'components/alertBar';
import AudienceRow from './audienceRow';

interface DashboardProps {
    selectedTeamId: string | null;
    teams: Team[];
    user: User;
    audiences: Audience[];
    audiencesPending: string[];
    getPersonalAudiences: () => void;
    getTeamAudiences: (teamId: string) => void;
    selectedDashboard: string | null;
    updateDashboardSelected: (dashboard: string) => void;
    updateApiIssue: (apiIssue: EApiIssue) => void;
    campaigns: CampaignBackend[];
    getPersonalCampaigns: () => void;
    getTeamCampaigns: (teamId: string) => void;
}

const useStyles = makeStyles(() => ({
    container: {
        padding: '40px 32px 0px',
        width: '100%',
        overflow: 'auto',
        '& > div:last-child': {
            marginBottom: 78,
        },
    },
    list: {
        padding: 0,
    },
    paper: {
        padding: '4px 0',
        background: colors.dark,
        color: colors.white,
        position: 'absolute',
        right: 0,
        top: -23,
    },
    disabledMenu: {
        color: colors.darkGrey,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const Dashboard = ({
    selectedTeamId,
    teams,
    user,
    audiences,
    audiencesPending,
    getPersonalAudiences,
    getTeamAudiences,
    updateDashboardSelected,
    campaigns,
    getTeamCampaigns,
    getPersonalCampaigns,
}: DashboardProps) => {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDeleteAudience, setShowDeleteAudience] = useState<boolean>(false);
    const [selectedAudienceId, setSelectedAudienceId] = useState<string>('');
    const [selectedAudienceName, setSelectedAudienceName] = useState<string>('');
    const [showAudienceUsage, setShowAudienceUsage] = useState<boolean>(false);
    const [audienceUsageData, setAudienceUsageData] = useState<AudienceUsageData[] | null>(null);
    const { pathname: path } = history.location;
    const { teamId } = useParams<{ teamId: string }>();
    const [conflictedAudiences, setConflictedAudiences] = useState<Audience[]>([]);
    const [importErrorNotifications, setImportErrorNotifications] = useState<NotificationData[]>([]);
    const [importCompleteNotifications, setImportCompleteNotifications] = useState<NotificationData[]>([]);

    const was = (count: number) => pluraliseVerb(count, 'was', 'were');
    const contact = (count: number) => pluralise(count, 'contact');

    const goToAudienceContactsPage = (audienceId: string) => history.push(`/audience/${audienceId}/contacts`);
    const goToImportCompletePage = (audienceId: string) => history.push(`/audience/${audienceId}/import-complete`);
    const goToResolveConflictsPage = (audienceId: string) => history.push(`/audience/${audienceId}/conflicts`);

    useEffect(() => {
        if (teamId && teams.length > 0 && isTeamValid(teamId, teams)) {
            updateDashboardSelected(teamId);
            getTeamAudiences(teamId);
            getTeamCampaigns(teamId);
        } else if (!teamId) {
            updateDashboardSelected('personal');
            getPersonalAudiences();
            getPersonalCampaigns();
        }
    }, [teamId, teams, audiencesPending]);

    useEffect(() => {
        if (audiences && audiences.length) {
            getNotifications(audiences);
            getConflictedAudiences(audiences);
        }
    }, [audiencesPending, audiences]);

    useEffect(() => {
        setAudienceUsageData(getAudienceUsageData(selectedAudienceId, campaigns));
    }, [selectedAudienceId]);

    const getConflictedAudiences = (audiences: Audience[]) => {
        setConflictedAudiences(
            audiences.filter(
                (audience) =>
                    audience.state === 'CONFLICTS' &&
                    audience.importedBy!.id == user.id &&
                    !audiencesPending.find((audienceId) => audienceId === audience.id),
            ),
        );
    };

    const filterImportCompleteAndErrorNotifications = (importSummary: NotificationData[], audiences: Audience[]) => {
        const importCompleteNotifcations: NotificationData[] = [];
        const importErrorNotifcations: NotificationData[] = [];
        importSummary.map((notification) => {
            const audienceId = notification.topic?.split('.', 3)[0];
            const audience = getAudience(audienceId ? audienceId : '', audiences);
            if (audience) {
                notification.audienceId = audienceId;
                notification.audience = audience;
                if (notification && notification.topic?.endsWith('import.completed')) {
                    importCompleteNotifcations.push(notification);
                } else if (notification && notification.topic?.endsWith('import.errors')) {
                    importErrorNotifcations.push(notification);
                }
            }
        });
        return [importCompleteNotifcations, importErrorNotifcations];
    };

    const getImportSummary = (notifications: NotificationData[]) => {
        const requests = notifications.reduce(
            (notifcationRequests: Promise<NotificationData>[], notification: NotificationData) => {
                if (notification.audienceId) {
                    notifcationRequests.push(
                        api
                            .getImportSummary(notification.audienceId)
                            .then((importSummaryResponse: ApiResponse<any>) => {
                                if (importSummaryResponse.ok) {
                                    notification.content = importSummaryResponse.data;
                                }
                                return notification;
                            }),
                    );
                }
                return notifcationRequests;
            },
            [],
        );
        return Promise.all(requests);
    };

    const getNotifications = (audiences: Audience[]) => {
        api.getNotifications().then((response: ApiResponse<any>) => {
            if (response.ok) {
                const [importCompleteNotifcations, importErrorNotifications] =
                    filterImportCompleteAndErrorNotifications(response.data, audiences);
                getImportSummary(importCompleteNotifcations).then((importCompleteNotifcationSummary) =>
                    setImportCompleteNotifications(importCompleteNotifcationSummary),
                );
                getImportSummary(importErrorNotifications).then((importErrorNoticiationSummary) => {
                    setImportErrorNotifications(importErrorNoticiationSummary);
                });
            }
        });
    };

    const handleClick = (event: MouseEvent<HTMLAnchorElement>, id: string) => {
        setAnchorEl(event.currentTarget as any);
        setSelectedAudienceId(id);
        setSelectedAudienceName(getAudienceName(id, audiences) || '');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteAudienceClick = () => {
        setShowDeleteAudience(true);
        handleClose();
    };

    const handleRowClick = (audience: Audience) => {
        if (audience && !isAudienceImporting(audience.state)) {
            goToAudienceContactsPage(audience.id);
        }
    };

    const handleManageAudience = () => goToAudienceContactsPage(selectedAudienceId);

    const handleDeleteAudience = () => {
        if (selectedAudienceId) {
            api.deleteAudience(selectedAudienceId).then((response) => {
                if (response.ok) {
                    teamId ? getTeamAudiences(teamId) : getPersonalAudiences();
                    setShowDeleteAudience(false);
                }
            });
        }
    };

    const renderNotification = (
        key: string,
        buttonHandle: () => void,
        buttonText: string,
        mainText: string,
        type: 'success' | 'warning',
    ) => {
        const buttonColor = type === 'success' ? colors.green : colors.yellow;
        return (
            <Box key={key} mb={2}>
                <AlertBar
                    type={type}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }} className={classes.ellipsis}>
                            {mainText}
                            <Button
                                onClick={buttonHandle}
                                style={{ marginLeft: '0.5rem', color: buttonColor }}
                                size="small"
                            >
                                <u>{buttonText}</u>
                            </Button>
                        </span>
                    }
                />
            </Box>
        );
    };

    return (
        <div className={classes.container}>
            <DashboardTitle
                title={`${selectedTeamId ? getTeamNameById(selectedTeamId, teams) : 'Personal'} Audiences`}
                subtitle="Create and manage audiences for your campaigns"
                button={
                    audiences &&
                    audiences.length > 0 && (
                        <Button variant="contained" color="primary" onClick={() => history.push(`${path}/new`)}>
                            Create new audience
                        </Button>
                    )
                }
                isTechPassUser={user.isTechPassUser}
            />

            {importCompleteNotifications.map((notification) => {
                const content = notification.content;
                const updateCount = content?.updateCount || 0;
                const addCount = content?.addCount || 0;

                const buttonText = 'View audience';
                const buttonHandle = () => goToAudienceContactsPage(notification.audienceId!);

                let mainText = `${notification.audience?.name} import is complete.`;
                if (addCount > 0 && updateCount > 0) {
                    mainText = `${mainText} ${formatNumber(addCount)} new ${contact(addCount)} ${was(
                        addCount,
                    )} added and 
                    ${formatNumber(updateCount)} existing ${contact(updateCount)} ${was(updateCount)} updated.`;
                } else if (addCount > 0) {
                    mainText = `${mainText} ${formatNumber(addCount)} new ${contact(addCount)} ${was(addCount)} added.`;
                } else if (updateCount > 0) {
                    mainText = `${mainText} ${formatNumber(updateCount)} new ${contact(updateCount)} ${was(
                        updateCount,
                    )} updated.`;
                } else {
                    mainText = `${mainText} No changes were made.`;
                }

                return renderNotification(notification.topic, buttonHandle, buttonText, mainText, 'success');
            })}

            {importErrorNotifications.map((notification) => {
                const content = notification.content;
                const issueCount = content?.issuesCount || 0;

                const buttonHandle = () => goToImportCompletePage(notification.audienceId!);
                const mainText = `${notification.audience?.name} import is complete. ${issueCount} ${contact(
                    issueCount,
                )} ${pluraliseVerb(issueCount, 'has', 'have')} issues. `;
                const buttonText = 'View issues';

                return renderNotification(notification.topic, buttonHandle, buttonText, mainText, 'success');
            })}

            {conflictedAudiences.map((audience) => {
                const buttonHandle = () => goToResolveConflictsPage(audience.id);
                const buttonText = 'Resolve conflicts';
                const mainText = `${audience.name} import has values that conflict with existing values.`;

                return renderNotification(audience.id, buttonHandle, buttonText, mainText, 'warning');
            })}

            {((audiences && audiences.length < 1) || !audiences) && (
                <EmptyPage title="No existing audiences" subtitle="You have not created any audiences" withBackground>
                    <Button
                        onClick={() => history.push(`${path}/new`)}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 24 }}
                    >
                        Create new audience
                    </Button>
                </EmptyPage>
            )}

            {audiences &&
                audiences.map((audience) => {
                    return (
                        <AudienceRow
                            key={audience.id}
                            audience={audience}
                            audiencesPending={audiencesPending}
                            handleRowClick={handleRowClick}
                            handleClick={handleClick}
                        />
                    );
                })}

            <Popper
                open={!!anchorEl && canManageAudience(getAudience(selectedAudienceId, audiences)!, audiencesPending)}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper className={classes.paper} style={{ transformOrigin: 'right top' }} elevation={2}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList style={{ padding: 0 }}>
                                    <MenuItem onClick={handleManageAudience}>Manage audience</MenuItem>
                                    {isAudienceDeletable(selectedAudienceId, campaigns) ? (
                                        <MenuItem onClick={handleDeleteAudienceClick}>Delete audience</MenuItem>
                                    ) : (
                                        <CustomTooltip
                                            title={
                                                <DeleteTooltip
                                                    title={
                                                        'Audience cannot be deleted as it is used in at least one campaign'
                                                    }
                                                    onClick={() => {
                                                        handleClose();
                                                        setShowAudienceUsage(true);
                                                    }}
                                                />
                                            }
                                            placement="right"
                                            arrow={true}
                                            interactive={true}
                                        >
                                            <MenuItem className={classes.disabledMenu}>Delete audience</MenuItem>
                                        </CustomTooltip>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <ConfirmModal
                open={showDeleteAudience}
                title="Delete audience"
                content={`“${selectedAudienceName}” will be deleted forever`}
                textNo="Cancel"
                textYes="Delete"
                onClose={() => {
                    setShowDeleteAudience(false);
                }}
                confirm={handleDeleteAudience}
                destructive
            />
            <AudienceUsageModal
                title={'Audience usage'}
                open={showAudienceUsage && selectedAudienceId !== ''}
                usedEntity={selectedAudienceName}
                usageData={selectedAudienceId && audienceUsageData ? audienceUsageData : []}
                handleClose={() => {
                    setShowAudienceUsage(false);
                }}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTeamId: state.userSlice.dashboardSelected !== 'personal' ? state.userSlice.dashboardSelected : null,
    selectedDashboard: state.userSlice.dashboardSelected,
    teams: state.teamSlice.teams,
    audiences: state.audienceSlice.audiences,
    audiencesPending: state.audienceSlice.audiencesPending,
    user: state.userSlice.user,
    campaigns: state.campaignSlice.campaigns,
});

const mapDispatchToProps = {
    getPersonalAudiences,
    getTeamAudiences,
    updateDashboardSelected,
    updateApiIssue,
    getPersonalCampaigns,
    getTeamCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React, { useEffect, ChangeEvent, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import BaseHeader from 'components/baseHeader';
import {
    Paper,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    Box,
    Button,
    InputAdornment,
    TextField,
    InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EyeIcon from '@material-ui/icons/VisibilityRounded';
import EyeOffIcon from '@material-ui/icons/VisibilityOffRounded';
import colors from 'colors';
import Avatar from 'components/avatar';
import { ToggleSnackbarProps } from 'components/snackBar';
import { getUser, updateUser, User } from 'reducers/userSlice';
import { toggleSnackbar } from 'reducers/appSlice';
import { RootState } from 'reducers';
import { BackendErrorMessagePhrase } from 'types';
import { isPasswordValid, passwordHelperText, isPasswordEqualEmail } from 'utils/password';
import CopyRight from 'components/copyRight';

const useStyles = makeStyles(() => ({
    title: {
        fontSize: 14,
        padding: 32,
        color: colors.darkGrey,
        verticalAlign: 'top',
    },
    row: {
        height: 88,
    },
    nameContainer: {
        display: 'flex',
        marginTop: 16,
        '&>div': {
            width: '313px',
        },
    },
}));

interface AccountSettingsProps {
    getUser: () => void;
    updateUser: (user: User, callback?: (data: { ok: boolean; status: number; message: string }) => void) => void;
    toggleSnackbar: (data: ToggleSnackbarProps) => void;
    user: User;
}

type State = {
    firstName: string;
    lastName: string;
    password: string;
    newPassword: string;
    editName: boolean;
    editPassword: boolean;
    showPassword: boolean;
    showNewPassword: boolean;
    passwordMismatch: boolean;
    passwordReused: boolean;
};

type Action = {
    type: string;
    payload?: string | boolean | { firstName: string; lastName: string };
};

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const PASSWORD = 'password';
const NEW_PASSWORD = 'newPassword';
const SHOW_PASSWORD = 'showPassword';
const SHOW_NEW_PASSWORD = 'showNewPassword';
const PASSWORD_MISMATCH = 'passwordMismatch';
const PASSWORD_REUSED = 'passwordReused';
const INIT_NAME = 'initName';
const CLEAR_PASSWORD = 'clearPassword';
const EDIT_NAME = 'editName';
const EDIT_PASSWORD = 'editPassword';
const UPDATE_PASSWORD_SUCCESS = 'updatePasswordSuccess';

const initialState = {
    firstName: '',
    lastName: '',
    password: '',
    newPassword: '',
    editName: false,
    editPassword: false,
    showPassword: false,
    showNewPassword: false,
    passwordMismatch: false,
    passwordReused: false,
};

function reducer(state: State, action: Action) {
    switch (action.type) {
        case PASSWORD:
            return {
                ...state,
                [action.type]: action.payload as string,
                passwordMismatch: false,
            };
        case FIRST_NAME:
        case LAST_NAME:
        case EDIT_NAME:
        case EDIT_PASSWORD:
        case NEW_PASSWORD:
            return {
                ...state,
                [action.type]: action.payload as string,
                passwordReused: false,
            };
        case PASSWORD_MISMATCH:
        case PASSWORD_REUSED:
        case SHOW_PASSWORD:
        case SHOW_NEW_PASSWORD:
            return { ...state, [action.type]: action.payload };
        case INIT_NAME:
            return { ...state, ...(action.payload as { firstName: string; lastName: string }) };
        case CLEAR_PASSWORD:
            return { ...state, password: '', newPassword: '' };
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                password: '',
                newPassword: '',
                showNewPassword: false,
                showPassword: false,
                editPassword: false,
            };
        default:
            throw new Error();
    }
}

const AccountSettings = ({ getUser, updateUser, user, toggleSnackbar }: AccountSettingsProps) => {
    const classes = useStyles();
    const { firstName, lastName, email } = user || {};
    const [state, dispatch] = useReducer(reducer, initialState);
    const { editName, editPassword, showPassword, showNewPassword, passwordMismatch, passwordReused } = state;
    const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
    const hasNameChanges = () => {
        return state.firstName && state.lastName && (state.firstName !== firstName || state.lastName !== lastName);
    };

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        dispatch({ type: INIT_NAME, payload: { firstName: firstName!, lastName: lastName! } });
    }, [user]);

    const handleSavePasswordClick = () => {
        setShowPasswordError(true);
        if (email && isPasswordValid(state.newPassword) && !isPasswordEqualEmail(state.newPassword, email)) {
            updateUser({ password: state.password, newPassword: state.newPassword }, (data) => {
                if (data.status === 401)
                    dispatch({
                        type: PASSWORD_MISMATCH,
                        payload: true,
                    });
                if (data.message === BackendErrorMessagePhrase.PASSWORD_REUSED) {
                    dispatch({
                        type: PASSWORD_REUSED,
                        payload: true,
                    });
                }
                if (data.ok) {
                    toggleSnackbar({ message: 'Password updated', type: 'success' });
                    dispatch({ type: UPDATE_PASSWORD_SUCCESS });
                }
            });
        }
    };

    return (
        <>
            <div style={{ background: colors.backgroundGrey, height: '100%' }}>
                <BaseHeader title="Account settings" backButton />
                <Paper style={{ maxWidth: 890, marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
                    <Typography style={{ fontWeight: 500, fontSize: 20, padding: '24px 32px' }}>
                        Account settings
                    </Typography>
                    <Divider />
                    <Table style={{ padding: '0 16px', tableLayout: 'fixed' }}>
                        <TableBody>
                            <TableRow className={classes.row} style={{ tableLayout: 'fixed' }}>
                                <TableCell className={classes.title} style={{ width: '17%' }}>
                                    <div>Name</div>
                                </TableCell>
                                {editName ? (
                                    <TableCell colSpan={2} style={{ width: '83%' }}>
                                        <Box className={classes.nameContainer}>
                                            <div style={{ marginRight: 16 }}>
                                                <InputLabel>First name</InputLabel>
                                                <TextField
                                                    variant="outlined"
                                                    value={state.firstName}
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                        dispatch({ type: FIRST_NAME, payload: event.target.value });
                                                    }}
                                                    fullWidth
                                                />
                                            </div>
                                            <div>
                                                <InputLabel>Last name</InputLabel>
                                                <TextField
                                                    variant="outlined"
                                                    value={state.lastName}
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                        dispatch({ type: LAST_NAME, payload: event.target.value });
                                                    }}
                                                    fullWidth
                                                />
                                            </div>
                                        </Box>
                                        <Box display="flex" margin="24px 0 32px">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                style={{ marginRight: 12 }}
                                                onClick={() => {
                                                    dispatch({ type: EDIT_NAME, payload: false });
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!hasNameChanges()}
                                                onClick={() => {
                                                    dispatch({ type: EDIT_NAME, payload: false });
                                                    updateUser(
                                                        { firstName: state.firstName, lastName: state.lastName },
                                                        (data) => {
                                                            if (data.ok) {
                                                                toggleSnackbar({
                                                                    message: 'Name updated',
                                                                    type: 'success',
                                                                });
                                                            }
                                                        },
                                                    );
                                                }}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell style={{ width: '60%' }}>{`${firstName} ${lastName}`}</TableCell>
                                        <TableCell style={{ width: '23%', textAlign: 'right', paddingRight: 34 }}>
                                            {user.isTechPassUser === false && (
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        dispatch({ type: EDIT_NAME, payload: true });
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                            <TableRow className={classes.row}>
                                <TableCell className={classes.title}>Photo</TableCell>
                                <TableCell>
                                    <Avatar firstName={firstName || ''} lastName={lastName || ''} rounded />
                                </TableCell>
                                <TableCell />
                            </TableRow>
                            <TableRow className={classes.row}>
                                <TableCell className={classes.title}>Email</TableCell>
                                <TableCell>{email}</TableCell>
                                <TableCell />
                            </TableRow>
                            {user.isTechPassUser === false && (
                                <TableRow className={classes.row}>
                                    <TableCell className={classes.title} style={{ width: '17%' }}>
                                        Password
                                    </TableCell>
                                    {editPassword ? (
                                        <TableCell colSpan={2} style={{ width: '83%', paddingLeft: 16 }}>
                                            <Box display="flex" style={{ marginTop: 16 }}>
                                                <div style={{ marginRight: 16 }}>
                                                    <InputLabel>Current password</InputLabel>
                                                    <TextField
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={state.password}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                            dispatch({ type: PASSWORD, payload: event.target.value });
                                                        }}
                                                        variant="outlined"
                                                        error={passwordMismatch}
                                                        helperText={passwordMismatch ? 'Incorrect password' : ''}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Button
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() => {
                                                                            dispatch({
                                                                                type: SHOW_PASSWORD,
                                                                                payload: !showPassword,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        style={{ width: 313 }}
                                                    />
                                                </div>
                                                <div>
                                                    <InputLabel>New password</InputLabel>
                                                    <TextField
                                                        variant="outlined"
                                                        type={showNewPassword ? 'text' : 'password'}
                                                        value={state.newPassword}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                            dispatch({
                                                                type: NEW_PASSWORD,
                                                                payload: event.target.value,
                                                            });
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Button
                                                                        aria-label="toggle new password visibility"
                                                                        onClick={() => {
                                                                            dispatch({
                                                                                type: SHOW_NEW_PASSWORD,
                                                                                payload: !showNewPassword,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {showNewPassword ? <EyeIcon /> : <EyeOffIcon />}
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        error={
                                                            (!!state.newPassword &&
                                                                !isPasswordValid(state.newPassword)) ||
                                                            passwordReused ||
                                                            (email !== undefined &&
                                                                showPasswordError &&
                                                                isPasswordEqualEmail(state.newPassword, email))
                                                        }
                                                        helperText={
                                                            (showPasswordError &&
                                                                email &&
                                                                isPasswordEqualEmail(state.newPassword, email) &&
                                                                passwordHelperText.email) ||
                                                            (state.newPassword && !isPasswordValid(state.newPassword)
                                                                ? passwordHelperText.complexity
                                                                : '') ||
                                                            (passwordReused ? passwordHelperText.used : '')
                                                        }
                                                        style={{ width: 313 }}
                                                    />
                                                </div>
                                            </Box>
                                            <Box display="flex" margin="28px 0 32px">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    style={{ marginRight: 12 }}
                                                    onClick={() => {
                                                        dispatch({ type: EDIT_PASSWORD, payload: false });
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!state.password || !isPasswordValid(state.newPassword)}
                                                    onClick={handleSavePasswordClick}
                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell>**********</TableCell>
                                            <TableCell style={{ textAlign: 'right', paddingRight: 34 }}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        dispatch({ type: EDIT_PASSWORD, payload: true });
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
            <CopyRight placement={'postLogin'} />
        </>
    );
};

const mapDispatchToProps = {
    getUser,
    updateUser,
    toggleSnackbar,
};

const mapStateToProps = (state: RootState) => ({
    user: state.userSlice.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);

import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isSignedIn } from 'auth';
import ErrorPage from './errorPage';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { EApiIssue } from 'types';
import { updateApiIssue } from 'reducers/userSlice';

export interface PageNotFoundProps {
    exact?: boolean;
    path?: string | string[];
    apiIssue?: EApiIssue;
    updateApiIssue?: (apiIssue: EApiIssue) => void;
}

export type Error = '404' | '500';

const PageNotFound = ({ exact, path, apiIssue, updateApiIssue, ...rest }: PageNotFoundProps) => {
    const [error, setError] = useState<Error>('404');
    useEffect(() => {
        setError(apiIssue === EApiIssue.SERVER_500 ? '500' : '404');
        if (updateApiIssue) updateApiIssue(EApiIssue.NONE);
    }, []);

    return (
        <Route
            path={path}
            {...rest}
            render={({ location }) =>
                isSignedIn() ? (
                    <ErrorPage error={error} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    apiIssue: state.userSlice.apiIssue,
});

const mapDispatchToProps = { updateApiIssue };

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);

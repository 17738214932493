import React, { ReactNode, useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTracking } from 'react-tracking';

import WithSessionManagement from 'components/sessionManagement';
import { RootState } from 'reducers';
import { isSignedIn } from 'auth';
import { EApiIssue, AppPage } from 'types';
import { updateApiIssue } from 'reducers/userSlice';

import CopyRight from 'components/copyRight';

export interface PrivateRouteProps {
    children?: ReactNode;
    exact?: boolean;
    path?: string | string[];
    page?: AppPage;
    apiIssue?: EApiIssue;
    updateApiIssue?: (apiIssue: EApiIssue) => void;
    isAutoLogout?: boolean;
    isUserLogout?: boolean;
    sessionTimeout?: () => void;
}

const PrivateRoute = ({
    children,
    exact,
    path,
    page,
    apiIssue,
    updateApiIssue,
    isAutoLogout,
    isUserLogout,
    sessionTimeout,
    ...rest
}: PrivateRouteProps) => {
    const [isRedirect, setIsRedirect] = useState<boolean>(false);
    const history = useHistory();

    const { Track } = useTracking({ page: page });

    useEffect(() => {
        let isSubscribed = true;
        if (isAutoLogout || isUserLogout) {
            if ((isAutoLogout || isUserLogout) && updateApiIssue) {
                if (isSubscribed) {
                    updateApiIssue(isAutoLogout ? EApiIssue.SESSION_TIMEOUT : EApiIssue.LOGOUT);
                }
            }
            setIsRedirect(true);
            return () => {
                isSubscribed = false;
            };
        }
    }, [apiIssue, isAutoLogout, isUserLogout]);

    useEffect(() => {
        if (isRedirect) {
            history.push('/signin');
        }
    }, [isRedirect]);

    useEffect(() => {
        if ((apiIssue && apiIssue === EApiIssue.SERVER_500) || apiIssue === EApiIssue.NOT_FOUND_404) {
            history.push('/page-not-found');
        }
    }, [apiIssue]);

    return (
        <Track>
            <Route
                path={path}
                {...rest}
                render={({ location }) =>
                    isSignedIn() ? (
                        <>
                            {sessionTimeout && sessionTimeout()} {children}
                        </>
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/signin',
                                state: { from: location },
                            }}
                        />
                    )
                }
            />
        </Track>
    );
};

const mapStateToProps = (state: RootState) => ({
    apiIssue: state.userSlice.apiIssue,
});

const mapDispatchToProps = { updateApiIssue };

export default connect(mapStateToProps, mapDispatchToProps)(WithSessionManagement(PrivateRoute));

import {
    onDragNode,
    onDragNodeStop,
    onDragCanvas,
    onDragCanvasStop,
    onLinkStart,
    onLinkMove,
    onLinkComplete,
    onLinkCancel,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onLinkClick,
    onCanvasClick,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onDeleteKey,
    onNodeClick,
    onNodeSizeChange,
    onPortPositionChange,
    onCanvasDrop,
    // draft
    onDragNodeDraft,
    onDragNodeStopDraft,
    onDragCanvasDraft,
    onDragCanvasStopDraft,
    onLinkStartDraft,
    onLinkMoveDraft,
    onLinkCompleteDraft,
    onLinkCancelDraft,
    onLinkMouseEnterDraft,
    onLinkMouseLeaveDraft,
    onLinkClickDraft,
    onCanvasClickDraft,
    onNodeMouseEnterDraft,
    onNodeMouseLeaveDraft,
    onDeleteKeyDraft,
    onNodeClickDraft,
    onNodeSizeChangeDraft,
    onPortPositionChangeDraft,
    onCanvasDropDraft,
} from 'features/campaigns/campaignSlice';

const callbacks = {
    onDragNode,
    onDragNodeStop,
    onDragCanvas,
    onDragCanvasStop,
    onLinkStart,
    onLinkMove,
    onLinkComplete,
    onLinkCancel,
    onLinkMouseEnter,
    onLinkMouseLeave,
    onLinkClick,
    onCanvasClick,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onDeleteKey,
    onNodeClick,
    onNodeSizeChange,
    onPortPositionChange,
    onCanvasDrop,
    // draft
    onDragNodeDraft,
    onDragNodeStopDraft,
    onDragCanvasDraft,
    onDragCanvasStopDraft,
    onLinkStartDraft,
    onLinkMoveDraft,
    onLinkCompleteDraft,
    onLinkCancelDraft,
    onLinkMouseEnterDraft,
    onLinkMouseLeaveDraft,
    onLinkClickDraft,
    onCanvasClickDraft,
    onNodeMouseEnterDraft,
    onNodeMouseLeaveDraft,
    onDeleteKeyDraft,
    onNodeClickDraft,
    onNodeSizeChangeDraft,
    onPortPositionChangeDraft,
    onCanvasDropDraft,
};

export default callbacks;

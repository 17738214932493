import React, { ChangeEvent } from 'react';
import {
    Paper,
    Box,
    Typography,
    Divider,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    FormHelperText,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from 'colors';
import { CampaignStateType } from 'types';
import { setCampaignAttribute, DefaultDraftCampaign } from '../campaignSlice';
import { RootState } from 'reducers';
import { isEmailValid, isReplyToEmailValid } from 'utils/email';
import { SENDER_EMAIL_DOMAIN } from 'common/constants';

const mapDispatchToProps = { setCampaignAttribute };

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: colors.darkGrey,
        textAlign: 'left',
        fontSize: 14,
        lineHeight: '21px',
    },
    input: {
        minWidth: 365,
    },
    tableContainer: {
        border: 0,
        boxShadow: 'none !important',
        '& th, td': {
            padding: '32px',
        },
        '& tbody tr:last-child th, & tbody tr:last-child td': {
            border: 0,
        },
    },
}));

type SettingsProps = {
    draftCampaign: DefaultDraftCampaign;
    setCampaignAttribute: ({ name, val }: { name: string; val: any }) => void;
};

const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
        case ' ':
            event.preventDefault();
            event.stopPropagation();
            return false;
        default:
            return true;
    }
};

const Settings = ({ draftCampaign, setCampaignAttribute }: SettingsProps) => {
    const classes = useStyles();
    const unEditable =
        draftCampaign.state === CampaignStateType.INACTIVE ||
        draftCampaign.state === CampaignStateType.ACTIVE ||
        draftCampaign.state === CampaignStateType.SCHEDULED;
    const emailUsername = draftCampaign.senderEmail ? draftCampaign.senderEmail.replace(SENDER_EMAIL_DOMAIN, '') : '';
    const emailError = !!draftCampaign.senderEmail && !isEmailValid(draftCampaign.senderEmail);
    const replyToEmail = draftCampaign.replyToEmail ? draftCampaign.replyToEmail : '';
    const replyToEmailError = !!replyToEmail && !isReplyToEmailValid(replyToEmail);
    const replyToEmailFormatError = !!replyToEmail && !isEmailValid(replyToEmail);
    return (
        <Paper style={{ margin: '40px 12%' }}>
            <Box display="flex" px={4} py={3}>
                <Typography variant="h2">Campaign settings</Typography>
            </Box>
            <Divider />
            <TableContainer classes={{ root: classes.tableContainer }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ width: '24%' }} className={classes.title}>
                                <div style={emailError ? { marginBottom: 20 } : { margin: 0 }}>Sender email</div>
                            </TableCell>
                            <TableCell style={{ display: 'flex' }}>
                                {unEditable ? (
                                    <span>
                                        {emailUsername}
                                        {SENDER_EMAIL_DOMAIN}
                                    </span>
                                ) : (
                                    <Box>
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                className={classes.input}
                                                variant="outlined"
                                                name="name"
                                                type="text"
                                                value={emailUsername}
                                                onKeyDown={handleKeyDown}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                    setCampaignAttribute({
                                                        name: 'senderEmail',
                                                        val:
                                                            event.target.value !== ''
                                                                ? (
                                                                      `${event.target.value}${SENDER_EMAIL_DOMAIN}` ||
                                                                      ''
                                                                  ).replace(/\s/g, '')
                                                                : null,
                                                    });
                                                }}
                                                placeholder="Info"
                                                error={emailError}
                                                autoComplete="off"
                                            />
                                            <span style={{ marginLeft: 12 }}>{SENDER_EMAIL_DOMAIN}</span>
                                        </Box>
                                        {emailError && (
                                            <FormHelperText error>
                                                Email address entered is not in the correct format
                                            </FormHelperText>
                                        )}
                                    </Box>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ width: '24%' }} className={classes.title}>
                                <div style={replyToEmailError ? { marginBottom: 20 } : { margin: 0 }}>
                                    Reply-to email
                                </div>
                            </TableCell>
                            <TableCell style={{ display: 'flex' }}>
                                {unEditable ? (
                                    <span>{replyToEmail}</span>
                                ) : (
                                    <Box>
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                className={classes.input}
                                                variant="outlined"
                                                name="name"
                                                type="text"
                                                value={replyToEmail}
                                                onKeyDown={handleKeyDown}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                    setCampaignAttribute({
                                                        name: 'replyToEmail',
                                                        val: (`${event.target.value}` || '').replace(/\s/g, ''),
                                                    });
                                                }}
                                                placeholder="support@notify.gov.sg (optional)"
                                                error={replyToEmailError || replyToEmailFormatError}
                                                autoComplete="off"
                                            />
                                        </Box>
                                        {replyToEmailFormatError && (
                                            <FormHelperText error>
                                                Email address entered is not in the correct format
                                            </FormHelperText>
                                        )}
                                        {replyToEmailError && !replyToEmailFormatError && (
                                            <FormHelperText error>
                                                Email address is not a valid government email address
                                            </FormHelperText>
                                        )}
                                    </Box>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const mapStateToProps = (state: RootState) => ({
    draftCampaign: state.campaignSlice.draftCampaign,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
